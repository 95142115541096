<script setup lang="ts">
  import type { ComputedRef } from 'vue';
  import { inject, ref, type Ref } from 'vue';

  const langs = [
    {
      value: 'ar',
      name: 'العربية',
    },
    {
      value: 'en',
      name: 'English',
    },
  ];
  const locale: Ref<string> = inject('locale', ref('en'));
  const isLoggedIn: Ref<boolean> = inject('isLoggedIn', ref(false));
  const langText: ComputedRef<string> | undefined = inject('langText');

  const changeLocale: (locale: string) => void = inject(
    'changeLocale',
    () => undefined
  );
</script>

<template>
  <v-menu transition="slide-y-transition" offset="12 12">
    <template v-slot:activator="{ props }">
      <v-list-item
        :prepend-icon="isLoggedIn ? '' : 'mdi-web'"
        :title="langText"
        v-bind="props"
        base-color="gray"
        aria-label="language"
        role="menuitem"
      >
        <template v-slot:append>
          <v-chip size="small" label color="primary" inline>
            {{ locale?.toUpperCase() }}
          </v-chip>
        </template>
      </v-list-item>
    </template>
    <v-card width="130px" class="rounded-lg">
      <v-list-item
        v-for="item in langs"
        :key="item.value"
        class="text-caption cursor-pointer my-2 rounded-lg mx-2"
        :active="locale === item.value"
        @click="changeLocale(item.value)"
        :append-icon="locale === item.value ? 'mdi-check' : ''"
        density="compact"
        :nav="true"
        color="primary"
      >
        {{ item.name }}
      </v-list-item>
    </v-card>
  </v-menu>
</template>
