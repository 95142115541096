import { CourseSubsection } from './course_subsection';
import { User } from './user';
import { Course } from './course';

export class StudentFinishedCourseSubsections {
  id: number;

  courseSubsectionId: number;

  courseSubsection: CourseSubsection;

  userId: number;

  user: User;

  courseId?: number;

  course?: Course;

  createdAt: Date;

  updatedAt: Date;
}
