import { Voucher } from './voucher';

export class VoucherRestrictionByOrganizationOrUser {
  id: number;

  voucherCode?: string;

  voucher?: Voucher;

  isRestrictedToOrganization: boolean;

  isRestrictedToUser: boolean;

  allowedIds?: object;
}
