import { User } from './user';

export class UserResetPasswordAttempt {
  id: number;

  userid: number;

  user: User;

  createdAt: Date;

  updatedAt: Date;

  isSuccessful: boolean;
}
