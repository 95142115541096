import { User } from './user';

export class ProviderBookedSlot {
  id: number;

  from: Date;

  to: Date;

  isFreeSession: boolean;

  isCancelledByProvider: boolean;

  isCancelledByUser: boolean;

  isUsingCredit: boolean;

  isPaid: boolean;

  userId: number;

  user: User;

  providerId: number;

  provider: User;

  createdAt: Date;

  updatedAt?: Date;
}
