export enum EnumOrganizationConfig {
  TWO_FACTOR_ENABLED = 'two-factor-enabled',
  REGISTRATION_REQUIRES_APPROVAL = 'registration-requires-approval',
  MARKETPLACE_ENABLED = 'marketplace-enabled',
  SELF_ENROLLMENT_ENABLED = 'self-enrollment-enabled',
  ALLOW_ANONYMOUS_COURSE_VIEW = 'allow-anonymous-course-view',
  ALLOW_ANONYMOUS_EVENT_VIEW = 'allow-anonymous-event-view',
  CERTIFICATES_ENABLED = 'certificates-enabled',
  PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_ADMINS = 'passing-criteria-configuration-enabled-for-admins',
  PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_INSTRUCTORS = 'passing-criteria-configuration-enabled-for-instructors',
  SELF_REGISTRATION_ENABLED = 'self-registration-enabled',
}
export enum EnumOrganizationTerminology {
  AUTH_IMAGE = 'auth_image',
  EVENT_HEADER_IMAGE = 'event_header_image',
  COURSE_HEADER_IMAGE = 'course_header_image',
  COURSE_BANNER_TITLE = 'course_banner_title',
  COURSE_BANNER_SUBTITLE = 'course_banner_subtitle',
  EXTERNAL_COURSE_HEADER_IMAGE = 'external_course_header_image',
  EXTERNAL_COURSE_BANNER_TITLE = 'external_course_banner_title',
  EXTERNAL_COURSE_BANNER_SUBTITLE = 'external_course_banner_subtitle',
  EVENT_BANNER_TITLE = 'event_banner_title',
  EVENT_BANNER_SUBTITLE = 'event_banner_subtitle',
  BLENDED = 'blended',
  COURSE = 'course',
  DASHBOARD = 'dashboard',
  INSTRUCTOR = 'instructor',
  MODULE = 'module',
  STUDENT = 'student',
  LOGIN_ORGANIZATION_LOGO = 'login_organization_logo',
  ENROLLMENT_TERMS_AND_CONDITIONS = 'enrollment_term_and_conditions',
}

export enum EnumOrganizationRegistrationStrategy {
  OFF = 'off',
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}
