import { Course } from './course';
import { CourseConfirmationCheckboxTranslation } from './course_confirmation_checkbox_translation';
import { UserCourseConfirmationCheckbox } from './user_course_confirmation_checkbox';

export class CourseConfirmationCheckbox {
  id: number;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;

  translations: CourseConfirmationCheckboxTranslation[];

  userResponses: UserCourseConfirmationCheckbox[];
}
