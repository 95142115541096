import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const miscellaneousRoutes: Array<RouteRecordRaw> = [
  {
    name: EnumRoutes.misc.notifications.name,
    path: EnumRoutes.misc.notifications.path,
    component: () =>
      import('@/components/header/notifications/NotificationsPage.vue'),
    meta: EnumRoutes.misc.notifications.meta,
  },
  {
    name: EnumRoutes.misc.notFound.name,
    path: EnumRoutes.misc.notFound.path,
    component: () => import('@/views/misc/NotFound.vue'),
    meta: EnumRoutes.misc.notFound.meta,
  },
];
