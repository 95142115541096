import { axiosI } from '@/plugins';
import { organizationsApiHandler } from '@/postman-to-ts/apiHandler/organizations';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import type { FormField } from '@/helpers/interfaces/register-form.interface';
import type { SortingFields } from '@/helpers/interfaces/register-form.interface';

const registrationForm = createQueryKeys('registrationForm', {
  getRegisterationForm: () => ({
    queryKey: ['RegisterForm'],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        organizationsApiHandler.getOrganizationsRegistrationFormFields;
      return (
        await axiosI.get<FormField[]>(path(), {
          method,
        })
      ).data;
    },
  }),
  getRegisterationFormSorting: () => ({
    queryKey: ['RegisterFormSort'],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        organizationsApiHandler.getRegistrationFormFieldsSort;
      return (
        await axiosI.get<SortingFields[]>(path(), {
          method,
        })
      ).data;
    },
  }),
});

export const registrationsQueryKeys = mergeQueryKeys(registrationForm);
