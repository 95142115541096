import { User } from './user';

export class UserRefreshToken {
  id: number;

  userId: number;

  user: User;

  hashedToken: string;

  sessionId: string;

  createdAt: Date;

  updatedAt: Date;
}
