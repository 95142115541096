<script setup lang="ts">
  import { useSnackbarStore } from '@/stores/snackbar.store';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const store = useSnackbarStore();
  const { message, type } = storeToRefs(store);
  const { clearMessage } = store;
  const showSnackbar = computed({
    get: () => message.value?.length > 0,
    set: (value) => {
      if (!value) {
        clearMessage();
      }
    },
  });

  const color = computed(() => {
    switch (type.value) {
      case 'success':
        return 'green';
      case 'error':
        return 'red';
      case 'warning':
        return 'orange';
      default:
        return 'blue';
    }
  });
</script>

<template>
  <v-snackbar v-model="showSnackbar" :color="color">
    {{ message }}
    <template v-slot:actions>
      <v-btn @click="showSnackbar = false" icon="mdi-close"> </v-btn>
    </template>
  </v-snackbar>
</template>
