import { Organization } from './organization';
import { OrganizationFieldSort } from './organization_field_sort';

export class OrganizationFieldGroup {
  id: number;

  name: string;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;

  fields: OrganizationFieldSort[];
}
