import { ChecklistItem } from './checklist_item';
import { User } from './user';
import { DueDateType } from './due_date_type';

export class ChecklistItemSettings {
  id: number;

  checklistItemId: number;

  checklistItem: ChecklistItem;

  link?: string;

  completionType: number;

  points: number;

  notifyAdminOnCompletion: boolean;

  adminId?: number;

  admin?: User;

  dueDateTypeId?: number;

  dueDateType?: DueDateType;

  dueDateDays?: number;

  fixedDueDate?: Date;

  linkedEntityType?: number;

  linkedEntityId?: number;

  createdAt: Date;

  updatedAt?: Date;
}
