import { Course } from './course';
import { User } from './user';

export class StudentFinishedCourse {
  id: number;

  courseId: number;

  course: Course;

  userId: number;

  user: User;

  creditHours: number;

  finalGrade: number;

  finalAttendance: number;

  createdAt: Date;

  updatedAt: Date;
}
