import { User } from './user';
import { ZoomMeeting } from './zoom_meeting';
import { ZoomWebinar } from './zoom_webinar';

export class ZoomOAuthToken {
  id: number;

  userId: number;

  user: User;

  accessToken: string;

  refreshToken: string;

  zoomUserId?: string;

  createdAt: Date;

  updatedAt?: Date;

  zoomMeetings: ZoomMeeting[];

  zoomWebinars: ZoomWebinar[];
}
