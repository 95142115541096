import { ExamQuestion } from './exam_question';
import { SubsectionExam } from './subsection_exam';
import { ExamLearningOutcome } from './exam_learning_outcome';

export class Exam {
  id: number;

  name: string;

  description?: string;

  createdAt: Date;

  updatedAt?: Date;

  examQuestions: ExamQuestion[];

  subsectionExam: SubsectionExam[];

  learningOutcomes: ExamLearningOutcome[];
}
