import { ExamInstance } from './exam_instance';
import { ExamAttemptEvent } from './exam_attempt_event';
import { ExamAttemptQuestion } from './exam_attempt_question';

export class ExamAttempt {
  id: number;

  timeLimitInMinutes?: number;

  isStarted: boolean;

  startedAt?: Date;

  isFinished?: boolean;

  finishedAt?: Date;

  attemptNumber: number = 1;

  totalScore: number;

  score: number;

  passingScorePercentage?: number = 50;

  isPassed: boolean;

  retakeInterval: number;

  isReviewed?: boolean;

  isApproved?: boolean;

  isRejected?: boolean;

  isCancelled?: boolean;

  isInstructorAttempt?: boolean;

  examInstanceId: number;

  examInstance: ExamInstance;

  events: ExamAttemptEvent[];

  examAttemptQuestions: ExamAttemptQuestion[];

  createdAt: Date;

  updatedAt?: Date;
}
