<script setup lang="ts">
  import { inject, ref, type ComputedRef, type Ref } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  const cartRoute: ComputedRef<RouteLocationRaw> | undefined =
    inject('cartRoute');
  const isLTR: Ref<boolean> = inject('isLTR', ref(true));
  const cartCount: Ref<number> = inject('cartCount', ref(0));
</script>

<template>
  <v-btn
    color="text"
    :to="cartRoute"
    aria-label="cart"
    class="text-none px-2"
    stacked
    min-width="0"
    density="compact"
  >
    <v-badge
      :content="cartCount || ''"
      :color="cartCount && cartCount > 0 ? 'red' : 'transparent'"
    >
      <v-icon
        icon="mdi-cart-outline"
        :class="isLTR ? '' : 'flip-icon'"
        color="primary"
      ></v-icon>
    </v-badge>
  </v-btn>
</template>
<style lang="scss">
  .flip-icon {
    transform: rotateY(180deg);
  }
</style>
