import { Rubric } from './rubric';
import { GroupCourseGrade } from './group_course_grade';
import { User } from './user';

export class GroupCourseGradeRubricAssessment {
  id: number;

  rubricId: number;

  rubric: Rubric;

  groupCourseGradeId: number;

  groupCourseGrade: GroupCourseGrade;

  instructorId: number;

  instructor: User;

  answers: string;

  finalGrade: number;

  createdAt: Date;

  updatedAt?: Date;
}
