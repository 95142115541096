import { Checklist } from './checklist';
import { User } from './user';

export class ChecklistEnrollment {
  id: number;

  checklistId: number;

  checklist: Checklist;

  studentId: number;

  student: User;

  createdAt: Date;

  updatedAt?: Date;
}
