<script setup lang="ts">
  import { inject, type ComputedRef } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  const favRoute: ComputedRef<RouteLocationRaw> | undefined =
    inject('favRoute');
</script>

<template>
  <v-btn
    :to="favRoute"
    aria-label="favorites page"
    class="text-none px-2"
    stacked
    color="text"
    min-width="0"
    density="compact"
  >
    <v-icon icon="mdi-heart-outline" color="primary"></v-icon>
  </v-btn>
</template>
