import { Organization } from './organization';
import { TaxClass } from './tax_class';

export class OrganizationTaxClass {
  id: number;

  organizationId: number;

  organization: Organization;

  taxClassId: number;

  taxClass: TaxClass;

  percentage: number;

  createdAt: Date;

  updatedAt?: Date;
}
