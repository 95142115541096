import { Course } from './course';
import { Language } from '../exported_type_enums/exported_type_enums';

export class CourseTranslation {
  id: number;

  name: string;

  normalizedName?: string;

  description?: string;

  welcomeMessage?: string;

  language: Language = Language.ENGLISH;

  courseId: number;

  course: Course;
}
