import { Organization } from './organization';
import { FontFamily } from './font_family';

export class OrganizationFontFamily {
  id: number;

  isDefault: boolean;

  organizationId: number;

  organization: Organization;

  fontFamilyId: number;

  fontFamily: FontFamily;

  language: string = 'ar';

  createdAt: Date;

  updatedAt?: Date;
}
