import type { CourseWithModifiedInstructors } from '@/helpers/interfaces/course.interface';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';
interface CoursesInterface {
  [key: string]: CourseWithModifiedInstructors;
}
interface LoadingCoursesInterface {
  [key: string]: boolean;
}
interface FetchingCoursesInterface {
  [key: string]: boolean;
}
export const useCoursesStore = defineStore('CoursesStore', () => {
  const courses: Ref<CoursesInterface> = ref({});
  const isFetchingCourses: Ref<FetchingCoursesInterface> = ref({});
  const isLoadingCourses: Ref<LoadingCoursesInterface> = ref({});
  function setCourses(newCourses: CourseWithModifiedInstructors, id: string) {
    courses.value[id] = newCourses;
  }
  function getCourse(id: string) {
    return courses.value[id];
  }
  function setIsFetchingCourses(id: string, value: boolean) {
    isFetchingCourses.value[id] = value;
  }
  function getIsFetchingCourses(id: string) {
    return isFetchingCourses.value[id];
  }
  function setIsLoadingCourses(id: string, value: boolean) {
    isLoadingCourses.value[id] = value;
  }
  function getIsLoadingCourses(id: string) {
    return isLoadingCourses.value[id];
  }

  return {
    courses,
    setCourses,
    getCourse,
    isFetchingCourses,
    setIsFetchingCourses,
    getIsFetchingCourses,
    isLoadingCourses,
    setIsLoadingCourses,
    getIsLoadingCourses,
  };
});
