import { EnumRoutes } from '@/helpers/enums/routes.enum';
import { type RouteRecordRaw } from 'vue-router';

export const externalCoursesRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.externalCourses.root.path,
    children: [
      {
        path: '',
        name: EnumRoutes.externalCourses.root.name,
        component: () => import('@/views/lms/ExternalCoursesLayout.vue'),
        meta: EnumRoutes.externalCourses.root.meta,
        children: [
          {
            name: EnumRoutes.externalCourses.allExternalCourses.name,
            path: EnumRoutes.externalCourses.allExternalCourses.path,
            component: () =>
              import(
                '@/views/lms/courses/external-courses/ExternalCourses.vue'
              ),
            meta: EnumRoutes.externalCourses.allExternalCourses.meta,
          },
          {
            name: EnumRoutes.externalCourses.myExternalCourses.name,
            path: EnumRoutes.externalCourses.myExternalCourses.path,
            component: () =>
              import(
                '@/views/lms/courses/external-courses/UserExternalCourses.vue'
              ),
            meta: EnumRoutes.externalCourses.myExternalCourses.meta,
          },
        ],
      },
      {
        name: EnumRoutes.externalCourses.externalCourseOverview.name,
        path: EnumRoutes.externalCourses.externalCourseOverview.path,
        component: () =>
          import(
            '@/views/lms/courses/external-courses/ExternalCourseOverview.vue'
          ),
        meta: EnumRoutes.externalCourses.externalCourseOverview.meta,
      },
      // {
      //   name: EnumRoutes.externalCourses.externalCoursePage.name,
      //   path: EnumRoutes.externalCourses.externalCoursePage.path,
      //   component: () =>
      //     import(
      //       '@/views/lms/courses/external-courses/ExternalCourseContent.vue'
      //     ),
      //   meta: EnumRoutes.externalCourses.externalCoursePage.meta,
      // },
    ],
  },
];
