import { Organization } from './organization';
import { CourseOrganizationLearningGroup } from './course_organization_learning_group';
import { LearningGroupStudent } from './learning_group_student';
import { ChecklistLearningGroup } from './checklist_learning_group';

export class LearningGroup {
  id: number;

  name: string;

  organizationId?: number;

  organization?: Organization;

  parentId?: number;

  parent?: LearningGroup;

  createdAt: Date;

  updatedAt?: Date;

  children: LearningGroup[];

  coursesWithOrganizations: CourseOrganizationLearningGroup[];

  students: LearningGroupStudent[];

  checklists: ChecklistLearningGroup[];
}
