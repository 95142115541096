import { Course } from './course';

export class CourseReminderEmail {
  id: number;

  courseId: number;

  course: Course;

  sentAt?: Date;

  createdAt: Date;

  updatedAt?: Date;
}
