import { PurchasedItemType } from './purchased_item_type';
import { OrgRefundRestriction } from './org_refund_restriction';
import {
  LearningTypes,
  CoachTitle,
} from '../exported_type_enums/exported_type_enums';

export class RefundRestriction {
  id: number;

  name: string;

  purchasedItemType: PurchasedItemType;

  purchasedItemTypeSlug: string;

  learningType?: LearningTypes;

  coachTitle?: CoachTitle;

  orgRefundRestrictions: OrgRefundRestriction[];

  createdAt: Date;

  updatedAt: Date;
}
