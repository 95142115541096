export enum EnumNotificationType {
  GROUP = '/groups',
  EVENT = '/events',
  ANNOUNCEMENT = '/announcement',
  CERTIFICATE = '/certificate',
  CHECKLIST = '/checklist',
  DISCUSSION_BOARD = '/discussionBoard',
  GRADES = '/grades',
  COURSE = '/courses',
  IMPORTEDCOURSE = '/importedCourse',
  PLAGIARISM = '/plagiarism',
  APPROVALCOURSE = '/approvalCourse',
  POST = '/post',
}
