import { scheduleQueryKeys } from './dashboard.querykey';
import { integrationsQueryKeys } from './integrations.querykey';
import { lmsQueryKeys } from './lms.querykey';
import { registrationsQueryKeys } from './registerForm.querykey';
import { socialQueryKeys } from './social.querykey';
import { externalCoursesQueryKeys } from './external.courses.querykey';
export const queryKeys = {
  lms: lmsQueryKeys,
  social: socialQueryKeys,
  dashboard: scheduleQueryKeys,
  integrations: integrationsQueryKeys,
  register: registrationsQueryKeys,
  externalCourses: externalCoursesQueryKeys,
};
