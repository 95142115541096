import { CoachPositions } from './coach_positions';

export class CoachingPositions {
  id: number;

  position: string;

  createdAt: Date;

  updatedAt: Date;

  coaches: CoachPositions[];
}
