import { User } from './user';
import { FormField } from './form_field';

export class UserFormFieldData {
  id: number;

  userId: number;

  user: User;

  formFieldId: number;

  formField: FormField;

  value: object;

  createdAt: Date;

  updatedAt?: Date;
}
