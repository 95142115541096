import { axiosI } from '@/plugins';
import type { ExternalCourse } from '@/submodules/generated_types/types/external_course';
import { lMSApiHandler } from '@/postman-to-ts/apiHandler/lMS';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import type { Paginated } from '@/helpers/interfaces/paginated.interface';
import { PrismaModel } from '@/submodules/generated_types/types';
import ExternalCourseEnrollment = PrismaModel.ExternalCourseEnrollment;

const externalCourses = createQueryKeys('externalCourses', {
  getExternalSingleCourses: (externalCourseId: number) => ({
    queryKey: ['SingleExternalCourse', externalCourseId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.externalCourses.student.getSingleCourseForStudent;

      const { data: studentData } = await axiosI<Partial<ExternalCourse>>(
        path({ externalCourseId }),
        {
          method,
        }
      );
      let dataProgress = null;
      if (studentData.enrollees?.length && studentData.enrollees?.[0]?.id) {
        const { path: pathProgress, method: methodProgress } =
          lMSApiHandler.externalCourses.controlBase.learnerProgress
            .learnerProgressForCourse;
        const { data } = await axiosI(
          pathProgress({
            externalCourseEnrollmentId: +studentData.enrollees?.[0]?.id,
          }),
          {
            method: methodProgress,
          }
        );
        dataProgress = data;
      }
      return {
        studentData: studentData,
        dataProgress,
      };
    },
  }),

  getUserExternalCourses: () => ({
    queryKey: ['GetUserExternalCourses'],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.externalCourses.student.enrolledCourses;
      return (
        await axiosI.get<Paginated<ExternalCourseEnrollment>>(path(), {
          method,
        })
      ).data;
    },
  }),

  getCornellLunchCourse: (externalCourseId: number, isCornell: boolean) => ({
    queryKey: ['GetCornellLunchCourse', externalCourseId],
    keepPreviousData: true,
    queryFn: async () => {
      const { path, method } =
        lMSApiHandler.externalCourses.student.launchCornellCourse;
      if (isCornell) {
        return (
          await axiosI.get(path({ externalCourseId }), {
            method,
          })
        ).data;
      } else {
        return [];
      }
    },
  }),
});

export const externalCoursesQueryKeys = mergeQueryKeys(externalCourses);
