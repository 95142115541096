import { OrganizationColor } from './organization_color';

export class ColorKey {
  id: number;

  name: string;

  key: string;

  organizationColors: OrganizationColor[];

  createdAt: Date;

  updatedAt?: Date;
}
