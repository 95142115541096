import { SocialEvent } from './social_event';
import { User } from './user';
import { SocialGroup } from './social_group';
import { PostMedia } from './post_media';
import { PostLikes } from './post_likes';
import { PostComment } from './post_comment';
import { PostSaves } from './post_saves';

export class Post {
  id: number;

  content?: string;

  isReadOnly: boolean;

  createdAt: Date;

  updatedAt: Date;

  eventId?: number;

  event?: SocialEvent;

  userId: number;

  createdBy: User;

  groupId: number;

  group: SocialGroup;

  isPinned: boolean;

  pinnedById?: number;

  pinnedBy?: User;

  pinnedAt: Date;

  media: PostMedia[];

  likes: PostLikes[];

  comments: PostComment[];

  saves: PostSaves[];
}
