import { User } from './user';
import { OrganizationAnnouncement } from './organization_announcement';

export class OrganizationAnnouncementDismissedUser {
  id: number;

  userId: number;

  user: User;

  organizationAnnouncementId: number;

  organizationAnnouncement: OrganizationAnnouncement;

  createdAt: Date;

  updatedAt?: Date;
}
