import { SocialEvent } from './social_event';
import { User } from './user';

export class SocialEventSeenBy {
  id: number;

  eventId: number;

  userId: number;

  createdAt: Date;

  event: SocialEvent;

  user: User;
}
