import { Organization } from './organization';
import { Media } from './media';
import { ArticleTag } from './article_tag';

export class Article {
  id: number;

  title: string;

  normalizedTitle?: string;

  description?: string;

  body: string;

  organizationId: number;

  organization: Organization;

  coverImageId?: number;

  coverImage?: Media;

  tags: ArticleTag[];

  createdAt: Date;

  updatedAt?: Date;
}
