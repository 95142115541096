import LandingLayOut from '@/layout/AuthLayout.vue';
import type { RouteRecordRaw } from 'vue-router';
import { EnumRoutes } from '@/helpers/enums/routes.enum';


export const landingPageRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Main Layout',
    component: LandingLayOut,
    redirect:
      import.meta.env.VITE_ALLOW_LANDING === 'false'
        ? EnumRoutes.lms.root.path
        : EnumRoutes.landing.root.path,
    meta: EnumRoutes.landing.root.meta,
    children: [
      {
        path: EnumRoutes.landing.root.path,
        name: EnumRoutes.landing.root.name,
        component: () => import('@/views/landing-page/HomePage.vue'),
      },
      {
        path: EnumRoutes.landing.leaderShipDevelopment.path,
        name: EnumRoutes.landing.leaderShipDevelopment.name,
        component: () => import('@/views/landing-page/service/LeaderShip.vue'),
        meta: EnumRoutes.landing.leaderShipDevelopment.meta,
      },
      {
        path: EnumRoutes.landing.executiveCoach.path,
        name: EnumRoutes.landing.executiveCoach.name,
        component: () => import('@/views/landing-page/service/Executive.vue'),
        meta: EnumRoutes.landing.executiveCoach.meta,
      },
      {
        path: EnumRoutes.landing.professional.path,
        name: EnumRoutes.landing.professional.name,
        component: () =>
          import('@/views/landing-page/service/Professional.vue'),
        meta: EnumRoutes.landing.professional.meta,
      },
      {
        path: EnumRoutes.landing.webinars.path,
        name: EnumRoutes.landing.webinars.name,
        component: () => import('@/views/landing-page/service/Webinars.vue'),
        meta: EnumRoutes.landing.webinars.meta,
      },
      {
        path: EnumRoutes.landing.about.path,
        name: EnumRoutes.landing.about.name,
        component: () => import('@/views/about-us/AboutUs.vue'),
        meta: EnumRoutes.landing.about.meta,
      },
      {
        path: EnumRoutes.landing.privacyPolicy.path,
        name: EnumRoutes.landing.privacyPolicy.name,
        component: () => import('@/views/landing-page/PrivacyPolicy.vue'),
        meta: EnumRoutes.landing.privacyPolicy.meta,
      },
      {
        name: EnumRoutes.landing.educationEthics.name,
        path: EnumRoutes.landing.educationEthics.path,
        component: () => import('@/views/landing-page/EducationEthics.vue'),
        meta: EnumRoutes.landing.educationEthics.meta,
      },
      {
        name: EnumRoutes.landing.termsOfUse.name,
        path: EnumRoutes.landing.termsOfUse.path,
        component: () => import('@/views/landing-page/TermOfUse.vue'),
        meta: EnumRoutes.landing.termsOfUse.meta,
      },
      {
        path: EnumRoutes.landing.contactUs.path,
        name: EnumRoutes.landing.contactUs.name,
        component: () => import('@/views/landing-page/ContactUs.vue'),
        meta: EnumRoutes.landing.contactUs.meta,
      },
      {
        name: EnumRoutes.landing.technicalSupport.name,
        path: EnumRoutes.landing.technicalSupport.path,
        component: () => import('@/views/landing-page/TechnicalSupport.vue'),
        meta: EnumRoutes.landing.technicalSupport.meta,
      },
    ],
  },
];
