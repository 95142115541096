import { User } from './user';
import { CourseAttendance } from './course_attendance';

export class StudentCourseAttendance {
  id: number;

  studentId: number;

  student: User;

  courseAttendanceId: number;

  courseAttendance: CourseAttendance;

  attendedAt?: Date;

  checkOutAt?: Date;

  createdAt: Date;

  updatedAt: Date;
}
