import { ChecklistItem } from './checklist_item';
import { User } from './user';

export class ChecklistItemStudentCompletion {
  id: number;

  checklistItemId: number;

  checklistItem: ChecklistItem;

  studentId: number;

  student: User;

  completedAt?: Date;

  createdAt: Date;

  updatedAt?: Date;
}
