import { Organization } from './organization';
import { OrganizationRegistrationFormField } from './organization_registration_form_field';
import { OrganizationFieldGroup } from './organization_field_group';

export class OrganizationFieldSort {
  id: number;

  organizationId: number;

  organization: Organization;

  staticFieldSlug?: string;

  fieldId?: number;

  field?: OrganizationRegistrationFormField;

  organizationFieldGroupId?: number;

  organizationFieldGroup?: OrganizationFieldGroup;

  weight: number;

  createdAt: Date;

  updatedAt?: Date;
}
