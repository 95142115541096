import { Question } from './question';
import { QuestionStemMedia } from './question_stem_media';

export class QuestionStem {
  id: number;

  body: string;

  questionId: number;

  question: Question;

  createdAt: Date;

  updatedAt?: Date;

  questionStemMedia: QuestionStemMedia[];
}
