import { SocialEvent } from './social_event';
import { Organization } from './organization';

export class SocialEventTargetOrganization {
  id: number;

  price: number;

  oldPrice?: number;

  socialEventId: number;

  socialEvent: SocialEvent;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt: Date;
}
