import { ExternalCourseTargetOrganization } from './external_course_target_organization';
import { OrganizationCreditUnitName } from './organization_credit_unit_name';

export class ExternalCourseTargetOrganizationCreditUnit {
  id: number;

  externalCourseTargetOrganizationId: number;

  courseTargetOrganization: ExternalCourseTargetOrganization;

  organizationCreditUnitNameId: number;

  organizationCreditUnitName: OrganizationCreditUnitName;

  creditUnitValue: number;

  createdAt: Date;

  updatedAt?: Date;
}
