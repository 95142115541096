import { Course } from './course';
import { CourseResourceAccessRestrictionStudent } from './course_resource_access_restriction_student';
import { CourseResourceAccessRestrictionGroup } from './course_resource_access_restriction_group';
import { CourseAssignment } from './course_assignment';
import { SubsectionExam } from './subsection_exam';
import { Survey } from './survey';
import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { ResourceAccessRestrictionType } from '../exported_type_enums/exported_type_enums';

export class CourseResourceAccessRestriction {
  id: number;

  courseId: number;

  course: Course;

  accessRestrictionType: ResourceAccessRestrictionType =
    ResourceAccessRestrictionType.ALL;

  isPublished: boolean = true;

  availableFrom?: Date;

  availableTo?: Date;

  students: CourseResourceAccessRestrictionStudent[];

  groups: CourseResourceAccessRestrictionGroup[];

  createdAt: Date;

  updatedAt: Date;

  assigments: CourseAssignment[];

  subsectionExams: SubsectionExam[];

  surveys: Survey[];

  discussionBoardItems: CourseDiscussionBoardItem[];
}
