import { LibraryTags } from './library_tags';
import { Media } from './media';
import { LibraryMediaTranslation } from './library_media_translation';

export class LibraryMedia {
  id: number;

  name: string;

  tagId?: number;

  tag?: LibraryTags;

  createdAt: Date;

  updatedAt: Date;

  mediaId?: number;

  media?: Media;

  translations: LibraryMediaTranslation[];
}
