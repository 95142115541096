import { CourseGroup } from './course_group';
import { User } from './user';
import { PlagiarismReport } from './plagiarism_report';

export class CourseGroupStudent {
  id: number;

  groupId: number;

  group: CourseGroup;

  studentId: number;

  student: User;

  createdAt: Date;

  updatedAt?: Date;

  plagiarismReport?: PlagiarismReport;

  plagiarismReportId?: number;
}
