import { Organization } from './organization';
import { VoucherConsumption } from './voucher_consumption';
import { VoucherRestrictionByItemtype } from './voucher_restriction_by_itemtype';
import { VoucherRestrictionByOrganizationOrUser } from './voucher_restriction_by_organization_or_user';

export class Voucher {
  id: number;

  code: string;

  discountAmount?: number;

  discountRate?: number;

  startDate?: Date;

  endDate?: Date;

  isValid: boolean = true;

  numberOfUses: number;

  maxAllowedUses?: number;

  maxAllowedUsesPerUser?: number;

  maxAllowedUsesPerOrg?: number;

  maxDiscountAmount?: number;

  isRestricted: boolean;

  organizationId?: number;

  organization?: Organization;

  voucherConsumptions: VoucherConsumption[];

  restrictionsByItemType: VoucherRestrictionByItemtype[];

  restrictionsByOrgOrUser: VoucherRestrictionByOrganizationOrUser[];

  createdAt: Date;

  updatedAt: Date;
}
