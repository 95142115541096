export const chatRoutesEnum = {
  root: {
    name: 'Chat Ai',
    path: '/chat',
    title: 'chatAi.title',
    icon: 'mdi-face-agent',
    meta: {
      requiresAuth: false,
    },
  },
};
