import { useShowToast } from '@/composables/useShowToast.composable';
import { AuthErrorCodesEnum } from '@/helpers/enums/authError.enum';
import { pinia } from '@/pinia';
import router from '@/router';
import { useAuthStore } from '@/stores/auth.store';
import { useLocaleStore } from '@/stores/locale.store';
import { usePermissionsStore } from '@/stores/permissoins.store';
import axios, {
  AxiosError,
  type AxiosRequestHeaders,
  type InternalAxiosRequestConfig,
} from 'axios';
interface AxiosResponseData {
  code?: string;
  errorCode?: string;
  explanation?: string;
  message?: string;
  statusCode?: number;
  time?: string;
}
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const localStore = useLocaleStore(pinia);
    if (!config) {
      config = {} as InternalAxiosRequestConfig<any>;
    }
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    config.headers['language'] = `${localStore.locale}`;
    config.headers['Organization'] =
      import.meta.env.VITE_CLIENT_ORGANIZATION_ID;

    const token = localStorage.getItem('access');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error: AxiosError) => {
    const authStore = useAuthStore();
    const permissionsStore = usePermissionsStore();
    // const { status } = error.response || {};
    const errorCode = (error.response?.data as AxiosResponseData).errorCode;
    if (errorCode) {
      switch (errorCode) {
        case AuthErrorCodesEnum.UserIsDisabled:
          // disabled user, logout if there is a user only
          if (authStore.user) {
            await authStore.handleLogout();
            permissionsStore.checkAuthentication(
              router.currentRoute.value.meta,
              true,
              router.currentRoute.value.fullPath
            );
            useShowToast.error({
              msg: 'error.disabledUser',
              timeout: 5000,
              translate: true,
            });
          }
          break;
        case AuthErrorCodesEnum.UserNotInOrganization:
          // userNotInOrganization
          break;
        case AuthErrorCodesEnum.InvalidCredentials:
          // UserNotFound
          break;
        case AuthErrorCodesEnum.TooManyRequests:
          // tooRequest
          break;
        case AuthErrorCodesEnum.UserShouldResetPassword:
          authStore.verifyToken(true);
          useShowToast.error({
            msg: 'error.userShouldResetPassword',
            timeout: 5000,
            translate: true,
          });
          break;
        case AuthErrorCodesEnum.InvalidAccessToken:
          // InvalidAccessToken
          // logout out manually to avoid infinite loop if logout API is used
          await authStore.handleLogout();
          permissionsStore.checkAuthentication(
            router.currentRoute.value.meta,
            true,
            router.currentRoute.value.fullPath
          );
          break;
        case AuthErrorCodesEnum.InvalidRefreshToken:
          // InvalidRefreshToken
          await authStore.logout();
          permissionsStore.checkAuthentication(
            router.currentRoute.value.meta,
            true,
            router.currentRoute.value.fullPath
          );
          break;
        case AuthErrorCodesEnum.Invalid2FAToken:
          // Invalid2FAToken
          break;
      }
    }
    return Promise.reject<AxiosError>(error);
  }
);

export default axiosInstance;
