import { PermissionSet } from './permission_set';
import { Organization } from './organization';
import { Media } from './media';
import { SocialEvent } from './social_event';
import { UserEventAttendance } from './user_event_attendance';
import { UserInterestedEvents } from './user_interested_events';
import { Order } from './order';
import { UserGroupsMemberShips } from './user_groups_member_ships';
import { SocialGroup } from './social_group';
import { Post } from './post';
import { PostSaves } from './post_saves';
import { PostLikes } from './post_likes';
import { PostComment } from './post_comment';
import { EventHosts } from './event_hosts';
import { PostCommentsLike } from './post_comments_like';
import { Course } from './course';
import { CourseEnrollee } from './course_enrollee';
import { CourseInstructor } from './course_instructor';
import { UserResetPasswordAttempt } from './user_reset_password_attempt';
import { StudentFinishedCourseSubsections } from './student_finished_course_subsections';
import { UserLoginAttempt } from './user_login_attempt';
import { UserWishlistedCourse } from './user_wishlisted_course';
import { UserRecentSearches } from './user_recent_searches';
import { LearningCategories } from './learning_categories';
import { UserLastVisitedSubsection } from './user_last_visited_subsection';
import { CoachDetails } from './coach_details';
import { CoachEducation } from './coach_education';
import { CoachWorkExperience } from './coach_work_experience';
import { CoachCertifications } from './coach_certifications';
import { CoachLanguages } from './coach_languages';
import { CoachSpecializations } from './coach_specializations';
import { CoachPositions } from './coach_positions';
import { CourseSurvey } from './course_survey';
import { SubsectionNotes } from './subsection_notes';
import { CourseAnnouncement } from './course_announcement';
import { StudentCourseCertificate } from './student_course_certificate';
import { AssignmentSubmission } from './assignment_submission';
import { StudentCourseGrade } from './student_course_grade';
import { StudentCourseGradeRubricAssessment } from './student_course_grade_rubric_assessment';
import { SpecializationEnrollee } from './specialization_enrollee';
import { Specialization } from './specialization';
import { StudentFinishedCourse } from './student_finished_course';
import { CourseAttendance } from './course_attendance';
import { StudentCourseAttendance } from './student_course_attendance';
import { ExamInstance } from './exam_instance';
import { AnswerReview } from './answer_review';
import { UserTwoFactorAuthentication } from './user_two_factor_authentication';
import { LearningGroupStudent } from './learning_group_student';
import { StudentSubsectionExamGrade } from './student_subsection_exam_grade';
import { ChecklistStudent } from './checklist_student';
import { ChecklistItemStudentCompletion } from './checklist_item_student_completion';
import { StudentCompletedChecklist } from './student_completed_checklist';
import { Checklist } from './checklist';
import { ChecklistItemSettings } from './checklist_item_settings';
import { ScormCourseRegistration } from './scorm_course_registration';
import { ChecklistEnrollment } from './checklist_enrollment';
import { StudentEnrolledChecklist } from './student_enrolled_checklist';
import { CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';
import { UserReadCourseDiscussionBoardItemComment } from './user_read_course_discussion_board_item_comment';
import { ProviderBookingSettings } from './provider_booking_settings';
import { ProviderBusinnessDay } from './provider_businness_day';
import { ProviderBusinessHour } from './provider_business_hour';
import { ProviderDisabledSlot } from './provider_disabled_slot';
import { ProviderBookedSlot } from './provider_booked_slot';
import { ProviderReschedulingCredit } from './provider_rescheduling_credit';
import { PlagiarismReport } from './plagiarism_report';
import { CourseGroupStudent } from './course_group_student';
import { GroupCourseGrade } from './group_course_grade';
import { ZoomOAuthToken } from './zoom_o_auth_token';
import { UserSingleSignOn } from './user_single_sign_on';
import { PollVote } from './poll_vote';
import { ProviderTargetOrganization } from './provider_target_organization';
import { OrganizationMember } from './organization_member';
import { CourseOrganizationUser } from './course_organization_user';
import { UserRefreshToken } from './user_refresh_token';
import { CourseResourceAccessRestrictionStudent } from './course_resource_access_restriction_student';
import { CourseDiscussionBoardItemCommentLike } from './course_discussion_board_item_comment_like';
import { PurchasedItem } from './purchased_item';
import { QoyodUser } from './qoyod_user';
import { SocialEventSeenBy } from './social_event_seen_by';
import { OrderRefund } from './order_refund';
import { UserPinnedCourse } from './user_pinned_course';
import { CourseApprovalRequest } from './course_approval_request';
import { UserCourseInterest } from './user_course_interest';
import { SocialEventAttendance } from './social_event_attendance';
import { UserFormFieldData } from './user_form_field_data';
import { UserApprovalRequest } from './user_approval_request';
import { GroupCourseGradeRubricAssessment } from './group_course_grade_rubric_assessment';
import { ExternalCourseEnrollment } from './external_course_enrollment';
import { PollChoice } from './poll_choice';
import { OrganizationAnnouncementDismissedUser } from './organization_announcement_dismissed_user';
import { SamlRequest } from './saml_request';
import { UserTranslation } from './user_translation';
import { UserCourseConfirmationCheckbox } from './user_course_confirmation_checkbox';
import { Gender, Portals } from '../exported_type_enums/exported_type_enums';

export class User {
  id: number;

  email: string;

  isVerified: boolean;

  permissionSetId?: number;

  permissionSet?: PermissionSet;

  phoneNumber?: string;

  hashedPassword: string;

  deactivatedAt?: Date;

  deactivatedById?: number;

  deactivatedBy?: User;

  requiresApproval: boolean;

  createdAt: Date;

  updatedAt: Date;

  firstName: string;

  lastName: string;

  organizationId?: number;

  organization?: Organization;

  organizationPermissionSetId?: number;

  organizationPermissionSet?: PermissionSet;

  arFirstName?: string;

  arLastName?: string;

  academicLevel?: string;

  specialty?: string;

  position?: string;

  workPlace?: string;

  nationality?: string;

  profileImageId?: number;

  profileImage?: Media;

  coverImageId?: number;

  coverImage?: Media;

  about?: string;

  occupation?: string;

  location?: string;

  idNumber?: string;

  gender?: Gender;

  scfhsId?: string;

  institute?: string;

  yearsOfExperience?: string;

  lastVisited?: Portals;

  country?: string;

  city?: string;

  uploadedMedia: Media[];

  eventsCreated: SocialEvent[];

  eventsAttended: UserEventAttendance[];

  eventsInterestedIn: UserInterestedEvents[];

  orders: Order[];

  groups: UserGroupsMemberShips[];

  groupsCreated: SocialGroup[];

  usersInvitedToGroups: UserGroupsMemberShips[];

  posts: Post[];

  savedPosts: PostSaves[];

  likedPosts: PostLikes[];

  pinnedPosts: Post[];

  comments: PostComment[];

  hostedEvents: EventHosts[];

  postCommentsLikes: PostCommentsLike[];

  linkedIn?: string;

  freeChemistrySessions: number = 2;

  coursesCreated: Course[];

  enrolledCourses: CourseEnrollee[];

  instructingCourses: CourseInstructor[];

  userResetPasswordAttempts: UserResetPasswordAttempt[];

  userFinishedCourseSubsections: StudentFinishedCourseSubsections[];

  userLoginAttempts: UserLoginAttempt[];

  userWishlistedCourses: UserWishlistedCourse[];

  searches: UserRecentSearches[];

  createdLearningCategories: LearningCategories[];

  lastVisitedSubsection: UserLastVisitedSubsection[];

  coach?: CoachDetails;

  coachEducation: CoachEducation[];

  coachWorkExperience: CoachWorkExperience[];

  coachCertifications: CoachCertifications[];

  coachLanguages: CoachLanguages[];

  coachSpecializations: CoachSpecializations[];

  coachPositions: CoachPositions[];

  surveyAnswers: CourseSurvey[];

  subsectionNotes: SubsectionNotes[];

  courseAnnouncements: CourseAnnouncement[];

  courseCertificates: StudentCourseCertificate[];

  assignmentSubmissions: AssignmentSubmission[];

  courseGrades: StudentCourseGrade[];

  gradedStudents: StudentCourseGrade[];

  gradedRubricAssessments: StudentCourseGradeRubricAssessment[];

  enrolledSpecializations: SpecializationEnrollee[];

  createdSpecializations: Specialization[];

  finishedCourses: StudentFinishedCourse[];

  attendancesTaken: CourseAttendance[];

  courseAttendance: StudentCourseAttendance[];

  examInstances: ExamInstance[];

  examAnswerReviews: AnswerReview[];

  twoFactorAuthentication?: UserTwoFactorAuthentication;

  learningGroups: LearningGroupStudent[];

  studentSubsectionExamGrade: StudentSubsectionExamGrade[];

  assignedChecklists: ChecklistStudent[];

  completedChecklistItems: ChecklistItemStudentCompletion[];

  completedChecklists: StudentCompletedChecklist[];

  adminChecklists: Checklist[];

  adminChecklistItems: ChecklistItemSettings[];

  scormCourseRegistrations: ScormCourseRegistration[];

  checklistEnrollments: ChecklistEnrollment[];

  enrolledChecklists: StudentEnrolledChecklist[];

  discussionBoardComments: CourseDiscussionBoardItemComment[];

  readDiscussionBoardComments: UserReadCourseDiscussionBoardItemComment[];

  providerBookingSettings?: ProviderBookingSettings;

  providerBusinnessDays: ProviderBusinnessDay[];

  providerBusinessHours: ProviderBusinessHour[];

  providerDisabledSlots: ProviderDisabledSlot[];

  userBookedSlots: ProviderBookedSlot[];

  providerBookedSlots: ProviderBookedSlot[];

  userReschedulingCredit: ProviderReschedulingCredit[];

  providerReschedulingCredit: ProviderReschedulingCredit[];

  plagiarismReports: PlagiarismReport[];

  courseGroups: CourseGroupStudent[];

  gradedCourseGroups: GroupCourseGrade[];

  zoomOAuthToken?: ZoomOAuthToken;

  singleSignOn?: UserSingleSignOn;

  votes: PollVote[];

  providerTargetOrganizations: ProviderTargetOrganization[];

  organizations: OrganizationMember[];

  ocoursesAllowedToView: CourseOrganizationUser[];

  refreshTokens: UserRefreshToken[];

  courseResourceAccessRestrictionStudent: CourseResourceAccessRestrictionStudent[];

  discussionBoardItemCommentLikes: CourseDiscussionBoardItemCommentLike[];

  purchasedItems: PurchasedItem[];

  qoyodUser?: QoyodUser;

  seenSocialEvents: SocialEventSeenBy[];

  refunds: OrderRefund[];

  deactivatedUsers: User[];

  pinnedCourses: UserPinnedCourse[];

  courseApprovalRequests: CourseApprovalRequest[];

  forceResetPassword: boolean;

  interestedCourses: UserCourseInterest[];

  socialEventAttendance: SocialEventAttendance[];

  extraFields: UserFormFieldData[];

  approvalRequest?: UserApprovalRequest;

  approvedUserRequests: UserApprovalRequest[];

  GroupCourseGradeRubricAssessment: GroupCourseGradeRubricAssessment[];

  externalCourseEnrollments: ExternalCourseEnrollment[];

  pollChoices: PollChoice[];

  dismissedOrganizationAnnouncements: OrganizationAnnouncementDismissedUser[];

  samlRequests: SamlRequest[];

  translations: UserTranslation[];

  courseConfirmationCheckboxs: UserCourseConfirmationCheckbox[];
}
