import { ExamAttempt } from './exam_attempt';
import { ExamAttemptEventType } from '../exported_type_enums/exported_type_enums';

export class ExamAttemptEvent {
  id: number;

  examAttemptId: number;

  examAttempt: ExamAttempt;

  type: ExamAttemptEventType;

  createdAt: Date;
}
