import { Media } from './media';
import { User } from './user';
import { UserGroupsMemberShips } from './user_groups_member_ships';
import { SocialEvent } from './social_event';
import { Post } from './post';
import { Organization } from './organization';
import { SocialGroupTargetOrganization } from './social_group_target_organization';
import { GroupPrivacy } from '../exported_type_enums/exported_type_enums';

export class SocialGroup {
  id: number;

  name: string;

  normalizedName?: string;

  imageId?: number;

  image?: Media;

  coverImageId?: number;

  coverImage?: Media;

  description?: string;

  createdAt: Date;

  updatedAt: Date;

  createdById: number;

  createdBy: User;

  privacy: GroupPrivacy = GroupPrivacy.PRIVATE;

  postPermission: number;

  members: UserGroupsMemberShips[];

  events: SocialEvent[];

  posts: Post[];

  organizationId?: number;

  organization?: Organization;

  targetOrganizations: SocialGroupTargetOrganization[];
}
