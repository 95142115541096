// Enum for string cases
export const enum StringCase {
  LENGTH = 'length',
  MINIMUM = 'minimum',
  MAXIMUM = 'maximum',
  EMAIL = 'email',
  URL = 'url',
  OPTIONAL = 'optional',
}

// Enum for date cases
export const enum DateCase {
  FUTURE = 'future',
  PAST = 'past',
  MIN_YEAR = 'min-year',
  MAX_YEAR = 'max-year',
}

// Enum for number cases
export const enum NumberCase {
  MINIMUM = 'minimum',
  MAXIMUM = 'maximum',
  GREATER_THAN = 'greater-than',
  LESS_THAN = 'less-than',
}

// Enum for array cases
export const enum ArrayCase {
  IS_ARRAY = 'array',
  MIN_ITEMS = 'min-items',
  MAX_ITEMS = 'max-items',
  LENGTH_ITEMS = 'length-items',
  NON_EMPTY = 'non-empty',
}
