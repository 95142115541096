import { Course } from './course';
import { User } from './user';
import { Organization } from './organization';

export class CourseApprovalRequest {
  id: number;

  note?: string;

  courseId: number;

  course: Course;

  instructorId: number;

  instructor: User;

  organizationId: number;

  organization: Organization;

  status: string = 'PENDING';

  createdAt: Date;

  updatedAt?: Date;
}
