import { Course } from './course';
import { CourseSubsection } from './course_subsection';
import { CourseSectionPrerequisite } from './course_section_prerequisite';

export class CourseSection {
  id: number;

  courseId: number;

  name: string;

  learningObjectives?: string;

  isPublished: boolean = true;

  unlockStartDate?: Date;

  week: number = 1;

  createdAt: Date;

  updatedAt: Date;

  course: Course;

  subsections: CourseSubsection[];

  prerequisites: CourseSectionPrerequisite[];

  prerequisiteToWhatSections: CourseSectionPrerequisite[];
}
