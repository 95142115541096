import { OrganizationTaxClass } from './organization_tax_class';

export class TaxClass {
  id: number;

  slug: string;

  name: string;

  organizations: OrganizationTaxClass[];

  createdAt: Date;

  updatedAt?: Date;
}
