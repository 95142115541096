import { Rubric } from './rubric';
import { RubricCriterionRating } from './rubric_criterion_rating';
import { QuestionRubricCriterion } from './question_rubric_criterion';

export class RubricCriterion {
  id: number;

  description: string;

  longDescription?: string;

  maxGrade: number;

  usesRange: boolean;

  rubricId?: number;

  rubric?: Rubric;

  createdAt: Date;

  updatedAt?: Date;

  ratings: RubricCriterionRating[];

  questionRubric: QuestionRubricCriterion[];
}
