import { User } from './user';

export class UserTranslation {
  id: number;

  bio: string;

  userId: number;

  user: User;

  language: string = 'en';

  createdAt: Date;

  updatedAt: Date;
}
