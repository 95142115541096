import { EnumMagicStrings } from '@/helpers/enums/misc.enum';
import { pinia } from '@/pinia';
import { useLocaleStore } from '@/stores/locale.store';
import { useOrganizationStore } from '@/stores/organzation.pinia';
import { useVuetifyStore } from '@/stores/vuetify.pinia';
import type { i18n } from 'i18next';
import i18next from 'i18next';
import { storeToRefs } from 'pinia';

export async function initializeI18n(): Promise<void | i18n> {
  const url = window.location.href;
  const lang = url.split('lang=')[1];
  const initialLang =
    lang == 'ar' || lang == 'en'
      ? lang
      : localStorage.getItem(EnumMagicStrings.localeLanguage) || 'en';
  return await i18next
    .init({
      // debug: true,
      fallbackLng: initialLang,
      preload: ['en', 'ar'],
      initImmediate: true,
      lng: initialLang, // Set initial language
      resources: {
        en: {
          translation: {},
        },
        ar: {
          translation: {},
        },
      },
      interpolation: {
        format: (value, format, lng) => {
          if (!value) return;
          if (format === 'lowercase') {
            return value.toLowerCase();
          }
          if (format === 'firstLetterUpperCase') {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
          }
          if (format === 'removeTheForArabic' && lng === 'ar') {
            return value.startsWith('ال') ? value.slice(2) : value;
          }
          if (format === 'addTheForArabic' && lng === 'ar') {
            return value.startsWith('ال') ? value : `ال${value}`;
          }
          return value;
        },
      },
    })
    .then(async () => {
      const organizationsStore = useOrganizationStore(pinia);
      const localStore = useLocaleStore(pinia);
      const vuetifyStore = useVuetifyStore(pinia);

      await Promise.all([
        localStore.changeLocale(initialLang, i18next, false),
        organizationsStore.callOrganization(),
        organizationsStore.getOrganizationTerminology(),
      ]);

      /*
       * Change theme colors and font family
       * based on organization colors and font family
       */
      const { organization } = storeToRefs(organizationsStore);
      if (organization.value) {
        vuetifyStore.changeThemeMainColors(
          organization.value?.colors[0].color,
          organization.value?.colors[1].color
        );
        vuetifyStore.changeFontFamily(organization.value?.fontFamilies);
        vuetifyStore.changeFavoriteIcon(organization.value?.icon?.url ?? '');
      }

      return i18next;
    });
}
