import { OrderPayment } from './order_payment';

export class PaymentMethod {
  id: number;

  nameEn: string;

  nameAr: string;

  slug: string;

  isEnabled: boolean = true;

  orderPayment: OrderPayment[];
}
