<script lang="ts" setup>
  import BasicLayout from '@/layout/main/BasicLayout.vue';
  import { useAuthStore } from '@/stores/auth.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { storeToRefs } from 'pinia';
  import { onBeforeMount, onMounted, watch } from 'vue';
  import SnackbarVue from './components/misc/snackbar.vue';
  import { useNotificationsStore } from './stores/notifications.store';

  const { isLTR } = storeToRefs(useLocaleStore());

  const authStore = useAuthStore();

  onBeforeMount(async () => {
    await Promise.all([
      //if there other stores or functions to initialize, add them here
      authStore.initializeAuth(),
    ]);
  });
  const assignFontFamily = () => {
    const overlayContainer = document.querySelector('body');

    if (overlayContainer) {
      if (isLTR.value) {
        overlayContainer.classList.remove('ar');
        overlayContainer.classList.add('en');
      } else {
        overlayContainer.classList.remove('en');
        overlayContainer.classList.add('ar');
      }
    }
  };
  onMounted(() => {
    assignFontFamily();
  });
  watch(
    () => isLTR.value,
    () => {
      assignFontFamily();
      if (authStore.user?.id) useNotificationsStore().getNotifications();
    }
  );
</script>

<template>
  <v-app class="bg-app">
    <BasicLayout></BasicLayout>
    <SnackbarVue />
  </v-app>
</template>
