const baseurl = import.meta.env.VITE_APP_API_BASE_URL.split('//')[1].replace(
  '/api/',
  ''
);
export const hotjarOptions = {
  id: +import.meta.env.VITE_HOTJAR_ID,
  isProduction:
    baseurl.includes('services') &&
    import.meta.env.VITE_CLIENT_ORGANIZATION_ID === '1',
  snippetVersion: 6,
};
