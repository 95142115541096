import { FormFieldType } from './form_field_type';
import { FormFieldTypeModifier } from './form_field_type_modifier';

export class FormFieldTypeAvailableModifier {
  id: number;

  formFieldTypeId: number;

  formFieldType: FormFieldType;

  formFieldTypeModifierId: number;

  formFieldTypeModifier: FormFieldTypeModifier;

  createdAt: Date;

  updatedAt?: Date;
}
