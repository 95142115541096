import { User } from './user';

export class UserTwoFactorAuthentication {
  id: number;

  userId: number;

  user: User;

  isEnabled: boolean = true;

  code: string;

  expiresAt: Date;

  createdAt: Date;

  updatedAt: Date;
}
