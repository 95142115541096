export enum PaymentMethodsEnum {
  CREDIT_CARD = 'Credit Card',
  ORGANIZATION_CREDIT = 'Organization Credit',
  VOUCHER = 'Voucher',
  FREE = 'Free',
  APPLE_PAY = 'Apple Pay',
  GOOGLE_PAY = 'Google Pay',
  WIRE_TRANSFER = 'Wire Transfer',
  PAYPAL = 'Paypal',
  CASH = 'Cash',
}

export enum FinancialStatusesEnum {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  FAILED = 'FAILED',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUNDED = 'REFUNDED',
  REFUND_REJECTED = 'REFUND_REJECTED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PARTIAL_REFUND_REQUESTED = 'PARTIAL_REFUND_REQUESTED',
  // PARTIAL_REFUND_REJECTED = 'PARTIAL_REFUND_REJECTED',
  CANCELED = 'CANCELED',
}

export enum PurchasedItemTypeEnum {
  COURSE = 'Course',
  SPECIALIZATION = 'Specialization',
  COACHING_SESSION = 'Coaching Session',
  EVENT = 'Event',
  BUNDLE = 'Bundle',
  EXTERNALCOURSE = 'External Course',
}

export enum PaymentGatewaysEnum {
  FRANSI = 0,
}
