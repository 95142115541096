import { QuestionDetail } from './question_detail';

export class QuestionDifficultyLevel {
  id: number;

  questionDetailId: number;

  questionDetail: QuestionDetail;

  correctAnswers: number;

  incorrectAnswers: number;

  createdAt: Date;

  updatedAt?: Date;
}
