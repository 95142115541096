import { User } from './user';

export class QoyodUser {
  id: number;

  userId: number;

  user: User;

  qoyodId: string;

  createdAt: Date;

  updatedAt: Date;
}
