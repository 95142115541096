import { CourseAssignment } from './course_assignment';
import { User } from './user';
import { CourseGroup } from './course_group';
import { AssignmentSubmissionMedia } from './assignment_submission_media';
import { PlagiarismReport } from './plagiarism_report';

export class AssignmentSubmission {
  id: number;

  courseAssignmentId: number;

  courseAssignment: CourseAssignment;

  studentId: number;

  student: User;

  body?: string;

  groupId?: number;

  group?: CourseGroup;

  createdAt: Date;

  updatedAt?: Date;

  uploadedMedia: AssignmentSubmissionMedia[];

  plagiarismReport?: PlagiarismReport;
}
