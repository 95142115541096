import { Exam } from './exam';
import { Question } from './question';
import { ExamAttemptQuestion } from './exam_attempt_question';

export class ExamQuestion {
  id: number;

  index: number;

  points: number = 1;

  gradingFlexibilityPercentage: number;

  caseSensitiveGrading: boolean;

  isBonus: boolean;

  examId?: number;

  exam?: Exam;

  questionId: number;

  question: Question;

  createdAt: Date;

  updatedAt?: Date;

  examAttemptQuestions: ExamAttemptQuestion[];

  parentQuestionId?: number;

  parentQuestion?: ExamQuestion;

  childQuestions: ExamQuestion[];
}
