import { QuestionType } from './question_type';
import { QuestionDetail } from './question_detail';
import { QuestionStem } from './question_stem';
import { QuestionAnswerKey } from './question_answer_key';
import { Answer } from './answer';
import { ExamQuestion } from './exam_question';
import { QuestionLearningOutcome } from './question_learning_outcome';

export class Question {
  id: number;

  questionTypeId: number;

  questionType: QuestionType;

  createdAt: Date;

  updatedAt?: Date;

  questionDetail?: QuestionDetail;

  questionStem?: QuestionStem;

  questionAnswerKey?: QuestionAnswerKey;

  isPublished: boolean;

  answers: Answer[];

  examQuestion: ExamQuestion[];

  learningOutcomes: QuestionLearningOutcome[];
}
