import { LinkKey } from './link_key';
import { Organization } from './organization';

export class OrganizationLink {
  id: number;

  linkKeyId?: number;

  linkKey?: LinkKey;

  url?: string;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;
}
