import { User } from './user';
import { SocialEvent } from './social_event';

export class UserEventAttendance {
  id: number;

  userId: number;

  user: User;

  eventId: number;

  event: SocialEvent;

  createdAt: Date;

  updatedAt?: Date;

  attendanceToken: string;

  attendedAt?: Date;

  leftAt?: Date;
}
