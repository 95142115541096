import { Survey } from './survey';

export class SurveySettings {
  id: number;

  isAnonymous: boolean;

  resultIsPublished: boolean = true;

  requireVotingForResult: boolean = true;

  maximumPollVotesPerUser: number = 1;

  allowPollChoiceSuggestion: boolean;

  surveyId: number;

  survey: Survey;

  createdAt: Date;

  updatedAt?: Date;
}
