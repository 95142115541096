import { Course } from './course';
import { AssignmentSubmission } from './assignment_submission';
import { CourseGroupStudent } from './course_group_student';
import { GroupCourseGrade } from './group_course_grade';
import { CourseResourceAccessRestrictionGroup } from './course_resource_access_restriction_group';

export class CourseGroup {
  id: number;

  name: string;

  notes?: string;

  weight: number;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;

  assignmentSubmissions: AssignmentSubmission[];

  students: CourseGroupStudent[];

  grades: GroupCourseGrade[];

  courseResourceAccessRestrictionGroups: CourseResourceAccessRestrictionGroup[];
}
