import { User } from './user';
import { SocialGroup } from './social_group';
import { GroupRole, Status } from '../exported_type_enums/exported_type_enums';

export class UserGroupsMemberShips {
  id: number;

  userId: number;

  user: User;

  groupId: number;

  group: SocialGroup;

  groupRole: GroupRole = GroupRole.MEMBER;

  status: Status = Status.PENDING;

  userWhoInvitedId?: number;

  invitedBy?: User;

  createdAt: Date;

  updatedAt?: Date;
}
