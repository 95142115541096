import { Organization } from './organization';
import { ConfigurationKey } from './configuration_key';

export class OrganizationConfiguration {
  id: number;

  organizationId: number;

  organization: Organization;

  configurationKeyId: number;

  configurationKey: ConfigurationKey;

  value: object;

  createdAt: Date;

  updatedAt?: Date;
}
