import { User } from './user';
import { CourseConfirmationCheckbox } from './course_confirmation_checkbox';

export class UserCourseConfirmationCheckbox {
  id: number;

  userId: number;

  user: User;

  courseConfirmationCheckboxId: number;

  courseConfirmationCheckbox: CourseConfirmationCheckbox;

  answer: boolean;

  createdAt: Date;

  updatedAt?: Date;
}
