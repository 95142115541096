import { Course } from './course';
import { LearningTypes } from '../exported_type_enums/exported_type_enums';

export class CourseTypes {
  id: number;

  courseId: number;

  course: Course;

  type: LearningTypes;

  createdAt: Date;

  updatedAt: Date;
}
