import { User } from './user';

export class SamlRequest {
  id: string;

  samlRequestId: string;

  samlRequest: string;

  samlResponse?: string;

  userId?: number;

  user?: User;

  createdAt: Date;

  updatedAt?: Date;
}
