import { Exam } from './exam';
import { LearningOutcome } from './learning_outcome';

export class ExamLearningOutcome {
  id: number;

  examId: number;

  exam: Exam;

  learningOutcomeId: number;

  learningOutcome: LearningOutcome;

  createdAt: Date;

  updatedAt?: Date;
}
