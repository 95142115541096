import { OrganizationRefundPolicy } from './organization_refund_policy';
import { RefundRestriction } from './refund_restriction';

export class OrgRefundRestriction {
  id: number;

  organizationRefundPolicyId: number;

  organizationRefundPolicy: OrganizationRefundPolicy;

  restrictionId: number;

  restriction: RefundRestriction;

  createdAt: Date;

  updatedAt: Date;
}
