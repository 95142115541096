import type { Paginated } from '@/helpers/interfaces/paginated.interface';
import type { SocialEventWithUserData } from '@/helpers/interfaces/social.interface';
import { axiosI } from '@/plugins';
import type { Order } from '@/submodules/generated_types/types/order';
import type { SocialEvent } from '@/submodules/generated_types/types/social_event';
import { socialApiHandler } from '@/postman-to-ts/apiHandler/social';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import type { Ref } from 'vue';

const socialEvents = createQueryKeys('socialEvents', {
  getAll: (params23: typeof socialApiHandler.events.getEvents_C.params) => ({
    queryKey: ['getAll', ...Object.values(params23)],
    queryFn: async () => {
      const { path, method } = socialApiHandler.events.getEvents_C;
      const data = (
        await axiosI.get<Paginated<SocialEvent>>(path(), {
          method,
          params: params23,
        })
      ).data;
      return data;
    },
  }),

  getEvent: (eventId: Ref<number>) => ({
    queryKey: ['event', eventId],
    queryFn: async () => {
      const { path, method } = socialApiHandler.events.getEvent_C;
      return (
        await axiosI<SocialEventWithUserData>(
          path({ eventId: eventId.value }),
          {
            method,
          }
        )
      ).data;
    },
  }),

  getTicket: (eventId: Ref<number>) => ({
    queryKey: ['getTicket', eventId],
    queryFn: async () => {
      const { path, method } =
        socialApiHandler.events.enrollment.getEnrollmentTicket;
      const data = (
        await axiosI<{
          order: Order;
          qrCode: string;
        }>(path({ eventId: eventId.value }), {
          method,
        })
      ).data;
      return data;
    },
  }),
});

export const socialQueryKeys = mergeQueryKeys(socialEvents);
