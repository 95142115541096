import { OrganizationAnnouncement } from './organization_announcement';
import { Language } from '../exported_type_enums/exported_type_enums';

export class OrganizationAnnouncementTranslation {
  id: number;

  language: Language = Language.ENGLISH;

  body: string;

  organizationAnnouncementId: number;

  organizationAnnouncement: OrganizationAnnouncement;

  createdAt: Date;

  updatedAt?: Date;
}
