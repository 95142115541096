export const landing = {
  root: {
    name: 'Landing Page home',
    title: 'header.home',
    path: '/landing-page',
    meta: {
      requiresAuth: false,
      hideSideBar: true,

      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Home`,
    },
  },
  leaderShipDevelopment: {
    name: 'LeaderShip Development',
    path: '/landing-page/leadership-development',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | LeaderShip Development`,
    },
  },
  executiveCoach: {
    name: 'Executive Coach',
    path: '/executive-coach',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Executive Coach`,
    },
  },
  professional: {
    name: 'Professional',
    path: '/professional-leadership',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Professional LeaderShip`,
    },
  },
  webinars: {
    path: '/webinars',
    name: 'Webinars',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Webinars`,
    },
  },
  about: {
    name: 'About',
    title: 'header.about',
    path: '/about-us',
    icon: 'mdi-information',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | About`,
    },
  },
  privacyPolicy: {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    title: 'header.privacyPolicy',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Privacy Policy`,
    },
  },
  educationEthics: {
    name: 'Education Ethics',
    path: '/education-ethics',
    title: 'header.educationEthics',

    icon: 'mdi-email',

    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Education Ethics`,
    },
  },
  termsOfUse: {
    name: 'Terms of Use',
    path: '/terms-of-use',
    title: 'header.termsOfUse',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Terms of Use`,
    },
  },
  contactUs: {
    name: 'ContactUs',
    title: 'header.contactUs',
    path: '/contact-us',
    icon: 'mdi-email',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Contact Us`,
    },
  },
  technicalSupport: {
    name: 'TechnicalSupport',
    title: 'header.technicalSupport',

    path: '/technical-support',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Technical Support`,
    },
    icon: 'mdi-face-agent',
  },
};
