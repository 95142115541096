import { Organization } from './organization';
import { BillingCycleType } from './billing_cycle_type';

export class OrganizationFuturePricingModel {
  id: number;

  organizationId: number;

  organization: Organization;

  billingCycleTypeId: number;

  billingCycleType: BillingCycleType;

  maxUserLimit?: number = 2;

  createdAt: Date;

  updatedAt?: Date;
}
