export const socialApiHandler = {
  search: {
    search: {
      method: 'GET',
      path: () => `search`,
      query: {} as { search?: string | number },
    },
  },

  comments: {
    likes: {
      likePostComment: {
        method: 'POST',
        path: (params: { commentId: string | number }) =>
          `comments/${params.commentId}/likes`,
      },
      getPostCommentLikes: {
        method: 'GET',
        path: (params: { commentId: string | number }) =>
          `comments/${params.commentId}/likes`,
        query: {} as { skip?: string | number; take?: string | number },
      },
      unlikePostComment: {
        method: 'DELETE',
        path: (params: { commentId: string | number }) =>
          `comments/${params.commentId}/likes`,
      },
    },
    deleteComment: {
      method: 'DELETE',
      path: (params: { commentId: string | number }) =>
        `comments/${params.commentId}`,
    },
    updateComment: {
      method: 'PATCH',
      path: (params: { commentId: string | number }) =>
        `comments/${params.commentId}`,
    },
    getComment: {
      method: 'GET',
      path: (params: { commentId: string | number }) =>
        `comments/${params.commentId}`,
    },
  },
  events: {
    guests: {
      inviteGuest: {
        method: 'POST',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/guests`,
      },
      updateGuest: {
        method: 'PATCH',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/guests`,
      },
      deleteGuest: {
        method: 'DELETE',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/guests`,
      },
    },
    hosts: {
      addHost: {
        method: 'POST',
        path: (params: { eventId: string | number; userId: string | number }) =>
          `events/${params.eventId}/hosts/${params.userId}`,
      },
      removeHost: {
        method: 'DELETE',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/hosts/3`,
      },
    },
    enrollment: {
      getEventEnrollements: {
        method: 'GET',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/enrollments`,
      },
      enrollInEvent: {
        method: 'POST',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/enrollments`,
      },
    },
    translations: {
      addEventTranslation: {
        method: 'POST',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/translations`,
      },
      updateEventTranslation: {
        method: 'PATCH',
        path: (params: {
          eventId: string | number;
          translationId: string | number;
        }) => `events/${params.eventId}/translations/${params.translationId}`,
      },
      deleteEventTranslation: {
        method: 'DELETE',
        path: (params: {
          eventId: string | number;
          translationId: string | number;
        }) => `events/${params.eventId}/translations/${params.translationId}`,
      },
    },
    favorite: {
      favoriteEvent: {
        method: 'POST',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/favorite`,
      },
      removeFavoriteFromEvent: {
        method: 'DELETE',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/favorite`,
      },
    },
    seen: {
      markEventAsSeen: {
        method: 'POST',
        path: (params: { eventId: string | number }) =>
          `events/${params.eventId}/seen`,
      },
    },
    'getEvents(C)': {
      method: 'GET',
      path: () => `events`,
      query: {} as {
        startTime?: string | number;
        endingTime?: string | number;
        skip?: string | number;
        take?: string | number;
        featuredOnly?: string | number;
        filterOutSeen?: string | number;
      },
    },
    'getEvent(C)': {
      method: 'GET',
      path: (params: { eventId: string | number }) =>
        `events/${params.eventId}`,
    },
    deleteEvent: {
      method: 'DELETE',
      path: (params: { eventId: string | number }) =>
        `events/${params.eventId}`,
    },
    updateEvent: {
      method: 'PATCH',
      path: (params: { eventId: string | number }) =>
        `events/${params.eventId}`,
    },
    'createEvent(REMOVED)': {
      method: 'POST',
      path: () => `events`,
    },
    takeAttendance: {
      method: 'POST',
      path: () => `events/attendance`,
    },
  },
  posts: {
    postComments: {
      createComment: {
        method: 'POST',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/comments`,
      },
      getPostComments: {
        method: 'GET',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/comments`,
        query: {} as { skip?: string | number; take?: string | number },
      },
    },
    saves: {
      savePost: {
        method: 'POST',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/saves`,
      },
      unsavePost: {
        method: 'DELETE',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/saves`,
      },
      getPostSaves: {
        method: 'GET',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/saves`,
        query: {} as { skip?: string | number; take?: string | number },
      },
    },
    likes: {
      likePost: {
        method: 'POST',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/likes`,
        query: {} as { skip?: string | number; take?: string | number },
      },
      unlikePost: {
        method: 'DELETE',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/likes`,
      },
      getPostLikes: {
        method: 'GET',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/likes`,
      },
    },
    pin: {
      pinPost: {
        method: 'POST',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/pin`,
      },
      unpinPost: {
        method: 'DELETE',
        path: (params: { postId: string | number }) =>
          `posts/${params.postId}/pin`,
      },
    },
    updatePost: {
      method: 'PATCH',
      path: (params: { postId: string | number }) => `posts/${params.postId}`,
    },
    deletePost: {
      method: 'DELETE',
      path: (params: { postId: string | number }) => `posts/${params.postId}`,
    },
    getSinglePost: {
      method: 'GET',
      path: (params: { postId: string | number }) => `posts/${params.postId}`,
    },
  },
  'groups(C)': {
    posts: {
      'getGroupPosts(C)': {
        method: 'GET',
        path: (params: { groupId: string | number }) =>
          `groups/${params.groupId}/posts`,
        query: {} as { skip?: string | number; take?: string | number },
      },
      'createGroupPost(C)': {
        method: 'POST',
        path: () => `groups/83/posts`,
      },
    },
    members: {
      joinAndLeave: {
        joinGroup: {
          method: 'POST',
          path: (params: { groupId: string | number }) =>
            `groups/${params.groupId}/members/join`,
        },
      },
      'getGroupMembers(C)': {
        method: 'GET',
        path: (params: { groupId: string | number }) =>
          `groups/${params.groupId}/members`,
        query: {} as {
          skip?: string | number;
          take?: string | number;
          status?: (string | number)[];
        },
      },
      groupMemberAction: {
        method: 'POST',
        path: (params: {
          groupId: string | number;
          memberId: string | number;
        }) => `groups/${params.groupId}/members/${params.memberId}`,
      },
      'inviteMemberByEmail(C)': {
        method: 'POST',
        path: (params: { groupId: string | number }) =>
          `groups/${params.groupId}/members/invite-member`,
      },
    },
    events: {
      getGroupEvents: {
        method: 'GET',
        path: (params: { groupId: string | number }) =>
          `groups/${params.groupId}/events`,
        query: {} as {
          skip?: string | number;
          take?: string | number;
          startTime?: string | number;
          endingTime?: string | number;
        },
      },
      createGroupEvent: {
        method: 'POST',
        path: (params: { groupId: string | number }) =>
          `groups/${params.groupId}/events`,
      },
    },
    'getGroup(C)': {
      method: 'GET',
      path: (params: { groupId: string | number }) =>
        `groups/${params.groupId}`,
    },
    'getGroups(C)': {
      method: 'GET',
      path: () => `groups`,
      query: {} as {
        skip?: string | number;
        take?: string | number;
        filterEnrolled?: string | number;
      },
    },
  },
  users: {
    groups: {
      'getUserGroups(c)': {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/groups`,
        query: {} as {
          skip?: string | number;
          take?: string | number;
          status?: (string | number)[];
        },
      },
      getUserGroupsEvents: {
        method: 'GET',
        header: {},
      },
    },
    posts: {
      getUserHomeFeed: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/home`,
        query: {} as { skip?: string | number; take?: string | number },
      },
      getUserLikedPosts: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/likes`,
        query: {} as { offset?: string | number; limit?: string | number },
      },
      getUserSavedPosts: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/saved-posts`,
        query: {} as { offset?: string | number; limit?: string | number },
      },
    },
    events: {
      getUserEnrolledEvents: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/enrolled-events`,
        query: {} as {
          skip?: string | number;
          take?: string | number;
          startTime?: string | number;
          endingTime?: string | number;
          groupId?: string | number;
        },
      },
      getUserFavoritedEvents: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/favorited-events`,
        query: {} as {
          skip?: string | number;
          take?: string | number;
          endingTime?: string | number;
          groupId?: string | number;
          startTime?: string | number;
        },
      },
      getAllUpcomingEventsForUserGroups: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/group-events`,
      },
    },
    comments: {
      getUserComments: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/comments`,
        query: {} as { limit?: string | number; offset?: string | number },
      },
    },
    preferences: {
      getUserPreferences: {
        method: 'GET',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/preferences`,
      },
      setUserPreferences: {
        method: 'POST',
        path: (params: { userId: string | number }) =>
          `users/${params.userId}/preferences`,
      },
    },
    getUser: {
      method: 'GET',
      path: (params: { userId: string | number }) => `users/${params.userId}`,
    },
    updateUser: {
      method: 'PATCH',
      path: () => `users/1`,
    },
    changePassword: {
      method: 'PATCH',
      path: () => `users/2358/password`,
    },
  },
};
