import { QuestionAnswerKey } from './question_answer_key';
import { RubricCriterion } from './rubric_criterion';
import { StudentGradeForQuestionRubricCriterion } from './student_grade_for_question_rubric_criterion';

export class QuestionRubricCriterion {
  id: number;

  questionAnswerKeyId: number;

  questionAnswerKey: QuestionAnswerKey;

  maxPoints: number;

  rubricCriterionId: number;

  criteria: RubricCriterion;

  studentGradedAnswers: StudentGradeForQuestionRubricCriterion[];

  createdAt: Date;

  updatedAt?: Date;
}
