import { CourseSubsection } from './course_subsection';
import { ScormCourseRegistration } from './scorm_course_registration';

export class ScormCourse {
  id: number;

  subsectionId?: number;

  subsection?: CourseSubsection;

  courseId?: number;

  title: string;

  importJobId?: string;

  importComplete?: boolean;

  previewUrl?: string;

  createdAt: Date;

  updatedAt?: Date;

  scormCourseRegistrations: ScormCourseRegistration[];
}
