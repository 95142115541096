import { Permission } from './permission';

export class PermissionPeer {
  id: number;

  permissionId: number;

  peerId: number;

  permission: Permission;

  peer: Permission;

  createdAt: Date;

  updatedAt?: Date;
}
