import { VideoConferenceType } from './video_conference_type';
import { CourseOnlineLecture } from './course_online_lecture';
import { ZoomMeeting } from './zoom_meeting';
import { ZoomWebinar } from './zoom_webinar';

export class VideoConference {
  id: number;

  videoConferenceTypeId?: number;

  videoConferenceType?: VideoConferenceType;

  itemId?: string;

  startTime: Date;

  duration: number;

  joinUrl: string;

  courseOnlineLectures?: CourseOnlineLecture;

  createdAt: Date;

  updatedAt?: Date;

  zoomMeetings: ZoomMeeting[];

  zoomWebinars: ZoomWebinar[];
}
