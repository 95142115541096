import { auth } from '@/router/auth.routes.enum';
import { checklist } from '@/router/checklist.routes.enum';
import { dashboard } from '@/router/dashboard.routes.enum';
import { financials } from '@/router/financials.routes.enum';
import { landing } from '@/router/landing.routes.enum';
import { libraryRoutesEnum } from '@/router/library.routes.enum';
import { lms } from '@/router/lms.routes.enum';
import { misc } from '@/router/miscellaneous.routes.enum';
import { mobile } from '@/router/mobile.routes.enum';
import { profile } from '@/router/profile.routes.enum';
import { social } from '@/router/social.routes.enum';
import { chatRoutesEnum } from '@/router/chat.routes.enum';
import { externalCourses } from '@/router/lms.externalCourses.enum';
import { journeyRoutesEnum } from '@/router/journey.routes.enum';

export const EnumRoutes = {
  auth,
  landing,
  lms,
  financials,
  social,
  profile,
  misc,
  dashboard,
  checklist,
  mobile,
  libraryRoutesEnum,
  chatRoutesEnum,
  externalCourses,
  journeyRoutesEnum,
};
