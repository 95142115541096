import { Checklist } from './checklist';
import { DueDateType } from './due_date_type';

export class ChecklistSettings {
  id: number;

  isRequired: boolean = true;

  checklistId: number;

  checklist: Checklist;

  dueDateTypeId?: number;

  dueDateType?: DueDateType;

  dueDateDays?: number;

  fixedDueDate?: Date;

  createdAt: Date;

  updatedAt?: Date;
}
