import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const profileRoutes: Array<RouteRecordRaw> = [
  {
    name: EnumRoutes.profile.root.name,
    path: EnumRoutes.profile.root.path,
    component: () => import('@/views/profile-dynamic/ProfilePage.vue'),
    meta: EnumRoutes.profile.root.meta,
  },
  {
    path: EnumRoutes.profile.favorite.root.path,
    name: EnumRoutes.profile.favorite.root.name,
    component: () => import('@/views/favorite/FavoritesPage.vue'),
    redirect: { name: EnumRoutes.profile.favorite.courses.name },
    meta: EnumRoutes.profile.favorite.root.meta,
    children: [
      {
        path: EnumRoutes.profile.favorite.events.path,
        name: EnumRoutes.profile.favorite.events.name,
        component: () => import('@/views/favorite/FavoritePageEvent.vue'),
      },
      {
        path: EnumRoutes.profile.favorite.courses.path,
        name: EnumRoutes.profile.favorite.courses.name,
        component: () => import('@/views/favorite/FavoritePageCourse.vue'),
      },
    ],
  },
  // activity log
  {
    path: EnumRoutes.profile.activityLog.root.path,
    name: EnumRoutes.profile.activityLog.root.name,
    component: () => import('@/views/activity-log/ActivityLog.vue'),
    meta: EnumRoutes.profile.activityLog.root.meta,
  },
  {
    path: EnumRoutes.profile.savedPost.root.path,
    name: EnumRoutes.profile.savedPost.root.name,
    component: () => import('@/views/social/groups/SavedPosts.vue'),
    meta: EnumRoutes.profile.savedPost.root.meta,
  },
  // my certificates
  {
    path: EnumRoutes.profile.myCertificates.root.path,
    name: EnumRoutes.profile.myCertificates.root.name,
    component: () => import('@/views/my-certificates/MyCertificates.vue'),
    meta: EnumRoutes.profile.myCertificates.root.meta,
  },
  // zoom policy
  {
    name: EnumRoutes.profile.zoomPolicy.name,
    path: EnumRoutes.profile.zoomPolicy.path,
    component: () =>
      import('@/views/profile-dynamic/components/zoom/ZoomPolicy.vue'),
    meta: EnumRoutes.profile.zoomPolicy.meta,
  },
];
