import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';
export const libraryRouter: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.libraryRoutesEnum.root.path,
    children: [
      {
        path: '',
        name: EnumRoutes.libraryRoutesEnum.root.name,
        component: () => import('@/views/Library/MainLibrary.vue'),
        meta: EnumRoutes.libraryRoutesEnum.root.meta,
      },
      {
        path: EnumRoutes.libraryRoutesEnum.video.path,
        name: EnumRoutes.libraryRoutesEnum.video.name,
        component: () => import('@/views/Library/LibraryVideo.vue'),
        meta: EnumRoutes.libraryRoutesEnum.video.meta,
      },
      {
        path: EnumRoutes.libraryRoutesEnum.article.path,
        name: EnumRoutes.libraryRoutesEnum.article.name,
        component: () => import('@/views/Library/LibraryArticle.vue'),
        meta: EnumRoutes.libraryRoutesEnum.article.meta,
      },
    ],
  },
];
