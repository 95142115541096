import { User } from './user';
import { CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';

export class CourseDiscussionBoardItemCommentLike {
  id: number;

  userId: number;

  user: User;

  discussionBoardCommentId: number;

  discussionBoardComment: CourseDiscussionBoardItemComment;

  createdAt: Date;

  updatedAt: Date;
}
