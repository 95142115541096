<script setup lang="ts">
  import { EnumRoutes } from '@/helpers/enums/routes.enum';
  import { useI18 } from '@/plugins';
  const { t } = useI18();
</script>

<template>
  <v-container>
    <v-card
      class="mx-auto pa-5"
      width="100%"
      max-width="600px"
      prepend-icon="mdi-lock-alert"
    >
      <template v-slot:title>
        {{ t('shared.notAuthorizedTitle') }}
      </template>

      <v-card-text>
        {{ t('shared.notAuthorizedText') }}
      </v-card-text>

      <v-card-actions class="px-4">
        <v-custom-btn
          color="primary"
          text
          :to="{ name: EnumRoutes.landing.root.name }"
        >
          {{ t('shared.home') }}
        </v-custom-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
