export enum EnumOrganizationPermissions {
  Everything = 'organization_everything',
  Enabled = 'organization_enabled',
  RegistrationEnabled = 'organization_registration_enabled',
  LMS = 'organization_lms',
  Coaching = 'organization_coaching',
  SocialPlatform = 'organization_social_platform',
  SocialEvents = 'organization_social_events',
  LearningPath = 'organization_learning_path',

  CRUDCourses = 'organization_crud_courses',
  CRUDExternalCourses = 'organization_crud_external_courses',
  CRUDMembers = 'organization_crud_members',
  CRUDSpecializations = 'organization_crud_specializations',
  CRUDSocialGroups = 'organization_crud_social_groups',
  CRUDSocialEvents = 'organization_crud_social_events',
  CRUDLearningPaths = 'organization_crud_learning_paths',
  CRUDChecklists = 'organization_crud_checklists',
  CRUDSupportTeams = 'organization_crud_support_teams',
}

export enum EnumOrganizationMemberPermissions {
  FullAccess = 'organization_member_full_access',

  // #User Portal Permissions //
  AccessCourses = 'organization_member_access_courses',
  AccessExternalCourses = 'organization_member_access_external_courses',
  AccessCommunities = 'organization_member_access_communities',
  AccessEvents = 'organization_member_access_events',
  AccessSkillsDevelopment = 'organization_member_access_skills_development',
  AccessCoaching = 'organization_member_access_coaching',
}
