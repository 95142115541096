<script lang="ts" setup>
  import { computed, inject, ref, type ComputedRef, type Ref } from 'vue';
  import SideMenuItems from './SideMenuItems.vue';

  const openMenu = ref(true);

  const rail = ref(false);
  const isLTR: Ref<boolean> = inject('isLTR', ref(true));
  const headerTitle: Ref<string> = inject('headerTitle', ref(''));
  const paddingTop: ComputedRef<number> | Ref<number> = inject(
    'paddingTop',
    ref(60)
  );
  const haveHeader: ComputedRef<boolean> | Ref<boolean> = inject(
    'haveHeader',
    ref(false)
  );
  const showToggleBtn: ComputedRef<boolean> | Ref<boolean> = inject(
    'showToggleBtn',
    ref(false)
  );

  const checkIcon = computed(() => {
    if (isLTR?.value)
      return rail.value ? 'mdi-chevron-right' : 'mdi-chevron-left';
    else return rail.value ? 'mdi-chevron-left' : 'mdi-chevron-right';
  });
  const positionAbsolute = inject('positionAbsolute', false);
  const positionRelative = inject('positionRelative', false);
  const emit = defineEmits<{
    (event: 'close'): void;
  }>();
  const updateDrawer = (value: boolean) => {
    if (value === false) emit('close');
  };
</script>

<template>
  <v-navigation-drawer
    v-model="openMenu"
    :absolute="positionAbsolute"
    :class="positionAbsolute || positionRelative ? '' : 'position-fixed'"
    :permanent="!positionAbsolute"
    :rail="rail"
    :style="{ 'padding-top': paddingTop + 'px' }"
    :temporary="positionAbsolute"
    aria-label="Menu-drawer"
    aria-owns="menu-drawer-items"
    class="drawer"
    role="listbox"
    style="box-shadow: 0px 0px 15px 0px #0000001a"
    @update:model-value="updateDrawer"
  >
    <div class="nav-drawer" role="option">
      <div class="d-flex justify-end mt-1 button-back" v-if="showToggleBtn">
        <v-btn
          :icon="checkIcon"
          aria-label="Close or open menu"
          color="primary"
          role="option"
          size="30"
          @click.stop="rail = !rail"
        ></v-btn>
      </div>
      <div
        :class="!rail ? 'px-4' : 'px-2'"
        class="pb-6 d-flex flex-column gap-3"
      >
        <div v-if="haveHeader || $slots.headerSubtitle">
          <div
            v-if="haveHeader"
            :title="headerTitle"
            class="d-flex align-center ga-3 w-100 justify-start mb-3"
            :class="rail ? 'px-0' : 'px-2'"
          >
            <slot v-if="$slots.prependIcon" name="prependIcon" />
            <div
              v-if="!rail && headerTitle"
              class="text-subtitle-1 font-weight-medium text-primary"
            >
              <span v-if="headerTitle?.length > 25">
                {{ headerTitle?.slice(0, 25) }}...
              </span>
              <span v-else>{{ headerTitle }}</span>
            </div>
            <slot v-if="$slots.appendIcon && !rail" name="appendIcon" />
          </div>
          <slot v-if="$slots.headerSubtitle && !rail" name="headerSubtitle" />
        </div>
        <SideMenuItems :rail="rail" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss">
  .button-back {
    opacity: 0;
  }

  .drawer {
    min-width: 61px;
    &:hover {
      .button-back {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }
  .v-navigation-drawer__content {
    /* Width of the scrollbar */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* For Firefox */
    & {
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;

      /* Handle on hover for Firefox */
      &:hover {
        scrollbar-color: #555 #f1f1f1;
      }
    }
  }

  .nav-drawer {
    .v-list-item--variant-text .v-list-item__overlay {
      background-color: grey !important;
    }

    .v-list-item:hover > .v-list-item__overlay {
      opacity: 0.08 !important;
    }

    .v-list-group__items .v-list-item {
      padding-inline-start: 8px !important;
    }

    .v-list-item__spacer {
      width: 16px !important;
    }
  }
</style>
