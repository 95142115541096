import { SocialEvent } from './social_event';
import { SocialEventAttendance } from './social_event_attendance';

export class SocialEventAttendanceItem {
  id: number;

  eventId: number;

  event: SocialEvent;

  date: Date;

  attendees: SocialEventAttendance[];

  createdAt: Date;

  updatedAt?: Date;
}
