import { EnumOrganizationMemberPermissions } from '@/helpers/enums/permissions.enum';

export const journeyRoutesEnum = {
  root: {
    name: 'journey root',
    path: '/journey',
    title: 'header.journey',
    icon: 'mdi-format-list-checks',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Journeys`,
      memberPermissions: [
        EnumOrganizationMemberPermissions.AccessSkillsDevelopment,
      ],
    },
  },
};
