import { Article } from './article';
import { TopicTag } from './topic_tag';

export class ArticleTag {
  id: number;

  articleId: number;

  article: Article;

  tagId: number;

  tag: TopicTag;
}
