import { CourseGrade } from './course_grade';
import { CourseGroup } from './course_group';
import { User } from './user';
import { Media } from './media';
import { GroupCourseGradeRubricAssessment } from './group_course_grade_rubric_assessment';
import { StudentCourseGrade } from './student_course_grade';
import { PlagiarismReport } from './plagiarism_report';

export class GroupCourseGrade {
  id: number;

  courseGradeId: number;

  courseGrade: CourseGrade;

  groupId: number;

  group: CourseGroup;

  instructorId?: number;

  instructor?: User;

  grade: number;

  comment?: string;

  mediaId?: number;

  media?: Media;

  isLinkedWithAssessment: boolean;

  groupCourseGradeRubricAssessment?: GroupCourseGradeRubricAssessment;

  createdAt: Date;

  updatedAt?: Date;

  studentCourseGrades: StudentCourseGrade[];

  plagiarismReportId?: number;

  plagiarismReport?: PlagiarismReport;
}
