export const examSystemApiHandler = {
  student: {
    attemptEvents: {
      getAttemptEvents: {
        method: 'GET',
        path: (payload: { examAttemptId: string | number }) =>
          `exams/${payload.examAttemptId}/events`,
      },
      createAttemptEvent: {
        method: 'POST',
        path: (payload: { examAttemptId: string | number }) =>
          `exams/${payload.examAttemptId}/events`,
      },
    },
    getPreviousExamAttempts: {
      method: 'GET',
      path: (payload: { subsectionExamId: string | number }) =>
        `exams/exam-instance/previous-attempts/${payload.subsectionExamId}`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    getExamInstance: {
      method: 'GET',
      path: (payload: { subsectionExamId: string | number }) =>
        `exams/exam-instance/${payload.subsectionExamId}`,
    },
    getOldExamAttempt: {
      method: 'GET',
      path: (payload: { examAttemptId: string | number }) =>
        `exams/${payload.examAttemptId}/start`,
    },
    createNewExamAttempt: {
      method: 'POST',
      path: (payload: { examInstanceId: string | number }) =>
        `exams/exam-instance/${payload.examInstanceId}/start`,
    },
    restartAttempt: {
      method: 'POST',
      path: (payload: { examInstanceId: string | number }) =>
        `exams/exam-instance/${payload.examInstanceId}/start-without-finishing-old-attempt`,
    },
    lastestQuestion: {
      method: 'GET',
      path: (payload: { examAttemptId: string | number }) =>
        `exams/${payload.examAttemptId}/latest`,
    },
    nextQuestion: {
      method: 'POST',
      path: (payload: { examAttemptId: string | number }) =>
        `exams/${payload.examAttemptId}/next`,
    },
    previousQuestion: {
      method: 'POST',
      path: (payload: { examAttemptId: string | number }) =>
        `exams/${payload.examAttemptId}/next`,
    },
    submitAnswer: {
      method: 'POST',
      path: (payload: { examAttemptId: string | number }) =>
        `exams/${payload.examAttemptId}/submit`,
    },
    getAllExamsInCourse: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `exams/course/${payload.courseId}`,
      params: {} as { skip?: string | number; take?: string | number },
    },
  },
  instructor: {
    exam: {
      createExam: {
        getExam: {
          method: 'GET',
          path: (payload: { subsectionExamId: string | number }) =>
            `exam/${payload.subsectionExamId}`,
        },
        createExam: {
          method: 'POST',
          path: () => `exam`,
        },
        editExam: {
          method: 'PATCH',
          path: (payload: { subsectionExamId: string | number }) =>
            `exam/${payload.subsectionExamId}/edit`,
        },
        editExamSettings: {
          method: 'PATCH',
          path: (payload: { subsectionExamId: string | number }) =>
            `exam/${payload.subsectionExamId}/settings`,
        },
        linkExamWithSubsection: {
          method: 'PATCH',
          path: (payload: {
            subsectionId: string | number;
            subsectionExamId: string | number;
          }) =>
            `course-subsections/${payload.subsectionId}/link/${payload.subsectionExamId}`,
        },
        getAllExamQuestions: {
          method: 'GET',
          path: (payload: { subsectionExamId: string | number }) =>
            `exam/${payload.subsectionExamId}/questions`,
        },
        deleteExam: {
          method: 'DELETE',
          path: (payload: { examId: string | number }) =>
            `exam/${payload.examId}`,
        },
      },
      reviewExams: {
        allExamsInCourse: {
          method: 'GET',
          path: (payload: { courseId: string | number }) =>
            `all-exams/${payload.courseId}`,
        },
        examQueries: {
          method: 'GET',
          path: (payload: { subsectionExamId: string | number }) =>
            `all-exams/instances/${payload.subsectionExamId}/query`,
          params: {} as {
            examAttemptId?: string | number;
            userId?: string | number;
            reviewSubmissions?: string | number;
            instructorAttempts?: string | number;
          },
        },
        answersThatNeedReview: {
          method: 'GET',
          path: (payload: { subsectionExamId: string | number }) =>
            `all-exams/instances/${payload.subsectionExamId}/review`,
        },
      },
    },
    questions_Q: {
      qsCRUD: {
        addManyQuestionsWithAnswers: {
          method: 'POST',
          path: () => `many-question-with-answers`,
        },
        'addQuestion+answer': {
          method: 'POST',
          path: () => `question-with-answers`,
        },
        getQuestionById: {
          method: 'GET',
          path: () => `question/1`,
        },
        sortQuestionsByIndex: {
          method: 'PATCH',
          path: () => `sort`,
        },
        updateQuestionWithAnswers: {
          method: 'PATCH',
          path: (payload: { examQuestionId: string | number }) =>
            `question-with-answers/${payload.examQuestionId}`,
        },
      },
      questionRubric: {
        createQuestioinRubric: {
          method: 'PATCH',
          path: () => `question-rubric/58`,
        },
        linkQuestionWithRubric: {
          method: 'POST',
          path: (payload: {
            questionAnswerKeyId: string | number;
            rubricId: string | number;
          }) =>
            `question-rubric/${payload.questionAnswerKeyId}/${payload.rubricId}`,
        },
        rubricCorrection: {
          method: 'PATCH',
          path: () => `submit-essay-grade`,
        },
        sendAnswerReviewById: {
          method: 'PATCH',
          path: () => `submit-essay-grade`,
        },
        getAnswerReviewById: {
          method: 'GET',
          path: (payload: { answerReviewId: string | number }) =>
            `answer-review/${payload.answerReviewId}`,
        },
        unlinkQuestionFromRubric: {
          method: 'DELETE',
          path: (payload: {
            questionAnswerKeyId: string | number;
            rubricId: string | number;
          }) =>
            `question-rubric/${payload.questionAnswerKeyId}/${payload.rubricId}`,
        },
      },
      qTypes: {
        'getAllQ-types': {
          method: 'GET',
          path: () => `question-types`,
        },
      },
      qCategories: {
        addNewCategory: {
          method: 'POST',
          path: () => `question-category`,
        },
        getAllCategories: {
          method: 'GET',
          path: () => `question-category`,
        },
      },
      qTags: {
        addNewTag: {
          method: 'POST',
          path: () => `question-tag`,
        },
        getAllTags: {
          method: 'GET',
          path: () => `question-tag`,
        },
      },
      getExamQuestionById: {
        method: 'GET',
        path: (payload: { examQuestionId: string | number }) =>
          `examquestion/${payload.examQuestionId}`,
      },
      addNewQuestion: {
        method: 'POST',
        path: () => `examquestion`,
      },
      editQuestion: {
        method: 'PATCH',
        path: (payload: { examQuestionId: string | number }) =>
          `examquestion/${payload.examQuestionId}`,
      },
      changeQuestionType: {
        method: 'PUT',
        path: (payload: { examQuestionId: string | number }) =>
          `examquestion/${payload.examQuestionId}`,
      },
      deleteQuestionById: {
        method: 'DELETE',
        path: (payload: { examQuestionId: string | number }) =>
          `examquestion/${payload.examQuestionId}`,
      },
    },
    answers: {
      addAnswer: {
        method: 'POST',
        path: () => `answers`,
      },
      updateAnswer: {
        method: 'PATCH',
        path: () => `answers/248`,
      },
      deleteAnswerById: {
        method: 'DELETE',
        path: (payload: { answerId: string | number }) =>
          `answers/${payload.answerId}`,
      },
    },
    getAllExamsInCourse: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/exams/instructor`,
      params: {} as { skip?: string | number; take?: string | number },
    },
  },
  courseExams: {
    studentGetAllExamsInCourse: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/exams`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    instructorGetAllExamsInCourse: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/exams/instructor`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    createExam: {
      method: 'POST',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/exams`,
    },
  },
  subsectionExams: {
    getSubsectionExam: {
      method: 'GET',
      path: (payload: { subsectionExamId: string | number }) =>
        `subsection-exam/${payload.subsectionExamId}`,
    },
    getSubsectionExamInstructor: {
      method: 'GET',
      path: (payload: { subsectionExamId: string | number }) =>
        `subsection-exam/${payload.subsectionExamId}/instructor`,
    },
    editSubsectionExamSettings: {
      method: 'PATCH',
      path: (payload: { subsectionExamId: string | number }) =>
        `subsection-exam/${payload.subsectionExamId}/settings`,
    },
    editSubsectionExam: {
      method: 'PATCH',
      path: (payload: { subsectionExamId: string | number }) =>
        `subsection-exam/${payload.subsectionExamId}`,
    },
  },
  generalQuestionData: {
    method: 'GET',
    path: () => `general-data`,
  },
};
