import { User } from './user';
import { CredentialType } from '../exported_type_enums/exported_type_enums';

export class CoachCertifications {
  id: number;

  credentialType?: CredentialType = CredentialType.CERTIFICATE;

  certificateName: string;

  issuingOrg?: string;

  issuedDate?: Date;

  expirationDate?: Date;

  credintialId?: string;

  credeintialUrl?: string;

  doesNotExpire: boolean = true;

  userId?: number;

  user?: User;
}
