<script setup lang="ts">
  import { ExternalUrlsEnum } from '@/assets/external-urls.enum';
  import { EnumRoutes } from '@/helpers/enums/routes.enum';
  import { useI18 } from '@/plugins';

  const { t } = useI18();
</script>

<template>
  <v-container>
    <v-row>
      <v-col lg="10" cols="12" class="mx-auto">
        <v-row class="mt-2 mt-sm-16">
          <v-col lg="6" md="12" cols="12">
            <div
              class="d-flex justify-center align-center flex-column gap-3 text-center"
            >
              <h1 class="text-grey text-h1 font-weight-bold">403</h1>
              <p class="text-primary text-h4 font-weight-medium">
                {{ t('shared.notAuthorizedTitle') }}
              </p>
              <p class="text-grey text-body-1 font-weight-medium">
                {{ t('shared.notAuthorizedText') }}
              </p>
            </div>
            <div class="d-flex justify-center align-center gap-2 mt-4">
              <v-custom-btn
                color="primary"
                text
                :to="{ name: EnumRoutes.auth.login.name }"
              >
                {{ t('shared.login') }}
              </v-custom-btn>
              <v-cancel-btn
                color="primary"
                text
                :to="{ name: EnumRoutes.auth.register.name }"
              >
                {{ t('shared.register') }}
              </v-cancel-btn>
            </div>
          </v-col>
          <v-col lg="6" md="12" cols="12">
            <v-img
              class="mt-10"
              :src="ExternalUrlsEnum.images.defaultImages.unauthorized"
              alt="unauthorized"
              width="100%"
              height="300px"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
