import { User } from './user';

export class UserRecentSearches {
  id: number;

  userId: number;

  user: User;

  createdAt: Date;

  search: string;

  isDeleted: boolean;
}
