import { User } from './user';
import {
  Language,
  LanguageProficiency,
} from '../exported_type_enums/exported_type_enums';

export class CoachLanguages {
  id: number;

  language: Language;

  proficiency?: LanguageProficiency = LanguageProficiency.ADVANCED;

  coachingAvailableInLangugage?: boolean;

  userId: number;

  user: User;
}
