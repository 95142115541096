import { Organization } from './organization';
import { OrganizationAnnouncementTranslation } from './organization_announcement_translation';
import { OrganizationAnnouncementDismissedUser } from './organization_announcement_dismissed_user';

export class OrganizationAnnouncement {
  id: number;

  body: string;

  externalLink?: string;

  startDate?: Date;

  endDate?: Date;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;

  translations: OrganizationAnnouncementTranslation[];

  dismissedBy: OrganizationAnnouncementDismissedUser[];
}
