import { User } from './user';
import { Post } from './post';

export class PostLikes {
  id: number;

  userId: number;

  postId: number;

  user: User;

  post: Post;

  createdAt: Date;

  updatedAt: Date;
}
