import { Order } from './order';
import { OrderPayment } from './order_payment';
import { OrderRefund } from './order_refund';
import { PurchasedItem } from './purchased_item';

export class FinancialStatus {
  id: number;

  nameEn: string;

  nameAr: string;

  slug: string;

  orders: Order[];

  payments: OrderPayment[];

  refunds: OrderRefund[];

  purchasedItems: PurchasedItem[];
}
