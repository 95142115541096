import { CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';
import { Media } from './media';

export class CourseDiscussionBoardItemCommentMedia {
  id: number;

  courseDiscussionBoardItemCommentId: number;

  courseDiscussionBoardItemComment: CourseDiscussionBoardItemComment;

  mediaId: number;

  media: Media;

  createdAt: Date;

  updatedAt?: Date;
}
