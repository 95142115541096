import { CoachingPositions } from './coaching_positions';
import { User } from './user';

export class CoachPositions {
  id: number;

  coachingPositionId: number;

  coachingPosition: CoachingPositions;

  user?: User;

  userId?: number;

  createdAt: Date;

  updatedAt: Date;
}
