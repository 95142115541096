import { OrganizationLink } from './organization_link';

export class LinkKey {
  id: number;

  name: string;

  key: string;

  organizationLinks: OrganizationLink[];

  createdAt: Date;

  updatedAt?: Date;
}
