import { Course } from './course';
import { Media } from './media';

export class CourseSyllabus {
  id: number;

  text?: string;

  courseId: number;

  course: Course;

  mediaId?: number;

  media?: Media;

  createdAt: Date;

  updatedAt?: Date;
}
