import { LibraryTags } from './library_tags';
import { Language } from '../exported_type_enums/exported_type_enums';

export class LibraryTagsTranslation {
  id: number;

  tagId: number;

  tag: LibraryTags;

  language: Language;

  name?: string;

  createdAt: Date;

  updatedAt: Date;
}
