export const miscApiHandler = {
  emailSubscriptions: {
    createEmailSubscription: {
      method: 'POST',
      path: () => `email-subscription`,
    },
  },
  library_OLD: {
    library: {
      method: 'GET',
      path: () => `library`,
    },
    libraryTags: {
      method: 'GET',
      path: () => `library/tags`,
    },
  },
  media: {
    uploadingLargeFiles: {
      getPresignedUrl_1: {
        method: 'POST',
        path: () => `media/presigned`,
      },
      putLargeFile_2: {
        method: 'PUT',
        path: () => `local/uploads/image/png/_edc478bc_img.png`,
        params: {} as {
          AWSAccessKeyId?: string | number;
          'Content-Type'?: string | number;
          Expires?: string | number;
          Signature?: string | number;
          'x-amz-acl'?: string | number;
        },
      },
      linkPresignedToDatabase_3: {
        method: 'PATCH',
        path: () => `media/presigned`,
      },
    },
    dropBox: {
      getAllUploadsByUser: {
        method: 'GET',
        path: () => `media/all-user-uploads`,
        params: {} as { take?: string | number; skip?: string | number },
      },
      updateMedia: {
        method: 'PATCH',
        path: (payload: { mediaId: string | number }) =>
          `media/${payload.mediaId}`,
      },
      swapMedia: {
        method: 'PATCH',
        path: (payload: { mediaId: string | number }) =>
          `media/swap/${payload.mediaId}`,
      },
    },
    uploadSmallFiles: {
      method: 'POST',
      path: () => `media/upload`,
    },
    generateTranscriptForMedia: {
      method: 'POST',
      path: (payload: { mediaId: string | number }) =>
        `media/${payload.mediaId}`,
    },
    getMediaById: {
      method: 'GET',
      path: (payload: { mediaId: string | number }) =>
        `media/${payload.mediaId}`,
    },
    deleteMediaById: {
      method: 'DELETE',
      path: (payload: { mediaId: string | number }) =>
        `media/${payload.mediaId}`,
    },
    convertAllImagesToWebp: {
      method: 'GET',
      path: () => `media/cleanServerFromLegacyImage`,
      params: {} as { take?: string | number },
    },
    uploadFilesNoAuth: {
      method: 'POST',
      path: () => `media/dump`,
    },
  },
  importingExternalContent: {
    canvas: {
      uploadCourseZip: {
        method: 'POST',
        path: () => `canvas/upload`,
        params: {} as { details?: string | number; upload?: string | number },
      },
      uploadCourseV2: {
        method: 'POST',
        path: () => `canvas/upload/v2`,
        params: {} as {
          details?: string | number;
          upload?: string | number;
          mediaId?: string | number;
        },
      },
      useCourseBasePathInServer: {
        method: 'GET',
        path: () => `canvas/convertedCourse`,
        params: {} as {
          basepath?: string | number;
          details?: string | number;
          upload?: string | number;
        },
      },
    },
    sCORM: {
      courses: {
        uploadCourseZip: {
          method: 'POST',
          path: () => `scorm/courses/upload`,
        },
        getScormCourse: {
          method: 'GET',
          path: (payload: { scormcourseId: string | number }) =>
            `scorm/courses/${payload.scormcourseId}`,
        },
      },
      registrations: {
        registerForCourse: {
          method: 'GET',
          path: (payload: { scormcourseId: string | number }) =>
            `scorm/courses/${payload.scormcourseId}/register`,
        },
        launchCourse: {
          method: 'GET',
          path: (payload: { scormCourseId: string | number }) =>
            `scorm/courses/${payload.scormCourseId}/launch`,
        },
        progress: {
          method: 'GET',
          path: (payload: { scormcourseId: string | number }) =>
            `scorm/courses/${payload.scormcourseId}/progress`,
        },
        getRegisteredStudentsAndTheirProgress: {
          method: 'GET',
          path: (payload: { scormcourseId: string | number }) =>
            `scorm/courses/${payload.scormcourseId}/sync`,
        },
      },
    },
  },
  notifications: {
    firebaseLiveUpdates: {
      getUserDoc_1: {
        method: 'GET',
        path: () => `notifications/user-doc`,
      },
      dONTTouchDeleteUsers: {
        method: 'DELETE',
        path: () => `notifications/users`,
      },
      getDataFromFirebase: {
        method: 'GET',
        path: () => `notifications/user-doc`,
      },
      sendFcmTokenToServer_2: {
        method: 'POST',
        path: () => `notifications/add-token`,
      },
      testYourIntegration_3: {
        method: 'GET',
        path: () => `notifications/notification`,
      },
      updateNotiCount_4: {
        method: 'POST',
        path: () => `firebase/notification/1`,
      },
      createNotificationObject: {
        method: 'POST',
        path: () => `firebase/create-notificationobject`,
      },
      sendMessageToTopic: {
        method: 'POST',
        path: () => `notifications/send-to-topic`,
      },
      deleteFCMToken: {
        method: 'DELETE',
        path: (payload: { fcmToken: string | number }) =>
          `notifications/fcmtoken/${payload.fcmToken}`,
      },
      getAllActiveUsers: {
        method: 'GET',
        path: () => `notifications/active-users`,
      },
    },
    himamNotificationCRUD: {
      getNotifications: {
        method: 'GET',
        path: () => `notifications`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          portals?: (string | number)[];
          language?: string | number;
        },
      },
      getNotificationsv2: {
        method: 'GET',
        path: () => `notifications/v2`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          portals?: (string | number)[];
        },
      },
      getNotificationCount: {
        method: 'GET',
        path: () => `notifications/count`,
        params: {} as { portals?: (string | number)[] },
      },
      markAllNotificationsAsRead: {
        method: 'POST',
        path: () => `notifications/read`,
      },
      markNotificationAsRead: {
        method: 'POST',
        path: (payload: { notificationId: string | number }) =>
          `notifications/read/${payload.notificationId}`,
      },
      'delete/HideNotification': {
        method: 'DELETE',
        path: (payload: { notificationId: string | number }) =>
          `notifications/${payload.notificationId}`,
      },
    },
    getNotificationTemplates: {
      method: 'GET',
      path: () => `notifications/templates`,
    },
    subdomain: {
      method: 'POST',
      path: () => `notifications/list`,
    },
  },
  toolTips: {
    getAll: {
      method: 'GET',
      path: () => `tooltips/en`,
    },
    getById: {
      method: 'GET',
      path: (payload: { tooltip_id: string | number }) =>
        `tooltips/${payload.tooltip_id}`,
    },
  },
  technicalSupport: {
    issueTicket: {
      method: 'POST',
      path: () => `technical-support`,
    },
    updateTicketStatus: {
      method: 'PATCH',
      path: () => `technical-support`,
    },
    getAllTickets: {
      method: 'GET',
      path: () => `technical-support`,
    },
    getTicketByUUID: {
      method: 'GET',
    },
  },
  cache: {
    v2: {
      fetch: {
        method: 'GET',
        path: () => `cache2/fetch`,
        params: {} as { pattern?: string | number },
      },
      mutate: {
        method: 'POST',
        path: () => `cache2/mutate`,
        params: {} as { pattern?: string | number },
      },
    },
    clearCache: {
      method: 'POST',
      path: () => `cache/clear`,
    },
    getKeys: {
      method: 'POST',
      path: () => `cache/get-keys`,
      params: {} as { pattern?: string | number },
    },
    getKeysCopy: {
      method: 'POST',
      path: () => `cache/get-key-value`,
      params: {} as { pattern?: string | number },
    },
    deleteKeys: {
      method: 'POST',
      path: () => `cache/delete-keys`,
    },
    deleteKeysPattern: {
      method: 'POST',
      path: () => `cache/delete-keys-patterns`,
    },
  },
  permissionsSystem: {
    permissionContexts: {
      getAllPermissionContexts: {
        method: 'GET',
        path: () => `permission-contexts`,
      },
    },
    permissionCategories: {
      getAllPermissionCategories: {
        method: 'GET',
        path: () => `permission-categories`,
        params: {} as { isHidden?: string | number },
      },
    },
    permissionSets: {
      getAllPermissionSets: {
        method: 'GET',
        path: () => `permission-sets`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          contexts?: (string | number)[];
          organizationIds?: (string | number)[];
        },
      },
      getSinglePermissionSet: {
        method: 'GET',
        path: (payload: { permissionSetId: string | number }) =>
          `permission-sets/${payload.permissionSetId}`,
      },
      updatePermissionSet: {
        method: 'PATCH',
        path: (payload: { permissionSetId: string | number }) =>
          `permission-sets/${payload.permissionSetId}`,
      },
    },
    permissions: {
      getAllPermissions: {
        method: 'GET',
        path: () => `permissions`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          contexts?: (string | number)[];
          categories?: (string | number)[];
          isHidden?: string | number;
        },
      },
    },
  },
  ouroboros: {
    test: {
      method: 'POST',
      path: () => `ouroboros/test`,
    },
    rebuildMain: {
      method: 'POST',
      path: () => `ouroboros/rebuild-main`,
    },
    testingThrowError: {
      method: 'POST',
      path: () => `ouroboros/throw`,
    },
  },
  slack: {
    editLoggingSettings: {
      method: 'POST',
      path: () => `slack/settings`,
    },
  },
  frontEndLogger: {
    frontEndCreateLog: {
      method: 'POST',
      path: () => `frontend-logger`,
    },
  },
  notificationsSystem: {
    notificationControlGroups: {
      notificationSettings: {
        getUserNotificationSettings: {
          method: 'GET',
          path: () => `notification-control-groups/settings`,
        },
        updateUserNotificationSettings: {
          method: 'PATCH',
          path: () => `notification-control-groups/settings`,
        },
      },
      getNotificationControlGroups: {
        method: 'GET',
        path: () => `notification-control-groups`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          portals?: (string | number)[];
          language?: string | number;
        },
      },
    },
    testNotification: {
      method: 'POST',
      path: () => `notifications-repository`,
    },
  },
  sSE: {
    subscribeToSSE: {
      method: 'GET',
      path: () => `sse`,
      params: {} as { token?: string | number },
    },
  },
  openAi: {
    test: {
      method: 'POST',
      path: () => `open-ai/test`,
    },
  },
  migrate_DontTouch: {
    method: 'GET',
    path: () => `migration`,
  },
  testEmail: {
    method: 'POST',
    path: () => `send-grid-mailer`,
  },
  getStudentCustomConfigByKey: {
    method: 'GET',
    path: (payload: {
      organizationId: string | number;
      key: string | number;
    }) =>
      `organizations-dashboard/custom-configs/${payload.organizationId}/${payload.key}`,
  },
};
