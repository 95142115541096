import type {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';
import { EnumRoutes } from '@/helpers/enums/routes.enum';
import { pinia } from '@/pinia';
import { chatRouter } from '@/router/chat.router';
import { hiddenRoutes, initializeHiddenRoutes } from '@/router/hidden.router';
import { libraryRouter } from '@/router/library.router';
import { useAuthStore } from '@/stores/auth.store';
import { useOrganizationStore } from '@/stores/organzation.pinia';
import { usePermissionsStore } from '@/stores/permissoins.store';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { authRoutes } from './auth.router';
import { checklistRoutes } from './checklist.router';
import { dashboardRoutes } from './dashboard.router';
import { financialRoutes } from './financials.router';
import { landingPageRoutes } from './landing.router';
import { lmsRoutes } from './lms.router';
import { miscellaneousRoutes } from './miscellaneous.router';
import { mobileRoutes } from './mobile.router';
import { profileRoutes } from './profile.router';
import { socialRoutes } from './social.router';
import { redirectAfterSuperToken } from './router-after-super-token.router';
import { externalCoursesRoutes } from './lms.externalCourses.route';
import { journeyRoutes } from '@/router/journey.router';
declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    requiresAuth?: boolean;
    memberPermissions?: Array<EnumOrganizationMemberPermissions>;
    organizationPermissions?: Array<EnumOrganizationPermissions>;
    needLanguage?: boolean;
    shouldHaveGroup?: boolean;
    groupMeta?: RouteMeta[];
    shouldHaveMarketplace?: boolean;
    shouldHaveSelfEnrollment?: boolean;
    dontUseKey?: boolean;
    shouldAllowAnonymousCourseView?: boolean;
    shouldAllowAnonymousEventView?: boolean;
    shouldHaveCertificatesEnabled?: boolean;
    shouldHaveRegistrationEnabled?: boolean;
    // Add other properties as needed...
  }
}
initializeHiddenRoutes();
const routes: Array<RouteRecordRaw> = [
  landingPageRoutes,
  authRoutes,
  lmsRoutes,
  financialRoutes,
  socialRoutes,
  profileRoutes,
  miscellaneousRoutes,
  dashboardRoutes,
  libraryRouter,
  checklistRoutes,
  mobileRoutes,
  chatRouter,
  externalCoursesRoutes,
  journeyRoutes,
].flat(3);
// remove all hidden routes from the routes array
const filteredRoutes: Array<RouteRecordRaw> = [];
routes.forEach((route) => {
  //check the route name if it is in the hidden routes array and if it has children then check the children names as well and remove the children if they are in the hidden routes array
  //check if has children
  if (route.children) {
    const children: Array<RouteRecordRaw> = [];
    //check children name if it is in the hidden routes array
    route.children.forEach((child) => {
      if (!hiddenRoutes.includes(child.name as string)) {
        children.push(child);
      }
    });
    route.children = children;
  }
  filteredRoutes.push(route);
});

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_API_BASE_URL),
  routes: filteredRoutes,
});

router.beforeEach(async (to, from, next) => {
  const orgStore = useOrganizationStore(pinia);
  if (to.name === EnumRoutes.lms.root.name) {
    if (orgStore.selfEnrollmentEnabled)
      next({
        name: EnumRoutes.lms.allCourses.name,
      });
    else
      next({
        name: EnumRoutes.lms.myCourses.name,
      });
    return;
  }
  if (to.name === EnumRoutes.externalCourses.root.name) {
    if (orgStore.selfEnrollmentEnabled)
      next({
        name: EnumRoutes.externalCourses.allExternalCourses.name,
      });
    else
      next({
        name: EnumRoutes.externalCourses.myExternalCourses.name,
      });
    return;
  }
  if (to.query?.super_token) {
    const onlyView = !!to.query?.student_view;
    if (onlyView) {
      localStorage.setItem('student_view', 'true');
      useAuthStore(pinia).instructorView = true;
    } else {
      sessionStorage.removeItem('instructorView');
      useAuthStore(pinia).instructorView = false;
    }
    await useAuthStore(pinia).loginSSO(`${to.query.super_token}`, onlyView);
    return next(redirectAfterSuperToken(to));
  }

  //handle ssoid token login
  if (to.query?.ssoid) {
    const isLoggedin = useAuthStore(pinia).user;
    // if the user is already logged in, then continue to the next route, but also send the ssoid token to the server
    if (isLoggedin) {
      await useAuthStore(pinia).handleSAMLSSO(`${to.query.ssoid}`);
    } else {
      //store the ssoid token in the local storage
      localStorage.setItem('ssoid', `${to.query.ssoid}`);

      // redirect to the login page and then login the user
      next({ name: EnumRoutes.auth.login.name });

      // ==> HELP still need to intercept the login process and send the ssoid token to the server after they login, and delete the ssoid token from the local storage
    }
    return

  }


  // if the user is not logged in and the next route doesn't require authentication, then remove the previous route
  if (
    !to.matched.some(
      (record) =>
        record.name === EnumRoutes.auth.root.name ||
        record.name === EnumRoutes.auth.register.name
    )
  ) {
    const authStore = useAuthStore(pinia);
    authStore.setPreviousRoute(null);
  }
  const permissionsStore = usePermissionsStore(pinia);
  // if we enter the site for the first time and there is no permissions in the locale storage we will fetch them
  if (
    localStorage.getItem('systemPermissions') === null ||
    localStorage.getItem('userPermissions') === null
  ) {
    await permissionsStore.initializePermissions();
  }
  to.meta.name
    ? (document.title = to.meta.name as string)
    : (document.title = import.meta.env.VITE_DEFAULT_TITLE as string);
  const meta = to.meta;
  permissionsStore.checkAuthentication(meta, true, to.fullPath);
  // if the route requires the user to be logged out, and the user is login, then redirect to the dashboard
  if (
    to.matched.some((record) => record.meta.requiresLogout) &&
    useAuthStore().user
  ) {
    next({ name: EnumRoutes.dashboard.root.name });
  } else next();
});
router.afterEach((to) => {
  const permissionsStore = usePermissionsStore(pinia);
  const authStore = useAuthStore(pinia);
  to.meta.name
    ? (document.title = to.meta.name as string)
    : (document.title = import.meta.env.VITE_DEFAULT_TITLE as string);
  const meta = to.meta;
  const auth = permissionsStore.checkAuthentication(meta, true, to.fullPath);
  if (auth) {
    authStore.shouldLogged = false;
    authStore.shouldHavePermission = false;
  }
});
export default router;
