import { ExamSettings } from './exam_settings';

export class ExamGradingSetttings {
  id: number;

  name: string;

  nameAr?: string;

  isAverageScore: boolean;

  isHighestScore: boolean;

  isLatestScore: boolean = true;

  createdAt: Date;

  updatedAt?: Date;

  examSettings: ExamSettings[];
}
