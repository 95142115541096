import { TermKey } from './term_key';

export class TermKeyCategory {
  id: number;

  slug: string;

  name: string;

  createdAt: Date;

  updatedAt?: Date;

  termKeys: TermKey[];
}
