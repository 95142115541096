import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { CourseWithUserData } from '@/helpers/interfaces/course.interface';
import type {
  CourseStatisitcs,
  EnrolledCourses,
} from '@/helpers/interfaces/dashboard.interface';
import type { StudentActivities } from '@/helpers/interfaces/schedule.interface';
import { axiosI } from '@/plugins';
import { dashboardApiHandler } from '@/postman-to-ts/apiHandler/dashboard';
import { scheduleApiHandler } from '@/postman-to-ts/apiHandler/schedule';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';
import { usePermissionsStore } from '@/stores/permissoins.store';
const studentActivities = createQueryKeys('studentActivities', {
  getStudentActivities: (
    params: (typeof scheduleApiHandler)['student:AllActivitiesSchedule:Assignments,Exams,CoachingSessions,Events']['params'],
    userId: number
  ) => ({
    queryKey: ['studentActivities', ...Object.values(params)],
    queryFn: async () => {
      const { path, method } =
        scheduleApiHandler[
          'student:AllActivitiesSchedule:Assignments,Exams,CoachingSessions,Events'
        ];
      return (
        await axiosI.get<StudentActivities>(path({ userId }), {
          method,
          params: params,
        })
      ).data;
    },
  }),
  getStudentCourseStatistics: (userId: number) => ({
    queryKey: ['studentCourseStatistics'],
    queryFn: async () => {
      const { path, method } = dashboardApiHandler.getStudentCourseStatistics;
      return (
        await axiosI.get<CourseStatisitcs>(path({ userId }), {
          method,
        })
      ).data;
    },
  }),
  getRecommendedCourses: (
    params: typeof dashboardApiHandler.getRecommendedCourses.params
  ) => ({
    queryKey: ['recommendedCourses'],
    queryFn: async () => {
      const { path, method } = dashboardApiHandler.getRecommendedCourses;
      if (
        usePermissionsStore().checkAuthentication(
          EnumRoutes.lms.allCourses.meta
        )
      )
        return (
          await axiosI<{
            count: number;
            data: CourseWithUserData[];
          }>(path(), {
            method,
            params,
          })
        ).data.data;
      else return [];
    },
  }),
  getEnrolledCoursesBasedOnProgress: (
    userId: string | number,
    params: typeof dashboardApiHandler.getEnrolledCoursesBasedOnProgress.params
  ) => ({
    queryKey: ['enrolledCoursesBasedOnProgress'],
    queryFn: async () => {
      const { path, method } =
        dashboardApiHandler.getEnrolledCoursesBasedOnProgress;
      return (
        await axiosI<{
          count: number;
          data: CourseWithUserData[];
        }>(path({ userId }), {
          method,
          params,
        })
      ).data.data;
    },
  }),
});

export const scheduleQueryKeys = mergeQueryKeys(studentActivities);
