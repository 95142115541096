import { VideoConference } from './video_conference';
import { Course } from './course';
import { CourseSubsection } from './course_subsection';
import { CourseOnlineLectureType } from '../exported_type_enums/exported_type_enums';

export class CourseOnlineLecture {
  id: number;

  startTime: Date;

  duration: number;

  title: string;

  content?: string;

  markedAsComplete: boolean;

  type: CourseOnlineLectureType = CourseOnlineLectureType.EXTERNAL;

  externalLink?: string;

  videoConferenceId?: number;

  videoConference?: VideoConference;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;

  subsections: CourseSubsection[];
}
