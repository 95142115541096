import { Question } from './question';
import { LearningOutcome } from './learning_outcome';

export class QuestionLearningOutcome {
  id: number;

  questionId: number;

  question: Question;

  learningOutcomeId: number;

  learningOutcome: LearningOutcome;

  createdAt: Date;

  updatedAt?: Date;
}
