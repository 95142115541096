import { EnumOrganizationMemberPermissions } from '@/helpers/enums/permissions.enum';

export const dashboard = {
  root: {
    name: 'Dashboard Page',
    path: '/dashboard',
    title: 'header.dashboard',
    icon: 'mdi-view-dashboard-outline',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Dashboard`,
      requiresAuth: true,

      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      dontUseKey: true,
    },
  },
  userActivities: {
    name: 'User Activities',
    path: '/dashboard/user-activities',
    title: 'header.myActivities',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Tasks`,
      requiresAuth: true,
      memberPermissions: [
        EnumOrganizationMemberPermissions.AccessCourses,
        EnumOrganizationMemberPermissions.AccessEvents,
      ],
    },
  },
};
