<script lang="ts" setup>
  import type { ComputedRef, Ref } from 'vue';
  import { inject, ref } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';
  const showNotificationsList = ref(false);
  const unreadCount: Ref<number> = inject('unreadCount', ref(0));

  const notificationsText: Ref<string> = inject(
    'notificationsText',
    ref('Notifications')
  );
  const allNotificationsText: Ref<string> = inject(
    'allNotificationsText',
    ref('All')
  );
  const unreadNotificationsText: Ref<string> = inject(
    'unreadNotificationsText',
    ref('Unread')
  );
  const markAllAsReadText: Ref<string> = inject(
    'markAllAsReadText',
    ref('Mark allas read')
  );
  const seeAllNotificationsText: Ref<string> = inject(
    'seeAllNotificationsText',
    ref('See All Notifications')
  );
  const markAllAsRead: () => void = inject('markAllAsRead', () => {});

  const seeAllNotificationsRoute: ComputedRef<RouteLocationRaw> | undefined =
    inject('seeAllNotificationsRoute');
  const getNotifications: () => void = inject('getNotifications', () => {});
  enum tabValue {
    all = 'all',
    unread = 'unread',
    settings = 'settings',
  }

  const tab = ref(tabValue.all);

  import NotificationList from './NotificationList.vue';

  export interface INotificationList {
    to: RouteLocationRaw;

    id: number;
    readAt?: string;
    img: string;
    text: string;
    createdAt: string;
  }

  const notificationsList: Ref<INotificationList[]> = inject(
    'notificationsList',
    ref([])
  );

  const unReadNotificationsList: Ref<INotificationList[]> = inject(
    'unReadNotificationsList',
    ref([])
  );

  import NotificationsSettings from './NotificationsSettings.vue';
</script>

<template>
  <v-menu
    v-model="showNotificationsList"
    :open-on-click="true"
    :scrim="true"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        @click="getNotifications"
        color="text"
        class="text-none px-2"
        density="compact"
        min-width="0"
        stacked
        v-bind="props"
        :class="unreadCount && unreadCount > 9 ? 'me-2' : ''"
      >
        <v-badge
          v-if="unreadCount"
          :color="unreadCount && unreadCount > 0 ? 'red' : 'transparent'"
          max="9"
          :content="unreadCount"
          rounded="rounded"
          dir="ltr"
        >
          <v-icon color="primary" icon="mdi-bell-outline"></v-icon>
        </v-badge>
        <v-icon v-else color="primary" icon="mdi-bell-outline"></v-icon>
      </v-btn>
    </template>

    <v-sheet class="rounded-lg" width="350">
      <v-toolbar color="transparent" density="compact">
        <v-toolbar-title class="text-subtitle-1 text-primary" @click.stop>
          {{ notificationsText }}
        </v-toolbar-title>
        <v-toolbar-items v-if="unreadCount > 0" variant="text">
          <v-btn class="text-caption text-text" @click="markAllAsRead">
            {{ markAllAsReadText }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-tabs
        v-model="tab"
        :fixed-tabs="false"
        :grow="false"
        :style="'color: #808080'"
        class="text-capitalize"
        color="primary"
        density="compact"
        slider-color="primary"
      >
        <v-row class="align-center" @click.stop>
          <v-tab
            :value="tabValue.all"
            class="text-capitalize text-decoration-none"
          >
            {{ allNotificationsText }}
          </v-tab>
          <v-divider class="my-4 mx-2" color="primary" vertical></v-divider>
          <!-- Unread Tab -->
          <v-badge :color="'primary'" :content="unreadCount" inline>
            <v-tab
              :value="tabValue.unread"
              class="text-capitalize text-decoration-none"
            >
              {{ unreadNotificationsText }}
            </v-tab>
          </v-badge>
          <v-spacer></v-spacer>

          <v-btn-text
            icon
            :ripple="false"
            :color="tab == tabValue.settings ? 'primary' : 'text'"
            class="me-2"
            v-model="tab"
            :value="tabValue.settings"
            @click="() => (tab = tabValue.settings)"
          >
            <v-icon icon="mdi-cog-outline" />
          </v-btn-text>
        </v-row>
      </v-tabs>

      <v-divider class="mb-2" @click.stop></v-divider>

      <v-window v-model="tab">
        <v-window-item :value="tabValue.all">
          <NotificationList :notifications-list="notificationsList" />
        </v-window-item>
        <v-window-item :value="tabValue.unread">
          <NotificationList :notifications-list="unReadNotificationsList" />
        </v-window-item>
        <v-window-item :value="tabValue.settings" @click.stop>
          <NotificationsSettings />
        </v-window-item>
      </v-window>

      <div v-if="tab != tabValue.settings" class="d-flex justify-end">
        <v-btn
          :to="seeAllNotificationsRoute"
          class="text-caption text-decoration-underline text-text"
          variant="text"
          @click.prevent="showNotificationsList = false"
        >
          {{ seeAllNotificationsText }}
        </v-btn>
      </div>
    </v-sheet>
    <slot name="notificationList" />
  </v-menu>
</template>
