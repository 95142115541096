import { Media } from './media';
import { FontFamily } from './font_family';

export class FontFamilyStyle {
  id: number;

  weight: number = 400;

  isItalic: boolean;

  mediaId?: number;

  media?: Media;

  fontFamilyId: number;

  fontFamily: FontFamily;

  createdAt: Date;

  updatedAt?: Date;
}
