import { OrganizationRegistrationFormField } from './organization_registration_form_field';
import { FormFieldTypeModifier } from './form_field_type_modifier';

export class OrganizationRegistrationFormFieldModifier {
  id: number;

  organizationRegistrationFormFieldId: number;

  organizationRegistrationFormField: OrganizationRegistrationFormField;

  formFieldTypeModifierId: number;

  formFieldTypeModifier: FormFieldTypeModifier;

  modifierValue?: object;

  createdAt: Date;

  updatedAt?: Date;
}
