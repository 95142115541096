export const financials = {
  cartPage: {
    name: 'Cart Page',
    path: '/cart',
    title: 'cart.myShoppingCart',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Cart`,
      requiresAuth: true,
      shouldHaveMarketplace: true,
    },
  },
  paymentHistory: {
    name: 'Payment History',
    title: 'menu.paymentHistory',
    icon: 'mdi-cash',
    path: '/profile/payment-history',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Payment History`,
      requiresAuth: true,
      shouldHaveMarketplace: true,
    },
  },
  singleOrder: {
    name: 'Single Order',
    title: 'menu.singleOrder',
    path: '/profile/payment-history/:orderId',
    pathFn: (orderId: string | number) => `/profile/payment-history/${orderId}`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Order's Detail`,
      requiresAuth: true,
      shouldHaveMarketplace: true,
    },
  },
};
