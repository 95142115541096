import { PollChoice } from './poll_choice';
import { User } from './user';
import { Poll } from './poll';

export class PollVote {
  id: number;

  choiceId: number;

  choice: PollChoice;

  userId: number;

  user: User;

  pollId: number;

  poll: Poll;

  createdAt: Date;

  updatedAt?: Date;
}
