import { VideoConferenceType } from './video_conference_type';

export class VideoConferencingTool {
  id: number;

  slug: string;

  name: string;

  description?: string;

  isEnabled: boolean;

  createdAt: Date;

  updatedAt?: Date;

  videoConferenceTypes: VideoConferenceType[];
}
