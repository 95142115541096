import { EnumSnackbarType } from '@/helpers/enums/misc.enum';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';
import { useI18 } from '@/plugins';
interface ISnackbarStore {
  message: Ref<string>;
  type: Ref<EnumSnackbarType>;
  showToast: (params: {
    msg: string;
    timeout?: number;
    snackbarType: EnumSnackbarType;
    translate?: boolean;
  }) => void;
  clearMessage: Function;
}

export const useSnackbarStore = defineStore(
  'snackbarStore',
  (): ISnackbarStore => {
    const message = ref('');
    const type = ref(EnumSnackbarType.SUCCESS);
    const clearMessage = () => {
      message.value = '';
    };
    const { t } = useI18();
    const showToast = ({
      msg,
      snackbarType,
      timeout,
      translate,
    }: {
      msg: string;
      snackbarType: EnumSnackbarType;
      timeout?: number | undefined;
      translate?: boolean;
    }) => {
      type.value = snackbarType;
      message.value = translate ? t(msg) : msg;
      setTimeout(() => {
        clearMessage();
      }, timeout ?? 3000);
    };

    return {
      message,
      type,
      showToast,
      clearMessage,
    };
  }
);
