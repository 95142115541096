import { QuestionLearningOutcome } from './question_learning_outcome';
import { ExamLearningOutcome } from './exam_learning_outcome';

export class LearningOutcome {
  id: number;

  name: string;

  description?: string;

  code?: string;

  createdAt: Date;

  updatedAt?: Date;

  questions: QuestionLearningOutcome[];

  exams: ExamLearningOutcome[];
}
