import { User } from './user';
import { Organization } from './organization';
import { FinancialStatus } from './financial_status';
import { PurchasedItem } from './purchased_item';
import { OrderPayment } from './order_payment';
import { OrderRefund } from './order_refund';
import { QoyodInvoice } from './qoyod_invoice';
import { Media } from './media';
import { OrderInvoice } from './order_invoice';

export class Order {
  id: number;

  user: User;

  userId: number;

  paidAmount?: number;

  amountWithoutTax?: number;

  taxAmount?: number;

  totalAmount?: number;

  discountAmount?: number;

  finalAmount?: number;

  isDeleted: boolean;

  organizationId?: number;

  organization?: Organization;

  statusSlug: string;

  status: FinancialStatus;

  currency: string = 'SAR';

  isTrxRecognized?: boolean;

  purchasedItems: PurchasedItem[];

  payments: OrderPayment[];

  refunds: OrderRefund[];

  createdAt: Date;

  updatedAt: Date;

  qoyodInvoice?: QoyodInvoice;

  invoiceId?: number;

  invoice?: Media;

  orderInvoice?: OrderInvoice;
}
