import { PurchasedItem } from './purchased_item';

export class PurchasedItemMetadata {
  id: number;

  purchasedItemId: number;

  purchasedItem: PurchasedItem;

  title: string;

  titleAr: string;

  imageUrl?: string;

  description?: string;

  descriptionAr?: string;

  startsAt: Date;

  oldPrice?: number;
}
