import { CourseGrade } from './course_grade';
import { User } from './user';
import { GroupCourseGrade } from './group_course_grade';
import { Media } from './media';
import { StudentCourseGradeRubricAssessment } from './student_course_grade_rubric_assessment';
import { StudentSubsectionExamGrade } from './student_subsection_exam_grade';

export class StudentCourseGrade {
  id: number;

  courseGradeId: number;

  courseGrade: CourseGrade;

  studentId: number;

  student: User;

  instructorId?: number;

  instructor?: User;

  groupCourseGradeId?: number;

  groupCourseGrade?: GroupCourseGrade;

  grade: number;

  comment?: string;

  mediaId?: number;

  media?: Media;

  isLinkedWithAssessment: boolean;

  rubricAssessment?: StudentCourseGradeRubricAssessment;

  attempt?: number;

  createdAt: Date;

  updatedAt?: Date;

  studentSubsectionExamGrade?: StudentSubsectionExamGrade;
}
