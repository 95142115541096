import { Voucher } from './voucher';
import { OrderPayment } from './order_payment';
import { Organization } from './organization';

export class VoucherConsumption {
  id: number;

  voucherCode: string;

  voucher: Voucher;

  discountAmount?: number;

  orderPaymentId?: number;

  orderPayment?: OrderPayment;

  organizationId?: number;

  organization?: Organization;
}
