import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { User } from './user';

export class CourseResourceAccessRestrictionStudent {
  id: number;

  accessRestrictionId: number;

  accessRestriction: CourseResourceAccessRestriction;

  userId: number;

  user: User;

  createdAt: Date;

  updatedAt: Date;
}
