import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';
export const checklistRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.checklist.root.path,
    name: EnumRoutes.checklist.root.name,
    component: () => import('@/views/checklist/ChecklistsPage.vue'),
    meta: EnumRoutes.checklist.root.meta,
  },
];
