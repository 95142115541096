import { Specialization } from './specialization';
import { Organization } from './organization';

export class SpecializationTargetOrganization {
  id: number;

  specializationId: number;

  specialization: Specialization;

  organizationId: number;

  organization: Organization;

  price: number;

  oldPrice?: number;

  createdAt: Date;

  updatedAt?: Date;
}
