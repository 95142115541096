import { Checklist } from './checklist';
import { LearningGroup } from './learning_group';

export class ChecklistLearningGroup {
  id: number;

  checklistId: number;

  checklist: Checklist;

  learningGroupId: number;

  learningGroup: LearningGroup;

  createdAt: Date;

  updatedAt?: Date;
}
