import { AnswerType } from './answer_type';
import { Question } from './question';
import { AnswerStem } from './answer_stem';
import { AnswerExplanation } from './answer_explanation';
import { ExamAttemptAnswer } from './exam_attempt_answer';

export class Answer {
  id: number;

  isCorrect?: boolean;

  answerTypeId: number;

  answerType: AnswerType;

  questionId: number;

  question: Question;

  answerStem?: AnswerStem;

  answerExplanation?: AnswerExplanation;

  examAttemptAnswers: ExamAttemptAnswer[];

  createdAt: Date;

  updatedAt?: Date;
}
