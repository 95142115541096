import { RubricCriterion } from './rubric_criterion';
import { StudentGradeForQuestionRubricCriterion } from './student_grade_for_question_rubric_criterion';

export class RubricCriterionRating {
  id: number;

  criterionId: number;

  criterion: RubricCriterion;

  grade: number;

  title: string;

  description: string;

  createdAt: Date;

  updatedAt?: Date;

  studentGradesForQuestions: StudentGradeForQuestionRubricCriterion[];
}
