import type { SingleParamType } from '../interfaces/paginated.interface';

export class CommonQuerySerializers {
  static booleanSerializer(key: string): SingleParamType<boolean | null> {
    return {
      key,
      serializerFn: (value) => value?.toString() ?? '',
      deserializeFn: (value) => {
        if (value === '') return null;
        return value === 'true';
      },
    };
  }

  static stringSerializer(key: string): SingleParamType<string | null> {
    return {
      key,
      serializerFn: (value) => value ?? '',
      deserializeFn: (value) => {
        if (value === '') return null;
        return value;
      },
    };
  }

  static numberSerializer(key: string): SingleParamType<number | null> {
    return {
      key,
      serializerFn: (value) => value?.toString() ?? '',
      deserializeFn: (value) => {
        if (value === '') return null;
        return Number(value);
      },
    };
  }

  static arraySerializer(key: string): SingleParamType<string[] | null> {
    return {
      key,
      serializerFn: (value) => value?.join(',') ?? '',
      deserializeFn: (value) => {
        if (!value) return [];
        return value.split(',');
      },
    };
  }
}
