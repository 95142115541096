import { axiosI } from '@/plugins';
import { ApiHandler } from '@/postman-to-ts/apiHandler';
import {
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory';

const integrations = createQueryKeys('integrations', {
  checkZoomAuthStatus: () => ({
    queryKey: ['zoomAuthStatus'],
    queryFn: async () => {
      const { method, path } =
        ApiHandler.integrations.zoom['oAuth2.0'].isAuthenticated;
      return (
        await axiosI(path(), {
          method,
        })
      ).data?.status;
    },
  }),
});

export const integrationsQueryKeys = mergeQueryKeys(integrations);
