import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';
export const mobileRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.mobile.downloadTheApp.path,
    name: EnumRoutes.mobile.downloadTheApp.name,
    component: () => import('@/views/mobile/MobileApp.vue'),
    meta: EnumRoutes.mobile.downloadTheApp.meta,
  },
];
