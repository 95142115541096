import { User } from './user';
import { Checklist } from './checklist';

export class StudentEnrolledChecklist {
  id: number;

  studentId: number;

  student: User;

  checklistId: number;

  checklist: Checklist;

  createdAt: Date;

  updatedAt?: Date;
}
