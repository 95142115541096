import { Organization } from './organization';
import { OrgRefundRule } from './org_refund_rule';
import { OrgRefundRestriction } from './org_refund_restriction';

export class OrganizationRefundPolicy {
  id: number;

  organizationId: number;

  organization: Organization;

  rules: OrgRefundRule[];

  restrictions: OrgRefundRestriction[];

  createdAt: Date;

  updatedAt: Date;
}
