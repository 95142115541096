import { CourseGrade } from './course_grade';
import { Media } from './media';
import { Course } from './course';
import { CourseSubsection } from './course_subsection';
import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { AssignmentSubmission } from './assignment_submission';
import { AssignmentSubmissionType } from './assignment_submission_type';

export class CourseAssignment {
  id: number;

  gradeItemId?: number;

  gradeItem?: CourseGrade;

  body?: string;

  title: string;

  mediaId?: number;

  media?: Media;

  isGroupAssignment: boolean;

  availableFrom?: Date;

  availableTo?: Date;

  dueDate?: Date;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;

  subsections: CourseSubsection[];

  accessRestrictionId?: number;

  accessRestriction?: CourseResourceAccessRestriction;

  submissions: AssignmentSubmission[];

  submissionTypes: AssignmentSubmissionType[];
}
