import { User } from './user';
import { Course } from './course';

export class LearningCategories {
  id: number;

  name: string;

  createdById: number;

  createdBy: User;

  createdAt: Date;

  updatedAt: Date;

  courses: Course[];
}
