<script setup lang="ts">
  import { EnumRoutes } from '@/helpers/enums/routes.enum';
  import { useI18 } from '@/plugins';
  import router from '@/router';
  import { ref } from 'vue';

  defineProps<{
    missingFields: string[];
  }>();

  const { t } = useI18();
  const show = ref(true);

  const close = () => {
    show.value = true;
  };

  const pushToLandingPage = () => {
    router.push({
      name: EnumRoutes.profile.root.name,
      query: {
        completeProfile: 'true',
      },
    });
  };
</script>

<template>
  <v-dialog v-model="show" @close="close" persistent max-width="500">
    <v-card>
      <template v-slot:title>
        <div class="d-flex align-center justify-space-between">
          <p class="text-primary text-h5">
            {{ t('profile.completeProfile') }}
          </p>
        </div>
      </template>
      <v-card-text>
        {{ t('profile.completeProfileHintLabel') }}
      </v-card-text>
      <v-card-item>
        {{ t('profile.completeProfileMissingFields') }}
        <v-list :items="missingFields">
          <template #item="items">
            <div class="py-1">
              {{ '•' }} {{ t(`extraField.${items.props.title}`) }}
            </div>
          </template>
        </v-list>
      </v-card-item>
      <v-card-actions class="justify-end">
        <v-btn @click="pushToLandingPage" color="primary" variant="outlined">
          {{ t('profile.completeNow') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
