import { Exam } from './exam';
import { CourseSubsection } from './course_subsection';
import { Course } from './course';
import { ExamSettings } from './exam_settings';
import { CourseGrade } from './course_grade';
import { ExamInstance } from './exam_instance';
import { CourseResourceAccessRestriction } from './course_resource_access_restriction';

export class SubsectionExam {
  id: number;

  examId: number;

  exam: Exam;

  courseSubsectionId?: number;

  courseSubsection?: CourseSubsection;

  courseId?: number;

  course?: Course;

  examSettings?: ExamSettings;

  gradeItemId?: number;

  gradeItem?: CourseGrade;

  examInstances: ExamInstance[];

  accessRestrictionId?: number;

  accessRestriction?: CourseResourceAccessRestriction;

  createdAt: Date;

  updatedAt?: Date;
}
