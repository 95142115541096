import { Media } from './media';
import { Answer } from './answer';

export class AnswerStem {
  id: number;

  body?: string;

  mediaId?: number;

  media?: Media;

  answerId: number;

  answer: Answer;

  alternativeAnswers?: object;

  createdAt: Date;

  updatedAt?: Date;
}
