import { OrganizationPricingModel } from './organization_pricing_model';
import { OrganizationFuturePricingModel } from './organization_future_pricing_model';

export class BillingCycleType {
  id: number;

  slug: string;

  name: string;

  isPublished: boolean = true;

  canBeBought: boolean = true;

  numberOfMonths?: number;

  discountPercentage: number;

  createdAt: Date;

  updatedAt?: Date;

  organizationPricingModels: OrganizationPricingModel[];

  organizationFuturePricingModels: OrganizationFuturePricingModel[];
}
