import { OrganizationRefundPolicy } from './organization_refund_policy';
import { RefundRule } from './refund_rule';

export class OrgRefundRule {
  id: number;

  organizationRefundPolicyId: number;

  organizationRefundPolicy: OrganizationRefundPolicy;

  refundRuleId: number;

  rule: RefundRule;

  createdAt: Date;

  updatedAt: Date;
}
