<script setup lang="ts">
  import { axiosI, useI18 } from '@/plugins';
  import { useAnnouncementsStore } from '@/stores/announcements.store';
  import { useAuthStore } from '@/stores/auth.store';
  import type { OrganizationAnnouncement } from '@/submodules/generated_types/types/organization_announcement';
  import { dashboardApiHandler } from '@/postman-to-ts/apiHandler/dashboard';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, ref, watch, type Ref } from 'vue';
  const { t } = useI18();
  const { user } = storeToRefs(useAuthStore());
  const emits = defineEmits(['hideBanner']);

  const announcementsStore = useAnnouncementsStore();
  const { getAnnouncementsItems } = announcementsStore;
  const { annmouncmentsItems } = storeToRefs(announcementsStore);

  onBeforeMount(async () => {
    await getAnnouncementsItems();
  });

  const readsItems: Ref<number[]> = ref(
    JSON.parse(localStorage.getItem('readsItems') || '[]')
  );
  const bannerItems = computed(() =>
    annmouncmentsItems.value?.data?.filter(
      (item: OrganizationAnnouncement) => !readsItems.value.includes(item.id)
    )
  );

  const activeBannerItem = computed(() => {
    if (bannerItems?.value?.length === 0) return null;
    return bannerItems?.value?.reduce(
      (
        mostRecent: OrganizationAnnouncement,
        currentItem: OrganizationAnnouncement
      ) => {
        return new Date(currentItem.createdAt) > new Date(mostRecent.createdAt)
          ? currentItem
          : mostRecent;
      },
      bannerItems?.value?.[0]
    );
  });
  const activeBannerLink = computed(() => {
    if (!activeBannerItem?.value?.externalLink) return null;
    return activeBannerItem?.value?.externalLink?.startsWith('www')
      ? `https://${activeBannerItem?.value?.externalLink}`
      : activeBannerItem?.value?.externalLink;
  });

  const dismissBanner = async () => {
    if (user.value) {
      const { path, method } =
        dashboardApiHandler.announcements.dismissAnnouncement;

      await axiosI.post(
        path({ announcementId: activeBannerItem?.value?.id || 0 }),
        {
          method,
        }
      );
    } else {
      localStorage.setItem(
        'readsItems',
        JSON.stringify([...readsItems.value, activeBannerItem?.value?.id])
      );
      readsItems.value = JSON.parse(localStorage.getItem('readsItems') || '[]');
    }
    await getAnnouncementsItems();
  };
  watch(
    () => user.value?.id,
    async () => {
      localStorage.removeItem('readsItems');
      readsItems.value = [];
      await getAnnouncementsItems();
    }
  );
  watch(
    () => activeBannerItem.value,
    () => emits('hideBanner', !activeBannerItem.value),
    { immediate: true }
  );
</script>

<template>
  <Transition name="slide-fade">
    <v-app-bar
      elevation="0"
      class="flex-grow-0 w-100 pa-0 ma-0 h-auto overflow-visible"
      v-if="activeBannerItem"
      height="auto"
    >
      <v-card
        id="banner"
        class="w-100 pa-3 ma-0 d-flex align-center justify-center gap-3 rounded-0 position-relative flex-column flex-md-row"
        color="secondary"
        rounded="rounded-0"
        elevation="0"
      >
        <div
          class="d-flex align-center justify-center flex-wrap px-6 px-md-10 gap-1"
        >
          <div v-if="activeBannerItem?.body">{{ activeBannerItem?.body }}.</div>
        </div>
        <v-btn
          v-if="activeBannerLink"
          color="white"
          variant="flat"
          density="comfortable"
          class="text-secondary"
          :href="activeBannerLink"
          target="_blank"
          style="z-index: 10000"
          aria-label="Visit Banner Link"
        >
          {{ t('banner.viewDetails') }}
        </v-btn>
        <div
          class="position-absolute w-100 h-100 d-flex align-start align-md-center justify-end pa-4 pa-md-8"
        >
          <v-btn
            color="white"
            density="comfortable"
            size="x-small"
            icon="mdi-close"
            @click="dismissBanner"
            aria-label="Close"
          >
          </v-btn>
        </div>
      </v-card>
    </v-app-bar>
  </Transition>
</template>
<style lang="scss">
  .slide-fade-leave-active,
  .slide-fade-enter-active {
    transition: all 0.5s ease-in-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }
</style>
