import { PlagiarismReport } from './plagiarism_report';
import { Media } from './media';

export class CopyLeaksReport {
  id: number;

  scanId: string;

  plagiarismReportId: number;

  plagiarismReport: PlagiarismReport;

  reportAvailable: boolean;

  reportPdfUrl?: string;

  mediaId?: number;

  media?: Media;

  createdAt: Date;

  updatedAt?: Date;
}
