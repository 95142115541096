import { OrganizationConfiguration } from './organization_configuration';

export class ConfigurationKey {
  id: number;

  name: string;

  key: string;

  isEditableByOrgAdmin: boolean;

  createdAt: Date;

  updatedAt?: Date;

  organizationConfig: OrganizationConfiguration[];
}
