import { ScormCourse } from './scorm_course';
import { User } from './user';
import { ScormCourseRegistrationProgress } from './scorm_course_registration_progress';

export class ScormCourseRegistration {
  id: number;

  scormCourseId: number;

  scormCourse: ScormCourse;

  userId: number;

  user: User;

  registrationId?: string;

  launchUrl?: string;

  launchUrlTtl?: Date;

  createdAt: Date;

  updatedAt?: Date;

  registrationProgress?: ScormCourseRegistrationProgress;
}
