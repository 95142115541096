import { ExamAttemptQuestion } from './exam_attempt_question';
import { Answer } from './answer';
import { AnswerReview } from './answer_review';

export class ExamAttemptAnswer {
  id: number;

  examAttemptQuestionId: number;

  examAttemptQuestion: ExamAttemptQuestion;

  answerId: number;

  answer: Answer;

  points: number;

  needsReview: boolean;

  reviewed: boolean;

  isCorrect: boolean;

  answerReview?: AnswerReview;

  createdAt: Date;

  updatedAt?: Date;
}
