import { Media } from './media';
import { Organization } from './organization';

export class GalleryMedium {
  id: number;

  title: string;

  mediaId: number;

  media: Media;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;
}
