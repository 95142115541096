import { ExternalCourse } from './external_course';

export class CornellCourse {
  id: number;

  short_description?: string;

  sections?: object;

  applicable_certificates?: object;

  urls?: object;

  code: string;

  course_type: string;

  target_audience?: string;

  description?: string;

  objectives?: string;

  title?: string;

  createdAt: Date;

  updatedAt?: Date;

  externalCourses: ExternalCourse[];
}
