export const mobile = {
  downloadTheApp: {
    path: '/download-the-app',
    name: 'DownloadTheApp',
    title: 'header.downloadTheApp',
    icon: 'mdi-cellphone',
    meta: {
      title: 'Download The App',
      requiresAuth: true,
    },
  },
};
