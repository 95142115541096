import { ProviderBusinnessDay } from './provider_businness_day';
import { User } from './user';

export class ProviderBusinessHour {
  id: number;

  from: string;

  to: string;

  dayId: number;

  day: ProviderBusinnessDay;

  providerId: number;

  provider: User;

  createdAt: Date;

  updatedAt?: Date;
}
