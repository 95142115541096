import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { CourseGroup } from './course_group';

export class CourseResourceAccessRestrictionGroup {
  id: number;

  accessRestrictionId: number;

  accessRestriction: CourseResourceAccessRestriction;

  groupId: number;

  group: CourseGroup;

  createdAt: Date;

  updatedAt: Date;
}
