import { Organization } from './organization';
import { CourseTargetOrganizationCreditUnit } from './course_target_organization_credit_unit';
import { ExternalCourseTargetOrganizationCreditUnit } from './external_course_target_organization_credit_unit';

export class OrganizationCreditUnitName {
  id: number;

  name: string;

  abbreviation?: string;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;

  courses: CourseTargetOrganizationCreditUnit[];

  externalCourses: ExternalCourseTargetOrganizationCreditUnit[];
}
