import { ScormCourseRegistration } from './scorm_course_registration';

export class ScormCourseRegistrationProgress {
  id: number;

  scormCourseRegistrationId: string;

  scormCourseRegistration: ScormCourseRegistration;

  updated?: Date;

  registrationCompletion?: string;

  registrationCompletionAmount?: number;

  registrationSuccess?: string;

  totalSecondsTracked?: number;

  firstAccessDate?: Date;

  lastAccessDate?: Date;

  completedDate?: Date;

  xapiRegistrationId?: string;

  createdAt: Date;

  updatedAt?: Date;
}
