import { svgAssetsExternalUrls } from './svgAssets';
const cdn = 'https://cdn.himam.com';
const oracle =
  'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axrfkphzxabg/b/himam/o';
// const oracle =
//   'https://axrfkphzxabg.objectstorage.me-jeddah-1.oci.customer-oci.com/n/axrfkphzxabg/b/himam/o';
const publicCdn = `${cdn}/public`;
const landing = `${publicCdn}/landing`;
const aboutus = `${landing}/AboutUs`;
const client = `${landing}/client`;
const features = `${landing}/features`;
const slider = `${landing}/HomeSlider/slider-`;
const socialLinks = `${publicCdn}/socialLinks`;
const defaultImages = `${publicCdn}/defaultImages`;
const attachment = `${publicCdn}/attachment`;
export const ExternalUrlsEnum = {
  jsons: {
    countries: `${publicCdn}/jsons/countries.json`,
    nationality: `${publicCdn}/jsons/nationalityList.json`,
  },
  svg: svgAssetsExternalUrls,
  images: {
    notifications: {
      announcment: `${oracle}/staging/media/image/png/_12cd07banotificationAnnouncments.png`,
      certificate: `${oracle}/staging/media/image/png/_4a46ac9cnotificationcertificate.png`,
      checklist: `${oracle}/staging/media/image/png/_4178d819notificationLearningPath.png`,
      discussionBoard: `${oracle}/staging/media/image/png/_5d0d96e8notificationDiscussionBorads.png`,
      grading: `${oracle}/staging/media/image/png/_f212267bnotificationGrading.png`,
      event: `${cdn}/defaultImages/event.webp`,
      course: `${cdn}/defaultImages/course.webp`,
      group: `${cdn}/defaultImages/group.webp`,
    },
    landingPage: {
      scfhsLogo: `${landing}/schfs-logo.webp`,
      saudiVisionLogo: `${landing}/SaudiVision2030.png`,
      favicon: `google.com/new-logo.webp`,
      logoLanding: `${landing}/logo-landing.webp`,
      slider: {
        image1: `${slider}1.webp`,
        image2: `${slider}2.webp`,
        image3: `${slider}3.webp`,
        image4: `${slider}4.webp`,
        image5: `${slider}5.webp`,
        image6: `${slider}6.webp`,
      },
      service: {
        leaderShip: `${landing}/service/landing-1-min%20.webp`,
        executive: `${landing}/service/landing-2-min%20.webp`,
        professional: `${landing}/service/landing-3-min%20.webp`,
        wibinars: `${landing}/service/landing-4-min.webp`,
      },
      client: {
        //staging/media/image/png/_f9783951partner8.webp",
        image1: `${client}/_f9783951partner8.webp`,
        image2: `${client}/_6abeb839partner12.webp`,
        image3: `${client}/_db15b525partner15.webp`,
        image4: `${client}/_1c35b19fpartner2.webp`,
        image5: `${client}/_87ed0a12partner3.webp`,
        image6: `${client}/_07e09fe2partner13.webp`,
        image7: `${client}/_0b1be001partner4.webp`,
        image8: `${client}/_f904e42bpartner1.webp`,
        image9: `${client}/_f904e42bpartner1.webp`,
        image10: `${client}/_0e94e0d5partner6.webp`,
        image11: `${client}/_92314374partner7.webp`,
        image12: `${client}/_5ef1eff4partner10.webp`,
      },
      features: {
        feature1: `${features}/feature1.svg`,
        feature2: `${features}/feature2.svg`,
        feature3: `${features}/feature3.svg`,
        feature4: `${features}/feature4.svg`,
        feature5: `${features}/feature5.svg`,
        feature6: `${features}/feature6.svg`,
        feature7: `${features}/feature7.svg`,
      },
    },
    aboutUs: {
      whyHla: `${oracle}/staging/media/image/jpeg/_1f50c003WhyHLA.webp`,
      about: `${oracle}/staging/media/image/jpeg/_8a2f29bb1.webp`,
      impact: `${cdn}/landing-pages/impact.png`,
      svgs: {
        impact: `${aboutus}/impact.svg`,
        collaboration: `${aboutus}/collaboration.svg`,
        innovation: `${aboutus}/innovation.svg`,
        motivation: `${aboutus}/motivation.svg`,
        americanCollegeLogo: `${aboutus}/american-college-logo.svg`,
        pokadots: `${aboutus}/polka-dots.svg`,
        programs: `${aboutus}/programs.svg`,
        lines: `${aboutus}/lines.svg`,
        schsTopLogo: `${aboutus}/schs-top-logo.svg`,
        beneficiaries: `${aboutus}/beneficiaries.svg`,
        trainingHours: `${aboutus}/training-hours.svg`,
      },
    },
    auth: {
      loginBackground: `${landing}/auth/login-background.webp`,
    },
    defaultImages: {
      coverImage:
        'https://cdn.himam.com/public/defaultImages/social-default-cover.png',
      svg: {
        user: `${publicCdn}/defaultImages/svg/user.svg`,
        converImage: ``,
        linkFile: `${defaultImages}/svg/linkFile.svg`,
      },
      undefinedImage: `${publicCdn}/defaultImages/undefined.webp`,
      event: `${publicCdn}/defaultImages/event.webp`,
      course: `${publicCdn}/defaultImages/course.webp`,
      group: `${publicCdn}/defaultImages/group.webp`,
      unauthorized: `${publicCdn}/defaultImages/unauthorized.webp`,
      notFound: `${publicCdn}/defaultImages/notFound404.webp`,
      qr: `${publicCdn}/defaultImages/Qr.webp`,
    },
    notFound: {
      noCalender: `${publicCdn}/notfound/noEvents.svg`,
      noCheckList: `${publicCdn}/notfound/noChecklist.svg`,
      noData: `${publicCdn}/notfound/NoData.svg`,
    },
    bannerImages: {
      courses: `${publicCdn}/bannerImages/coursesWallpaper.jpg`,
      events: `${publicCdn}/bannerImages/eventWallpaper.jpg`,
      library: `${publicCdn}/bannerImages/dots.svg`,
    },
    mobileApp: {
      appStore: `${publicCdn}/downloadTheApp/apple-store.webp`,
      playStore: `${publicCdn}/downloadTheApp/palystore.webp`,
      mobileAr: `${publicCdn}/downloadTheApp/mobile-ar.webp`,
      mobileEn: `${publicCdn}/downloadTheApp/mobile-EN.webp`,
      mobileQRCode: `${publicCdn}/downloadTheApp/mobile-QR-code.webp`,
    },
    socialLinks: {
      twitter: `${socialLinks}/twitterBlack.png`,
      linkedin: `${socialLinks}/linkedin.png`,
    },
    attachment: {
      mp3: `${attachment}/mp3.svg`,
      video: `${attachment}/video.svg`,
      image: `${attachment}/image.svg`,
      pdf: `${attachment}/pdf.svg`,
      doc: `${attachment}/doc.svg`,
      ppt: `${attachment}/ppt.svg`,
      xls: `${attachment}/xls.svg`,
      word: `${attachment}/word.svg`,
      link: `${attachment}/link.svg`,
    },
  },
};

// Content-Security-Policy: default-src 'self' 'unsafe-inline'; script-src 'self' ajax.cloudflare.com; base-uri 'self'; font-src 'self'; form-action 'self'; frame-ancestors 'self' https://himam.com https://instructor-himam.web.app https://instructor-staging.web.app https://mastered.himam.com https://instructor.himam.com; img-src 'self' data:; object-src 'none'; style-src 'self'; upgrade-insecure-requests
