import { CourseAnnouncement } from './course_announcement';
import { Media } from './media';

export class CourseAnnouncementsMedia {
  id: number;

  announcementId: number;

  announcement: CourseAnnouncement;

  mediaId: number;

  media: Media;
}
