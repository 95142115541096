import { Rubric } from './rubric';
import { StudentCourseGrade } from './student_course_grade';
import { User } from './user';

export class StudentCourseGradeRubricAssessment {
  id: number;

  rubricId: number;

  rubric: Rubric;

  studentCourseGradeId: number;

  studentCourseGrade: StudentCourseGrade;

  instructorId: number;

  instructor: User;

  answers: string;

  finalGrade: number;

  createdAt: Date;

  updatedAt?: Date;
}
