import { User } from './user';

export class UserLoginAttempt {
  id: number;

  userId: number;

  user: User;

  createdAt: Date;
}
