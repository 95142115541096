import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';

export const profile = {
  root: {
    name: 'Profile',
    path: '/profile',
    title: 'header.profile',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Profile`,
    },
  },
  favorite: {
    root: {
      name: 'Favorite',
      path: '/favorite',
      meta: {
        requiresAuth: true,
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Favorite`,
      },
    },
    events: {
      name: 'Favorite Events',
      path: '/favorite/events',
    },
    courses: {
      name: 'Favorite Courses',
      path: '/favorite/courses',
    },
  },
  activityLog: {
    root: {
      name: 'Activity Log',
      title: 'menu.activityLog',
      path: '/activity-log',
      icon: 'mdi-history',
      meta: {
        requiresAuth: true,
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Activity Log`,
        needLanguage: true,
      },
    },
  },
  myCertificates: {
    root: {
      name: 'My Certificates',
      path: '/my-certificates',
      title: 'header.myCertificates',
      meta: {
        requiresAuth: true,
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | My Certificates`,
        shouldHaveCertificatesEnabled: true,
      },
    },
  },
  savedPost: {
    root: {
      name: 'Saved Post',
      path: '/saved-post',
      title: 'header.savedPost',
      meta: {
        requiresAuth: true,
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Saved Post`,
        memberPermissions: [
          EnumOrganizationMemberPermissions.AccessCommunities,
        ],
        organizationPermissions: [EnumOrganizationPermissions.SocialPlatform],
        shouldHaveGroup: true,
      },
    },
  },
  zoomPolicy: {
    name: 'Zoom Policy',
    path: '/zoom-policy',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Zoom Policy`,
    },
  },
};
