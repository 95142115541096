import { QuestionCategory } from './question_category';

export class SingleQuestionCategory {
  id: number;

  name: string;

  createdAt: Date;

  updatedAt?: Date;

  questionCategories: QuestionCategory[];
}
