import { useShowToast } from '@/composables/useShowToast.composable';
import type { AxiosError } from 'axios';

export const handleErrorMessageFromServer = (e: AxiosError, key?: string) => {
  const errorData = e?.response?.data as any;
  const message = key
    ? errorData[key]
    : (errorData?.message ?? e.message ?? 'Something went wrong');
  const status = e?.response?.status ?? 500;
  const result = { message, status };
  useShowToast.error({ msg: result.message });
  return result;
};
