import { Organization } from './organization';

export class EmailSubscription {
  id: number;

  email: string;

  isDeleted: boolean;

  organizationId?: number;

  organization?: Organization;

  createdAt: Date;

  updatedAt: Date;
}
