import { User } from './user';
import { AssignmentSubmission } from './assignment_submission';
import { AnswerReview } from './answer_review';
import { CopyLeaksReport } from './copy_leaks_report';
import { CourseGroupStudent } from './course_group_student';
import { GroupCourseGrade } from './group_course_grade';

export class PlagiarismReport {
  id: number;

  userId: number;

  user: User;

  assignmentSubmissionId?: number;

  assignmentSubmission?: AssignmentSubmission;

  answerReviewId?: number;

  answerReview?: AnswerReview;

  reports: CopyLeaksReport[];

  createdAt: Date;

  updatedAt?: Date;

  students: CourseGroupStudent[];

  grades: GroupCourseGrade[];
}
