import { User } from './user';

export class UserSingleSignOn {
  id: number;

  userId: number;

  user: User;

  hashedToken: string;

  createdAt: Date;

  updatedAt: Date;

  expiresAt: Date;
}
