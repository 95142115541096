export const UserRole = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  INSTRUCTOR: 'INSTRUCTOR',
  STUDENT: 'STUDENT',
  COACH: 'COACH',
  COACHVERIFIED: 'COACHVERIFIED',
} as const;
export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export const OrganizationRole = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
} as const;
export type OrganizationRole =
  (typeof OrganizationRole)[keyof typeof OrganizationRole];
export const EventType = {
  ONLINE: 'ONLINE',
  FACETOFACE: 'FACETOFACE',
  HYBRID: 'HYBRID',
} as const;
export type EventType = (typeof EventType)[keyof typeof EventType];

export const Language = {
  ENGLISH: 'ENGLISH',
  ARABIC: 'ARABIC',
} as const;
export type Language = (typeof Language)[keyof typeof Language];
export const GroupRole = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
} as const;
export type GroupRole = (typeof GroupRole)[keyof typeof GroupRole];
export const MediaType = {
  FILE: 'FILE',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
} as const;
export type MediaType = (typeof MediaType)[keyof typeof MediaType];
export const Status = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  BLOCKED: 'BLOCKED',
  INVITED: 'INVITED',
} as const;
export type Status = (typeof Status)[keyof typeof Status];
export const GroupPrivacy = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  SECRET: 'SECRET',
} as const;
export type GroupPrivacy = (typeof GroupPrivacy)[keyof typeof GroupPrivacy];
export const LearningTypes = {
  SELF_PACED: 'SELF_PACED',
  LIVE_ONLINE: 'LIVE_ONLINE',
  ON_SITE: 'ON_SITE',
  MODERATED: 'MODERATED',
} as const;
export type LearningTypes = (typeof LearningTypes)[keyof typeof LearningTypes];
export const CourseLevel = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
} as const;
export type CourseLevel = (typeof CourseLevel)[keyof typeof CourseLevel];
export const SubsectionType = {
  LECTURE: 'LECTURE',
  READING: 'READING',
  QUIZ: 'QUIZ',
  ASSIGNMENT: 'ASSIGNMENT',
  HOMEWORK: 'HOMEWORK',
  LINK: 'LINK',
  EVENT: 'EVENT',
} as const;
export type SubsectionType =
  (typeof SubsectionType)[keyof typeof SubsectionType];
export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
} as const;
export type Gender = (typeof Gender)[keyof typeof Gender];
export const CredentialType = {
  LICENSE: 'LICENSE',
  CERTIFICATE: 'CERTIFICATE',
  ASSESSMENTTOOL: 'ASSESSMENTTOOL',
} as const;
export type CredentialType =
  (typeof CredentialType)[keyof typeof CredentialType];
export const LanguageProficiency = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
} as const;
export type LanguageProficiency =
  (typeof LanguageProficiency)[keyof typeof LanguageProficiency];
export const EducationDegree = {
  ASSOCIATE: 'ASSOCIATE',
  BACHELORS: 'BACHELORS',
  MASTERS: 'MASTERS',
  DOCTORAL: 'DOCTORAL',
} as const;
export type EducationDegree =
  (typeof EducationDegree)[keyof typeof EducationDegree];
export const EmploymentType = {
  FULLTIME: 'FULLTIME',
  PARTTIME: 'PARTTIME',
  SELFEMPLOYED: 'SELFEMPLOYED',
  FREELANCE: 'FREELANCE',
} as const;
export type EmploymentType =
  (typeof EmploymentType)[keyof typeof EmploymentType];
export const CoachTitle = {
  ACC: 'ACC',
  PCC: 'PCC',
  MCC: 'MCC',
} as const;
export type CoachTitle = (typeof CoachTitle)[keyof typeof CoachTitle];
export const ResourceAccessRestrictionType = {
  ALL: 'ALL',
  SPECIFIC_GROUPS: 'SPECIFIC_GROUPS',
  SPECIFIC_STUDENTS: 'SPECIFIC_STUDENTS',
} as const;
export type ResourceAccessRestrictionType =
  (typeof ResourceAccessRestrictionType)[keyof typeof ResourceAccessRestrictionType];
export const AssignmentRestrictionType = {
  ALL: 'ALL',
  GROUP: 'GROUP',
  INDIVIDUAL: 'INDIVIDUAL',
} as const;
export type AssignmentRestrictionType =
  (typeof AssignmentRestrictionType)[keyof typeof AssignmentRestrictionType];
export const AccessRestrictionType = {
  PUBLIC: 'PUBLIC',
  USERS: 'USERS',
  LEARNING_GROUPS: 'LEARNING_GROUPS',
} as const;
export type AccessRestrictionType =
  (typeof AccessRestrictionType)[keyof typeof AccessRestrictionType];
export const TicketStatus = {
  RECEIVED: 'RECEIVED',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
} as const;
export type TicketStatus = (typeof TicketStatus)[keyof typeof TicketStatus];
export const CourseOnlineLectureType = {
  EXTERNAL: 'EXTERNAL',
  VIDEO_CONFERENCE_TOOL: 'VIDEO_CONFERENCE_TOOL',
} as const;
export type CourseOnlineLectureType =
  (typeof CourseOnlineLectureType)[keyof typeof CourseOnlineLectureType];
export const SurveyTypes = {
  SURVEY: 'SURVEY',
  POLL: 'POLL',
} as const;
export type SurveyTypes = (typeof SurveyTypes)[keyof typeof SurveyTypes];
export const ExamAttemptEventType = {
  FOCUS: 'FOCUS',
  UNFOCUS: 'UNFOCUS',
} as const;
export type ExamAttemptEventType =
  (typeof ExamAttemptEventType)[keyof typeof ExamAttemptEventType];
export const DurationUnit = {
  YEARS: 'YEARS',
  MONTHS: 'MONTHS',
  WEEKS: 'WEEKS',
  DAYS: 'DAYS',
  HOURS: 'HOURS',
} as const;
export type DurationUnit = (typeof DurationUnit)[keyof typeof DurationUnit];

export const CertificatesToIssue = {
  PROVIDER: 'PROVIDER',
  HOME: 'HOME',
  NEITHER: 'NEITHER',
  BOTH: 'BOTH',
} as const;
export type CertificatesToIssue =
  (typeof CertificatesToIssue)[keyof typeof CertificatesToIssue];

export const AssignmentSubmissionTypes = {
  TEXT: 'TEXT',
  FILE: 'FILE',
  VIDEO_RECORDING: 'VIDEO_RECORDING',
  AUDIO_RECORDING: 'AUDIO_RECORDING',
} as const;
export type AssignmentSubmissionTypes =
  (typeof AssignmentSubmissionTypes)[keyof typeof AssignmentSubmissionTypes];

export const UnPublishedBy = {
  ADMIN: 'ADMIN',
  INSTRUCTOR: 'INSTRUCTOR',
} as const;

export type UnPublishedBy = (typeof UnPublishedBy)[keyof typeof UnPublishedBy];

export const Portals = {
  ADMIN: 'ADMIN',
  INSTRUCTOR: 'INSTRUCTOR',
  STUDENT: 'STUDENT',
  COACH: 'COACH',
} as const;

export type Portals = (typeof Portals)[keyof typeof UnPublishedBy];

export type CourseStatus = (typeof CourseStatus)[keyof typeof CourseStatus];
export const CourseStatus = {
  Pending: 'Pending',
  Active: 'Active',
  Expired: 'Expired',
} as const;
