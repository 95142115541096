import { Organization } from './organization';
import { PermissionContext } from './permission_context';
import { PermissionSetPermission } from './permission_set_permission';
import { User } from './user';

export class PermissionSet {
  id: number;

  slug: string;

  name: string;

  organizationId?: number;

  organization?: Organization;

  canBeEdited: boolean = true;

  canBeDeleted: boolean = true;

  isPredefined: boolean;

  clonedFromId?: number;

  clonedFrom?: PermissionSet;

  permissionContextId: number;

  context: PermissionContext;

  permissions: PermissionSetPermission[];

  createdAt: Date;

  updatedAt?: Date;

  clones: PermissionSet[];

  users: User[];

  organizationMembers: User[];

  permissionSetForOrganizations: Organization[];

  defaultPermissionSetForOrganizations: Organization[];
}
