import { PermissionCategory } from './permission_category';
import { PermissionContext } from './permission_context';
import { PermissionPrerequisite } from './permission_prerequisite';
import { PermissionSetPermission } from './permission_set_permission';
import { PermissionPeer } from './permission_peer';

export class Permission {
  id: number;

  slug: string;

  name: string;

  isHidden: boolean;

  categoryId?: number;

  category?: PermissionCategory;

  contextId: number;

  context: PermissionContext;

  prerequisites: PermissionPrerequisite[];

  prerequisitesToWhatPermissions: PermissionPrerequisite[];

  permissionSets: PermissionSetPermission[];

  createdAt: Date;

  updatedAt?: Date;

  peers: PermissionPeer[];

  peerOf: PermissionPeer[];
}
