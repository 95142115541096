import { Media } from './media';
import { TicketStatus } from '../exported_type_enums/exported_type_enums';

export class TechnicalSupportTicket {
  id: number;

  uuid: string;

  name: string;

  email: string;

  language?: string;

  issueTopic: string;

  issueDescription: string;

  resolvedBy?: string;

  assignedTo?: string;

  imageId?: number;

  image?: Media;

  status: TicketStatus = TicketStatus.RECEIVED;

  createdAt: Date;

  updatedAt?: Date;
}
