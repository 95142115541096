import { User } from './user';
import { EducationDegree } from '../exported_type_enums/exported_type_enums';

export class CoachEducation {
  id: number;

  school?: string;

  degree: EducationDegree = EducationDegree.BACHELORS;

  startDate?: Date;

  endDate?: Date;

  userId?: number;

  user?: User;
}
