import { Tooltip } from './tooltip';
import { LanguageCode } from './language_code';

export class TooltipContent {
  id: number;

  tooltipId: number;

  tooltip: Tooltip;

  language: LanguageCode;

  langId: number;

  text: string;
}
