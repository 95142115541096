import { Permission } from './permission';
import { PermissionSet } from './permission_set';

export class PermissionContext {
  id: number;

  slug: string;

  name: string;

  permissions: Permission[];

  permissionSets: PermissionSet[];

  createdAt: Date;

  updatedAt?: Date;
}
