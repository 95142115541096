import { Checklist } from './checklist';
import { ChecklistItemSettings } from './checklist_item_settings';
import { ChecklistItemStudentCompletion } from './checklist_item_student_completion';

export class ChecklistItem {
  id: number;

  title: string;

  instructions?: string;

  type: number;

  checklistId: number;

  checklist: Checklist;

  createdAt: Date;

  updatedAt?: Date;

  settings?: ChecklistItemSettings;

  studentsWhoFinishedThisItem: ChecklistItemStudentCompletion[];
}
