import { Course } from './course';
import { RubricCriterion } from './rubric_criterion';
import { CourseGrade } from './course_grade';
import { StudentCourseGradeRubricAssessment } from './student_course_grade_rubric_assessment';
import { GroupCourseGradeRubricAssessment } from './group_course_grade_rubric_assessment';

export class Rubric {
  id: number;

  courseId: number;

  course: Course;

  title: string;

  createdAt: Date;

  updatedAt?: Date;

  criteria: RubricCriterion[];

  gradeItems: CourseGrade[];

  gradedAssessments: StudentCourseGradeRubricAssessment[];

  groupGradedAssessments: GroupCourseGradeRubricAssessment[];
}
