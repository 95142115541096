import { Order } from './order';
import { PaymentMethod } from './payment_method';
import { FinancialStatus } from './financial_status';
import { PaymentWebhook } from './payment_webhook';
import { VoucherConsumption } from './voucher_consumption';

export class OrderPayment {
  id: number;

  amount: number;

  transactionId: string;

  orderId: number;

  order: Order;

  paymentMethod: PaymentMethod;

  paymentMethodSlug: string;

  statusSlug: string;

  status: FinancialStatus;

  webhooks: PaymentWebhook[];

  voucherConsumption?: VoucherConsumption;
}
