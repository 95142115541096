export const organizationsApiHandler = {
  getOrganization: {
    method: 'GET',
    path: () => `organizations-dashboard`,
  },
  getIdFromPrefix: {
    method: 'GET',
    path: (payload: { prefix: string | number }) =>
      `organizations-dashboard/prefix/${payload.prefix}`,
  },
  getOrganizationsRegistrationFormFields: {
    method: 'GET',
    path: () => `organizations-dashboard/registration`,
  },
  getOrganizationsRegistrationFormFieldsSort: {
    method: 'GET',
    path: () => `organizations-dashboard/registration`,
  },
  getOrganizationsTerminology: {
    method: 'GET',
    path: () => `organizations-dashboard/terminology`,
  },
  getOrganizationsTerminologyDashboard: {
    method: 'GET',
    path: () => `organizations-dashboard/terminology/dashboard`,
  },
  getRegistrationFormFieldsSort: {
    method: 'GET',
    path: () => `organizations-dashboard/registration/sort`,
  },
};
