import { EnumMagicStrings } from '@/helpers/enums/misc.enum';
import vuetify from '@/plugins/vuetify';
import axios from 'axios';
import type { i18n } from 'i18next';
import i18next from 'i18next';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useOrganizationStore } from './organzation.pinia';
import { pinia } from '@/pinia';
export const useLocaleStore = defineStore('localeStore', () => {
  /**
  >>>>>>>>>>>>>>> state variables >>>>>>>>>>>>>>>
  */
  const locale = ref<string>(
    localStorage.getItem(EnumMagicStrings.localeLanguage) || 'en'
  );
  const isLTR = ref<boolean>(locale.value === 'en');

  const tootlips = ref<Record<string, string>>({});

  return {
    locale,
    isLTR,
    changeLocale,
    loadLocaleMessages,
  };
  type LocalizationData = {
    student: Record<string, string>;
    tooltips: Record<string, string>;
  };
  /**
  >>>>>>>>>>>>>>> functions >>>>>>>>>>>>>>>
  */

  //>>>>>>> i18n shananigans >>>>>>>>>>>>>>>
  async function changeLocale(
    languageCode: string,
    i18next: i18n,
    setTerms: boolean = true
  ) {
    //>>>>>>> gets the messages from either cms or local storage >>>>>>>>>>>>>>>
    localStorage.setItem(EnumMagicStrings.localeLanguage, languageCode);
    locale.value = languageCode;
    isLTR.value = languageCode === 'en';

    const messages = await loadLocaleMessages();

    i18next.addResourceBundle(
      languageCode,
      'translation',
      messages[languageCode],
      true,
      true
    );

    await i18next.changeLanguage(languageCode);
    vuetify.locale.current.value = languageCode;
    if (setTerms) useOrganizationStore(pinia).setOrganizationTerms();
    return i18next;
  }

  async function loadLocaleMessages() {
    const messages: Record<string, Record<string, string>> = {};
    const localData = await getLocalizationsFromLocalStorage();
    messages[locale.value] = localData.student;
    tootlips.value = localData.tooltips;
    return messages;
  }

  async function getDataFromCms(): Promise<LocalizationData> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${import.meta.env.VITE_I18N_TOKEN}`,
    };
    const studentRes = await axios.get(
      `${import.meta.env.VITE_APP_CMS_BASE_URL}-${locale.value}`,
      {
        headers,
      }
    );

    const data: LocalizationData = {
      student: studentRes.data.data.attributes.json,
      tooltips: studentRes.data.data.attributes.json.tooltips,
    };
    saveDataToLocalStorage({ data });
    return data;
  }

  function saveDataToLocalStorage({ data }: { data: LocalizationData }) {
    localStorage.setItem(locale.value, JSON.stringify(data));

    i18next.addResourceBundle(
      locale.value,
      'translation',
      data.student,
      true,
      true
    );
  }

  async function getLocalizationsFromLocalStorage() {
    const data = localStorage.getItem(locale.value);
    if (!data) return await getDataFromCms();
    getDataFromCms();
    return JSON.parse(data) as LocalizationData;
  }
});

//======================================================================
//>>>>>>>>>>>>>>> i18n shananigans >>>>>>>>>>>>>>>
//======================================================================
