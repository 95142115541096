import { Question } from './question';
import { QuestionRubricCriterion } from './question_rubric_criterion';

export class QuestionAnswerKey {
  id: number;

  key: string;

  length: number = 1;

  questionId: number;

  question: Question;

  createdAt: Date;

  updatedAt?: Date;

  rubricCriterions: QuestionRubricCriterion[];
}
