export enum EnumPaginatedQueriesKeys {
  // LMS
  Courses = 'courses',
  CourseAssignments = 'courseAssignments',
  CourseAnnouncements = 'courseAnnouncements',
  CourseSurveys = 'courseSurveys',
  CourseOnlineLectures = 'courseOnlineLectures',
  CourseOnCampusLectures = 'courseOnCampusLectures',
  CourseDiscussionBoards = 'courseDiscussionBoards',
  CourseGradeItems = 'courseGradeItems',
  CourseExams = 'courseExams',
  ExamAttempts = 'examAttempts',
  Notifications = 'notifications',
  Attendance = 'attendance',
  MyCourses = 'myCourses',
  Specialization = 'allSpecialization',
  // Social
  SocialEvents = 'socialEvents',
  EnrolledEvents = 'enrolledEvents',

  // OrderHistory
  OrderHistory = 'orderHistory',

  // Favorite
  FavoriteCourses = 'favoriteCourses',
  FavoriteEvents = 'favoriteEvents',
  //checklist
  Checklist = 'checklistStudent',
  // Activity Log
  ActivityLog = 'activityLog',
  // Certificates
  StudentCertificates = 'studentCertificates',
  // my groups
  MyGroups = 'myGroups',
  ExternalCourses = 'externalCourses',
  MYExternalCourses = 'myExternalCourses',
}

export enum EnumInfinitePaginatedQueriesKeys {
  // LMS
  CourseDiscussionBoardComments = 'courseDiscussionBoardComments',
  SingleGroupPosts = 'singleGroupPosts',
  SinglePostComments = 'singlePostComments',
  SavedPost = 'savedPost',
}
