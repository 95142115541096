import { Specialization } from './specialization';
import { Course } from './course';

export class SpecializationCourse {
  id: number;

  specializationId: number;

  specialization: Specialization;

  weight: number;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;
}
