import { Organization } from './organization';
import { ArticleTag } from './article_tag';

export class TopicTag {
  id: number;

  name: string;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt?: Date;

  articleTags: ArticleTag[];
}
