import { SocialGroup } from './social_group';
import { Organization } from './organization';

export class SocialGroupTargetOrganization {
  id: number;

  socialGroupId: number;

  socialGroup: SocialGroup;

  organizationId: number;

  organization: Organization;

  createdAt: Date;

  updatedAt: Date;
}
