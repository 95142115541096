import { User } from './user';
import { ProviderBusinessHour } from './provider_business_hour';

export class ProviderBusinnessDay {
  id: number;

  dayId: number;

  isActive: boolean;

  providerId: number;

  provider: User;

  createdAt: Date;

  updatedAt?: Date;

  businessHours: ProviderBusinessHour[];
}
