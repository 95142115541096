import { Question } from './question';
import { QuestionDifficultyLevel } from './question_difficulty_level';
import { QuestionExplanation } from './question_explanation';
import { QuestionsTag } from './questions_tag';
import { QuestionCategory } from './question_category';

export class QuestionDetail {
  id: number;

  notes?: string;

  createdAt: Date;

  updatedAt?: Date;

  questionId: number;

  question: Question;

  questionDifficultyLevel?: QuestionDifficultyLevel;

  questionExplanation?: QuestionExplanation;

  questionTags: QuestionsTag[];

  questionCategories: QuestionCategory[];
}
