import { Voucher } from './voucher';
import { PurchasedItemType } from './purchased_item_type';

export class VoucherRestrictionByItemtype {
  id: number;

  voucherCode?: string;

  voucher?: Voucher;

  itemTypeSlug?: string;

  itemType?: PurchasedItemType;

  allowedItemsIds?: object;
}
