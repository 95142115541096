import { LibraryTagsTranslation } from './library_tags_translation';
import { LibraryMedia } from './library_media';

export class LibraryTags {
  id: number;

  name: string;

  createdAt: Date;

  updatedAt: Date;

  translation: LibraryTagsTranslation[];

  libraryMedia: LibraryMedia[];
}
