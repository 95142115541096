import { FormFieldTypeAvailableModifier } from './form_field_type_available_modifier';
import { OrganizationRegistrationFormFieldModifier } from './organization_registration_form_field_modifier';

export class FormFieldTypeModifier {
  id: number;

  slug: string;

  name: string;

  priority: number;

  zodValidator?: string;

  acceptsValue: boolean;

  createdAt: Date;

  updatedAt?: Date;

  availableForFieldTypes: FormFieldTypeAvailableModifier[];

  organizationsFormFieldModifiers: OrganizationRegistrationFormFieldModifier[];
}
