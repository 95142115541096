import { User } from './user';
import { Organization } from './organization';
import { ChecklistLearningGroup } from './checklist_learning_group';
import { ChecklistStudent } from './checklist_student';
import { ChecklistEnrollment } from './checklist_enrollment';
import { ChecklistItem } from './checklist_item';
import { StudentCompletedChecklist } from './student_completed_checklist';
import { StudentEnrolledChecklist } from './student_enrolled_checklist';
import { ChecklistSettings } from './checklist_settings';
import { AccessRestrictionType } from '../exported_type_enums/exported_type_enums';

export class Checklist {
  id: number;

  name: string;

  description?: string;

  isPublished: boolean;

  notifyAdminOnCompletion: boolean;

  sendDueDateReminders: boolean = true;

  admin?: User;

  adminId?: number;

  accessRestrictionType: AccessRestrictionType = AccessRestrictionType.PUBLIC;

  organizationId?: number;

  organization?: Organization;

  createdAt: Date;

  updatedAt?: Date;

  assignedLearningGroups: ChecklistLearningGroup[];

  assignedStudents: ChecklistStudent[];

  optionalEnrollments: ChecklistEnrollment[];

  checklistItems: ChecklistItem[];

  studentsWhoCompletedTheChecklist: StudentCompletedChecklist[];

  enrolledStudents: StudentEnrolledChecklist[];

  settings?: ChecklistSettings;
}
