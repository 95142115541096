import { Course } from './course';

export class CanvasCourse {
  id: number;

  canvasId: string;

  basePathInServer: string;

  title: string;

  createdAt: Date;

  updatedAt?: Date;

  courses: Course[];
}
