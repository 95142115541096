import { QuestionDetail } from './question_detail';
import { SingleQuestionTag } from './single_question_tag';

export class QuestionsTag {
  id: number;

  questionDetailId: number;

  questionDetail: QuestionDetail;

  questionTagId: number;

  questionTag: SingleQuestionTag;

  createdAt: Date;

  updatedAt?: Date;
}
