import type {
  INotification,
  NotificationControlGroup,
  UserNotificationSettings,
} from '@/helpers/interfaces/notification.interface';
import { axiosI } from '@/plugins';
import { miscApiHandler } from '@/postman-to-ts/apiHandler/misc';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';
import { useLocaleStore } from './locale.store';
interface INotificationsStore {
  unreadCount: Ref<number>;
  notifications: Ref<INotification[]>;
  userNotificationSettings: Ref<UserNotificationSettings>;
  notificationControlGroup: Ref<NotificationControlGroup>;
  getNotifications: () => Promise<void>;
  markAllAsRead: () => Promise<void>;
  markNotificationAsRead: (notificationId: number | string) => Promise<void>;
  deleteNotification: (notificationId: number | string) => Promise<void>;
  getNotificationControlGroup: () => Promise<
    NotificationControlGroup | undefined
  >;
  getUserNotificationSettings: () => Promise<
    UserNotificationSettings | undefined
  >;
  updateNotificationSettings: (data: {
    notificationControlGroupId: number;
    isDisabled: boolean;
  }) => Promise<void>;
}

export const useNotificationsStore = defineStore(
  'NotificationsStore',
  (): INotificationsStore => {
    //======================================================================
    //>>>>>>>>>>>>>>> State Variables <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    //======================================================================
    const unreadCount = ref(0);
    const notifications = ref<INotification[]>([]);
    const userNotificationSettings = ref<UserNotificationSettings>({
      count: 0,
      data: [],
    });
    const notificationControlGroup = ref<NotificationControlGroup>({
      count: 0,
      data: [],
    });

    //======================================================================
    //>>>>>>>>>>>>>>> Functions <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    //======================================================================
    //________________ Get notifications ________________
    async function getNotifications() {
      await Promise.all([getUnreadCount(), getFirstNotifications()]);
    }

    async function getUnreadCount() {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD.getNotificationCount;
      try {
        const { data } = await axiosI<{ count: number }>(path(), {
          method,
          params: { portals: [1] },
        });
        unreadCount.value = data.count;
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    async function getFirstNotifications() {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD.getNotifications;
      try {
        const data = (
          await axiosI<INotification[]>(path(), {
            method,
            params: {
              skip: 0,
              take: 10,
              portals: [1],
              language: localStorage.getItem('locale') || 'en',
            },
          })
        ).data;

        notifications.value = data;
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    //________________  Mark all as read ________________
    async function markAllAsRead() {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD
          .markAllNotificationsAsRead;
      try {
        await axiosI<{ count: number }>(path(), { method });
        unreadCount.value = 0;
        getFirstNotifications();
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    //________________  Mark notification as read ________________
    async function markNotificationAsRead(notificationId: number | string) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD
          .markNotificationAsRead;
      try {
        await axiosI<{ count: number }>(
          path({
            notificationId: notificationId,
          }),
          { method }
        );
        if (unreadCount.value > 0) unreadCount.value = unreadCount.value - 1;
        getFirstNotifications();
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    //________________  Delete notification  ________________
    async function deleteNotification(notificationId: number | string) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD[
          'delete/HideNotification'
        ];
      try {
        await axiosI<{}>(
          path({
            notificationId: notificationId,
          }),
          { method }
        );
        getNotifications();
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    //________________  Get User Notification settings  ________________
    async function getUserNotificationSettings() {
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .notificationSettings.getUserNotificationSettings;

      try {
        const response = await axiosI.get<UserNotificationSettings>(path(), {
          method,
        });
        userNotificationSettings.value = response.data;
        return response.data;
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }
    //________________  Get Notification Control Group  ________________
    async function getNotificationControlGroup() {
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .getNotificationControlGroups;

      getUserNotificationSettings();
      try {
        const response = await axiosI.get<NotificationControlGroup>(path(), {
          method,
          params: {
            skip: 0,
            take: 10,
            language: useLocaleStore().locale,
            portals: [1],
          },
        });

        notificationControlGroup.value = response.data;

        return response.data;
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    //________________  update notification settings  ________________

    // Ask Othman about this
    async function updateNotificationSettings(data: {
      notificationControlGroupId: number;
      isDisabled: boolean;
    }) {
      const patchData = [
        {
          notificationControlGroupId: data.notificationControlGroupId,
          isDisabled: data.isDisabled,
        },
      ];
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .notificationSettings.updateUserNotificationSettings;

      try {
        await axiosI.patch(path(), {
          method,
          data: patchData,
        });
      } catch (e) {
        console.log('ERROR IN-->', e);
      }
    }

    return {
      unreadCount,
      notifications,
      userNotificationSettings,
      notificationControlGroup,
      getNotifications,
      markAllAsRead,
      markNotificationAsRead,
      deleteNotification,
      getNotificationControlGroup,

      getUserNotificationSettings,
      updateNotificationSettings,
    };
  }
);
