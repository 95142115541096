import { Media } from './media';
import { User } from './user';
import { Organization } from './organization';
import { SpecializationCourse } from './specialization_course';
import { SpecializationEnrollee } from './specialization_enrollee';
import { SpecializationTargetOrganization } from './specialization_target_organization';
import { CourseEnrollee } from './course_enrollee';

export class Specialization {
  id: number;

  name: string;

  description?: string;

  isDeleted: boolean;

  isPublished: boolean;

  coverImageId?: number;

  coverImage?: Media;

  createdById?: number;

  createdBy?: User;

  createdAt: Date;

  updatedAt?: Date;

  organizationId?: number;

  organization?: Organization;

  courses: SpecializationCourse[];

  enrollees: SpecializationEnrollee[];

  targetOrganizations: SpecializationTargetOrganization[];

  courseEnrollees: CourseEnrollee[];
}
