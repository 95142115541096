import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';

const allExternalCoursesMeta = {
  requiresAuth: false,
  memberPermissions: [EnumOrganizationMemberPermissions.AccessExternalCourses],
  organizationPermissions: [EnumOrganizationPermissions.CRUDExternalCourses],
  shouldHaveSelfEnrollment: true,
  shouldAllowAnonymousCourseView: true,
  name: `${import.meta.env.VITE_DEFAULT_TITLE} | All External Courses`,
  groupMeta: undefined,
};

const myExternalCoursesMeta = {
  requiresAuth: true,
  memberPermissions: [EnumOrganizationMemberPermissions.AccessExternalCourses],
  organizationPermissions: [],
  name: `${import.meta.env.VITE_DEFAULT_TITLE} | My External Courses`,
  groupMeta: undefined,
};

export const externalCourses = {
  root: {
    name: 'External Courses',
    path: '/external-courses',
    title: 'header.externalCourses',
    icon: 'mdi-bookshelf',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | External Courses`,
      groupMeta: [myExternalCoursesMeta, allExternalCoursesMeta],
    },
  },
  myExternalCourses: {
    name: 'My External Courses',
    path: '/external-courses/my-courses',
    title: 'course.myCourses',
    icon: 'mdi-bookshelf',
    meta: myExternalCoursesMeta,
  },
  allExternalCourses: {
    name: 'All External Courses',
    title: 'header.courses',
    path: '/external-courses/all-courses',
    icon: 'mdi-bookshelf',
    meta: allExternalCoursesMeta,
  },
  externalCourseOverview: {
    name: ' External Course Overview',
    path: '/external-courses/:courseId/overview',
    pathFn: (courseId: string | number) =>
      `/external-courses/${courseId}/overview`,
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | External Course Overview`,
      memberPermissions: [
        EnumOrganizationMemberPermissions.AccessExternalCourses,
      ],
      organizationPermissions: [
        EnumOrganizationPermissions.CRUDExternalCourses,
      ],
    },
  },
  externalCoursePage: {
    name: 'External Course Page',
    path: '/external-courses/courses/:courseId',
    pathFn: (courseId: string | number) =>
      `/external-courses/courses/${courseId}`,
    meta: {
      requiresAuth: true,
      hideSideBar: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | External Course Content`,
      memberPermissions: [
        EnumOrganizationMemberPermissions.AccessExternalCourses,
      ],
    },
  },
};
