import { EnumRoutes } from '@/helpers/enums/routes.enum';

export const chatRouter = [
  {
    name: EnumRoutes.chatRoutesEnum.root.name,
    path: EnumRoutes.chatRoutesEnum.root.path,
    component: () => import('@/views/chat/Chat.vue'),
    meta: EnumRoutes.chatRoutesEnum.root.meta,
  },
];
