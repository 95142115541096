import { Permission } from './permission';

export class PermissionPrerequisite {
  id: number;

  permissionId: number;

  prerequisiteId: number;

  permission: Permission;

  prerequisite: Permission;

  createdAt: Date;

  updatedAt?: Date;
}
