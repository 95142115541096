import { Course } from './course';
import { CourseSubsection } from './course_subsection';

export class CourseOnCampusLecture {
  id: number;

  courseId: number;

  course: Course;

  name: string;

  description?: string;

  startTime?: Date;

  endingTime?: Date;

  markedAsComplete: boolean;

  longitude?: number;

  latitude?: number;

  location?: string;

  createdAt: Date;

  updatedAt: Date;

  subsections: CourseSubsection[];
}
