import { options } from '@/plugins/query';
import '@/styles/base.scss';
import { VueQueryPlugin } from '@tanstack/vue-query';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import { createApp } from 'vue';
import VueHotjar from 'vue-hotjar-next';
import App from './App.vue';
import { pinia } from './pinia';
import { hotjarOptions } from './plugins/hotjar';
import { initializeI18n } from './plugins/i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import * as Sentry from '@sentry/vue';

initializeI18n().then(() => {
  const app = createApp(App);

  Sentry.init({
    app,
    dsn: 'https://a7ecab734537f0b382ad4cbdfd5aadaf@o4507191879860224.ingest.de.sentry.io/4508157329408080',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.himam\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.PROD, // only enable in production
  });

  app.use(pinia);
  app.use(vuetify);
  app.use(router);
  app.use(VueQueryPlugin, options);
  app.use(I18NextVue, {
    i18next: i18next,
    rerenderOn: ['languageChanged', 'loaded', 'added', 'removed'],
    // legacyI18nOptionsSupport: true,
  });
  if (hotjarOptions.isProduction) app.use(VueHotjar, hotjarOptions);
  app.mount('#app');
});
