// Notifications Route Handler - This is the route handler for the notifications

import { ExternalUrlsEnum } from '@/assets/external-urls.enum';
import { EnumNotificationType } from '@/helpers/enums/notifications-type.enum';
import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { INotification } from '@/helpers/interfaces/notification.interface';
import type { RouteLocationRaw } from 'vue-router';

// router.push(destinationRoute);

export const notificationsRouteHandler: (
  type: string,
  notification: INotification
) => RouteLocationRaw = (type: string, notification: INotification) => {
  switch (type) {
    case EnumNotificationType.GROUP:
    case EnumNotificationType.POST:
      return {
        name: EnumRoutes.social.postPage.name,
        params: {
          groupId: notification.data.groupId || 0,
          postId: notification.data.postId || 0,
        },
      };
    case EnumNotificationType.EVENT:
      return {
        name: EnumRoutes.social.eventPage.name,
        params: { eventId: notification.data.eventId || 0 },
      };
    case EnumNotificationType.ANNOUNCEMENT:
      // will result in error for the old notifications since the announcementId is not included in the old notifications
      return {
        name: EnumRoutes.lms.courseAnnouncements.singleCourseAnnouncement.name,
        params: {
          courseId: notification.data.courseId, //made this default to zero to avoid errors in console until it's resolved to handle all cases
          announcementId: notification.data.announcementId || 0,
        },
      };
    case EnumNotificationType.CERTIFICATE:
      return {
        name: EnumRoutes.lms.courseCertificate.name,
        params: { courseId: notification.data.courseId },
      };
    case EnumNotificationType.CHECKLIST:
      /*
                                    to be fixed when the route is added
                                     */
      return '';
    case EnumNotificationType.GRADES:
      // will result in error for the old notifications since the gradeItemId is not included in the old notifications

      return {
        name: EnumRoutes.lms.courseGradeItems.singleGradeItem.name,
        params: {
          courseId: notification.data.courseId, //made this default to zero to avoid errors in console until it's resolved to handle all cases
          gradeItemId: notification.data.gradeItemId || 0,
        },
      };
    case EnumNotificationType.DISCUSSION_BOARD:
      /*
                                    to be fixed when the route is added
                                    */
      return {
        name: EnumRoutes.lms.courseDiscussionBoards.singleDiscussionBoard.name,
        params: {
          courseId: notification?.data?.courseId || 0,
          discussionBoardId: notification.data.discussionBoardId || 0,
        },
      };

    case EnumNotificationType.COURSE:
      return {
        name: EnumRoutes.lms.courseOverview.name,
        params: {
          courseId: notification?.data?.courseId || 0,
        },
      };
    default:
      return '';
  }
};

// Notifications image placholder Handler - This is the image placeholder handler for the notifications

export const notificationsImagePlacholderHandler: (
  notification: INotification
) => string = (notification: INotification) => {
  const type = detectNotificationType(
    notification.data.notificationTemplate.id
  );
  switch (type) {
    case EnumNotificationType.GROUP:
      return ExternalUrlsEnum.images.notifications.group;
    case EnumNotificationType.POST:
      return ExternalUrlsEnum.images.notifications.group;
    case EnumNotificationType.EVENT:
      return ExternalUrlsEnum.images.notifications.event;

    case EnumNotificationType.ANNOUNCEMENT:
      return ExternalUrlsEnum.images.notifications.announcment;

    case EnumNotificationType.CERTIFICATE:
      return ExternalUrlsEnum.images.notifications.certificate;

    case EnumNotificationType.CHECKLIST:
      return ExternalUrlsEnum.images.notifications.checklist;

    case EnumNotificationType.DISCUSSION_BOARD:
      return ExternalUrlsEnum.images.notifications.discussionBoard;

    case EnumNotificationType.GRADES:
      return ExternalUrlsEnum.images.notifications.grading;
    default:
      return ExternalUrlsEnum.images.notifications.course;
  }
};

// function to detect the notification type

export const detectNotificationType = (templateId: number) => {
  switch (templateId) {
    case 1:
      return EnumNotificationType.GROUP;
    case 2:
      return EnumNotificationType.EVENT;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 19:
    case 20:
    case 24:
      return EnumNotificationType.DISCUSSION_BOARD;
    case 11:
      return EnumNotificationType.ANNOUNCEMENT;
    case 12:
      return EnumNotificationType.CERTIFICATE;
    case 13:
    case 14:
      return EnumNotificationType.CHECKLIST;
    case 15:
      return EnumNotificationType.IMPORTEDCOURSE;
    case 16:
    case 17:
      return EnumNotificationType.PLAGIARISM;
    case 18:
      return EnumNotificationType.GRADES;
    case 22:
      return EnumNotificationType.APPROVALCOURSE;
    case 21:
    case 23:
    case 25:
    case 31:
      return EnumNotificationType.COURSE;
    case 27:
    case 28:
    case 29:
    case 30:
      return EnumNotificationType.POST;
    default:
      return EnumNotificationType.COURSE;
  }
};
