import { useOrganizationStore } from '@/stores/organzation.pinia';
import type {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';
import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { Permission } from '@/helpers/interfaces/permissoins.interface';
import { axiosI } from '@/plugins';
import router from '@/router';
import { authApiHandler } from '@/postman-to-ts/apiHandler/auth';
import { miscApiHandler } from '@/postman-to-ts/apiHandler/misc';
import isEqual from 'lodash.isequal';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { RouteMeta } from 'vue-router';
import { useAuthStore } from './auth.store';

export enum EnumPermissionContextIds {
  Global = 1,
  User = 2,
  Organization = 3,
  OrganizationMember = 4,
  CourseInstructor = 5,
}

export const usePermissionsStore = defineStore('PermissionsStore', () => {
  const systemPermissions = ref<Permission[]>([] as Permission[]);
  const userPermissions = ref<Permission[]>([] as Permission[]);
  function checkPermission(
    permissions: Array<
      EnumOrganizationMemberPermissions | EnumOrganizationPermissions
    >
  ): boolean {
    const checkAllPermissionPrerequisites = (
      permissionSlugs: string[]
    ): boolean => {
      return permissionSlugs.some((permissionSlug) => {
        const permissionWithPrerequisites = systemPermissions.value?.find(
          (permissionData) => permissionData?.slug === permissionSlug
        );

        let isTrue = true;

        if (permissionWithPrerequisites?.prerequisites?.length) {
          const prerequisites = permissionWithPrerequisites.prerequisites.map(
            (prerequisite) => prerequisite.prerequisite.slug
          );
          isTrue = checkAllPermissionPrerequisites(prerequisites);
        }
        return (
          isTrue &&
          userPermissions.value?.some(
            (userPermission) =>
              userPermission.slug === permissionSlug ||
              userPermission.slug ===
                getBypassPermissionName(permissionWithPrerequisites?.contextId)
          )
        );
      });
    };

    return checkAllPermissionPrerequisites(permissions);
  }
  function getBypassPermissionName(
    permissionContextId: EnumPermissionContextIds | undefined
  ) {
    switch (permissionContextId) {
      case EnumPermissionContextIds.OrganizationMember:
        return 'organization_member_full_access';
      case EnumPermissionContextIds.User:
        return 'user_everything';
      case EnumPermissionContextIds.CourseInstructor:
        return 'whatever';
      case EnumPermissionContextIds.Global:
        return 'global_everything';
      case EnumPermissionContextIds.Organization:
        return 'organization_everything';
      default:
        return 'never_gonna_give_you_up';
    }
  }

  //to be called from outside
  function setPermissions() {
    const systemPermissionsFromStorage =
      localStorage.getItem('systemPermissions');
    systemPermissions.value = systemPermissionsFromStorage
      ? JSON.parse(systemPermissionsFromStorage)
      : [];
    const userPermissionsFromStorage = localStorage.getItem('userPermissions');
    userPermissions.value = userPermissionsFromStorage
      ? JSON.parse(userPermissionsFromStorage)
      : [];
  }

  // to be called on auth change
  async function initializePermissions() {
    return await Promise.all([getAllPermissions(), getUserPermissions()]);
  }
  async function getAllPermissions() {
    const { path, method } =
      miscApiHandler.permissionsSystem.permissions.getAllPermissions;
    try {
      const { data, status } = await axiosI<{
        count: number;
        data: Permission[];
      }>(path(), {
        method,
        params: {
          take: Number.MAX_SAFE_INTEGER,
        },
      });
      systemPermissions.value = data.data;
      const systemPermissionsFromStorage =
        localStorage.getItem('systemPermissions');
      const convertedSystemPermissions: Permission[] = JSON.parse(
        systemPermissionsFromStorage ?? '[]'
      );
      if (
        systemPermissionsFromStorage &&
        !isEqual(convertedSystemPermissions, data.data)
      ) {
        localStorage.setItem('systemPermissions', JSON.stringify(data.data));
        // instead of reloading the page just check if he have the permission to access the current page
        // window.location.reload();
        const currentRoute = router.currentRoute.value;
        const havePermission = usePermissionsStore().checkAuthentication(
          currentRoute.meta
        );
        if (havePermission) return;
        else await router.replace({ name: EnumRoutes.landing.root.name });
      }
      localStorage.setItem('systemPermissions', JSON.stringify(data.data));
      return { data, status };
    } catch (error: any) {
      return { error };
    }
  }

  async function getUserPermissions() {
    const { path, method } = authApiHandler.getUserPermissions;
    try {
      const { data, status } = await axiosI<{
        count: number;
        data: Permission[];
      }>(path(), {
        method,
      });
      userPermissions.value = data.data;
      const userPermissionsFromStorage =
        localStorage.getItem('userPermissions');
      const convertedUserPermissions: Permission[] = JSON.parse(
        userPermissionsFromStorage ?? '[]'
      );
      if (
        userPermissionsFromStorage &&
        !isEqual(convertedUserPermissions, data.data)
      ) {
        localStorage.setItem('userPermissions', JSON.stringify(data.data));
        // instead of reloading the page just check if he have the permission to access the current page
        // window.location.reload();
        const currentRoute = router.currentRoute.value;
        const havePermission = usePermissionsStore().checkAuthentication(
          currentRoute.meta
        );
        if (havePermission) return;
        else await router.replace({ name: EnumRoutes.landing.root.name });
      }
      localStorage.setItem('userPermissions', JSON.stringify(data.data));
      return { data, status };
    } catch (error: any) {
      return { error };
    }
  }

  // can be used before each route or to show the components
  // requiresAuth: need to be logged in
  // memberPermissions: need to have user permission (when logged in)
  // organizationPermissions: need to have organization permission (when not logged)
  function checkAuthentication(
    meta: RouteMeta,
    beforeRoute: boolean = false,
    to: string = ''
  ) {
    if (meta.groupMeta) {
      return meta.groupMeta.some((item) => {
        return checkSingleMeta(item, beforeRoute, to);
      });
    } else return checkSingleMeta(meta, beforeRoute, to);
  }
  function checkOrganizationConfig(meta: RouteMeta) {
    const orgStore = useOrganizationStore();
    if (meta.shouldHaveSelfEnrollment && !orgStore.selfEnrollmentEnabled)
      return false;
    if (meta.shouldHaveRegistrationEnabled && !orgStore.selfRegistrationEnabled)
      return false;
    if (meta.shouldHaveMarketplace && !orgStore.marketplaceEnabled)
      return false;
    if (
      meta.shouldAllowAnonymousCourseView &&
      !orgStore.allowAnonymousCourseView &&
      !useAuthStore().user?.id
    )
      return false;
    if (
      meta.shouldAllowAnonymousEventView &&
      !orgStore.allowAnonymousEventView &&
      !useAuthStore().user?.id
    )
      return false;
    if (meta.shouldHaveCertificatesEnabled && !orgStore.certificatesEnabled)
      return false;
    return true;
  }
  function checkSingleMeta(
    meta: RouteMeta,
    beforeRoute: boolean = false,
    to: string = ''
  ) {
    // beforeRoute and to are used for the route guard not for the component guard
    const requiresAuth = (meta?.requiresAuth as boolean) ?? false;
    const memberPermissions =
      (meta?.memberPermissions as EnumOrganizationMemberPermissions[]) ?? [];
    const organizationPermissions =
      (meta?.organizationPermissions as EnumOrganizationPermissions[]) ?? [];
    // cases for authintication
    // 1- if the user is authinticated and the route requires user permission
    // 2- if the user is authinticated and the route requires config permission
    // 3- if the user is not authinticated and the route requires authintication
    // 4- if the user is not authinticated and the route requires organization permission
    // 5- if the user is not authinticated and the route requires config permission
    if (useAuthStore().user && memberPermissions.length > 0) {
      // check the user permissions
      if (checkPermission(memberPermissions) && checkOrganizationConfig(meta)) {
        return true;
      } else {
        useAuthStore().shouldHavePermission = beforeRoute
          ? true
          : useAuthStore().shouldHavePermission;
        return false;
      }
    } else if (useAuthStore().user) {
      if (checkOrganizationConfig(meta)) {
        return true;
      } else {
        useAuthStore().shouldHavePermission = beforeRoute
          ? true
          : useAuthStore().shouldHavePermission;
        return false;
      }
    } else if (!useAuthStore().user && requiresAuth) {
      if (beforeRoute) {
        useAuthStore().shouldLogged = true;
        useAuthStore().setPreviousRoute(to);
      }
      return false;
    } else if (!useAuthStore().user && organizationPermissions.length > 0) {
      if (
        checkPermission(organizationPermissions) &&
        checkOrganizationConfig(meta)
      ) {
        return true;
      } else {
        useAuthStore().shouldHavePermission = beforeRoute
          ? true
          : useAuthStore().shouldHavePermission;
        return false;
      }
    } else if (!useAuthStore().user) {
      if (checkOrganizationConfig(meta)) {
        return true;
      } else {
        useAuthStore().shouldHavePermission = beforeRoute
          ? false
          : useAuthStore().shouldHavePermission;
        return false;
      }
    } else {
      return true;
    }
  }

  return {
    systemPermissions,
    userPermissions,
    checkPermission,
    getBypassPermissionName,
    initializePermissions,
    getAllPermissions,
    getUserPermissions,
    setPermissions,
    checkAuthentication,
    checkOrganizationConfig,
  };
});
