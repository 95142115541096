import { User } from './user';
import { CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';

export class UserReadCourseDiscussionBoardItemComment {
  id: number;

  userId: number;

  user: User;

  discussionBoardItemCommentId: number;

  discussionBoardItemComment: CourseDiscussionBoardItemComment;

  createdAt: Date;

  updatedAt?: Date;
}
