export const socialApiHandler = {
  search: {
    search: {
      method: 'GET',
      path: () => `search`,
      params: {} as { search?: string | number },
    },
  },
  comments: {
    likes: {
      likePostComment: {
        method: 'POST',
        path: (payload: { commentId: string | number }) =>
          `comments/${payload.commentId}/likes`,
      },
      getPostCommentLikes: {
        method: 'GET',
        path: (payload: { commentId: string | number }) =>
          `comments/${payload.commentId}/likes`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      unlikePostComment: {
        method: 'DELETE',
        path: (payload: { commentId: string | number }) =>
          `comments/${payload.commentId}/likes`,
      },
    },
    deleteComment: {
      method: 'DELETE',
      path: (payload: { commentId: string | number }) =>
        `comments/${payload.commentId}`,
    },
    updateComment: {
      method: 'PATCH',
      path: (payload: { commentId: string | number }) =>
        `comments/${payload.commentId}`,
    },
    getComment: {
      method: 'GET',
      path: (payload: { commentId: string | number }) =>
        `comments/${payload.commentId}`,
    },
  },
  events: {
    guests: {
      inviteGuest: {
        method: 'POST',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/guests`,
      },
      updateGuest: {
        method: 'PATCH',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/guests`,
      },
      deleteGuest: {
        method: 'DELETE',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/guests`,
      },
    },
    hosts: {
      addHost: {
        method: 'POST',
        path: (payload: {
          eventId: string | number;
          userId: string | number;
        }) => `events/${payload.eventId}/hosts/${payload.userId}`,
      },
      removeHost: {
        method: 'DELETE',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/hosts/3`,
      },
    },
    enrollment: {
      getEventEnrollements: {
        method: 'GET',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/enrollments`,
      },
      enrollInEvent: {
        method: 'POST',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/enrollments`,
      },
      getEnrollmentTicket: {
        method: 'GET',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/enrollments/ticket`,
      },
    },
    translations: {
      addEventTranslation: {
        method: 'POST',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/translations`,
      },
      updateEventTranslation: {
        method: 'PATCH',
        path: (payload: {
          eventId: string | number;
          translationId: string | number;
        }) => `events/${payload.eventId}/translations/${payload.translationId}`,
      },
      deleteEventTranslation: {
        method: 'DELETE',
        path: (payload: {
          eventId: string | number;
          translationId: string | number;
        }) => `events/${payload.eventId}/translations/${payload.translationId}`,
      },
    },
    favorite: {
      favoriteEvent: {
        method: 'POST',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/favorite`,
      },
      removeFavoriteFromEvent: {
        method: 'DELETE',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/favorite`,
      },
    },
    seen: {
      markEventAsSeen: {
        method: 'POST',
        path: (payload: { eventId: string | number }) =>
          `events/${payload.eventId}/seen`,
      },
    },
    getEvents_C: {
      method: 'GET',
      path: () => `events`,
      params: {} as {
        startTime?: string | number;
        endingTime?: string | number;
        skip?: string | number;
        take?: string | number;
        featuredOnly?: string | number;
        filterOutSeen?: string | number;
        search?: string | number;
        startEnrollmentDate?: string | number;
        endEnrollmentDate?: string | number;
        filterOutUntimedEvents?: string | number;
        expiryDate?: string | number;
      },
    },
    getEvent_C: {
      method: 'GET',
      path: (payload: { eventId: string | number }) =>
        `events/${payload.eventId}`,
    },
    deleteEvent: {
      method: 'DELETE',
      path: (payload: { eventId: string | number }) =>
        `events/${payload.eventId}`,
    },
    updateEvent: {
      method: 'PATCH',
      path: (payload: { eventId: string | number }) =>
        `events/${payload.eventId}`,
    },
    takeAttendance: {
      method: 'POST',
      path: () => `events/attendance`,
    },
    checkoutEvent: {
      method: 'POST',
      path: () => `events/checkout`,
    },
  },
  posts: {
    postComments: {
      createComment: {
        method: 'POST',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/comments`,
      },
      getPostComments: {
        method: 'GET',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/comments`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    saves: {
      savePost: {
        method: 'POST',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/saves`,
      },
      unsavePost: {
        method: 'DELETE',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/saves`,
      },
      getPostSaves: {
        method: 'GET',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/saves`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    likes: {
      likePost: {
        method: 'POST',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/likes`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      unlikePost: {
        method: 'DELETE',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/likes`,
      },
      getPostLikes: {
        method: 'GET',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/likes`,
      },
    },
    pin: {
      pinPost: {
        method: 'POST',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/pin`,
      },
      unpinPost: {
        method: 'DELETE',
        path: (payload: { postId: string | number }) =>
          `posts/${payload.postId}/pin`,
      },
    },
    updatePost: {
      method: 'PATCH',
      path: (payload: { postId: string | number }) => `posts/${payload.postId}`,
    },
    deletePost: {
      method: 'DELETE',
      path: (payload: { postId: string | number }) => `posts/${payload.postId}`,
    },
    getSinglePost: {
      method: 'GET',
      path: (payload: { postId: string | number }) => `posts/${payload.postId}`,
    },
  },
  groups_C: {
    posts: {
      getGroupPosts_C: {
        method: 'GET',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/posts`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      createGroupPost_C: {
        method: 'POST',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/posts`,
      },
    },
    members: {
      joinAndLeave: {
        joinGroup: {
          method: 'POST',
          path: (payload: { groupId: string | number }) =>
            `groups/${payload.groupId}/members/join`,
        },
      },
      getGroupMembers_C: {
        method: 'GET',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/members`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          status?: (string | number)[];
        },
      },
      groupMemberAction: {
        method: 'POST',
        path: (payload: {
          groupId: string | number;
          memberId: string | number;
        }) => `groups/${payload.groupId}/members/${payload.memberId}`,
      },
      inviteMemberByEmail_C: {
        method: 'POST',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/members/invite-member`,
      },
    },
    events: {
      getGroupEvents: {
        method: 'GET',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/events`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          startTime?: string | number;
          endingTime?: string | number;
        },
      },
      createGroupEvent: {
        method: 'POST',
        path: (payload: { groupId: string | number }) =>
          `groups/${payload.groupId}/events`,
      },
    },
    getGroup_C: {
      method: 'GET',
      path: (payload: { groupId: string | number }) =>
        `groups/${payload.groupId}`,
    },
    getGroups_C: {
      method: 'GET',
      path: () => `groups`,
      params: {} as {
        skip?: string | number;
        take?: string | number;
        filterEnrolled?: string | number;
      },
    },
  },
  users: {
    groups: {
      getUserGroups_c: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/groups`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          status?: (string | number)[];
        },
      },
      getUserGroupsEvents: {
        method: 'GET',
        header: {},
      },
    },
    posts: {
      getUserHomeFeed: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/home`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getUserLikedPosts: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/likes`,
        params: {} as { offset?: string | number; limit?: string | number },
      },
      getUserSavedPosts: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/saved-posts`,
        params: {} as { offset?: string | number; limit?: string | number },
      },
    },
    events: {
      getUserEnrolledEvents: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/enrolled-events`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          startTime?: string | number;
          endingTime?: string | number;
          groupId?: string | number;
        },
      },
      getUserFavoritedEvents: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/favorited-events`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          endingTime?: string | number;
          groupId?: string | number;
          startTime?: string | number;
        },
      },
      getUserHostedEvents: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/hosted-events`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          endingTime?: string | number;
          groupId?: string | number;
          startTime?: string | number;
        },
      },
      getAllUpcomingEventsForUserGroups: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/group-events`,
      },
    },
    comments: {
      getUserComments: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/comments`,
        params: {} as { limit?: string | number; offset?: string | number },
      },
    },
    preferences: {
      getUserPreferences: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/preferences`,
      },
      setUserPreferences: {
        method: 'POST',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/preferences`,
      },
    },
    getUser: {
      method: 'GET',
      path: (payload: { userId: string | number }) => `users/${payload.userId}`,
    },
    updateUser: {
      method: 'PATCH',
      path: () => `users/1`,
    },
    changePassword: {
      method: 'PATCH',
      path: () => `users/2358/password`,
    },
  },
};
