import { User } from './user';
import { Course } from './course';
import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { CourseAnnouncementsMedia } from './course_announcements_media';

export class CourseAnnouncement {
  id: number;

  title: string;

  content: string;

  announcementDate?: Date;

  isPublished: boolean;

  postAnonymously: boolean;

  userId?: number;

  user?: User;

  courseId: number;

  course: Course;

  discussionBoardItemId?: number;

  discussionBoardItem?: CourseDiscussionBoardItem;

  createdAt: Date;

  updatedAt?: Date;

  announcementMedia: CourseAnnouncementsMedia[];
}
