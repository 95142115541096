<script setup lang="ts">
  import type { ComputedRef, Ref } from 'vue';
  import { inject, ref } from 'vue';
  import { type RouteLocationRaw } from 'vue-router';
  import { useDisplay } from 'vuetify';
  import CartIcon from '../CartIcon.vue';
  import FavoriteIcon from '../FavoriteIcon.vue';
  import LoginAndRegister from '../LoginAndRegister.vue';
  import ProfileIcon from '../ProfileIcon.vue';
  import NotificationIcon from '../notification/NotificationIcon.vue';
  import MenuDrawer from './drawer/MenuDrawer.vue';

  const openMenu = ref(false);
  const hideSideBar: Ref<boolean> = inject('hideSideBar', ref(false));
  const showEnv: Ref<boolean> = inject('showEnv', ref(false));
  const isLoggedIn: Ref<boolean> = inject('isLoggedIn', ref(false));
  const showLogin: Ref<boolean> = inject('showLogin', ref(false));
  const showNotificationIcon: Ref<boolean> = inject(
    'showNotificationIcon',
    ref(false)
  );
  const isStudentView: Ref<boolean> = inject('isStudentView', ref(false));
  const showFavIcon: Ref<boolean> = inject('showFavIcon', ref(false));
  const showCartIcon: Ref<boolean> = inject('showCartIcon', ref(false));
  const logo: string | undefined = inject('logo');
  const icon: string | undefined = inject('icon');
  const betaText: ComputedRef<string> | undefined = inject(
    'betaText',
    undefined
  );
  const baseurl: ComputedRef<string> | undefined = inject('baseurl', undefined);
  const logoRoute: ComputedRef<RouteLocationRaw> | undefined = inject(
    'logoRoute',
    undefined
  );

  const { smAndUp } = useDisplay();
  const showNavIcon: ComputedRef<boolean> | Ref<boolean> = inject(
    'showNavIcon',
    ref(true)
  );

  const showAppBar: ComputedRef<boolean> | Ref<boolean> = inject(
    'showAppBar',
    ref(true)
  );

  const appBar = ref(false);
  const emit = defineEmits<{
    (event: 'update', value: boolean): void;
  }>();
</script>

<template>
  <MenuDrawer
    id="menu-drawer-items-opening"
    @update:openMenu="(val) => (openMenu = val)"
    :openMenu="openMenu"
  >
    <template v-if="$slots?.prependIcon" #prependIcon>
      <slot name="prependIcon" />
    </template>
    <template v-if="$slots?.appendIcon" #appendIcon>
      <slot name="appendIcon" />
    </template>
    <template v-if="$slots?.headerSubtitle" #headerSubtitle>
      <slot name="headerSubtitle" />
    </template>
  </MenuDrawer>
  <v-app-bar
    v-model="appBar"
    @update:model-value="emit('update', appBar)"
    :absolute="!showAppBar || isStudentView"
    :scroll-behavior="!showAppBar ? undefined : 'hide'"
    :scroll-threshold="!showAppBar ? 0 : 243"
    height="60"
    elevation="0"
    class="flex-grow-0"
    style="border-bottom: 0.5px solid #e0e0e0"
  >
    <template v-slot:prepend v-if="showNavIcon">
      <v-app-bar-nav-icon
        aria-label="open or close menu"
        :class="hideSideBar ? 'd-block' : 'd-block d-md-none'"
        @click.prevent="openMenu = !openMenu"
      ></v-app-bar-nav-icon>
    </template>
    <v-app-bar-title v-if="!openMenu" class="ma-0">
      <div class="d-flex align-center ga-1">
        <RouterLink :to="logoRoute || ''">
          <img
            :src="smAndUp ? logo : icon"
            alt="SCFHS Logo"
            contain
            height="45"
            class="my-auto d-block"
            :class="showNavIcon ? '' : 'ms-2'"
          />
        </RouterLink>
        <span
          v-if="false"
          class="px-2 font-italic font-weight-thin align-self-end flex-grow-0"
          style="font-size: 0.6rem"
        >
          {{ betaText }}
        </span>
      </div>
    </v-app-bar-title>
    <h6 v-if="showEnv">{{ baseurl }}</h6>
    <template v-slot:append>
      <div class="d-flex">
        <!--if user Authorized  -->
        <div v-if="isLoggedIn" class="d-flex ga-2 align-center">
          <NotificationIcon v-if="showNotificationIcon"> </NotificationIcon>
          <FavoriteIcon v-if="showFavIcon" />
          <CartIcon v-if="showCartIcon" />
          <v-divider
            v-if="showCartIcon || showFavIcon || showNotificationIcon"
            vertical
            color="primary"
          ></v-divider>
          <ProfileIcon />
        </div>
        <div v-else-if="showLogin" class="ga-2 d-none d-sm-flex">
          <LoginAndRegister />
        </div>
      </div>
    </template>
  </v-app-bar>
</template>
