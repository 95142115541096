import { QuestionStem } from './question_stem';
import { Media } from './media';

export class QuestionStemMedia {
  id: number;

  questionStemId: number;

  questionStem: QuestionStem;

  mediaId: number;

  media: Media;

  createdAt: Date;

  updatedAt?: Date;
}
