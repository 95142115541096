import { VideoConference } from './video_conference';
import { ZoomOAuthToken } from './zoom_o_auth_token';

export class ZoomMeeting {
  id: number;

  videoConferenceId?: number;

  videoConference?: VideoConference;

  meetingId: string;

  joinUrl: string;

  startTime: Date;

  duration: number;

  createdUsingTokenId: number;

  createdUsingToken: ZoomOAuthToken;

  createdAt: Date;

  updatedAt?: Date;
}
