import { AssignmentSubmission } from './assignment_submission';
import { Media } from './media';

export class AssignmentSubmissionMedia {
  id: number;

  submissionId?: number;

  submission?: AssignmentSubmission;

  mediaId: number;

  media: Media;

  createdAt: Date;

  updatedAt?: Date;
}
