/**
 * this file to import all the routes that you want to hide from the menu and all the website
 *
 */
import { useEnvBoolean } from '@/composables/useEnvBoolean.composable';
import { EnumRoutes } from '@/helpers/enums/routes.enum';

export const hiddenRoutes: string[] = [];

/**
 * this function to initialize the hidden routes array
 * @returns void
 * @example
 * initializeHiddenRoutes();
 * hiddenRoutes = [
 * 'landing',
 * 'landing-leadership-development',
 * 'landing-executive-coach',
 * 'landing-professional',
 * 'landing-webinars',
 * 'landing-about',
 * 'library',
 * 'library-article',
 * 'library-video',
 * 'cart',
 * 'payment-history',
 * 'single-order',
 * 'all-courses',
 * 'events',
 * ];
 *
 */
export const initializeHiddenRoutes: () => void = () => {
  const { allowLandingPage } = useEnvBoolean();
  if (!allowLandingPage) {
    hiddenRoutes.push(
      EnumRoutes.landing.root.name,
      EnumRoutes.landing.leaderShipDevelopment.name,
      EnumRoutes.landing.executiveCoach.name,
      EnumRoutes.landing.professional.name,
      EnumRoutes.landing.webinars.name,
      EnumRoutes.landing.about.name,
      EnumRoutes.libraryRoutesEnum.root.name,
      EnumRoutes.libraryRoutesEnum.article.name,
      EnumRoutes.libraryRoutesEnum.video.name
    );
  }
};
