import { QuestionRubricCriterion } from './question_rubric_criterion';
import { RubricCriterionRating } from './rubric_criterion_rating';
import { AnswerReview } from './answer_review';

export class StudentGradeForQuestionRubricCriterion {
  id: number;

  questionRubricId: number;

  questionRubric: QuestionRubricCriterion;

  ratingId?: number;

  rating?: RubricCriterionRating;

  grade: number;

  comment?: string;

  answerReviewId: number;

  answerReview: AnswerReview;

  createdAt: Date;

  updatedAt?: Date;
}
