export const libraryRoutesEnum = {
  root: {
    name: 'Library',
    path: '/library',
    title: 'header.library',
    icon: 'mdi-book-open-page-variant',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Library`,
    },
  },
  article: {
    name: 'Library Article',
    path: '/library/:id/article',
    title: 'header.library',
    pathFn: (id: number) => `/library/${id}/article`,
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Library`,
    },
  },
  video: {
    name: 'Library Video',
    path: '/library/:id',
    pathFn: (id: number) => `/library/${id}`,
    title: 'header.library',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Library`,
    },
  },
};
