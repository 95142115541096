// enum class for svg assets
const cdn = 'https://cdn.himam.com';
const publicCdn = `${cdn}/public`;
export const svgAssetsExternalUrls = {
  emptyData: `${publicCdn}/defaultImages/svg/emptyData.svg`,
  smallLogo: `${publicCdn}/defaultImages/svg/smallLogo.svg`,
  himamLogo: `${publicCdn}/svgs/himam-logo.svg`,
  himamLogoWhite: `${publicCdn}/svgs/logo-white.svg`,
  himamLogoColored: `${publicCdn}/svgs/logo-colored.svg`,
  himamLogoIcon: `${publicCdn}/svgs/himam-logo-icon.svg`,
  playStoreEn: `${publicCdn}/store-icons/en-play-store.png`,
  playStoreAr: `${publicCdn}/store-icons/ar-play-store.png`,
  appStoreEn: `${publicCdn}/store-icons/en-app-store.svg`,
  appStoreAr: `${publicCdn}/store-icons/ar-app-store.svg`,
  appGalleryEn: `${publicCdn}/store-icons/app-gallery-en.png`,
  appGalleryAr: `${publicCdn}/store-icons/app-gallery-ar-1.png`,
  chatAiIcon: `${publicCdn}/svgs/chatAIIcon.png`,
  ceuWhite: `${publicCdn}/svgs/ceuWhite.svg`,
  ceuBlack: `${publicCdn}/svgs/ceuBlack.svg`,
  ceuPrimary: `${publicCdn}/svgs/ceuPrimary.svg`,
  ceuSecondary: `${publicCdn}/svgs/ceuSecondary.svg`,
  zoom: `${publicCdn}/svgs/zoom.svg`,
};
