import { PaymentWebhook } from './payment_webhook';

export class OttuPayment {
  id: number;

  amount: number;

  paymentGatewayId?: string;

  orderId: number;

  currencyCode?: string;

  state?: string;

  gatewayAccount?: string;

  gatewayName?: string;

  referenceNumber?: string;

  result?: string;

  refundedAmount?: number;

  totalPaidAmount?: number;

  totalVoidedAmount?: number;

  totalAuthorizedAmount?: number;

  signature?: string;

  acsEci?: string;

  authenticationToken?: string;

  transactionId?: string;

  creationTime?: Date;

  lastUpdateTime?: Date;

  referenceId?: string;

  status?: string;

  brand?: string;

  fundingMethod?: string;

  localBrand?: string;

  nameOnCard?: string;

  creditCardNumber?: string;

  expMonth?: number;

  expYear?: number;

  browser?: string;

  ipAddress?: string;

  settlementDate?: Date;

  batch?: number;

  authorizationCode?: string;

  authenticationStatus?: string;

  source?: string;

  receipt?: string;

  terminal?: string;

  type?: string;

  acquirerMessage?: string;

  deletedAt?: Date;

  createdAt: Date;

  updatedAt: Date;

  paymentWebhook?: PaymentWebhook;

  paymentWebhookId?: number;
}
