export const externalIntegrationsApiHandler = {
  internalHimamUse: {
    createApiKey: {
      method: 'POST',
      path: () => `external-integrations/create-key`,
    },
    updateApiKey: {
      method: 'POST',
      path: () => `external-integrations/update-key`,
    },
  },
  externalHimamUse: {
    createUser: {
      method: 'POST',
      path: () => `external-integrations-api/create-user`,
    },
  },
};
