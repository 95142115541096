import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const journeyRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.journeyRoutesEnum.root.path,
    name: EnumRoutes.journeyRoutesEnum.root.name,
    component: () => import('@/views/journey-planner/JourneyPlanner.vue'),
    meta: EnumRoutes.journeyRoutesEnum.root.meta,
  },
];
