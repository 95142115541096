import { QueryClient, type VueQueryPluginOptions } from '@tanstack/vue-query';
import type { MaybeRefDeep } from 'node_modules/@tanstack/vue-query/build/lib/types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0 as MaybeRefDeep<1 | undefined>,
    },
  },
});

const options: VueQueryPluginOptions = { queryClient };

export { options, queryClient };
