import { QuestionsTag } from './questions_tag';

export class SingleQuestionTag {
  id: number;

  name: string;

  createdAt: Date;

  updatedAt?: Date;

  questionTags: QuestionsTag[];
}
