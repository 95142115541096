import { Media } from './media';
import { SocialEvent } from './social_event';

export class Guest {
  name: string;

  email: string;

  organization?: string;

  occupation?: string;

  brief?: string;

  imageId?: number;

  image?: Media;

  eventId: number;

  invitedTo: SocialEvent;

  createdAt: Date;

  updatedAt: Date;
}
