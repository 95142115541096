import { CourseTargetOrganization } from './course_target_organization';
import { OrganizationCreditUnitName } from './organization_credit_unit_name';

export class CourseTargetOrganizationCreditUnit {
  id: number;

  courseTargetOrganizationId: number;

  courseTargetOrganization: CourseTargetOrganization;

  organizationCreditUnitNameId: number;

  organizationCreditUnitName: OrganizationCreditUnitName;

  creditUnitValue: number;

  createdAt: Date;

  updatedAt?: Date;
}
