import { CourseAssignment } from './course_assignment';
import { AssignmentSubmissionTypes } from '../exported_type_enums/exported_type_enums';

export class AssignmentSubmissionType {
  id: number;

  assignmentId: number;

  assignment: CourseAssignment;

  type: AssignmentSubmissionTypes;

  createdAt: Date;

  updatedAt?: Date;
}
