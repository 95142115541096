<script setup lang="ts">
  import { inject, ref, watch, type ComputedRef, type Ref } from 'vue';
  import { type RouteLocationRaw } from 'vue-router';

  import type { headerItem } from './../../../helpers/interfaces/header-item.interface';
  const headerItems: ComputedRef<headerItem[]> | undefined =
    inject('headerItems');
  defineProps<{ rail: boolean }>();
  const activeItem: Ref<RouteLocationRaw[]> = ref([]);
  const path: Ref<string> = inject('path', ref(''));
  watch(
    () => path.value,
    () => {
      let firstMath = '';
      headerItems?.value.forEach((item) => {
        if (!item.children) {
          if (
            path.value.startsWith(item.to as string) &&
            (item.to as string)?.length >= firstMath.length
          ) {
            firstMath = item.to as string;
            activeItem.value = [item.to as string];
          }
        } else {
          item.children.forEach((child) => {
            if (
              path.value.startsWith(child.to as string) &&
              (child.to as string)?.length >= firstMath.length
            ) {
              firstMath = child.to as string;
              activeItem.value = [child.to as string];
            }
          });
        }
      });
    },
    { immediate: true }
  );
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
</script>

<template>
  <v-list
    mandatory
    nav
    class="pa-0"
    v-model:selected="activeItem"
    aria-label="Menu Drawer"
    id="menu-drawer-items"
    role="option"
  >
    <template v-for="item in headerItems" :key="item.title">
      <v-list-group v-if="item.children && !rail" class="mb-1">
        <template v-slot:activator="{ props }">
          <v-list-item
            density="compact"
            :value="item.to"
            v-bind="props"
            :to="item.to"
            color="primary"
            class="rounded-0 mb-0"
            :class="
              item.children.some((child) => activeItem?.[0] === child.to)
                ? 'text-primary'
                : 'text-gray'
            "
            active-class="bg-light"
          >
            <template #prepend>
              <v-icon :icon="item.icon || 'mdi-minus'" />
            </template>
            <template #title>
              <div
                :class="!rail ? 'text-wrap' : 'text-no-wrap'"
                class="text-capitalize"
              >
                {{ item.title }}
              </div>
            </template>
          </v-list-item>
        </template>
        <div class="bg-grey-lighten-4 pa-0">
          <v-list-item
            @click="scrollUp"
            :active="activeItem?.[0] === child.to"
            density="compact"
            v-for="child in item.children"
            :key="child.title"
            :value="child.to"
            :to="child.to"
            color="primary"
            class="mb-0 rounded-0"
            :class="activeItem?.[0] === child.to ? 'text-primary' : 'text-gray'"
            active-class="bg-light"
          >
            <template #prepend>
              <v-icon :icon="child.icon || 'mdi-minus'" />
            </template>
            <template #title>
              <div
                :class="!rail ? 'text-wrap' : 'text-no-wrap'"
                class="text-capitalize"
              >
                {{ child.title }}
              </div>
            </template>
          </v-list-item>
        </div>
      </v-list-group>
      <v-menu
        v-else-if="item.children"
        location="end"
        open-on-click
        close-on-content-click
        open-on-hover
        content-class="nav-drawer"
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            density="compact"
            v-bind="props"
            :value="item.to"
            :to="item.to"
            color="primary"
            class="mb-1 rounded-lg"
            :class="
              item.children.some((child) => activeItem?.[0] === child.to)
                ? 'text-primary'
                : 'text-gray'
            "
            active-class="bg-light"
          >
            <template #prepend>
              <v-icon :icon="item.icon || 'mdi-minus'" />
            </template>
            <template #title>
              <div
                :class="!rail ? 'text-wrap' : 'text-no-wrap'"
                class="text-capitalize"
              >
                {{ item.title }}
              </div>
            </template>
          </v-list-item>
        </template>
        <v-list mandatory nav class="pa-2" v-model:selected="activeItem">
          <v-list-item
            @click="scrollUp"
            :active="activeItem?.[0] === child.to"
            density="compact"
            v-for="(child, index) in item.children"
            :key="child.title"
            :value="child.to"
            :to="child.to"
            color="primary"
            class="rounded-lg"
            :class="{
              'text-primary': activeItem?.[0] === child.to,
              'text-gray': activeItem?.[0] !== child.to,
              'mb-1': index !== item.children.length - 1,
              'mb-0': index == item.children.length - 1,
            }"
            active-class="bg-light"
          >
            <template #prepend>
              <v-icon :icon="child.icon || 'mdi-minus'" />
            </template>
            <template #title>
              <div
                :class="!rail ? 'text-wrap' : 'text-no-wrap'"
                class="text-capitalize"
              >
                {{ child.title }}
              </div>
            </template>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item
        @click="scrollUp"
        :active="activeItem?.[0] === item.to"
        density="compact"
        v-else
        :value="item.to"
        :to="item.to"
        color="primary"
        class="mb-1 rounded-lg"
        :class="activeItem?.[0] === item.to ? 'text-primary' : 'text-gray'"
        active-class="bg-light"
      >
        <template #prepend>
          <v-icon :icon="item.icon || 'mdi-minus'" />
        </template>
        <template #title>
          <div
            :class="!rail ? 'text-wrap' : 'text-no-wrap'"
            class="text-capitalize"
          >
            {{ item.title }}
          </div>
        </template>
      </v-list-item>
    </template>
  </v-list>
</template>

<style lang="scss">
  .button-back {
    opacity: 0;
  }

  .drawer {
    min-width: 61px;
    &:hover {
      .button-back {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }
  .v-navigation-drawer__content {
    /* Width of the scrollbar */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* For Firefox */
    & {
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;

      /* Handle on hover for Firefox */
      &:hover {
        scrollbar-color: #555 #f1f1f1;
      }
    }
  }

  .nav-drawer {
    .v-list-item--variant-text .v-list-item__overlay {
      background-color: grey !important;
    }
    .v-list-item:hover > .v-list-item__overlay {
      opacity: 0.08 !important;
    }
    .v-list-group__items .v-list-item {
      padding-inline-start: 8px !important;
    }
    .v-list-item__spacer {
      width: 16px !important;
    }
  }
</style>
../../../helpers/interfaces/header-item.interface
../../../helpers/interfaces/header-item.interface
