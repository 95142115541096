import { GradingSchema } from './grading_schema';

export class GradeMapping {
  id: number;

  letter: string;

  minValue: number;

  maxValue: number;

  description?: string;

  gradingSchemaId: number;

  gradingSchema: GradingSchema;
}
