import { StudentCourseCertificate } from './student_course_certificate';
import { Media } from './media';
import { CertificateTemplate } from './certificate_template';

export class StudentCourseCertificateMedium {
  id: number;

  studentCourseCertificateId: string;

  studentCourseCertificate: StudentCourseCertificate;

  mediumId: number;

  medium: Media;

  templateId?: number;

  template?: CertificateTemplate;
}
