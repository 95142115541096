import { User } from './user';
import { Post } from './post';
import { PostCommentsLike } from './post_comments_like';

export class PostComment {
  id: number;

  userId: number;

  postId: number;

  user: User;

  post: Post;

  content: string;

  parentCommentId?: number;

  parentComment?: PostComment;

  likes: PostCommentsLike[];

  replies: PostComment[];

  createdAt: Date;

  updatedAt: Date;
}
