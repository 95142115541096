import { Organization } from './organization';
import { User } from './user';

export class OrganizationMember {
  id: number;

  organizationId: number;

  organization: Organization;

  memberId: number;

  member: User;

  permissionSetId?: number;

  permissionSetIsLocked: boolean;

  createdAt: Date;

  updatedAt?: Date;
}
