import { Order } from './order';
import { User } from './user';
import { Organization } from './organization';
import { PurchasedItemType } from './purchased_item_type';
import { FinancialStatus } from './financial_status';
import { OrderRefund } from './order_refund';
import { PurchasedItemMetadata } from './purchased_item_metadata';

export class PurchasedItem {
  id: number;

  amountWithoutTax?: number;

  taxAmount?: number;

  totalAmount?: number;

  discountAmount?: number;

  finalAmount?: number;

  itemId: number;

  isTrxRecognized?: boolean;

  orderId: number;

  order: Order;

  userId: number;

  user: User;

  organizationId?: number;

  organization?: Organization;

  purchasedItemType: PurchasedItemType;

  purchasedItemTypeSlug: string;

  statusSlug?: string;

  status?: FinancialStatus;

  refund?: OrderRefund;

  metadata?: PurchasedItemMetadata;

  updatedAt?: Date;

  createdAt?: Date;
}
