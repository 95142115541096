<script setup lang="ts">
  import type { Ref } from 'vue';
  import { computed, inject, ref } from 'vue';
  import { type RouteLocationRaw } from 'vue-router';
  const emit = defineEmits<{
    (event: 'refresh'): () => void;
  }>();
  export interface INotificationList {
    to: RouteLocationRaw;
    id: number;
    readAt?: string;
    img: string;
    text: string;
    createdAt: string;
  }
  withDefaults(
    defineProps<{
      notificationsList: INotificationList[];
      fullHeight?: boolean;
    }>(),
    {
      fullHeight: false,
    }
  );

  const handleNotificationClick: (
    notification: INotificationList
  ) => Promise<void> = inject('handleNotificationClick', async () => {});
  const handleNotificationClickFun = async (
    notification: INotificationList
  ) => {
    if (!notification.readAt) {
      await handleNotificationClick(notification);
      emit('refresh');
    }
  };
  const handleDeleteNotification: (notificationId: number) => Promise<void> =
    inject('handleDeleteNotification', async () => {});
  const handleDeleteNotificationFun = async (notificationId: number) => {
    await handleDeleteNotification(notificationId);
    emit('refresh');
  };
  const notificationDeleteText: Ref<string> = inject(
    'notificationDeleteText',
    ref('Delete')
  );
  const noNotificationsText: Ref<string> = inject(
    'noNotificationsText',
    ref(`You're up to date with your notifications`)
  );
  const agoText: Ref<string> = inject('agoText', ref(''));
  const isLTR: Ref<boolean> = inject('isLTR', ref(true));
  const isLoading: Ref<boolean> = inject('isLoadingNotification', ref(false));

  const notificationTimeText = (time: string) =>
    computed(() => {
      if (!time) return;
      const notificationTime = new Date(time);
      const today = new Date();
      const diffTime = today.getTime() - notificationTime.getTime();
      const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
      const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
      const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      const diffTimeText =
        diffYears > 1
          ? diffYears + ' ' + yearsText.value
          : diffMonths > 2
            ? diffMonths + ' ' + monthsText.value
            : diffWeeks > 1
              ? diffWeeks + ' ' + weeksText.value
              : diffDays > 1
                ? diffDays + ' ' + daysText.value
                : diffHours > 1
                  ? diffHours + ' ' + hoursText.value
                  : diffMinutes > 10
                    ? ' ' + fewMinutesText.value
                    : diffMinutes > 1
                      ? diffMinutes + 'm '
                      : ' ' + nowText.value;
      return diffTimeText;
    });

  const yearsText: Ref<string> = inject('yearsText', ref(''));
  const monthsText: Ref<string> = inject('monthsText', ref(''));
  const weeksText: Ref<string> = inject('weeksText', ref(''));
  const daysText: Ref<string> = inject('daysText', ref(''));
  const hoursText: Ref<string> = inject('hoursText', ref(''));
  const fewMinutesText: Ref<string> = inject('fewMinutesText', ref(''));
  const nowText: Ref<string> = inject('nowText', ref(''));
</script>

<template>
  <v-list
    :max-height="fullHeight ? '100%' : '400'"
    :min-height="fullHeight ? '100%' : '300'"
  >
    <div v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        size="70"
        width="50%"
        color="primary"
        class="mx-auto"
      />
    </div>
    <div v-else-if="notificationsList.length > 0" class="w-100">
      <v-list-item
        :to="notification.to"
        @click="handleNotificationClickFun(notification)"
        v-for="notification in notificationsList"
        :key="notification.id"
        color="primary"
        :active="notification.readAt == null"
        class="my-2 py-2"
      >
        <template v-slot:prepend>
          <v-avatar
            color="primary"
            :image="notification.img"
            class="unselectable cursor-pointer"
            :size="'default'"
          >
          </v-avatar>
        </template>
        <div class="d-flex align-center justify-space-between">
          <v-list-item-title class="text-caption text-wrap">
            {{ notification.text }}
          </v-list-item-title>

          <v-icon
            v-if="notification.readAt == null"
            color="primary"
            size="8"
            icon="mdi-circle"
          ></v-icon>
          <v-menu width="100px" v-if="notification.readAt != null">
            <template v-slot:activator="{ props }">
              <v-btn
                @click.prevent
                icon="mdi-dots-horizontal"
                variant="text"
                density="compact"
                color="text"
                v-bind="props"
              >
              </v-btn>
            </template>

            <v-list density="compact">
              <v-list-item
                :value="notification.id"
                @click="handleDeleteNotificationFun(notification.id)"
              >
                <v-list-item-title class="text-caption text-wrap">
                  {{ notificationDeleteText }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-list-item-subtitle class="text-caption">
          <span
            v-if="
              !isLTR &&
              notificationTimeText(notification.createdAt).value !==
                ` ${nowText}`
            "
          >
            {{ agoText }}</span
          >
          {{
            notificationTimeText(notification.createdAt).value?.replace('"', '')
          }}
          <span
            v-if="
              isLTR &&
              notificationTimeText(notification.createdAt).value !==
                ` ${nowText}`
            "
          >
            {{ agoText }}</span
          >
        </v-list-item-subtitle>
      </v-list-item>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center">
      <v-img
        src="https://cdn.himam.com/public/defaultImages/svg/emptyData.svg"
        height="100px"
        width="100px"
      >
      </v-img>
      <v-list-item-title class="text-subtitle-2 text-text">
        {{ noNotificationsText }}
      </v-list-item-title>
    </div>
  </v-list>
</template>
