import { VideoConferencingTool } from './video_conferencing_tool';
import { VideoConference } from './video_conference';

export class VideoConferenceType {
  id: number;

  slug: string;

  videoConferencingToolId: number;

  videoConferencingTool: VideoConferencingTool;

  name: string;

  description?: string;

  isEnabled: boolean;

  createdAt: Date;

  updatedAt?: Date;

  videoConferences: VideoConference[];
}
