<script setup lang="ts">
  const Footer = defineAsyncComponent(
    () => import('@/components/footer/Footer.vue')
  );

  const CookiesDialog = defineAsyncComponent(
    () => import('@/components/base/CookiesDialog.vue')
  );
  import Banner from '@/components/header/Banner.vue';
  import {
    detectNotificationType,
    notificationsImagePlacholderHandler,
    notificationsRouteHandler,
  } from '@/components/header/notifications/notifications-handler';
  import { queryKeys } from '@/components/pagination/query-key-factory';
  import { EnumHeaderList } from '@/helpers/enums/header-lists.enum';
  import { EnumRoutes } from '@/helpers/enums/routes.enum';
  import { StringCase } from '@/helpers/enums/validation-enums';
  import type { INotification } from '@/helpers/interfaces/notification.interface';
  import type { Paginated } from '@/helpers/interfaces/paginated.interface';
  import type { UserWithExtraFields } from '@/helpers/interfaces/user.inteface';
  import { AllPaginatedQueries } from '@/helpers/paginated-queries/paginated-queries';
  import { userCityIsDefinedAndNotEmpty } from '@/helpers/validations/user';
  import { pinia } from '@/pinia';
  import { useI18 } from '@/plugins';
  import { useAuthStore } from '@/stores/auth.store';
  import { useCartStore } from '@/stores/cart.store';
  import { useGroupStore } from '@/stores/groups.store';
  import { useLocaleStore } from '@/stores/locale.store';
  import { useNotificationsStore } from '@/stores/notifications.store';
  import { useOrganizationStore } from '@/stores/organzation.pinia';
  import { usePermissionsStore } from '@/stores/permissoins.store';
  import AppBar from '@/submodules/himam-common-components/src/components/header/AppBar.vue';
  import SideMenu from '@/submodules/himam-common-components/src/components/header/drawer/SideMenu.vue';
  import type { INotificationList } from '@/submodules/himam-common-components/src/components/notification/NotificationIcon.vue';
  import type {
    headerItem,
    switchAccountItem,
  } from '@/submodules/himam-common-components/src/helpers/interfaces/header-item.interface';
  import CompleteProfileDialog from '@/views/dashboard/CompleteProfileDialog.vue';
  import NotAllowed from '@/views/misc/NotAllowed.vue';
  import NotAuthenticated from '@/views/misc/NotAuthenticated.vue';
  import BasicChangePassword from '@/views/profile-dynamic/components/change-password/BasicChangePassword.vue';
  import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
  import { storeToRefs } from 'pinia';
  import type { ComputedRef } from 'vue';
  import {
    computed,
    defineAsyncComponent,
    nextTick,
    onMounted,
    onUnmounted,
    provide,
    ref,
    watch,
    type Ref,
  } from 'vue';
  import { useRoute, type RouteMeta } from 'vue-router';
  import { useDisplay } from 'vuetify';

  const authStore = useAuthStore(pinia);
  const {
    shouldLogged,
    shouldHavePermission,
    instructorView,
    shouldResetPassword,
    user,
    portals,
  } = storeToRefs(authStore);
  const organizationStore = useOrganizationStore();
  const { organization, marketplaceEnabled, instructorText, coursesText } =
    storeToRefs(organizationStore);
  const hideSideBar = computed(
    () => useRoute()?.meta?.hideSideBar || !authStore?.user
  );
  const HideAppBar = computed(() => useRoute()?.meta?.hideAppBar || false);
  const hideBanner = ref(true);
  const bannerHeight = ref(60);
  const setBannerHeight = () => {
    bannerHeight.value = document.getElementById('banner')?.clientHeight || 60;
  };
  onMounted(() => {
    setBannerHeight();
    window.addEventListener('resize', setBannerHeight);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', setBannerHeight);
  });
  const marginTop = computed(() => {
    return instructorView.value
      ? 0
      : showBanner.value && !hideBanner.value
        ? 60 + bannerHeight.value
        : 60;
  });
  const updateBanner = (event: boolean) => {
    hideBanner.value = event;
    nextTick(() => setBannerHeight());
  };
  const localeStore = useLocaleStore();
  const { isLTR, locale } = storeToRefs(localeStore);
  const { t } = useI18();
  const permissionsStore = usePermissionsStore();
  const { checkAuthentication } = permissionsStore;
  const store = useNotificationsStore();
  const {
    unreadCount,
    notifications: data,
    userNotificationSettings,
  } = storeToRefs(store);
  const cartStore = useCartStore();

  const cartCount = computed(() => {
    return cartStore.cart?.purchasedItems?.length ?? 0;
  });
  /** >>>>>>>>>>>>>>> variable for the submodule <<<<<<<<<<<<<<< **/
  const betaText = computed(() => t('shared.beta'));
  const logoRoute = computed(() => {
    return { name: EnumRoutes.landing.root.name };
  });
  const isLoggedIn = computed(() => !!user.value?.id);
  const showLogin = computed(() => true);
  const showNotificationIcon = computed(() => true);
  const showFavIcon = computed(() => true);
  const showCartIcon = computed(() => marketplaceEnabled.value);
  const langText = computed(() => t('menu.language'));
  const canRegister = computed(() =>
    checkAuthentication(EnumRoutes.auth.register.meta)
  );
  const haveHeader = computed(() => false);
  const regiterRoute = computed(() => {
    return { name: EnumRoutes.auth.register.name };
  });
  const profileRoute = computed(() => {
    return { name: EnumRoutes.profile.root.name };
  });
  const regiterText = computed(() => t('menu.register'));
  const loginRoute = computed(() => {
    return { name: EnumRoutes.auth.login.name };
  });
  const cartRoute = computed(() => {
    return { name: EnumRoutes.financials.cartPage.name };
  });
  const favRoute = computed(() => {
    return { name: EnumRoutes.profile.favorite.root.name };
  });
  const groupStore = useGroupStore();
  const loginText = computed(() => t('menu.login'));
  const userFirstName = computed(() => user.value?.firstName);
  const userLastName = computed(() => user.value?.lastName);
  const logoutText = computed(() => t('menu.logout'));
  const userEmail = computed(() => user.value?.email);
  const profileImage = computed(() => user.value?.profileImage?.url);
  const headerItems = computed(() => {
    const items: headerItem[] = [];
    if (user.value) {
      EnumHeaderList.value.headerItemsAuth.forEach((item) => {
        if (checkAuthentication(item.meta as RouteMeta))
          if (
            !(item.meta as RouteMeta).shouldHaveGroup ||
            groupStore.groups?.data?.length
          ) {
            items.push({
              title: (item.meta as RouteMeta).dontUseKey
                ? item.title
                : t(item.title, { courseText: coursesText.value }).toString(),
              icon: item.icon,
              to: item.path,
            });
          }
      });
    } else {
      EnumHeaderList.value.headerItemsUnAuth.forEach((item) => {
        if (checkAuthentication(item.meta as RouteMeta))
          items.push({
            title: (item.meta as RouteMeta).dontUseKey
              ? item.title
              : t(item.title, { courseText: coursesText.value }).toString(),
            icon: item.icon,
            to: item.path,
          });
      });
    }
    return items;
  });
  const profileItems = computed(() => {
    const items: headerItem[] = [];
    EnumHeaderList.value.headerProfileItems.forEach((item) => {
      if (usePermissionsStore().checkAuthentication(item.meta) && item.meta)
        if (
          !(item.meta as RouteMeta).shouldHaveGroup ||
          groupStore.groups?.data?.length
        )
          items.push({
            title: t(item.title),
            to: (item.meta as RouteMeta)?.needLanguage
              ? `${item.path}?language=${locale.value}`
              : item.path,
          });
    });
    return items;
  });
  const notificationsText = computed(() => t('menu.notifications'));
  const allNotificationsText = computed(() => t('shared.all'));
  const unreadNotificationsText = computed(() => t('shared.unRead'));
  const markAllAsReadText = computed(() => t('shared.markAllAsRead'));
  const seeAllNotificationsText = computed(() =>
    t('shared.seeAllNotifications')
  );
  provide('notificationsText', notificationsText);
  provide('allNotificationsText', allNotificationsText);
  provide('unreadNotificationsText', unreadNotificationsText);
  provide('markAllAsReadText', markAllAsReadText);
  provide('headerItems', headerItems);
  provide('seeAllNotificationsText', seeAllNotificationsText);
  provide('hideSideBar', hideSideBar);
  provide('profileItems', profileItems);
  provide('logo', organization.value?.logo?.url);
  provide('icon', organization.value?.icon?.url);
  provide('betaText', betaText);
  provide('isLTR', isLTR);
  provide('isLoggedIn', isLoggedIn);
  provide('showLogin', showLogin);
  provide('showNotificationIcon', showNotificationIcon);
  provide('showFavIcon', showFavIcon);
  provide('showCartIcon', showCartIcon);
  provide('locale', locale);
  provide('langText', langText);
  provide('canRegister', canRegister);
  provide('haveHeader', haveHeader);
  provide('regiterRoute', regiterRoute);
  provide('regiterText', regiterText);
  provide('loginRoute', loginRoute);
  provide('loginText', loginText);
  provide('paddingTop', marginTop);
  provide('userFirstName', userFirstName);
  provide('userLastName', userLastName);
  provide('userEmail', userEmail);
  provide('profileImage', profileImage);
  provide('profileRoute', profileRoute);
  provide('logoutText', logoutText);
  provide('logoRoute', logoRoute);
  provide('unreadCount', unreadCount);
  provide('cartCount', cartCount);
  provide('cartRoute', cartRoute);
  provide('favRoute', favRoute);
  provide('seeAllNotificationsRoute', {
    name: EnumRoutes.misc.notifications.name,
  });
  const { smAndDown } = useDisplay();
  const changeLocale = (languageCode: string) => {
    localeStore.changeLocale(languageCode, i18.i18next).then(() => {});
  };
  const i18 = useI18();
  const logout = () => {
    authStore.logout();
  };
  provide('logout', logout);
  provide('changeLocale', changeLocale);
  provide('showToggleBtn', ref(true));

  // to initialize permissions after loading the website without the need to await
  usePermissionsStore().initializePermissions();

  //>>>>>>> to tell us if we deployed to prod but we are using dev baseurl
  const baseurl = import.meta.env.VITE_APP_API_BASE_URL.split('//')[1].replace(
    '/api/',
    ''
  );
  const env = import.meta.env.MODE;
  const showEnv = computed(() => {
    return env === 'production' && !baseurl.includes('services');
  });

  provide('showEnv', showEnv);
  provide('baseurl', baseurl);
  const notificationsStore = useNotificationsStore();

  const { markAllAsRead } = notificationsStore;
  provide('markAllAsRead', markAllAsRead);

  const noNotificationsText = computed(() => t('shared.noNotifications'));
  provide('noNotificationsText', noNotificationsText);
  const take: Ref<number> = ref(15);
  const { key } = AllPaginatedQueries.notifications(take);
  const queryKey = computed(() => [...key, take.value, undefined, ref(1)]);
  const emits = defineEmits(['hideMenu']);
  const { markNotificationAsRead, deleteNotification } = store;
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data: { isdeleted: boolean; notificationId: number }) =>
      data.isdeleted
        ? deleteNotification(data.notificationId)
        : markNotificationAsRead(data.notificationId),
    onMutate: async (data) => {
      if (!queryClient.getQueryData(queryKey.value)) return;
      await queryClient.cancelQueries({ queryKey: queryKey.value });
      queryClient.setQueryData<Paginated<INotification>>(
        queryKey.value,
        (old) => {
          if (data.isdeleted) {
            return {
              count: old?.count || 0 - 1,
              data: old?.data
                ? old?.data.filter((item) => item.id !== data.notificationId)
                : [],
            };
          } else {
            return {
              count: old?.count || 0,
              data: old?.data
                ? old?.data.map((item) => {
                    if (item.id === data.notificationId) {
                      return {
                        ...item,
                        readAt: new Date().toISOString(),
                      };
                    }
                    return item;
                  })
                : [],
            };
          }
        }
      );
    },
    onSuccess: () => {
      queryClient.refetchQueries<Paginated<INotification>>(queryKey.value);
    },
    onError: () => {
      queryClient.refetchQueries<Paginated<INotification>>(queryKey.value);
    },
  });
  const handleNotificationClick = async (item: INotification) => {
    emits('hideMenu');
    mutate({
      isdeleted: false,
      notificationId: item.id,
    });
  };
  const handleDeleteNotification = async (id: number) => {
    mutate({
      isdeleted: true,
      notificationId: id,
    });
  };

  provide('handleNotificationClick', handleNotificationClick);
  provide('handleDeleteNotification', handleDeleteNotification);

  const notificationDeleteText = computed(() => t('shared.delete'));
  provide('notificationDeleteText', notificationDeleteText);

  const agoText = computed(() => t('shared.ago'));
  provide('agoText', agoText);

  const yearsText = computed(() => t('shared.years'));
  const monthsText = computed(() => t('shared.months'));
  const weeksText = computed(() => t('shared.weeks'));
  const daysText = computed(() => t('shared.days'));
  const hoursText = computed(() => t('shared.hours'));
  const fewMinutesText = computed(() => t('shared.fewMinutes'));
  const nowText = computed(() => t('shared.now'));
  provide('yearsText', yearsText);
  provide('monthsText', monthsText);
  provide('weeksText', weeksText);
  provide('daysText', daysText);
  provide('hoursText', hoursText);
  provide('fewMinutesText', fewMinutesText);
  provide('nowText', nowText);
  const notifications: ComputedRef<INotificationList[]> = computed(
    () =>
      data.value?.map((notification) => {
        const type = detectNotificationType(
          notification.data.notificationTemplate.id
        );
        return {
          to: notificationsRouteHandler(type, notification),
          id: notification.id,
          readAt: notification.readAt,
          img:
            notification.media?.url ||
            notificationsImagePlacholderHandler(notification),
          text: notification.text,
          createdAt: notification.createdAt,
        };
      }) || []
  );
  const unReadList = computed(() => {
    return notifications.value.filter((item) => item.readAt == null);
  });

  provide('notificationsList', notifications);
  provide('unReadNotificationsList', unReadList);

  const manageNotificationsText = computed(() =>
    t('shared.manageNotifications')
  );
  provide('manageNotificationsText', manageNotificationsText);

  const {
    isLoading,
    isFetching,
    data: notificationSettings,
    refetch: notificationSettingsRefetch,
  } = useQuery({
    queryKey: ['notificationControlGroupData'],
    queryFn: async () => {
      if (user.value?.id) return await store.getNotificationControlGroup();
      return true;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  watch(
    () => locale.value,
    () => {
      if (user.value?.id) notificationSettingsRefetch();
    }
  );
  provide('isLoading', isLoading);
  provide('isFetching', isFetching);
  provide('notificationSettings', notificationSettings);

  const updateNotificationSettings = (id: number, value: boolean) => {
    store.updateNotificationSettings({
      isDisabled: !value,
      notificationControlGroupId: id,
    });
  };
  provide('updateNotificationSettings', updateNotificationSettings);
  provide('userNotificationSettings', userNotificationSettings);
  const route = useRoute();
  const path = computed(() => route.path);
  provide('path', path);
  const showBanner = computed(() => {
    return !instructorView.value && !HideAppBar.value;
  });
  /** >>>>>>>>>>>>>>> Switch Account <<<<<<<<<<<<<<< **/
  const haveSwitchAccount = computed(() => {
    return switchAccountItems.value
      ? switchAccountItems.value?.length > 0
      : false;
  });
  const switchAccountItems: ComputedRef<switchAccountItem[]> = computed(() => {
    const items: switchAccountItem[] = [];
    if (portals.value?.isAdministrator)
      items.push({
        title: t('shared.switchToAdmin'),
        to: undefined,
        disabled: false,
        fun: () => {
          switchToPortal('admin');
        },
      });
    if (portals.value?.isInstructor)
      items.push({
        title: t('shared.switchToInstructor', {
          instructorText: instructorText.value,
        }),
        to: undefined,
        disabled: false,
        fun: () => {
          switchToPortal('instructor');
        },
      });

    return items;
  });

  const switchToPortal = async (targetLink: string) => {
    const token = await useAuthStore(pinia).requestSSO();
    const webUrl = `dashboard.${organization.value?.domainPrefix}.himam.com/${targetLink}`;
    let redirectUrl = `https://${webUrl}?super_token=${token}&lang=${locale.value}`;
    window.location.href = redirectUrl;
  };
  provide('haveSwitchAccount', haveSwitchAccount);
  provide('switchAccountItems', switchAccountItems);

  // ======================= Complete Profile ======================= //
  // === Check Route === //
  const isInProfile = computed(
    () => route.name === EnumRoutes.profile.root.name
  );

  // === Dynamic Fields === //
  const { data: extraFields, isLoading: isExtraFieldsLoading } = useQuery({
    ...queryKeys.register.registrationForm.getRegisterationForm(),
    refetchOnWindowFocus: false,
  });
  // ====================== //

  const isCompletedProfile = computed(() => {
    const missingFieldNames: string[] = [];

    if (userCityIsDefinedAndNotEmpty(user)) {
      missingFieldNames.push('city');
    }

    extraFields.value?.filter((formField) => {
      const isOptional =
        formField.modifiers.some(
          (modifier) =>
            modifier.formFieldTypeModifier.slug === StringCase.OPTIONAL
        ) || formField.formField.typeSlug === 'boolean';

      if (!isOptional) {
        const userWithExtraFields = user.value as UserWithExtraFields;
        const userExtraField = userWithExtraFields?.extraFields.find(
          (userField) => {
            return (
              userField.formField.fieldKey === formField.formField.fieldKey
            );
          }
        );

        // Check if the user has set a value for the required extra field.

        if (
          !userExtraField ||
          userExtraField.value === null ||
          userExtraField.value === undefined ||
          userExtraField.value === ''
        ) {
          missingFieldNames.push(formField.formField.slug);
          return true;
        } else {
          return false;
        }
      }
    });

    return {
      isCompleted: !missingFieldNames.length,
      missingFields: missingFieldNames,
    };
  });

  // ================================================================ //
</script>

<template>
  <BasicChangePassword v-if="shouldResetPassword" />
  <template v-else>
    <CompleteProfileDialog
      v-if="
        !instructorView &&
        !isInProfile &&
        !isCompletedProfile.isCompleted &&
        !isExtraFieldsLoading &&
        user?.id
      "
      :missing-fields="isCompletedProfile.missingFields"
    />
    <div class="hide-on-print">
      <AppBar v-if="!instructorView && !HideAppBar"> </AppBar>
      <Banner v-if="showBanner" @hide-banner="updateBanner" />
    </div>
    <v-layout
      id="app-body"
      :style="{ 'margin-top': marginTop + 'px' }"
      class="overflow-visible"
    >
      <div class="hide-on-print">
        <SideMenu v-if="!hideSideBar && !instructorView && !smAndDown" />
      </div>
      <v-main>
        <NotAllowed class v-if="shouldHavePermission" />
        <NotAuthenticated v-else-if="shouldLogged" />
        <div v-else :class="!hideSideBar ? 'ma-4' : 'pa-4 pa-md-6'">
          <RouterView />
        </div>
      </v-main>
    </v-layout>
    <v-divider style="z-index: 999" class="mt-0 hide-on-print"></v-divider>
    <Teleport to="body">
      <div class="hide-on-print">
        <Footer v-if="!instructorView && !HideAppBar" />
      </div>
    </Teleport>
    <CookiesDialog v-if="!instructorView && !HideAppBar" />
  </template>
</template>

<style lang="scss">
  #app-body {
    width: 100%;
    max-width: 1920px;
    margin-inline: auto;
    background: none;
    flex-grow: 1;
  }
</style>
