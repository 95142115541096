import { Order } from './order';
import { PurchasedItem } from './purchased_item';
import { FinancialStatus } from './financial_status';
import { User } from './user';

export class OrderRefund {
  id: number;

  order: Order;

  orderId: number;

  purchasedItem?: PurchasedItem;

  purchasedItemId?: number;

  statusSlug: string;

  status: FinancialStatus;

  userId: number;

  user: User;

  amount: number;

  currency: string = 'SAR';

  organizationId?: number;

  rejectReason?: string;

  createdAt: Date;

  updatedAt: Date;
}
