import { Answer } from './answer';
import { Media } from './media';

export class AnswerExplanation {
  id: number;

  answerId: number;

  answer: Answer;

  body: string;

  mediaId?: number;

  media?: Media;

  createdAt: Date;

  updatedAt?: Date;
}
