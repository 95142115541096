import { QuestionTypeDetails } from './question_type_details';
import { Question } from './question';

export class QuestionType {
  id: number;

  name: string;

  nameAr?: string;

  createdAt: Date;

  updatedAt?: Date;

  providesAnswerOptions: boolean = true;

  isAutoCorrected: boolean = true;

  hasOneCorrectAnswer: boolean = true;

  isMatching: boolean;

  isSorting: boolean;

  isTextOnly: boolean;

  isAudioRecording: boolean;

  isVideoRecording: boolean;

  needsAnswerKey: boolean;

  isPublished: boolean = true;

  needsRubric: boolean;

  hasDetails: boolean;

  details?: QuestionTypeDetails;

  questions: Question[];
}
