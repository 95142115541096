import { Media } from './media';
import { Organization } from './organization';
import { CourseTargetOrganization } from './course_target_organization';
import { StudentCourseCertificateMedium } from './student_course_certificate_medium';

export class CertificateTemplate {
  id: number;

  settings: object;

  mediaId: number;

  media: Media;

  organization?: Organization;

  organizationCourses: CourseTargetOrganization[];

  studentCourseCertificateMedium: StudentCourseCertificateMedium[];

  createdAt: Date;

  updatedAt?: Date;
}
