import { Organization } from './organization';
import { ColorKey } from './color_key';

export class OrganizationColor {
  id: number;

  organizationId: number;

  organization: Organization;

  colorKeyId: number;

  colorKey: ColorKey;

  color: string = '#ffffff';

  createdAt: Date;

  updatedAt?: Date;
}
