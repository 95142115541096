import { Organization } from './organization';
import { BillingCycleType } from './billing_cycle_type';

export class OrganizationPricingModel {
  id: number;

  packageStartDate: Date;

  expiryDate?: Date;

  organizationId: number;

  organization: Organization;

  billingCycleTypeId: number;

  billingCycleType: BillingCycleType;

  maxUserLimit?: number = 2;

  pricePerUserPerMonth: number;

  flatFeePerMonth: number;

  createdAt: Date;

  updatedAt?: Date;
}
