import { Course } from './course';
import { CourseGrade } from './course_grade';
import { CourseAnnouncement } from './course_announcement';
import { CourseDiscussionBoardItemSettings } from './course_discussion_board_item_settings';
import { CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';
import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { CourseSubsection } from './course_subsection';

export class CourseDiscussionBoardItem {
  id: number;

  courseId: number;

  course: Course;

  title: string;

  description?: string;

  weight: number;

  gradeItemId?: number;

  gradeItem?: CourseGrade;

  createdAt: Date;

  updatedAt?: Date;

  announcement?: CourseAnnouncement;

  settings?: CourseDiscussionBoardItemSettings;

  comments: CourseDiscussionBoardItemComment[];

  accessRestrictionId?: number;

  accessRestriction?: CourseResourceAccessRestriction;

  subsections: CourseSubsection[];
}
