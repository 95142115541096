import { ExternalCourse } from './external_course';

export class UdacityCourse {
  id: number;

  duration?: number;

  duration_type?: string;

  image_url?: string;

  key: string;

  modified_date?: string;

  redirect_url?: string;

  summary?: string;

  syllabus_url?: string;

  title?: string;

  type?: string;

  version?: number = 1;

  createdAt?: Date;

  updatedAt?: Date;

  externalCourses: ExternalCourse[];
}
