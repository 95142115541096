export class action_events {
  id: BigInt;

  batch_id: string;

  user_id: BigInt;

  name: string;

  actionable_type: string;

  actionable_id: BigInt;

  target_type: string;

  target_id: BigInt;

  model_type: string;

  model_id?: BigInt;

  fields: string;

  status: string = 'running';

  exception: string;

  created_at?: Date;

  updated_at?: Date;

  original?: string;

  changes?: string;
}
