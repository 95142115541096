import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';

export const social = {
  eventRoot: {
    name: 'Social Events',
    path: '/social/events',
    title: 'header.events',
    icon: 'mdi-calendar-multiple',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Social`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessEvents],
      organizationPermissions: [EnumOrganizationPermissions.SocialEvents],
    },
  },
  events: {
    name: 'All Events',
    title: 'event.events',
    path: '/social/all-events',
    icon: 'mdi-calendar-multiple',
    meta: {
      // Users don't need to be logged in to view the events. What determines the visibility of the events are the organization's permissions (if the user is not logged in) or the user's permissions (if the user is logged in).
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Events`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessEvents],
      organizationPermissions: [EnumOrganizationPermissions.SocialEvents],
      shouldAllowAnonymousEventView: true,
    },
  },
  enrolledEvents: {
    name: 'My Events',
    title: 'event.myEvents',
    path: '/social/my-events',
    icon: 'mdi-calendar-multiple',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Events`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessEvents],
      organizationPermissions: [EnumOrganizationPermissions.SocialEvents],
    },
  },
  eventPage: {
    name: 'Social Event Page',
    path: '/event/:eventId',
    pathFn: (eventId: string | number) => `/event/${eventId}`,
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Event Detail`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessEvents],
      organizationPermissions: [EnumOrganizationPermissions.SocialEvents],
    },
  },
  eventTicket: {
    name: 'Social Event Ticket',
    path: '/event/:eventId/ticket',
    pathFn: (eventId: string | number) => `/event/${eventId}/ticket`,
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Ticket Detail`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessEvents],
      organizationPermissions: [EnumOrganizationPermissions.SocialEvents],
    },
  },
  socialGroups: {
    name: 'Social Groups',
    path: '/groups',
    title: 'groups.allGroups',
    icon: 'mdi-account-group',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Groups`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCommunities],
      organizationPermissions: [EnumOrganizationPermissions.SocialPlatform],
      shouldHaveGroup: true,
    },
  },
  enrolledGroups: {
    name: 'My Groups',
    path: '/groups/mine',
    title: 'groups.myGroups',
    meta: {
      requiresAuth: true,
      shouldHaveGroup: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Enrolled Groups`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCommunities],
      organizationPermissions: [EnumOrganizationPermissions.SocialPlatform],
    },
  },
  groupPage: {
    name: 'Social Group Page',
    path: '/groups/:groupId',
    pathFn: (groupId: string | number) => `/groups/${groupId}`,
    title: 'groups.groupPage',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Group Detail`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCommunities],
      organizationPermissions: [EnumOrganizationPermissions.SocialPlatform],
    },
  },
  postPage: {
    name: 'Post Page',
    path: '/groups/:groupId/post/:postId',
    pathFn: (groupId: string | number, postId: string | number) =>
      `/groups/${groupId}/post/${postId}`,

    title: 'groups.postPage',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Post Page`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCommunities],
      organizationPermissions: [EnumOrganizationPermissions.SocialPlatform],
    },
  },
};
