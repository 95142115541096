import { User } from './user';
import { Course } from './course';
import { CourseSubsection } from './course_subsection';

export class UserLastVisitedSubsection {
  id: number;

  userId: number;

  user: User;

  courseId?: number;

  course?: Course;

  subsectionId: number;

  subsection: CourseSubsection;

  createdAt: Date;

  updatedAt: Date;
}
