import { SubsectionExam } from './subsection_exam';
import { ExamGradingSetttings } from './exam_grading_setttings';

export class ExamSettings {
  id: number;

  numberOfQuestions?: number;

  hasTimeLimit: boolean;

  timeLimitInMinutes?: number;

  hasPassingScore: boolean;

  passingScorePercentage?: number;

  hasPublishDate: boolean;

  publishDate?: Date;

  multiDirectional: boolean;

  shuffleAnswers: boolean;

  shuffleQuestions: boolean;

  showCorrectAnswerInstantly: boolean;

  showCorrectAnswerOnReview: boolean;

  showResultAtEnd: boolean;

  allowRetake: boolean;

  maxAttempts?: number;

  retakeIntervalInMinutes?: number;

  isCheatDetectionEnabled: boolean;

  subsectionExamId: number;

  subsectionExam: SubsectionExam;

  examGradingSettingsId?: number;

  gradeSettings?: ExamGradingSetttings;

  oneQuestionAtATime: boolean;

  createdAt: Date;

  updatedAt?: Date;
}
