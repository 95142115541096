import { FormFieldTypeAvailableModifier } from './form_field_type_available_modifier';
import { FormField } from './form_field';

export class FormFieldType {
  id: number;

  slug: string;

  name: string;

  zodValidator?: string;

  availableModifiers: FormFieldTypeAvailableModifier[];

  createdAt: Date;

  updatedAt?: Date;

  formFields: FormField[];
}
