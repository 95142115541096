import { Order } from './order';

export class QoyodInvoice {
  id: number;

  invoiceId: number;

  reference: string;

  amount: number;

  order: Order;

  orderId: number;

  createdAt: Date;

  updatedAt: Date;
}
