<script setup lang="ts">
  import { inject, ref, type ComputedRef, type Ref } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  const canRegister: Ref<boolean> = inject('canRegister', ref(false));

  const regiterRoute: ComputedRef<RouteLocationRaw> | undefined = inject(
    'regiterRoute',
    undefined
  );
  const loginRoute: ComputedRef<RouteLocationRaw> | undefined =
    inject('loginRoute');

  const regiterText: Ref<string> = inject('regiterText', ref('Register'));
  const loginText: Ref<string> = inject('loginText', ref('Login'));
</script>

<template>
  <v-btn
    v-if="canRegister"
    color="primary"
    :to="regiterRoute"
    aria-label="Register"
    variant="flat"
  >
    {{ regiterText }}
  </v-btn>
  <v-btn
    color="primary"
    :to="loginRoute"
    variant="outlined"
    aria-label="Log In"
  >
    {{ loginText }}
  </v-btn>
</template>
