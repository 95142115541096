import { Organization } from './organization';
import { ExternalCourse } from './external_course';
import { ExternalCourseTargetOrganizationCreditUnit } from './external_course_target_organization_credit_unit';

export class ExternalCourseTargetOrganization {
  id: number;

  price: number;

  oldPrice?: number;

  isDiscounted: boolean;

  startDate?: Date;

  endDate?: Date;

  startEnrollmentDate?: Date;

  endEnrollmentDate?: Date;

  maxEnrollmentCount?: number;

  fromTo?: string;

  isPublished: boolean = true;

  organizationId: number;

  organization: Organization;

  externalCourseId: number;

  externalCourse: ExternalCourse;

  creditUnits: ExternalCourseTargetOrganizationCreditUnit[];

  createdAt: Date;

  updatedAt?: Date;
}
