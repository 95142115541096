import { Course } from './course';
import { SurveySettings } from './survey_settings';
import { Poll } from './poll';
import { CourseSubsection } from './course_subsection';
import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { SurveyTypes } from '../exported_type_enums/exported_type_enums';

export class Survey {
  id: number;

  title: string;

  description?: string;

  type: SurveyTypes;

  courseId: number;

  course: Course;

  surveySettings?: SurveySettings;

  polls: Poll[];

  createdAt: Date;

  updatedAt?: Date;

  subsections: CourseSubsection[];

  accessRestrictionId?: number;

  accessRestriction?: CourseResourceAccessRestriction;
}
