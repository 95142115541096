import { CoachingSpecialization } from './coaching_specialization';
import { User } from './user';

export class CoachSpecializations {
  id: number;

  specializationId: number;

  specialization: CoachingSpecialization;

  userId: number;

  user: User;
}
