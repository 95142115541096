import { SubsectionQuestionnaire } from './subsection_questionnaire';
import { SubsectionQuestionAnswer } from './subsection_question_answer';

export class SubsectionQuestion {
  id: number;

  questionnaireId?: number;

  questionnaire?: SubsectionQuestionnaire;

  question: string;

  position: number;

  answers: SubsectionQuestionAnswer[];

  createdAt: Date;

  updatedAt: Date;
}
