import { Organization } from './organization';
import { FormField } from './form_field';
import { OrganizationRegistrationFormFieldModifier } from './organization_registration_form_field_modifier';
import { OrganizationFieldSort } from './organization_field_sort';

export class OrganizationRegistrationFormField {
  id: number;

  organizationId: number;

  organization: Organization;

  formFieldId: number;

  formField: FormField;

  weight: number;

  availableOptions?: object;

  modifiers: OrganizationRegistrationFormFieldModifier[];

  createdAt: Date;

  updatedAt?: Date;

  sortingOptions?: OrganizationFieldSort;
}
