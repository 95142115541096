import { Organization } from './organization';
import { Course } from './course';
import { CourseOrganizationLearningGroup } from './course_organization_learning_group';
import { CourseOrganizationUser } from './course_organization_user';
import { CourseTargetOrganizationCreditUnit } from './course_target_organization_credit_unit';
import { CertificateTemplate } from './certificate_template';
import { CoursePassingCriteria } from './course_passing_criteria';
import { AccessRestrictionType } from '../exported_type_enums/exported_type_enums';

export class CourseTargetOrganization {
  id: number;

  price: number;

  oldPrice?: number;

  isDiscounted: boolean;

  organizationId: number;

  organization: Organization;

  courseId: number;

  course: Course;

  isPublished: boolean = true;

  accessRestrictionType: AccessRestrictionType = AccessRestrictionType.PUBLIC;

  createdAt: Date;

  updatedAt?: Date;

  organizationLearningGroups: CourseOrganizationLearningGroup[];

  organizationUsers: CourseOrganizationUser[];

  creditUnits: CourseTargetOrganizationCreditUnit[];

  issueCertificate?: boolean;

  certificateTemplate?: CertificateTemplate;

  certificateTemplateId?: number;

  coursePassingCriteria?: CoursePassingCriteria;

  coursePassingCriteriaId?: number;
}
