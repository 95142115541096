import { SubsectionQuestion } from './subsection_question';

export class SubsectionQuestionAnswer {
  id: number;

  questionId: number;

  question: SubsectionQuestion;

  answer: string;

  isCorrect: boolean;

  explanation?: string;

  createdAt: Date;

  updatedAt: Date;
}
