export const authApiHandler = {
  register: {
    register: {
      method: 'POST',
      path: () => `auth/register`,
    },
  },
  login: {
    login: {
      method: 'POST',
      path: () => `auth/login`,
    },
    twoFactorAuthentication: {
      method: 'POST',
      path: () => `auth/2fa`,
      params: {} as { token?: string | number; code?: string | number },
    },
    resendCode: {
      method: 'POST',
      path: () => `auth/resend`,
      params: {} as { token?: string | number },
    },
  },
  resetPassword: {
    requestResetPassword: {
      method: 'POST',
      path: (payload: { prefix: string | number }) =>
        `reset-password/${payload.prefix}`,
    },
    requestResetPasswordStudent: {
      method: 'POST',
      path: () => `reset-password/student`,
    },
    resetPassword: {
      method: 'POST',
      path: () => `reset-password/confirm`,
    },
  },
  sSO: {
    generateSSOToken: {
      method: 'POST',
      path: () => `auth/sso`,
      params: {} as { courseId?: string | number; portal?: string | number },
    },
    getTokensUsingSSO: {
      method: 'GET',
      path: () => `auth/sso`,
      params: {} as { token?: string | number },
    },
  },
  sAML: {
    usedByCornellONLY: {
      getIdPMetadata: {
        method: 'GET',
        path: () => `auth/sso/ecornell/metadata`,
      },
      'gETSSO-RedirectLogin': {
        method: 'GET',
        path: () => `auth/sso/ecornell/login`,
      },
      'gETSSO-RedirectLoginWithTestData': {
        method: 'GET',
        path: () => `auth/sso/ecornell/login`,
        params: {} as {
          SAMLRequest?: string | number;
          SigAlg?: string | number;
          Signature?: string | number;
        },
      },
    },
    usedByUdacity: {
      getIdPMetadata: {
        method: 'GET',
        path: () => `auth/sso/ecornell/metadata`,
      },
      pOSTAuthnRequest: {
        method: 'POST',
        path: () => `auth/sso/udacity/login`,
      },
    },
    authenticateUser: {
      method: 'GET',
      path: (payload: { ssoid: string | number }) =>
        `auth/sso/ecornell/authenticate-user/${payload.ssoid}`,
    },
  },
  emailVerification: {
    verifyEmail: {
      method: 'POST',
      path: () => `auth/verify-email`,
    },
  },
  verifyToken: {
    method: 'GET',
    path: () => `auth`,
  },
  getUserPermissions: {
    method: 'GET',
    path: () => `permissions-system`,
  },
  logout: {
    method: 'POST',
    path: () => `auth/logout`,
  },
  refresh: {
    method: 'GET',
    path: () => `auth/refresh`,
    params: {} as { token?: string | number },
  },
  refresh_New: {
    method: 'POST',
    path: () => `auth/refresh`,
  },
  deleteAccount: {
    method: 'DELETE',
    path: () => `auth/deleteREMOVETHISUPPERCASETEXT`,
  },
  masquerade: {
    method: 'POST',
    path: () => `auth/masquerade`,
  },
  getEmailOrganizations: {
    method: 'GET',
    path: () => `auth/organizations`,
  },
  getOrganization: {
    method: 'GET',
    path: () => `organizations-dashboard`,
  },
};
