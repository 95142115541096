import { CourseTargetOrganization } from './course_target_organization';
import { LearningGroup } from './learning_group';

export class CourseOrganizationLearningGroup {
  id: number;

  courseOrganizationId: number;

  courseOrganization: CourseTargetOrganization;

  learningGroupId: number;

  learningGroup: LearningGroup;

  createdAt: Date;

  updatedAt?: Date;
}
