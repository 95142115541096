import { TermKeyCategory } from './term_key_category';
import { TermKeySuggestion } from './term_key_suggestion';
import { OrganizationTerm } from './organization_term';

export class TermKey {
  id: number;

  slug: string;

  name: string;

  defaultValue?: object;

  isMedia: boolean;

  termKeyCategoryId?: number;

  termKeyCategory?: TermKeyCategory;

  suggestions: TermKeySuggestion[];

  organizationTerms: OrganizationTerm[];

  createdAt: Date;

  updatedAt?: Date;
}
