<script setup lang="ts">
  import type { ComputedRef, Ref } from 'vue';
  import { computed, inject, ref } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';
  import type {
    headerItem,
    switchAccountItem,
  } from './../helpers/interfaces/header-item.interface';
  import LanguageToggle from './LanguageToggle.vue';

  const profileImage: ComputedRef<string> | undefined = inject(
    'profileImage',
    undefined
  );
  const userFirstName: ComputedRef<string> | undefined =
    inject('userFirstName');
  const userLastName: ComputedRef<string> | undefined = inject('userLastName');
  const userEmail: ComputedRef<string> | undefined = inject('userEmail');
  const profileRoute: ComputedRef<RouteLocationRaw> | undefined =
    inject('profileRoute');
  const logoutText: Ref<string> = inject('logoutText', ref('Logout'));
  const profileItems: ComputedRef<headerItem[]> | undefined =
    inject('profileItems');
  const logout: () => void = inject('logout', () => undefined);

  const switchAccountItems: Ref<switchAccountItem[]> = inject(
    'switchAccountItems',
    ref([])
  );
  const modifiedSwitchAccountItems: ComputedRef<switchAccountItem[]> = computed(
    () => switchAccountItems.value.filter((item) => !item.disabled)
  );
  const haveLanguageToggle: Ref<boolean> = inject(
    'haveLanguageToggle',
    ref(true)
  );
</script>

<template>
  <v-menu :open-on-click="true" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-avatar
        role="button"
        color="primary"
        :image="profileImage"
        class="unselectable"
        v-bind="props"
        aria-label="User avatar image"
      >
        {{ userFirstName?.[0]?.toUpperCase()
        }}{{ userLastName?.[0]?.toUpperCase() }}
      </v-avatar>
    </template>
    <v-card width="270px" rounded="lg">
      <v-list-item
        class="pa-4"
        :title="userFirstName + ' ' + userLastName"
        :to="profileRoute"
        :active="false"
      >
        <template #prepend>
          <v-avatar
            color="primary"
            :image="profileImage"
            class="unselectable cursor-pointer"
            aria-label="User avatar image"
          >
            {{ userFirstName?.[0]?.toUpperCase()
            }}{{ userLastName?.[0]?.toUpperCase() }}
          </v-avatar>
        </template>
        <template #subtitle>
          <p class="text-primary opacity-100">
            {{ userEmail }}
          </p>
        </template>
      </v-list-item>
      <v-divider></v-divider>

      <template v-if="modifiedSwitchAccountItems.length">
        <v-list>
          <v-list-item
            v-for="item in modifiedSwitchAccountItems"
            :key="item.title"
            role="option"
            base-color="gray"
            :to="item.to"
            :title="item.title"
            @click="item.fun"
          />
        </v-list>
        <v-divider></v-divider>
      </template>

      <v-list class="ma-0">
        <v-list-item
          v-for="item in profileItems"
          :key="item.title"
          role="option"
          base-color="gray"
          :to="item.to"
          :title="item.title"
        ></v-list-item>
        <v-list
          aria-label="Language Drawer Items"
          role="menu"
          class="pa-0"
          v-if="haveLanguageToggle"
        >
          <LanguageToggle />
        </v-list>

        <v-list-item
          role="option"
          base-color="gray"
          @click="logout"
          :title="logoutText"
        ></v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
../helpers/interfaces/header-item.interface
../helpers/interfaces/header-item.interface
