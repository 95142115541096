import { CourseSubsection } from './course_subsection';
import { SubsectionQuestion } from './subsection_question';

export class SubsectionQuestionnaire {
  id: number;

  subsections: CourseSubsection[];

  questions: SubsectionQuestion[];

  createdAt: Date;

  updatedAt: Date;
}
