import { User } from './user';
import { EmploymentType } from '../exported_type_enums/exported_type_enums';

export class CoachWorkExperience {
  id: number;

  title: string;

  employmentType?: EmploymentType = EmploymentType.FULLTIME;

  organizationName?: string;

  location?: string;

  currentlyWorking: boolean;

  startDate?: Date;

  endDate?: Date;

  responsibilities?: string;

  userId: number;

  user: User;
}
