import { QuestionDetail } from './question_detail';
import { SingleQuestionCategory } from './single_question_category';

export class QuestionCategory {
  id: number;

  questionDetailId: number;

  questionDetail: QuestionDetail;

  questionCategoryId: number;

  questionCategory: SingleQuestionCategory;

  createdAt: Date;

  updatedAt?: Date;
}
