import { SocialEvent } from './social_event';
import { Media } from './media';

export class SocialEventAttachment {
  id: number;

  eventId: number;

  event: SocialEvent;

  mediumId: number;

  medium: Media;

  createdAt: Date;

  updatedAt: Date;
}
