import { ExternalCourseType } from './external_course_type';
import { UdacityCourse } from './udacity_course';
import { CornellCourse } from './cornell_course';
import { ExternalCourseTargetOrganization } from './external_course_target_organization';
import { ExternalCourseEnrollment } from './external_course_enrollment';

export class ExternalCourse {
  id: number;

  title: string;

  ExternalCourseType: ExternalCourseType;

  externalCourseTypeSlug: string;

  rawCourseId: number;

  isAvailable: boolean = true;

  maxEnrollmentCount?: number;

  udacityCourseId?: number;

  udacityCourse?: UdacityCourse;

  cornellCourseId?: number;

  cornellCourse?: CornellCourse;

  externalCourseTargetOrganizations: ExternalCourseTargetOrganization[];

  createdAt: Date;

  updatedAt?: Date;

  enrollees: ExternalCourseEnrollment[];
}
