import { User } from './user';
import { ExternalCourse } from './external_course';

export class ExternalCourseEnrollment {
  id: number;

  userId: number;

  user: User;

  externalCourseId: number;

  externalCourse: ExternalCourse;

  isConfirmed: boolean;

  createdAt: Date;

  updatedAt?: Date;
}
