import { PurchasedItem } from './purchased_item';
import { QoyodProduct } from './qoyod_product';
import { VoucherRestrictionByItemtype } from './voucher_restriction_by_itemtype';
import { RefundRestriction } from './refund_restriction';

export class PurchasedItemType {
  id: number;

  nameEn: string;

  nameAr: string;

  slug: string;

  purchasedItems: PurchasedItem[];

  qoyodProducts: QoyodProduct[];

  creditRestriction: VoucherRestrictionByItemtype[];

  refundRestriction: RefundRestriction[];
}
