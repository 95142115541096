import { queryKeys } from './../components/pagination/query-key-factory/index';
import { EnumRoutes } from '@/helpers/enums/routes.enum';

/**
 * this function to redirect the user to the correct route after login with super token
 * @param to
 * @returns
 * @example
 * redirectAfterSuperToken(to);
 */

enum queryKeysEnum {
  SUBSECTION = 'subsectionId',
  POLL = 'pollId',
  SURVEY = 'surveyId',
  SUBSECTIONEXAMID = 'subsectionExamId',
  ASSIGNMENT = 'assignmentId',
  DISCUSSION = 'discussionId',
}

export const redirectAfterSuperToken = (to: any) => {
  let route = {
    ...to,
    query: {
      ...to.query,
      super_token: undefined,
      student_view: undefined,
      lang: undefined,
    },
  };
  const keys = Object.keys(to.query);
  switch (true) {
    case keys.includes(queryKeysEnum.SUBSECTION):
      route = {
        name: EnumRoutes.lms.courseContent.name,
        params: {
          courseId: to.params.courseId,
          subsectionId: to.query[queryKeysEnum.SUBSECTION],
        },
        query: {
          ...route.query,
          subsectionId: undefined,
        },
      };
      break;
    case keys.includes(queryKeysEnum.POLL):
      route = {
        name: EnumRoutes.lms.courseSurveys.singlePoll.name,
        params: {
          courseId: to.params.courseId,
          surveyId: to.query[queryKeysEnum.POLL],
        },
        query: {
          ...route.query,
          pollId: undefined,
        },
      };
      break;
    case keys.includes(queryKeysEnum.SURVEY):
      route = {
        name: EnumRoutes.lms.courseSurveys.singleSurvey.name,
        params: {
          courseId: to.params.courseId,
          surveyId: to.query[queryKeysEnum.SURVEY],
        },
        query: {
          ...route.query,
          surveyId: undefined,
        },
      };
      break;
    case keys.includes(queryKeysEnum.SUBSECTIONEXAMID):
      route = {
        name: EnumRoutes.lms.courseExams.examLandingPage.name,
        params: {
          courseId: to.params.courseId,
          subsectionExamId: to.query[queryKeysEnum.SUBSECTIONEXAMID],
        },
        query: {
          ...route.query,
          subsectionExamId: undefined,
        },
      };
      break;
    case keys.includes(queryKeysEnum.ASSIGNMENT):
      route = {
        name: EnumRoutes.lms.courseAssignments.singleCourseAssignment.name,
        params: {
          courseId: to.params.courseId,
          assignmentId: to.query[queryKeysEnum.ASSIGNMENT],
        },
        query: {
          ...route.query,
          assignmentId: undefined,
        },
      };
      break;
    case keys.includes(queryKeysEnum.DISCUSSION):
      route = {
        name: EnumRoutes.lms.courseDiscussionBoards.singleDiscussionBoard.name,
        params: {
          courseId: to.params.courseId,
          discussionBoardId: to.query[queryKeysEnum.DISCUSSION],
        },
        query: {
          ...route.query,
          discussionId: undefined,
        },
      };
      break;
  }
  return route;
};
