import { Course } from './course';
import { User } from './user';
import { StudentCourseAttendance } from './student_course_attendance';

export class CourseAttendance {
  id: number;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt: Date;

  userId: number;

  user: User;

  attendanceDate: Date;

  students: StudentCourseAttendance[];
}
