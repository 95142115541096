import { User } from './user';

export class ProviderDisabledSlot {
  id: number;

  from: Date;

  to: Date;

  providerId: number;

  provider: User;

  createdAt: Date;

  updatedAt?: Date;
}
