import { QuestionType } from './question_type';

export class QuestionTypeDetails {
  id: number;

  details?: string;

  questionTypeId: number;

  questionType: QuestionType;

  createdAt: Date;

  updatedAt?: Date;
}
