import { User } from './user';
import { Status } from '../exported_type_enums/exported_type_enums';

export class UserApprovalRequest {
  id: number;

  status: Status = Status.PENDING;

  userId: number;

  user: User;

  approvedById?: number;

  approvedBy?: User;

  createdAt: Date;

  updatedAt: Date;
}
