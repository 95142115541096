import { User } from './user';
import { SocialGroup } from './social_group';
import { PostMedia } from './post_media';
import { SocialEvent } from './social_event';
import { Guest } from './guest';
import { CourseSyllabus } from './course_syllabus';
import { Course } from './course';
import { SubsectionAttachments } from './subsection_attachments';
import { LibraryMedia } from './library_media';
import { LibraryMediaTranslation } from './library_media_translation';
import { CourseSubsection } from './course_subsection';
import { CoachDetails } from './coach_details';
import { CourseAnnouncementsMedia } from './course_announcements_media';
import { AssignmentSubmissionMedia } from './assignment_submission_media';
import { CourseAssignment } from './course_assignment';
import { MediaTranscript } from './media_transcript';
import { StudentCourseGrade } from './student_course_grade';
import { Specialization } from './specialization';
import { QuestionStemMedia } from './question_stem_media';
import { QuestionExplanation } from './question_explanation';
import { AnswerStem } from './answer_stem';
import { AnswerExplanation } from './answer_explanation';
import { AnswerReview } from './answer_review';
import { CourseDiscussionBoardItemCommentMedia } from './course_discussion_board_item_comment_media';
import { GroupCourseGrade } from './group_course_grade';
import { TechnicalSupportTicket } from './technical_support_ticket';
import { Organization } from './organization';
import { CopyLeaksReport } from './copy_leaks_report';
import { PollChoice } from './poll_choice';
import { Order } from './order';
import { StudentCourseCertificateMedium } from './student_course_certificate_medium';
import { SocialEventAttachment } from './social_event_attachment';
import { CertificateTemplate } from './certificate_template';
import { FontFamilyStyle } from './font_family_style';
import { OrganizationTerm } from './organization_term';
import { Article } from './article';
import { GalleryMedium } from './gallery_medium';
import { MediaType } from '../exported_type_enums/exported_type_enums';

export class Media {
  id: number;

  type: MediaType = MediaType.FILE;

  mime: string = 'text/plain';

  key: string;

  fileName: string;

  etag: string;

  url: string;

  thumbnailUrl?: string;

  sizeKB: number;

  durationSeconds?: number;

  isPublished: boolean = true;

  optimize: boolean = true;

  createdAt: Date;

  updatedAt?: Date;

  createdById?: number;

  createdBy?: User;

  userProfileImages: User[];

  userCoverImages: User[];

  groupImage: SocialGroup[];

  groupCoverImage: SocialGroup[];

  postsMedia: PostMedia[];

  eventsMedia: SocialEvent[];

  guests: Guest[];

  syllabus: CourseSyllabus[];

  coursesIntroductoryVideos: Course[];

  coursesCoverImages: Course[];

  subsectionAttachments: SubsectionAttachments[];

  libraryMedia: LibraryMedia[];

  libraryMediaTranslation: LibraryMediaTranslation[];

  courseSubsectionFiles: CourseSubsection[];

  coachIntroductoryVideos: CoachDetails[];

  cvs: CoachDetails[];

  courseAnnouncementsMedia: CourseAnnouncementsMedia[];

  assignmentSubmissionMedia: AssignmentSubmissionMedia[];

  createdAssignments: CourseAssignment[];

  videoTranscripts: MediaTranscript[];

  gradeMedia: StudentCourseGrade[];

  specializationCoverImages: Specialization[];

  questionStemMedia: QuestionStemMedia[];

  questionExplanation: QuestionExplanation[];

  answerStem: AnswerStem[];

  answerExplanation: AnswerExplanation[];

  instructorAnswerReview: AnswerReview[];

  studentAnswerReview: AnswerReview[];

  courseDiscussionBoardItemComments: CourseDiscussionBoardItemCommentMedia[];

  GroupCourseGrade: GroupCourseGrade[];

  technicalSupportTickets: TechnicalSupportTicket[];

  organizationsLogos: Organization[];

  copyLeaksReport: CopyLeaksReport[];

  pollChoices: PollChoice[];

  orderInvoice: Order[];

  organizationsIcons: Organization[];

  certificatesMedia: StudentCourseCertificateMedium[];

  SocialEventAttachment: SocialEventAttachment[];

  certificateTemplate: CertificateTemplate[];

  originalFileId?: number;

  originalFile?: Media;

  optimizedFiles: Media[];

  convertToPdf: boolean;

  fontFamilyStyles: FontFamilyStyle[];

  organizationTerms: OrganizationTerm[];

  articles: Article[];

  photoGalleryMedia: GalleryMedium[];
}
