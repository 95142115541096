import { EnumOrganizationPermissions } from './../helpers/enums/permissions.enum';
export const auth = {
  root: {
    name: 'Auth Layout',
    path: '/auth',
    meta: {
      requiresAuth: false,
      hideSideBar: true,
    },
  },
  login: {
    name: 'Login Page',
    path: 'login',
    meta: {
      requiresAuth: false,
      requiresLogout: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Login`,
    },
  },
  twoFactorVerification: {
    name: 'Two Factor Verification Page',
    path: 'verification-code',
    meta: {
      requiresAuth: false,
      requiresLogout: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Verification Code`,
    },
  },
  forgotPassword: {
    path: 'forget-password',
    name: 'Forgot Password Page',
    meta: {
      requiresAuth: false,
      requiresLogout: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Forget Password`,
    },
  },
  resetPassword: {
    name: 'Reset Password Page',
    path: 'reset-password',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Reset Password`,
    },
  },
  verifyEmail: {
    name: 'Verify Email Page',
    path: 'verify-email',
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Verify Email`,
    },
  },
  register: {
    name: 'Register Page',
    path: '/register',
    meta: {
      requiresAuth: false,
      hideSideBar: true,
      organizationPermissions: [
        EnumOrganizationPermissions.RegistrationEnabled,
      ],
      requiresLogout: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Register`,
      shouldHaveRegistrationEnabled: true,
    },
  },
};
