import { Specialization } from './specialization';
import { User } from './user';

export class SpecializationEnrollee {
  id: number;

  specializationId: number;

  specialization: Specialization;

  userId: number;

  user: User;

  createdAt: Date;

  updatedAt?: Date;
}
