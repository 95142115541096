import { CourseSection } from './course_section';

export class CourseSectionPrerequisite {
  id: number;

  sectionId: number;

  section: CourseSection;

  prerequisiteId: number;

  prerequisite: CourseSection;

  createdAt: Date;

  updatedAt: Date;
}
