import { Answer } from './answer';

export class AnswerType {
  id: number;

  name: string;

  acceptsMedia: boolean;

  isAutoCorrected: boolean = true;

  isMatchingA: boolean;

  isMatchingB: boolean;

  createdAt: Date;

  updatedAt?: Date;

  answers: Answer[];
}
