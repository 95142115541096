import { User } from './user';
import { Course } from './course';
import { Specialization } from './specialization';

export class CourseEnrollee {
  id: number;

  enrolleeId: number;

  enrollee: User;

  courseId: number;

  course: Course;

  createdAt: Date;

  updatedAt?: Date;

  isPinned: boolean;

  isFinished: boolean;

  fromSpecialization: boolean;

  specializationId?: number;

  specialization?: Specialization;
}
