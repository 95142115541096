import { TooltipContent } from './tooltip_content';

export class LanguageCode {
  id: number;

  code: string;

  name: string;

  tooltips: TooltipContent[];
}
