import { Survey } from './survey';
import { PollChoice } from './poll_choice';
import { PollVote } from './poll_vote';

export class Poll {
  id: number;

  body: string;

  surveyId: number;

  survey: Survey;

  choices: PollChoice[];

  votes: PollVote[];

  createdAt: Date;

  updatedAt?: Date;
}
