import { Media } from './media';

export class MediaTranscript {
  id: number;

  videoId: number;

  video: Media;

  languageCode: string = 'en';

  jsonUrl?: string;

  vttUrl?: string;

  createdAt: Date;

  updatedAt?: Date;
}
