import { Organization } from './organization';

export class PaymentGateway {
  id: number;

  name: string;

  url: string;

  testGateway: string;

  liveGateway: string;

  organizations: Organization[];

  createdAt: Date;

  updatedAt?: Date;
}
