import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.dashboard.root.path,
    children: [
      {
        path: '',
        name: EnumRoutes.dashboard.root.name,
        component: () => import('@/views/dashboard/DashboardLayout.vue'),
        meta: EnumRoutes.dashboard.root.meta,
      },
      {
        path: EnumRoutes.dashboard.userActivities.path,
        name: EnumRoutes.dashboard.userActivities.name,
        component: () => import('@/views/dashboard/UserActivities.vue'),
        meta: EnumRoutes.dashboard.userActivities.meta,
      },
    ],
  },
];
