import { SocialEvent } from './social_event';
import { User } from './user';

export class UserInterestedEvents {
  id: number;

  userId: number;

  eventId: number;

  event: SocialEvent;

  user: User;

  createdAt: Date;

  updatedAt?: Date;
}
