import { OrderPayment } from './order_payment';
import { OttuPayment } from './ottu_payment';

export class PaymentWebhook {
  id: number;

  data: string;

  orderPaymentId?: number;

  orderPayment?: OrderPayment;

  ottuPaymentId?: number;

  ottuPayment?: OttuPayment;

  createdAt: Date;

  updatedAt?: Date;
}
