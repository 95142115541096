import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { User } from './user';
import { Organization } from './organization';
import { UserReadCourseDiscussionBoardItemComment } from './user_read_course_discussion_board_item_comment';
import { CourseDiscussionBoardItemCommentMedia } from './course_discussion_board_item_comment_media';
import { CourseDiscussionBoardItemCommentLike } from './course_discussion_board_item_comment_like';

export class CourseDiscussionBoardItemComment {
  id: number;

  comment?: string;

  courseDiscussionBoardItemId: number;

  courseDiscussionBoardItem: CourseDiscussionBoardItem;

  studentId: number;

  student: User;

  deletedById?: number;

  organizationId?: number;

  organization?: Organization;

  parentId?: number;

  parent?: CourseDiscussionBoardItemComment;

  replies: CourseDiscussionBoardItemComment[];

  createdAt: Date;

  updatedAt?: Date;

  usersWhoReadThisComment: UserReadCourseDiscussionBoardItemComment[];

  commentMedia: CourseDiscussionBoardItemCommentMedia[];

  commentLikes: CourseDiscussionBoardItemCommentLike[];
}
