import { OrgRefundRule } from './org_refund_rule';

export class RefundRule {
  id: number;

  name: string;

  refundPercentage: number;

  timeLeftInHours: number;

  maxRefundAmount?: number;

  maxRefundPercentage?: number;

  orgRefundRules: OrgRefundRule[];

  createdAt: Date;

  updatedAt: Date;
}
