import type { Paginated } from '@/helpers/interfaces/paginated.interface';
import { computed, ref, type Ref } from 'vue';
import { axiosI, useI18 } from '@/plugins';
import { socialApiHandler } from '@/postman-to-ts/apiHandler/social';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth.store';
import type { SocialGroup } from '@/submodules/generated_types/types/social_group';
import { EnumRoutes } from '@/helpers/enums/routes.enum';
import { usePermissionsStore } from './permissoins.store';
import type { IPost } from '@/helpers/interfaces/post.interface';
import type { AxiosError } from 'axios';
import { useShowToast } from '@/composables/useShowToast.composable';

export type ISocialGroup = SocialGroup & {
  _count: {
    members: number;
  };
  groupRole: string;
};

export enum GroupStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

interface IGroupStore {
  groups: Ref<Paginated<ISocialGroup> | null>;
  getUserGroup: Function;
  fetchGroupById: Function;
  fetchGroupMembers: Function;
  group: Ref<ISocialGroup | null>;
  isAdmin: Ref<boolean>;
  post: Ref<IPost | null>;
  savePost: Function;
  unSavePost: Function;
  deletePost: Function;
  likePost: Function;
  unLikePost: Function;
  likeComment: Function;
  unLikeComment: Function;
  deleteComment: Function;
  createComment: Function;
  updateComment: Function;
  createPost: Function;
  updatePost: Function;
  pinPost: Function;
  unPinPost: Function;
  getPostById: Function;
}

export const useGroupStore = defineStore('groupStore', (): IGroupStore => {
  const groups = ref<Paginated<ISocialGroup> | null>(null);
  const authStore = useAuthStore();
  const permissionsStore = usePermissionsStore();
  const group = ref<ISocialGroup | null>(null);
  const post = ref<IPost | null>(null);
  const isAdmin = computed(() => {
    return group.value?.groupRole == 'ADMIN';
  });
  const { t } = useI18();

  //======================================================================
  // >>>>>>>>>>>>>>> Methods
  //======================================================================
  const getUserGroup = async (params: { skip: number; take: number }) => {
    const { path, method } = socialApiHandler.users.groups.getUserGroups_c;
    try {
      if (
        !permissionsStore.checkAuthentication(
          EnumRoutes.social.socialGroups.meta
        )
      )
        return;
      if (!authStore.user?.id) return;
      const { data, status } = await axiosI(
        path({ userId: authStore.user?.id }),
        {
          method,
          params: params,
        }
      );
      if (status == 200 || status == 201) {
        groups.value = data;
      }
    } catch (e) {
      console.log('ERROR IN-->getUserGroup()', e);
      return e;
    }
  };

  const fetchGroupById = async (groupId: number) => {
    const { path, method } = socialApiHandler.groups_C.getGroup_C;
    try {
      const { data } = await axiosI(path({ groupId }), {
        method,
      });
      if (data?.status == null) {
        //this means that the user is not a member of the group
        useAuthStore().shouldHavePermission = true;
      }
      group.value = data;
    } catch (e) {
      useAuthStore().shouldHavePermission = true;
      return e;
    }
  };
  const savePost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.saves.savePost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const unSavePost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.saves.unsavePost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const pinPost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.pin.pinPost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const unPinPost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.pin.unpinPost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const deletePost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.deletePost;
    await axiosI(path({ postId }), {
      method,
    });
    useShowToast.success({
      msg: t('post.deletedSuccessfully'),
    });
  };
  const likePost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.likes.likePost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const unLikePost = async (postId: number) => {
    const { path, method } = socialApiHandler.posts.likes.unlikePost;
    return await axiosI(path({ postId }), {
      method,
    });
  };
  const likeComment = async (commentId: number) => {
    const { path, method } = socialApiHandler.comments.likes.likePostComment;
    const { data } = await axiosI(path({ commentId }), {
      method,
    });
    return data;
  };
  const unLikeComment = async (commentId: number) => {
    const { path, method } = socialApiHandler.comments.likes.unlikePostComment;
    const { data } = await axiosI(path({ commentId }), {
      method,
    });
    return data;
  };
  const deleteComment = async (commentId: number) => {
    const { path, method } = socialApiHandler.comments.deleteComment;
    await axiosI(path({ commentId }), {
      method,
    });
  };
  const createComment = async (postId: number, comment: string) => {
    const { path, method } = socialApiHandler.posts.postComments.createComment;
    return await axiosI(path({ postId }), {
      method,
      data: {
        content: comment,
      },
    });
  };
  const updateComment = async (commentId: number, comment: string) => {
    const { path, method } = socialApiHandler.comments.updateComment;
    await axiosI(path({ commentId }), {
      method,
      data: {
        content: comment,
      },
    });
  };
  const createPost = async (
    groupId: number,
    content: string,
    mediaIds: number[]
  ) => {
    const { path, method } = socialApiHandler.groups_C.posts.createGroupPost_C;
    return await axiosI(path({ groupId }), {
      method,
      data: {
        content,
        mediaIds,
      },
    });
  };
  const updatePost = async (
    postId: number,
    content: string,
    mediaIds: number[]
  ) => {
    const { path, method } = socialApiHandler.posts.updatePost;
    await axiosI(path({ postId }), {
      method,
      data: {
        content,
        mediaIds,
      },
    });
  };
  const getPostById = async (postId: number) => {
    try {
      const { path, method } = socialApiHandler.posts.getSinglePost;
      const { data } = await axiosI(path({ postId }), {
        method,
      });
      post.value = data;
      return data;
    } catch (e) {
      useAuthStore().shouldHavePermission = true;
      return e;
    }
  };

  const fetchGroupMembers = async (
    payload: {
      skip: number;
      take: number;
      status?: Array<
        GroupStatus.PENDING | GroupStatus.ACCEPTED | GroupStatus.REJECTED
      >;
    } = {
      skip: 0,
      take: 10,
      status: [GroupStatus.ACCEPTED],
    }
  ) => {
    if (!group.value) return;
    if (!payload.status) {
      payload.status = [GroupStatus.ACCEPTED];
    }

    const { path, method } =
      socialApiHandler.groups_C.members.getGroupMembers_C;
    try {
      const { data } = await axiosI(path({ groupId: group.value?.id }), {
        method,
        params: payload,
      });
      return data;
    } catch (e) {
      console.log('ERROR IN-->fetchGroupMembers()', e);
      return e;
    }
  };
  return {
    groups,
    getUserGroup,
    fetchGroupById,
    savePost,
    unSavePost,
    deletePost,
    likePost,
    unLikePost,
    likeComment,
    unLikeComment,
    deleteComment,
    createComment,
    updateComment,
    createPost,
    updatePost,
    pinPost,
    unPinPost,
    getPostById,
    post,
    group,
    isAdmin,
    fetchGroupMembers,
  };
});
