import { Permission } from './permission';

export class PermissionCategory {
  id: number;

  name: string;

  slug: string;

  isHidden: boolean;

  parentId?: number;

  parent?: PermissionCategory;

  createdAt: Date;

  updatedAt?: Date;

  permissions: Permission[];

  children: PermissionCategory[];
}
