export const dashboardApiHandler = {
  announcements: {
    getAnnouncements: {
      method: 'GET',
      path: () => `org-announcements-management`,
      params: {} as {
        filterDismissed?: string | number;
        filterExpired?: string | number;
        skip?: string | number;
        take?: string | number;
      },
    },
    dismissAnnouncement: {
      method: 'POST',
      path: (payload: { announcementId: string | number }) =>
        `org-announcements-management/${payload.announcementId}/dismiss`,
    },
  },
  financialSystem: {
    users: {
      addToCart: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `orders/add/${payload.courseId}`,
      },
      'create-test-order': {
        method: 'POST',
        path: () => `orders/test-order`,
      },
      'create-test-orderCopy': {
        method: 'POST',
        path: (payload: { orderId: string | number }) =>
          `orders/create-invoice/${payload.orderId}`,
      },
      requestRefund: {
        method: 'POST',
        path: (payload: { purchasedItemId: string | number }) =>
          `orders/refund/${payload.purchasedItemId}`,
      },
      getOrderById: {
        method: 'GET',
        path: (payload: { orderId: string | number }) =>
          `orders/${payload.orderId}`,
      },
      getCart: {
        method: 'GET',
        path: () => `orders/cart`,
      },
      paymentDisclosure: {
        method: 'POST',
        path: () => `orders/ottu-payment-disclosure`,
      },
      sendToQoyod: {
        method: 'POST',
        path: (payload: { orderId: string | number }) =>
          `orders/send-toqoyod/${payload.orderId}`,
      },
      checkout: {
        method: 'POST',
        path: () => `orders/checkout`,
      },
      sendEmail: {
        method: 'POST',
        path: () => `orders/checkout`,
      },
      'migrateDONTTOUCH!': {
        method: 'POST',
        path: () => `orders/migrate`,
      },
      removeItemFromCart: {
        method: 'DELETE',
        path: (payload: { purchasedItemId: string | number }) =>
          `orders/${payload.purchasedItemId}`,
      },
      allOrders: {
        method: 'GET',
        path: () => `orders/all`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      allOrdersPaginated: {
        method: 'GET',
        path: () => `orders/all-v2`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    qoyod: {
      getAllCustomers: {
        method: 'GET',
        path: () => `qoyod`,
      },
      updateCustomer: {
        method: 'PUT',
        path: () => `qoyod`,
      },
      createInvoice: {
        method: 'POST',
        path: () => `qoyod/create-invoice`,
      },
    },
    vouchers: {
      createVoucher: {
        method: 'POST',
        path: () => `orders/create-voucher`,
      },
      applyVoucher: {
        method: 'POST',
        path: () => `orders/apply-voucher`,
      },
      removeVoucher: {
        method: 'POST',
        path: () => `orders/remove-voucher`,
      },
    },
  },
  getStudentCourseStatistics: {
    method: 'GET',
    path: (payload: { userId: string | number }) =>
      `users/${payload.userId}/course-statistics`,
  },
  getStudentUpcomingActivites: {
    method: 'GET',
    path: (payload: { userId: string | number }) =>
      `users/${payload.userId}/calendar`,
    params: {} as {
      startTime?: string | number;
      endingTime?: string | number;
      completedFlag?: string | number;
    },
  },
  getEnrolledCoursesBasedOnProgress: {
    method: 'GET',
    path: (payload: { userId: string | number }) =>
      `users/${payload.userId}/enrolled-courses`,
    params: {} as { skip?: string | number; take?: string | number },
  },
  getRecommendedCourses: {
    method: 'GET',
    path: () => `courses`,
    params: {} as {
      skip?: string | number;
      take?: string | number;
      filterOutEnrolledCourses?: string | number;
      expiryDate?: string | number;
    },
  },
};
