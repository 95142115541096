import { TooltipContent } from './tooltip_content';

export class Tooltip {
  id: number;

  name: string;

  isPublished: boolean = true;

  tips: TooltipContent[];

  children: Tooltip[];

  parent?: Tooltip;

  parentId?: number;
}
