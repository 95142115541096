/* eslint-disable @typescript-eslint/no-unused-vars */
import { axiosI } from '@/plugins';
import type { CourseAnnouncement } from '@/submodules/generated_types/types/course_announcement';

import type { ActivityLog } from '@/helpers/interfaces/activity-log.interface';
import type { IChecklist } from '@/helpers/interfaces/checklist.interface';
import type { CourseAssignment } from '@/submodules/generated_types/types/course_assignment';
import type { CourseDiscussionBoardItem } from '@/submodules/generated_types/types/course_discussion_board_item';
import type { CourseGrade } from '@/submodules/generated_types/types/course_grade';
import type { CourseOnCampusLecture } from '@/submodules/generated_types/types/course_on_campus_lecture';
import type { CourseOnlineLecture } from '@/submodules/generated_types/types/course_online_lecture';
import type { ExamAttempt } from '@/submodules/generated_types/types/exam_attempt';
import type { StudentCourseCertificate } from '@/submodules/generated_types/types/student_course_certificate';
import type { SubsectionExam } from '@/submodules/generated_types/types/subsection_exam';
import { ApiHandler } from '@/postman-to-ts/apiHandler';
import { examSystemApiHandler } from '@/postman-to-ts/apiHandler/examSystem';
import { financialSystemApiHandler } from '@/postman-to-ts/apiHandler/financialSystem';
import { lMSApiHandler } from '@/postman-to-ts/apiHandler/lMS';
import { miscApiHandler } from '@/postman-to-ts/apiHandler/misc';
import { socialApiHandler } from '@/postman-to-ts/apiHandler/social';
import type { Ref } from 'vue';
import { EnumPaginatedQueriesKeys } from '../enums/paginated-query.enum';
import { CommonQuerySerializers } from '../functions/commonQuerySerializers';
import type { SingleAttendance } from '../interfaces/attendance.interface';
import type { CourseWithUserData } from '../interfaces/course.interface';
import type { INotification } from '../interfaces/notification.interface';
import type { SingleOrder } from '../interfaces/order.interface';
import type {
  Paginated,
  PaginationArgs,
  QueryParamSerializer,
} from '../interfaces/paginated.interface';
import type { SocialEventWithUserData } from '../interfaces/social.interface';
import type { Specialization } from '../interfaces/specialization.interface';
import type { SingleSurvey } from '../interfaces/survey.interface';
import type { SubsectionExamWithCount } from '../interfaces/exam.interface';
import type { ISocialGroup } from '@/stores/groups.store';
import type { ExternalCourseTargetOrganization } from '@/submodules/generated_types/types/external_course_target_organization';
import type { ExternalCourse } from '@/submodules/generated_types/types/external_course';
import type { ExternalCourseEnrollment } from '@/submodules/generated_types/types/external_course_enrollment';

//======================================================================
//>>>>>>>>>>>>>>> Interfaces for PaginationArgs <<<<<<<<<<<<<<<<<<<<<<<<
//======================================================================
export interface CoursesPaginationArgs extends PaginationArgs {
  filterOutEnrolledCourses: boolean | null;
  languages: string[] | null;
  learningCategories: [] | null;
  learningTypes: string[] | null;
  sort: null | string;
  minPrice: null | number;
  maxPrice: null | number;
  search: null | string;
  isCertificateIssuedManually: boolean | null;
}
export interface SocialEventsPaginationArgs extends PaginationArgs {
  search: null | string;
}

export interface ActivityLogPaginationArgs extends PaginationArgs {
  activityType: (string | number)[];
  language?: string;
}

export interface MyCoursesPaginationArgs extends PaginationArgs {
  showOnlyCompletedCourses: number;
  showOnlyUncompletedCourses: number;
}

interface CourseAssignmentsPaginationArgs extends PaginationArgs {}
interface ExternalCoursesPaginationArgs extends PaginationArgs {}

interface UserExternalCoursesPaginationArgs extends PaginationArgs {}

interface CourseAnnouncementsPaginationArgs extends PaginationArgs {}

interface CourseSurveysPaginationArgs extends PaginationArgs {}

interface CourseOnlineLecturesPaginationArgs extends PaginationArgs {}
interface CourseOnCampusLecturesPaginationArgs extends PaginationArgs {}

interface CourseExamsPaginationArgs extends PaginationArgs {}

interface NotificationsPaginationArgs extends PaginationArgs {}

interface CourseGradeItemsPaginationArgs extends PaginationArgs {}
interface EnrolledEventsPaginationArgs extends PaginationArgs {}

interface ExamAttemptsPaginationArgs extends PaginationArgs {}

interface OrderHistoryPaginationArgs extends PaginationArgs {}

interface FavoriteCoursesPaginationArgs extends PaginationArgs {}

interface FavoriteEventsPaginationArgs extends PaginationArgs {}

interface CourseDiscussionBoardsPaginationArgs extends PaginationArgs {}

interface StudentAttendancePaginationArgs extends PaginationArgs {}

interface ChecklistPaginationArgs extends PaginationArgs {}
interface StudentCertificatesPaginationArgs extends PaginationArgs {}
interface SpecializationPaginationArgs extends PaginationArgs {}

interface MyGroupsPaginationArgs extends PaginationArgs {}

interface JourneyPaginationArgs extends PaginationArgs {}

//======================================================================
//>>>>>>>>>>>>>>> All Paginated Queries <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
//======================================================================
export class AllPaginatedQueries {
  //________________ Order History ________________

  static orderHistory(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.OrderHistory],
      defaultArgs: {} as OrderHistoryPaginationArgs,
      serializer: {} as QueryParamSerializer<OrderHistoryPaginationArgs>,
      fetcher: async (page: number, extraArgs?: OrderHistoryPaginationArgs) => {
        const { path, method } =
          financialSystemApiHandler.users.allOrdersPaginated;
        return (
          await axiosI<Paginated<SingleOrder>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }

  //________________ Courses ________________
  static courses(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Courses],
      defaultArgs: {
        filterOutEnrolledCourses: null,
        languages: [],
        learningCategories: [],
        learningTypes: [],
        sort: null,
        minPrice: null,
        maxPrice: null,
        search: null,
        isCertificateIssuedManually: null,
      } as CoursesPaginationArgs,
      serializer: {
        filterOutEnrolledCourses: CommonQuerySerializers.booleanSerializer(
          'hideEnrolledCourses'
        ),
        isCertificateIssuedManually: CommonQuerySerializers.booleanSerializer(
          'manuallyCertificate'
        ),
        languages: CommonQuerySerializers.arraySerializer('languages'),
        learningCategories:
          CommonQuerySerializers.arraySerializer('learningCategories'),
        sort: CommonQuerySerializers.stringSerializer('sort'),
        learningTypes: CommonQuerySerializers.arraySerializer('learningTypes'),
        minPrice: CommonQuerySerializers.numberSerializer('minPrice'),
        maxPrice: CommonQuerySerializers.numberSerializer('maxPrice'),
        search: CommonQuerySerializers.stringSerializer('search'),
      } as QueryParamSerializer<CoursesPaginationArgs>,
      fetcher: async (page: number, extraArgs?: CoursesPaginationArgs) => {
        const lmsApi = lMSApiHandler.courses.getCourses;
        const { path, method } = lmsApi;
        return (
          await axiosI<Paginated<CourseWithUserData>>(path(), {
            method,
            params: {
              search: extraArgs?.search,
              skip: page * take.value,
              take: take.value,
              filterOutEnrolledCourses: extraArgs?.filterOutEnrolledCourses,
              languages: extraArgs?.languages,
              learningCategories: extraArgs?.learningCategories,
              sort: extraArgs?.sort,
              learningTypes: extraArgs?.learningTypes,
              minPrice: extraArgs?.minPrice,
              maxPrice: extraArgs?.maxPrice,
              isCertificateIssuedManually:
                extraArgs?.isCertificateIssuedManually,
              expiryDate: new Date(
                Date.now() - 30 * 24 * 60 * 60 * 1000
              ).toISOString(),
            },
          })
        ).data;
      },
    };
  }
  //________________External Courses ________________
  static externalCourses(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.ExternalCourses],
      defaultArgs: {} as ExternalCoursesPaginationArgs,
      serializer: {} as QueryParamSerializer<ExternalCoursesPaginationArgs>,
      fetcher: async (page: number) => {
        const lmsApi = lMSApiHandler.externalCourses.student.coursesForStudent;
        const { path, method } = lmsApi;
        return (
          await axiosI<Paginated<ExternalCourseTargetOrganization>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }
  static userExternalCourses(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.MYExternalCourses],
      defaultArgs: {} as UserExternalCoursesPaginationArgs,
      serializer: {} as QueryParamSerializer<UserExternalCoursesPaginationArgs>,
      fetcher: async (page: number) => {
        const lmsApi = lMSApiHandler.externalCourses.student.enrolledCourses;
        const { path, method } = lmsApi;
        return (
          await axiosI<Paginated<ExternalCourseEnrollment>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }

  //________________ All Course Assignments ________________
  static courseAssignments(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseAssignments, courseId.value],
      defaultArgs: {} as CourseAssignmentsPaginationArgs,
      serializer: {} as QueryParamSerializer<CourseAssignmentsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseAssignmentsPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.assignments.getCourseAssignmentsStudent;
        return (
          await axiosI<Paginated<CourseAssignment>>(
            path({ courseId: courseId.value }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
                portals: [1],
              },
            }
          )
        ).data;
      },
    };
  }

  // _______________ All Course Discussion Boards ________________
  static courseDiscussionBoards(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseDiscussionBoards, courseId.value],
      defaultArgs: {} as CourseDiscussionBoardsPaginationArgs,
      serializer:
        {} as QueryParamSerializer<CourseDiscussionBoardsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseDiscussionBoardsPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.discussionBoardV2.getCourseDiscussionBoards;
        return (
          await axiosI<Paginated<CourseDiscussionBoardItem>>(
            path({ courseId: courseId.value }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course Announcements ________________
  static courseAnnouncements(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseAnnouncements, courseId.value],
      defaultArgs: {} as CourseAnnouncementsPaginationArgs,
      serializer: {} as QueryParamSerializer<CourseAnnouncementsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseAnnouncementsPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.announcements.getAnnouncementsForStudents;
        return (
          await axiosI<Paginated<CourseAnnouncement>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course Surveys ________________
  static courseSurveys(take: Ref<number>, courseId: Ref<number>, type: string) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseSurveys, courseId.value, type],
      defaultArgs: {} as CourseSurveysPaginationArgs,
      serializer: {} as QueryParamSerializer<CourseSurveysPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseSurveysPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.surveys.getCourseSurveysStudent;
        return (
          await axiosI<Paginated<SingleSurvey>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
                type,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course Online Lectures ________________
  static courseOnlineLectures(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseOnlineLectures, courseId.value],
      defaultArgs: {} as CourseOnlineLecturesPaginationArgs,
      serializer:
        {} as QueryParamSerializer<CourseOnlineLecturesPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseOnlineLecturesPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.onlineLectures.getOnlineLectures;
        return (
          await axiosI<Paginated<CourseOnlineLecture>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course On Campus Lectures ________________
  static courseOnCampusLectures(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseOnCampusLectures, courseId.value],
      defaultArgs: {} as CourseOnCampusLecturesPaginationArgs,
      serializer:
        {} as QueryParamSerializer<CourseOnCampusLecturesPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseOnCampusLecturesPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.onCampusLectures.getOnCampusLectures;
        return (
          await axiosI<Paginated<CourseOnCampusLecture>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course Grades ________________
  static courseGradeItems(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.CourseGradeItems, courseId.value],
      defaultArgs: {} as CourseGradeItemsPaginationArgs,
      serializer: {} as QueryParamSerializer<CourseGradeItemsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: CourseGradeItemsPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.gradeItems.getCourseGradeItemsForStudent;
        return (
          await axiosI<Paginated<CourseGrade>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Course Exams ________________
  static courseExams(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: ['courseExams', courseId.value],
      defaultArgs: {} as CourseExamsPaginationArgs,
      serializer: {} as QueryParamSerializer<CourseExamsPaginationArgs>,
      fetcher: async (page: number, extraArgs?: CourseExamsPaginationArgs) => {
        const { path, method } =
          examSystemApiHandler.courseExams.studentGetAllExamsInCourse;
        return (
          await axiosI<Paginated<SubsectionExamWithCount>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Exam Attempts for a single exam ________________
  static examAttempts(take: Ref<number>, subsectionExamId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.ExamAttempts, subsectionExamId.value],
      defaultArgs: {} as ExamAttemptsPaginationArgs,
      serializer: {} as QueryParamSerializer<ExamAttemptsPaginationArgs>,
      fetcher: async (page: number, extraArgs?: ExamAttemptsPaginationArgs) => {
        const { path, method } =
          examSystemApiHandler.student.getPreviousExamAttempts;
        return (
          await axiosI<Paginated<ExamAttempt>>(
            path({
              subsectionExamId: subsectionExamId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ Social ________________
  static socialEvents(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.SocialEvents],
      defaultArgs: {
        search: null,
      } as SocialEventsPaginationArgs,
      serializer: {
        search: CommonQuerySerializers.stringSerializer('search'),
      } as QueryParamSerializer<SocialEventsPaginationArgs>,
      fetcher: async (page: number, extraArgs?: SocialEventsPaginationArgs) => {
        const { path, method } = socialApiHandler.events.getEvents_C;
        return (
          await axiosI<Paginated<SocialEventWithUserData>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
              search: extraArgs?.search,
              expiryDate: new Date(
                Date.now() - 30 * 24 * 60 * 60 * 1000
              ).toISOString(),
            },
          })
        ).data;
      },
    };
  }

  //________________ Enrolled Events ________________
  static enrolledEvents(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.EnrolledEvents],
      defaultArgs: {} as EnrolledEventsPaginationArgs,
      serializer: {} as QueryParamSerializer<EnrolledEventsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: EnrolledEventsPaginationArgs
      ) => {
        const { path, method } =
          socialApiHandler.users.events.getUserEnrolledEvents;
        return (
          await axiosI<Paginated<SocialEventWithUserData>>(
            path({ userId: userId.value }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }
  //________________ Notifications ________________
  static notifications(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Notifications, 1],
      defaultArgs: {} as NotificationsPaginationArgs,
      serializer: {} as QueryParamSerializer<NotificationsPaginationArgs>,
      fetcher: async (page: number) => {
        const { path, method } =
          miscApiHandler.notifications.himamNotificationCRUD.getNotificationsv2;
        return (
          await axiosI<Paginated<INotification>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
              portals: [1],
            },
          })
        ).data;
      },
    };
  }

  //________________ Favorite Courses ________________
  static favoriteCourses(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.FavoriteCourses, userId.value],
      defaultArgs: {} as FavoriteCoursesPaginationArgs,
      serializer: {} as QueryParamSerializer<FavoriteCoursesPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: FavoriteCoursesPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.users.wishlist.getWishlistedCourses;
        return (
          await axiosI<Paginated<CourseWithUserData>>(
            path({
              userId: userId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  static favoriteEvents(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.FavoriteEvents],
      defaultArgs: {} as FavoriteEventsPaginationArgs,
      serializer: {} as QueryParamSerializer<FavoriteEventsPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: FavoriteEventsPaginationArgs
      ) => {
        const { path, method } =
          socialApiHandler.users.events.getUserFavoritedEvents;
        return (
          await axiosI<Paginated<SocialEventWithUserData>>(
            path({
              userId: userId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ All Student Course Attendance ________________
  static studentCourseAttendance(take: Ref<number>, courseId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Attendance, courseId.value],
      defaultArgs: {} as StudentAttendancePaginationArgs,
      serializer: {} as QueryParamSerializer<StudentAttendancePaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: StudentAttendancePaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.courses.attendance.getStudentCourseAttendance;
        return (
          await axiosI<Paginated<SingleAttendance>>(
            path({
              courseId: courseId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ Checklist ________________
  static checklistStudent(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Checklist],
      defaultArgs: {} as ChecklistPaginationArgs,
      serializer: {} as QueryParamSerializer<ChecklistPaginationArgs>,
      fetcher: async (page: number, extraArgs?: ChecklistPaginationArgs) => {
        const { path, method } =
          ApiHandler.lMS.studentChecklists.getUserChecklists;
        return (
          await axiosI<Paginated<IChecklist>>(path({ userId: userId.value }), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }

  // __________________ Activity Log __________________
  static activityLog(take: Ref<number>, userId: number) {
    return {
      key: [EnumPaginatedQueriesKeys.ActivityLog],
      defaultArgs: {
        activityType: [],
        language: 'en',
      } as ActivityLogPaginationArgs,
      serializer: {
        activityType: CommonQuerySerializers.arraySerializer('activityType'),
        language: CommonQuerySerializers.stringSerializer('language'),
      } as QueryParamSerializer<ActivityLogPaginationArgs>,
      fetcher: async (page: number, extraArgs?: ActivityLogPaginationArgs) => {
        const { path, method } = lMSApiHandler.users.getUsersActivityLog;
        return (
          await axiosI<Paginated<ActivityLog>>(
            path({
              userId: userId,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
                activityType: extraArgs?.activityType,
                language: extraArgs?.language,
              },
            }
          )
        ).data;
      },
    };
  }

  // __________________ My Courses __________________

  static myCourses(take: Ref<number>, userId: number) {
    return {
      key: [EnumPaginatedQueriesKeys.MyCourses],
      defaultArgs: {
        showOnlyCompletedCourses: 0,
        showOnlyUncompletedCourses: 0,
      } as MyCoursesPaginationArgs,
      serializer: {
        showOnlyCompletedCourses: CommonQuerySerializers.numberSerializer(
          'showOnlyCompletedCourses'
        ),
        showOnlyUncompletedCourses: CommonQuerySerializers.numberSerializer(
          'showOnlyUncompletedCourses'
        ),
      } as QueryParamSerializer<MyCoursesPaginationArgs>,
      fetcher: async (page: number, extraArgs?: MyCoursesPaginationArgs) => {
        const { path, method } =
          lMSApiHandler.users.enrollment.getEnrolledCourses;
        return (
          await axiosI<Paginated<CourseWithUserData>>(
            path({
              userId: userId,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
                showOnlyCompletedCourses: extraArgs?.showOnlyCompletedCourses,
                showOnlyUncompletedCourses:
                  extraArgs?.showOnlyUncompletedCourses,
              },
            }
          )
        ).data;
      },
    };
  }

  // __________________ Specialization __________________

  static specialization(take: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Specialization],
      defaultArgs: {} as SpecializationPaginationArgs,
      serializer: {} as QueryParamSerializer<SpecializationPaginationArgs>,
      fetcher: async (page: number) => {
        const { path, method } =
          lMSApiHandler.specializations.getSpecializations;
        return (
          await axiosI<Paginated<Specialization>>(path(), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }

  // __________________ My Certificates __________________
  static usersCertificates(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.StudentCertificates],
      defaultArgs: {} as StudentCertificatesPaginationArgs,
      serializer: {} as QueryParamSerializer<StudentCertificatesPaginationArgs>,
      fetcher: async (
        page: number,
        extraArgs?: StudentCertificatesPaginationArgs
      ) => {
        const { path, method } =
          lMSApiHandler.users.courseCertificates.getUsersCertificate;
        return (
          await axiosI<Paginated<StudentCourseCertificate>>(
            path({
              userId: userId.value,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  static getMyGroups(take: Ref<number>, userId: number) {
    return {
      key: [EnumPaginatedQueriesKeys.MyGroups],
      defaultArgs: {} as MyGroupsPaginationArgs,
      serializer: {} as QueryParamSerializer<MyGroupsPaginationArgs>,
      fetcher: async (page: number, extraArgs?: MyGroupsPaginationArgs) => {
        const { path, method } = socialApiHandler.users.groups.getUserGroups_c;
        return (
          await axiosI<Paginated<ISocialGroup>>(
            path({
              userId: userId,
            }),
            {
              method,
              params: {
                skip: page * take.value,
                take: take.value,
              },
            }
          )
        ).data;
      },
    };
  }

  //________________ Journey ________________
  static Journeys(take: Ref<number>, userId: Ref<number>) {
    return {
      key: [EnumPaginatedQueriesKeys.Checklist],
      defaultArgs: {} as JourneyPaginationArgs,
      serializer: {} as QueryParamSerializer<JourneyPaginationArgs>,
      fetcher: async (page: number, extraArgs?: JourneyPaginationArgs) => {
        const { path, method } =
          ApiHandler.lMS.studentChecklists.getUserChecklists;
        return (
          await axiosI<Paginated<IChecklist>>(path({ userId: userId.value }), {
            method,
            params: {
              skip: page * take.value,
              take: take.value,
            },
          })
        ).data;
      },
    };
  }
}
