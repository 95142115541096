interface EnvBoolean {
  allowLandingPage: boolean;
}

/*
 * This composable is used to get the boolean value from .env file
 * and return it as an object with the key name as the variable name
 * @returns {Object} - Object with the key name as the variable name
 * @example
 * const { allowLandingPage } = useEnvBoolean();
 * VITE_ALLOW_LANDING used in @/router/landing.routes.ts to enable/disable the landing page and landing page routes
 */

export const useEnvBoolean = (): EnvBoolean => {
  const allowLandingPage = import.meta.env.VITE_ALLOW_LANDING === 'true';

  return {
    allowLandingPage,
  };
};
