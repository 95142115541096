import { QuestionDetail } from './question_detail';
import { Media } from './media';

export class QuestionExplanation {
  id: number;

  questionDetailId: number;

  questionDetail: QuestionDetail;

  body: string;

  mediaId?: number;

  media?: Media;

  createdAt: Date;

  updatedAt?: Date;
}
