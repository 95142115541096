import { PermissionSet } from './permission_set';
import { Permission } from './permission';

export class PermissionSetPermission {
  id: number;

  permissionSetId: number;

  permissionSet: PermissionSet;

  permissionId: number;

  permission: Permission;

  createdAt: Date;

  updatedAt?: Date;
}
