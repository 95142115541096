import { ChecklistSettings } from './checklist_settings';
import { ChecklistItemSettings } from './checklist_item_settings';

export class DueDateType {
  id: number;

  name: string;

  createdAt: Date;

  updatedAt?: Date;

  checklistSettings: ChecklistSettings[];

  checklistItemSettings: ChecklistItemSettings[];
}
