import { SubsectionExam } from './subsection_exam';
import { User } from './user';
import { ExamAttempt } from './exam_attempt';
import { StudentSubsectionExamGrade } from './student_subsection_exam_grade';

export class ExamInstance {
  id: number;

  subsectionExamId: number;

  subsectionExam?: SubsectionExam;

  userId: number;

  user: User;

  createdAt: Date;

  updatedAt?: Date;

  examAttempts: ExamAttempt[];

  examGrade?: StudentSubsectionExamGrade;
}
