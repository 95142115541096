import { SocialEventAttendanceItem } from './social_event_attendance_item';
import { User } from './user';

export class SocialEventAttendance {
  id: number;

  attendanceItemId: number;

  attendanceItem: SocialEventAttendanceItem;

  userId: number;

  user: User;

  attendedAt?: Date;

  checkOutAt?: Date;

  createdAt: Date;

  updatedAt?: Date;
}
