import { Media } from './media';
import { CourseSubsection } from './course_subsection';

export class SubsectionAttachments {
  id: number;

  name?: string;

  link?: string;

  attachmentId?: number;

  attachment?: Media;

  subsectionId: number;

  subsection: CourseSubsection;

  createdAt: Date;

  updatedAt: Date;
}
