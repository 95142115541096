import { CourseTypes } from './course_types';
import { LearningCategories } from './learning_categories';
import { Organization } from './organization';
import { Media } from './media';
import { CourseSyllabus } from './course_syllabus';
import { User } from './user';
import { GradingSchema } from './grading_schema';
import { UserWishlistedCourse } from './user_wishlisted_course';
import { CourseSection } from './course_section';
import { CourseEnrollee } from './course_enrollee';
import { CourseInstructor } from './course_instructor';
import { CourseTranslation } from './course_translation';
import { CourseSurvey } from './course_survey';
import { UserLastVisitedSubsection } from './user_last_visited_subsection';
import { StudentFinishedCourseSubsections } from './student_finished_course_subsections';
import { CourseSubsection } from './course_subsection';
import { SubsectionNotes } from './subsection_notes';
import { CourseAnnouncement } from './course_announcement';
import { StudentCourseCertificate } from './student_course_certificate';
import { CourseAssignment } from './course_assignment';
import { Rubric } from './rubric';
import { CourseGrade } from './course_grade';
import { SpecializationCourse } from './specialization_course';
import { CourseAttendance } from './course_attendance';
import { SubsectionExam } from './subsection_exam';
import { StudentFinishedCourse } from './student_finished_course';
import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { CanvasCourse } from './canvas_course';
import { CourseGroup } from './course_group';
import { CourseOnlineLecture } from './course_online_lecture';
import { Survey } from './survey';
import { CourseTargetOrganization } from './course_target_organization';
import { CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { UserPinnedCourse } from './user_pinned_course';
import { CourseApprovalRequest } from './course_approval_request';
import { CourseOnCampusLecture } from './course_on_campus_lecture';
import { UserCourseInterest } from './user_course_interest';
import { CourseReminderEmail } from './course_reminder_email';
import { CourseConfirmationCheckbox } from './course_confirmation_checkbox';
import {
  CourseStatus,
  Language,
  DurationUnit,
} from '../exported_type_enums/exported_type_enums';

export class Course {
  id: number;

  isDeleted: boolean;

  isApproved: boolean;

  isPublished: boolean;

  isPublishedByAdmin: boolean;

  isComingSoon: boolean;

  status: CourseStatus = CourseStatus.Pending;

  isSync: boolean = true;

  isCertificateIssuedManually: boolean;

  passingGradePercentage: number = 0.5;

  mandatoryAttendancePercentage: number = 1;

  startEnrollmentDate?: Date;

  endEnrollmentDate?: Date;

  language: Language = Language.ENGLISH;

  learningTypes: CourseTypes[];

  categoryId?: number;

  category?: LearningCategories;

  name: string;

  normalizedName?: string;

  briefDescription?: string;

  description?: string;

  country?: string;

  city?: string;

  welcomeMessage?: string;

  duration?: number;

  durationUnit?: DurationUnit;

  scormId?: string;

  startDate?: Date;

  endDate?: Date;

  maxEnrollmentCount?: number;

  fromTo?: string;

  organizationId?: number;

  organization?: Organization;

  coverImageId?: number;

  coverImage?: Media;

  courseSyllabus?: CourseSyllabus;

  introductoryVideoId?: number;

  introductoryVideo?: Media;

  createdAt: Date;

  updatedAt: Date;

  createdById: number;

  createdBy: User;

  gradingSchemaId?: number;

  gradingSchema?: GradingSchema;

  wishlistedCourses: UserWishlistedCourse[];

  courseSections: CourseSection[];

  enrollees: CourseEnrollee[];

  instructors: CourseInstructor[];

  translations: CourseTranslation[];

  surveyAnswers: CourseSurvey[];

  usersLastVisitedSubsection: UserLastVisitedSubsection[];

  studentFinishedCourseSubsections: StudentFinishedCourseSubsections[];

  courseSubsections: CourseSubsection[];

  subsectionNotes: SubsectionNotes[];

  announcements: CourseAnnouncement[];

  studentCourseCertificates: StudentCourseCertificate[];

  assignments: CourseAssignment[];

  rubrics: Rubric[];

  courseGrades: CourseGrade[];

  partOfSpecializations: SpecializationCourse[];

  attendance: CourseAttendance[];

  exams: SubsectionExam[];

  studentsWhoFinishedTheCourse: StudentFinishedCourse[];

  discussionBoardItems: CourseDiscussionBoardItem[];

  canvasCourseId?: number;

  canvasCourse?: CanvasCourse;

  courseGroups: CourseGroup[];

  onlineLectures: CourseOnlineLecture[];

  surveys: Survey[];

  targetOrganizations: CourseTargetOrganization[];

  resourceAccessRestrictions: CourseResourceAccessRestriction[];

  pinnedBy: UserPinnedCourse[];

  approvalRequest?: CourseApprovalRequest;

  onCampusLectures: CourseOnCampusLecture[];

  interestedUsers: UserCourseInterest[];

  remindersSent: CourseReminderEmail[];

  confirmationCheckbox?: CourseConfirmationCheckbox;
}
