export class nova_notifications {
  id: string;

  type: string;

  notifiable_type: string;

  notifiable_id: BigInt;

  data: string;

  read_at?: Date;

  created_at?: Date;

  updated_at?: Date;

  deleted_at?: Date;
}
