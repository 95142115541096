<script setup lang="ts">
  const getNotificationSettings = inject('getNotificationSettings', () => {});
  onMounted(() => {
    getNotificationSettings();
  });
  import { onMounted } from 'vue';
  import { inject, ref, type Ref } from 'vue';
  export interface UserNotificationSettings {
    count: number;
    data: Array<{
      id: number;
      userId: number;
      controlGroupId: number;
      isDisabled: boolean;
      createdAt: string;
      updatedAt: string;
    }>;
  }
  export interface NotificationControlGroup {
    count: number;
    data: Array<{
      id: number;
      slug: string;
      name: string;
      weight: number;
      isPublished: boolean;
      canBeDisabled: boolean;
      parentControlGroupId: number;
      createdAt: string;
      updatedAt: string;
      children: Array<{
        id: number;
        slug: string;
        name: string;
        weight: number;
        isPublished: boolean;
        canBeDisabled: boolean;
        parentControlGroupId: number;
      }>;
    }>;
  }
  const getValue = (id: number) => {
    return !userNotificationSettings.value?.data?.find(
      (element) => element.controlGroupId === id
    )?.isDisabled;
  };
  const manageNotificationsText: Ref<string> = inject(
    'manageNotificationsText',
    ref('Manage Notifications')
  );
  const isLoading: Ref<boolean> = inject('isLoading', ref(false));
  const isFetching: Ref<boolean> = inject('isFetching', ref(false));
  const updateNotificationSettings: (id: number, event: boolean) => void =
    inject('updateNotificationSettings', () => {});

  const data: Ref<NotificationControlGroup> = inject(
    'notificationSettings',
    ref({ count: 0, data: [] })
  );
  const userNotificationSettings: Ref<UserNotificationSettings> = inject(
    'userNotificationSettings',
    ref({
      count: 0,
      data: [],
    })
  );
</script>

<template>
  <v-container>
    <v-toolbar-title class="text-subtitle-1 text-primary">
      {{ manageNotificationsText }}
    </v-toolbar-title>
  </v-container>
  <v-row
    class="fill-height"
    align-content="center"
    justify="center"
    v-if="isLoading"
  >
    <v-col cols="2">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-col>
  </v-row>
  <div v-if="!isLoading && isFetching">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </div>
  <v-list height="400">
    <template
      v-for="notificationControlGroup in data?.data"
      :key="notificationControlGroup.id"
    >
      <v-list-item
        v-for="item in notificationControlGroup.children"
        :key="item.id"
      >
        <template v-slot:append>
          <v-switch
            color="primary"
            density="comfortable"
            :hide-details="true"
            @change="updateNotificationSettings(item.id, $event.target.checked)"
            :model-value="getValue(item.id)"
          >
          </v-switch>
        </template>
        <div class="d-flex justify-space-between align-center" width="100%">
          <v-list-item-title class="text-subtitle-2">
            {{ item.name }}
          </v-list-item-title>
        </div>
      </v-list-item>
    </template>
  </v-list>
</template>
