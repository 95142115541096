import { computed } from 'vue';
import { EnumRoutes } from './routes.enum';
import { useOrganizationStore } from '@/stores/organzation.pinia';
import { pinia } from '@/pinia';
import { hiddenRoutes } from '@/router/hidden.router';

import { storeToRefs } from 'pinia';

const orgStore = useOrganizationStore(pinia);
const { dashboardText, coursesText } = storeToRefs(orgStore);
const baseHeaderItems = computed(() => [
  { ...EnumRoutes.dashboard.root, title: dashboardText.value },
  { ...EnumRoutes.lms.root, title: coursesText.value },
  { ...EnumRoutes.externalCourses.root },
  EnumRoutes.social.events,
  EnumRoutes.social.socialGroups,
  EnumRoutes.journeyRoutesEnum.root,
  EnumRoutes.mobile.downloadTheApp,
  EnumRoutes.libraryRoutesEnum.root,
  EnumRoutes.chatRoutesEnum.root,
]);
const headerItemsUnAuth = computed(() => [
  ...baseHeaderItems.value,
  EnumRoutes.landing.about,
  EnumRoutes.landing.contactUs,
]);
const headerProfileItems = computed(() => [
  EnumRoutes.profile.activityLog.root,
  EnumRoutes.profile.savedPost.root,
  EnumRoutes.financials.paymentHistory,
  EnumRoutes.profile.myCertificates.root,
  EnumRoutes.dashboard.userActivities,
]);
export const EnumHeaderList = computed(() => ({
  headerItemsAuth: [
    ...baseHeaderItems.value.filter(
      (item) => !hiddenRoutes.includes(item.name)
    ),
  ],
  headerItemsUnAuth: headerItemsUnAuth.value.filter(
    (item) => !hiddenRoutes.includes(item.name)
  ),
  headerProfileItems: headerProfileItems.value.filter(
    (item) => !hiddenRoutes.includes(item.name)
  ),
}));
