import { Course } from './course';
import { Rubric } from './rubric';
import { StudentCourseGrade } from './student_course_grade';
import { CourseAssignment } from './course_assignment';
import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { SubsectionExam } from './subsection_exam';
import { StudentSubsectionExamGrade } from './student_subsection_exam_grade';
import { GroupCourseGrade } from './group_course_grade';

export class CourseGrade {
  id: number;

  courseId: number;

  course: Course;

  name: string;

  shortName: string;

  description?: string;

  maxPoints: number;

  canExceed: boolean;

  bonus: boolean;

  isCountedTowardsFinalGrade: boolean = true;

  rubricId?: number;

  rubric?: Rubric;

  weight: number;

  isPublished: boolean = true;

  createdAt: Date;

  updatedAt: Date;

  studentGrades: StudentCourseGrade[];

  assignment?: CourseAssignment;

  discussionBoardItem?: CourseDiscussionBoardItem;

  subsectionExam?: SubsectionExam;

  studentSubsectionExamGrade: StudentSubsectionExamGrade[];

  groupGrades: GroupCourseGrade[];
}
