import { Organization } from './organization';
import { CourseTargetOrganization } from './course_target_organization';

export class CoursePassingCriteria {
  id: number;

  passingGradePercentage: number;

  mandatoryAttendancePercentage: number;

  organization: Organization[];

  courses: CourseTargetOrganization[];

  createdAt: Date;

  updatedAt?: Date;
}
