import { EnumSnackbarType } from '@/helpers/enums/misc.enum';
import { useSnackbarStore } from '@/stores/snackbar.store';

const showToast = () => {
  const store = useSnackbarStore();
  const { showToast } = store;
  const toast = {
    success: ({
      msg,
      timeout,
      translate,
    }: {
      msg: string;
      timeout?: number;
      translate?: boolean;
    }) => {
      return showToast({
        msg,
        timeout,
        snackbarType: EnumSnackbarType.SUCCESS,
        translate,
      });
    },
    error: ({
      msg,
      timeout,
      translate,
    }: {
      msg: string;
      timeout?: number;
      translate?: boolean;
    }) => {
      return showToast({
        msg,
        timeout,
        snackbarType: EnumSnackbarType.ERROR,
        translate,
      });
    },
    warning: ({
      msg,
      timeout,
      translate,
    }: {
      msg: string;
      timeout?: number;
      translate?: boolean;
    }) => {
      showToast({
        msg,
        timeout,
        snackbarType: EnumSnackbarType.WARNING,
        translate,
      });
    },
    info: ({
      msg,
      timeout,
      translate,
    }: {
      msg: string;
      timeout?: number;
      translate?: boolean;
    }) => {
      showToast({
        msg,
        timeout,
        snackbarType: EnumSnackbarType.INFO,
        translate,
      });
    },
  };

  return toast;
};

export class useShowToast {
  static success = ({
    msg,
    timeout,
    translate,
  }: {
    msg: string;
    timeout?: number;
    translate?: boolean;
  }) => {
    return showToast().success({ msg, timeout, translate });
  };

  static error = ({
    msg,
    timeout,
    translate,
  }: {
    msg: string;
    timeout?: number;
    translate?: boolean;
  }) => {
    return showToast().error({ msg, timeout, translate });
  };

  static warning = ({
    msg,
    timeout,
    translate,
  }: {
    msg: string;
    timeout?: number;
    translate?: boolean;
  }) => {
    return showToast().warning({ msg, timeout, translate });
  };

  static info = ({
    msg,
    timeout,
    translate,
  }: {
    msg: string;
    timeout?: number;
    translate?: boolean;
  }) => {
    return showToast().info({ msg, timeout, translate });
  };
}
