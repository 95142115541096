import { Organization } from './organization';

export class ApiKey {
  id: number;

  key: string;

  name: string;

  organizationId: number;

  organization?: Organization;

  isActive: boolean = true;

  createdAt: Date;

  updatedAt?: Date;
}
