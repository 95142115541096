import { PurchasedItemType } from './purchased_item_type';
import { Organization } from './organization';

export class QoyodProduct {
  id: number;

  sku: string;

  itemId: number;

  organizationId: number;

  purchasedItemTypeSlug: string;

  qoyodId?: number;

  purchasedItemType: PurchasedItemType;

  organization: Organization;

  createdAt: Date;

  updatedAt: Date;
}
