import { User } from './user';
import { Course } from './course';

export class CourseInstructor {
  id: number;

  instructorId: number;

  instructor: User;

  courseId: number;

  course: Course;

  isHidden: boolean;

  permissionSetId?: number;

  createdAt: Date;

  updatedAt?: Date;
}
