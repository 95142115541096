import { Organization } from './organization';
import { FontFamilyStyle } from './font_family_style';
import { OrganizationFontFamily } from './organization_font_family';

export class FontFamily {
  id: number;

  name: string;

  organizationId?: number;

  organization?: Organization;

  isGoogleFont: boolean;

  createdAt: Date;

  updatedAt?: Date;

  styles: FontFamilyStyle[];

  organizations: Organization[];

  organizationFontFamilies: OrganizationFontFamily[];
}
