import { User } from './user';
import { Media } from './media';
import { CoachTitle } from '../exported_type_enums/exported_type_enums';

export class CoachDetails {
  id: number;

  nda: boolean;

  userId: number;

  user: User;

  recentJob?: string;

  recentOrg?: string;

  introductoryVideoId?: number;

  introductoryVideo?: Media;

  cvId?: number;

  cv?: Media;

  title?: CoachTitle;

  position?: string;

  bookedSessions: number;
}
