//@ts-ignore-next-line
import vuetifyLocalization from '@/helpers/constants/vuetifyLocalization.json';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import { md3 } from 'vuetify/blueprints';
import { VAlert, VBtn, VSelect, VTextField } from 'vuetify/components';

import 'vuetify/styles';
const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    tertiary: '#4a607b',
    error: '#ba1a1a',
    danger: '#ba1a1a',
    gray: '#7d7d7d',
    light: '#f3f3f3',
    hint: '#ececec',
    main: '#1F2F49',
    white: '#ffffff',
    black: '#000000',
    background: '#fafdfc',
    outline: '#6f7978',
    'dark-gray': '#323639',
    'light-pink': '#F5EDFF',
    primary: '#00416b',
    header: '#00416b',
    success: '#00c08b',
    'gray-1': '#707070',
    secondary: '#00c08b',
    info: '#54c0e8',
    'dark-text': '#404040',
    blue: '#75ADDF',
    text: '#666666',
  },
  variables: {
    'high-emphasis-opacity': 0.87, // default 0.87
    'medium-emphasis-opacity': 0.6, // default 0.6
    'disabled-opacity': 0.38, // default 0.38
    'idle-opacity': 0.04, // default 0.04
    'hover-opacity': 0.04, // default 0.04
    'focus-opacity': 0.12, // default 0.12
    'selected-opacity': 0.08, // default 0.08
    'activated-opacity': 0.12, // default 0.12
    'pressed-opacity': 0.12, // default 0.12
    'dragged-opacity': 0.08, // default 0.08
  },
};

//TODO someone fix this pls, on refresh the header and background are messed up
const myCustomDarkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    black: '#252f38',
    primary: '#4e98bf', // The Old color was #1867C0 I used this one to make it more visible on the dark theme [Based On Material Design Color Docs]
    header: '#00416b',
    success: '#00c08b',
    'gray-1': '#707070',
    danger: '#d02c2f',
    secondary: '#00c08b',
    background: '#121212',
    light: '#393939',

    info: '#54c0e8',
    'dark-text': '#404040',
    blue: '#75ADDF',
    text: '#BDBDBD',
  },
  variables: {
    'high-emphasis-opacity': 0.87, // default 0.87
    'medium-emphasis-opacity': 0.6, // default 0.6
    'disabled-opacity': 0.38, // default 0.38
    'idle-opacity': 0.04, // default 0.04
    'hover-opacity': 0.04, // default 0.04
    'focus-opacity': 0.12, // default 0.12
    'selected-opacity': 0.08, // default 0.08
    'activated-opacity': 0.12, // default 0.12
    'pressed-opacity': 0.12, // default 0.12
    'dragged-opacity': 0.08, // default 0.08
  },
};
const savedTheme = localStorage.getItem('theme');

const vuetify = createVuetify({
  // https://vuetifyjs.com/en/features/treeshaking/
  blueprint: md3,
  theme: {
    defaultTheme:
      import.meta.env.VITE_HIDE_DARK_THEME === 'true'
        ? 'myCustomLightTheme'
        : savedTheme || 'myCustomLightTheme',
    // defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
      myCustomDarkTheme,
    },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 6,
      darken: 2,
    },
  },
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: vuetifyLocalization,
  },
  aliases: {
    // use vuetify components as baseline for your custom component
    VCustomTextField: VTextField,
    VCustomBtn: VBtn,
    VCancelBtn: VBtn,
    VError: VAlert,
    VOutlineField: VTextField,
    VOutlineSelect: VSelect,
    VBtnText: VBtn,
  },

  defaults: {
    VBtn: {
      class: 'text-capitalize',
      rounded: 'xs',
      color: '',
    },
    // set default prop values for vuetify components
    VCustomTextField: {
      class: 'flex-grow-0 glex-shrink-0',
      variant: 'outlined',
    },
    VCustomBtn: {
      color: 'primary',
      class: 'text-capitalize px-4 py-3 h-auto w-auto',
      size: 'default',
      'min-width': '200px',
      rounded: 'xs',
    },
    VCancelBtn: {
      variant: 'text',
      class: 'text-capitalize px-4 py-3 h-auto',
      size: 'default',
      rounded: 'xs',
      color: '',
    },
    VAutocomplete: {
      variant: 'outlined',
      density: 'default',
    },
    VTab: {
      class: 'text-capitalize',
    },
    VBtnText: {
      variant: 'text',
      class: 'text-capitalize text-subtitle-2',
      color: 'primary',
      rounded: 0,
      height: '35px',
    },
    VOutlineField: {
      variant: 'outlined',
      density: 'compact',
      class: '  rounded',
    },
    VOutlineSelect: {
      variant: 'outlined',
      density: 'compact',
      class: '  rounded',
    },
  },
});

export default vuetify;
