import { action_events as _action_events } from './action_events';
import { personal_access_tokens as _personal_access_tokens } from './personal_access_tokens';
import { failed_jobs as _failed_jobs } from './failed_jobs';
import { nova_notifications as _nova_notifications } from './nova_notifications';
import { NovaUsers as _NovaUsers } from './nova_users';
import { User as _User } from './user';
import { UserTranslation as _UserTranslation } from './user_translation';
import { UserRefreshToken as _UserRefreshToken } from './user_refresh_token';
import { UserTwoFactorAuthentication as _UserTwoFactorAuthentication } from './user_two_factor_authentication';
import { UserSingleSignOn as _UserSingleSignOn } from './user_single_sign_on';
import { UserApprovalRequest as _UserApprovalRequest } from './user_approval_request';
import { CoachDetails as _CoachDetails } from './coach_details';
import { CoachWorkExperience as _CoachWorkExperience } from './coach_work_experience';
import { CoachCertifications as _CoachCertifications } from './coach_certifications';
import { CoachEducation as _CoachEducation } from './coach_education';
import { CoachLanguages as _CoachLanguages } from './coach_languages';
import { CoachingSpecialization as _CoachingSpecialization } from './coaching_specialization';
import { CoachSpecializations as _CoachSpecializations } from './coach_specializations';
import { CoachingPositions as _CoachingPositions } from './coaching_positions';
import { CoachPositions as _CoachPositions } from './coach_positions';
import { Media as _Media } from './media';
import { MediaTranscript as _MediaTranscript } from './media_transcript';
import { SocialEvent as _SocialEvent } from './social_event';
import { SocialEventAttendanceItem as _SocialEventAttendanceItem } from './social_event_attendance_item';
import { SocialEventAttendance as _SocialEventAttendance } from './social_event_attendance';
import { SocialEventAttachment as _SocialEventAttachment } from './social_event_attachment';
import { SocialEventSeenBy as _SocialEventSeenBy } from './social_event_seen_by';
import { EventTranslation as _EventTranslation } from './event_translation';
import { EventHosts as _EventHosts } from './event_hosts';
import { UserEventAttendance as _UserEventAttendance } from './user_event_attendance';
import { UserInterestedEvents as _UserInterestedEvents } from './user_interested_events';
import { Guest as _Guest } from './guest';
import { SocialGroup as _SocialGroup } from './social_group';
import { UserGroupsMemberShips as _UserGroupsMemberShips } from './user_groups_member_ships';
import { Post as _Post } from './post';
import { PostMedia as _PostMedia } from './post_media';
import { PostLikes as _PostLikes } from './post_likes';
import { PostSaves as _PostSaves } from './post_saves';
import { PostComment as _PostComment } from './post_comment';
import { PostCommentsLike as _PostCommentsLike } from './post_comments_like';
import { LearningCategories as _LearningCategories } from './learning_categories';
import { Course as _Course } from './course';
import { UserCourseInterest as _UserCourseInterest } from './user_course_interest';
import { CourseTypes as _CourseTypes } from './course_types';
import { CourseTranslation as _CourseTranslation } from './course_translation';
import { CourseSection as _CourseSection } from './course_section';
import { CourseSectionPrerequisite as _CourseSectionPrerequisite } from './course_section_prerequisite';
import { CourseSubsection as _CourseSubsection } from './course_subsection';
import { SubsectionQuestionnaire as _SubsectionQuestionnaire } from './subsection_questionnaire';
import { SubsectionQuestion as _SubsectionQuestion } from './subsection_question';
import { SubsectionQuestionAnswer as _SubsectionQuestionAnswer } from './subsection_question_answer';
import { CourseOnCampusLecture as _CourseOnCampusLecture } from './course_on_campus_lecture';
import { CourseResourceAccessRestriction as _CourseResourceAccessRestriction } from './course_resource_access_restriction';
import { CourseResourceAccessRestrictionStudent as _CourseResourceAccessRestrictionStudent } from './course_resource_access_restriction_student';
import { CourseResourceAccessRestrictionGroup as _CourseResourceAccessRestrictionGroup } from './course_resource_access_restriction_group';
import { SubsectionNotes as _SubsectionNotes } from './subsection_notes';
import { SubsectionAttachments as _SubsectionAttachments } from './subsection_attachments';
import { CourseEnrollee as _CourseEnrollee } from './course_enrollee';
import { CourseInstructor as _CourseInstructor } from './course_instructor';
import { UserWishlistedCourse as _UserWishlistedCourse } from './user_wishlisted_course';
import { StudentFinishedCourseSubsections as _StudentFinishedCourseSubsections } from './student_finished_course_subsections';
import { StudentFinishedCourse as _StudentFinishedCourse } from './student_finished_course';
import { QoyodInvoice as _QoyodInvoice } from './qoyod_invoice';
import { QoyodUser as _QoyodUser } from './qoyod_user';
import { QoyodProduct as _QoyodProduct } from './qoyod_product';
import { OrderRefund as _OrderRefund } from './order_refund';
import { OrganizationRefundPolicy as _OrganizationRefundPolicy } from './organization_refund_policy';
import { RefundRule as _RefundRule } from './refund_rule';
import { OrgRefundRule as _OrgRefundRule } from './org_refund_rule';
import { RefundRestriction as _RefundRestriction } from './refund_restriction';
import { OrgRefundRestriction as _OrgRefundRestriction } from './org_refund_restriction';
import { Order as _Order } from './order';
import { OrderInvoice as _OrderInvoice } from './order_invoice';
import { FinancialStatus as _FinancialStatus } from './financial_status';
import { OrderPayment as _OrderPayment } from './order_payment';
import { PaymentMethod as _PaymentMethod } from './payment_method';
import { PurchasedItem as _PurchasedItem } from './purchased_item';
import { PurchasedItemMetadata as _PurchasedItemMetadata } from './purchased_item_metadata';
import { PurchasedItemType as _PurchasedItemType } from './purchased_item_type';
import { Voucher as _Voucher } from './voucher';
import { VoucherRestrictionByItemtype as _VoucherRestrictionByItemtype } from './voucher_restriction_by_itemtype';
import { VoucherRestrictionByOrganizationOrUser as _VoucherRestrictionByOrganizationOrUser } from './voucher_restriction_by_organization_or_user';
import { VoucherConsumption as _VoucherConsumption } from './voucher_consumption';
import { OttuPayment as _OttuPayment } from './ottu_payment';
import { PaymentWebhook as _PaymentWebhook } from './payment_webhook';
import { PaymentGateway as _PaymentGateway } from './payment_gateway';
import { PurchaseRedirectURL as _PurchaseRedirectURL } from './purchase_redirect_url';
import { UserResetPasswordAttempt as _UserResetPasswordAttempt } from './user_reset_password_attempt';
import { UserLoginAttempt as _UserLoginAttempt } from './user_login_attempt';
import { EmailSubscription as _EmailSubscription } from './email_subscription';
import { LibraryTags as _LibraryTags } from './library_tags';
import { LibraryTagsTranslation as _LibraryTagsTranslation } from './library_tags_translation';
import { LibraryMedia as _LibraryMedia } from './library_media';
import { LibraryMediaTranslation as _LibraryMediaTranslation } from './library_media_translation';
import { UserRecentSearches as _UserRecentSearches } from './user_recent_searches';
import { UserLastVisitedSubsection as _UserLastVisitedSubsection } from './user_last_visited_subsection';
import { CourseSurvey as _CourseSurvey } from './course_survey';
import { CourseAnnouncement as _CourseAnnouncement } from './course_announcement';
import { CourseAnnouncementsMedia as _CourseAnnouncementsMedia } from './course_announcements_media';
import { CourseAttendance as _CourseAttendance } from './course_attendance';
import { StudentCourseAttendance as _StudentCourseAttendance } from './student_course_attendance';
import { StudentCourseCertificate as _StudentCourseCertificate } from './student_course_certificate';
import { StudentCourseCertificateMedium as _StudentCourseCertificateMedium } from './student_course_certificate_medium';
import { CourseAssignment as _CourseAssignment } from './course_assignment';
import { AssignmentSubmissionType as _AssignmentSubmissionType } from './assignment_submission_type';
import { AssignmentSubmission as _AssignmentSubmission } from './assignment_submission';
import { AssignmentSubmissionMedia as _AssignmentSubmissionMedia } from './assignment_submission_media';
import { Rubric as _Rubric } from './rubric';
import { RubricCriterion as _RubricCriterion } from './rubric_criterion';
import { RubricCriterionRating as _RubricCriterionRating } from './rubric_criterion_rating';
import { CourseGrade as _CourseGrade } from './course_grade';
import { StudentCourseGrade as _StudentCourseGrade } from './student_course_grade';
import { StudentCourseGradeRubricAssessment as _StudentCourseGradeRubricAssessment } from './student_course_grade_rubric_assessment';
import { ColorKey as _ColorKey } from './color_key';
import { LinkKey as _LinkKey } from './link_key';
import { ConfigurationKey as _ConfigurationKey } from './configuration_key';
import { SendGridTemplate as _SendGridTemplate } from './send_grid_template';
import { Specialization as _Specialization } from './specialization';
import { SpecializationCourse as _SpecializationCourse } from './specialization_course';
import { SpecializationEnrollee as _SpecializationEnrollee } from './specialization_enrollee';
import { QuestionType as _QuestionType } from './question_type';
import { QuestionTypeDetails as _QuestionTypeDetails } from './question_type_details';
import { QuestionStemMedia as _QuestionStemMedia } from './question_stem_media';
import { QuestionStem as _QuestionStem } from './question_stem';
import { QuestionDifficultyLevel as _QuestionDifficultyLevel } from './question_difficulty_level';
import { SingleQuestionCategory as _SingleQuestionCategory } from './single_question_category';
import { SingleQuestionTag as _SingleQuestionTag } from './single_question_tag';
import { QuestionsTag as _QuestionsTag } from './questions_tag';
import { QuestionCategory as _QuestionCategory } from './question_category';
import { QuestionExplanation as _QuestionExplanation } from './question_explanation';
import { QuestionDetail as _QuestionDetail } from './question_detail';
import { QuestionAnswerKey as _QuestionAnswerKey } from './question_answer_key';
import { Question as _Question } from './question';
import { AnswerType as _AnswerType } from './answer_type';
import { AnswerStem as _AnswerStem } from './answer_stem';
import { AnswerExplanation as _AnswerExplanation } from './answer_explanation';
import { Answer as _Answer } from './answer';
import { Exam as _Exam } from './exam';
import { SubsectionExam as _SubsectionExam } from './subsection_exam';
import { ExamSettings as _ExamSettings } from './exam_settings';
import { QuestionRubricCriterion as _QuestionRubricCriterion } from './question_rubric_criterion';
import { StudentGradeForQuestionRubricCriterion as _StudentGradeForQuestionRubricCriterion } from './student_grade_for_question_rubric_criterion';
import { ExamGradingSetttings as _ExamGradingSetttings } from './exam_grading_setttings';
import { ExamQuestion as _ExamQuestion } from './exam_question';
import { ExamAttempt as _ExamAttempt } from './exam_attempt';
import { ExamAttemptEvent as _ExamAttemptEvent } from './exam_attempt_event';
import { ExamAttemptQuestion as _ExamAttemptQuestion } from './exam_attempt_question';
import { ExamInstance as _ExamInstance } from './exam_instance';
import { StudentSubsectionExamGrade as _StudentSubsectionExamGrade } from './student_subsection_exam_grade';
import { AnswerReview as _AnswerReview } from './answer_review';
import { ExamAttemptAnswer as _ExamAttemptAnswer } from './exam_attempt_answer';
import { LearningOutcome as _LearningOutcome } from './learning_outcome';
import { QuestionLearningOutcome as _QuestionLearningOutcome } from './question_learning_outcome';
import { ExamLearningOutcome as _ExamLearningOutcome } from './exam_learning_outcome';
import { LearningGroup as _LearningGroup } from './learning_group';
import { LearningGroupStudent as _LearningGroupStudent } from './learning_group_student';
import { CourseOrganizationLearningGroup as _CourseOrganizationLearningGroup } from './course_organization_learning_group';
import { DueDateType as _DueDateType } from './due_date_type';
import { Checklist as _Checklist } from './checklist';
import { ChecklistEnrollment as _ChecklistEnrollment } from './checklist_enrollment';
import { ChecklistSettings as _ChecklistSettings } from './checklist_settings';
import { StudentEnrolledChecklist as _StudentEnrolledChecklist } from './student_enrolled_checklist';
import { ChecklistLearningGroup as _ChecklistLearningGroup } from './checklist_learning_group';
import { ChecklistStudent as _ChecklistStudent } from './checklist_student';
import { ChecklistItem as _ChecklistItem } from './checklist_item';
import { ChecklistItemSettings as _ChecklistItemSettings } from './checklist_item_settings';
import { ChecklistItemStudentCompletion as _ChecklistItemStudentCompletion } from './checklist_item_student_completion';
import { StudentCompletedChecklist as _StudentCompletedChecklist } from './student_completed_checklist';
import { CourseOrganizationUser as _CourseOrganizationUser } from './course_organization_user';
import { ScormCourse as _ScormCourse } from './scorm_course';
import { ScormCourseRegistration as _ScormCourseRegistration } from './scorm_course_registration';
import { ScormCourseRegistrationProgress as _ScormCourseRegistrationProgress } from './scorm_course_registration_progress';
import { CourseDiscussionBoardItem as _CourseDiscussionBoardItem } from './course_discussion_board_item';
import { CourseDiscussionBoardItemSettings as _CourseDiscussionBoardItemSettings } from './course_discussion_board_item_settings';
import { CourseDiscussionBoardItemComment as _CourseDiscussionBoardItemComment } from './course_discussion_board_item_comment';
import { CourseDiscussionBoardItemCommentMedia as _CourseDiscussionBoardItemCommentMedia } from './course_discussion_board_item_comment_media';
import { UserReadCourseDiscussionBoardItemComment as _UserReadCourseDiscussionBoardItemComment } from './user_read_course_discussion_board_item_comment';
import { CourseDiscussionBoardItemCommentLike as _CourseDiscussionBoardItemCommentLike } from './course_discussion_board_item_comment_like';
import { CanvasCourse as _CanvasCourse } from './canvas_course';
import { ProviderBookingSettings as _ProviderBookingSettings } from './provider_booking_settings';
import { ProviderBusinnessDay as _ProviderBusinnessDay } from './provider_businness_day';
import { ProviderBusinessHour as _ProviderBusinessHour } from './provider_business_hour';
import { ProviderDisabledSlot as _ProviderDisabledSlot } from './provider_disabled_slot';
import { ProviderBookedSlot as _ProviderBookedSlot } from './provider_booked_slot';
import { ProviderReschedulingCredit as _ProviderReschedulingCredit } from './provider_rescheduling_credit';
import { CourseGroup as _CourseGroup } from './course_group';
import { CourseGroupStudent as _CourseGroupStudent } from './course_group_student';
import { GroupCourseGrade as _GroupCourseGrade } from './group_course_grade';
import { GroupCourseGradeRubricAssessment as _GroupCourseGradeRubricAssessment } from './group_course_grade_rubric_assessment';
import { TechnicalSupportTicket as _TechnicalSupportTicket } from './technical_support_ticket';
import { PlagiarismReport as _PlagiarismReport } from './plagiarism_report';
import { CopyLeaksReport as _CopyLeaksReport } from './copy_leaks_report';
import { VideoConferencingTool as _VideoConferencingTool } from './video_conferencing_tool';
import { VideoConferenceType as _VideoConferenceType } from './video_conference_type';
import { VideoConference as _VideoConference } from './video_conference';
import { ZoomMeeting as _ZoomMeeting } from './zoom_meeting';
import { ZoomWebinar as _ZoomWebinar } from './zoom_webinar';
import { CourseOnlineLecture as _CourseOnlineLecture } from './course_online_lecture';
import { Tooltip as _Tooltip } from './tooltip';
import { TooltipContent as _TooltipContent } from './tooltip_content';
import { LanguageCode as _LanguageCode } from './language_code';
import { ZoomOAuthToken as _ZoomOAuthToken } from './zoom_o_auth_token';
import { Organization as _Organization } from './organization';
import { BillingCycleType as _BillingCycleType } from './billing_cycle_type';
import { OrganizationPricingModel as _OrganizationPricingModel } from './organization_pricing_model';
import { OrganizationFuturePricingModel as _OrganizationFuturePricingModel } from './organization_future_pricing_model';
import { OrganizationMember as _OrganizationMember } from './organization_member';
import { OrganizationLink as _OrganizationLink } from './organization_link';
import { OrganizationColor as _OrganizationColor } from './organization_color';
import { OrganizationConfiguration as _OrganizationConfiguration } from './organization_configuration';
import { OrganizationTaxClass as _OrganizationTaxClass } from './organization_tax_class';
import { CourseTargetOrganization as _CourseTargetOrganization } from './course_target_organization';
import { CourseTargetOrganizationCreditUnit as _CourseTargetOrganizationCreditUnit } from './course_target_organization_credit_unit';
import { SpecializationTargetOrganization as _SpecializationTargetOrganization } from './specialization_target_organization';
import { SocialGroupTargetOrganization as _SocialGroupTargetOrganization } from './social_group_target_organization';
import { SocialEventTargetOrganization as _SocialEventTargetOrganization } from './social_event_target_organization';
import { ProviderTargetOrganization as _ProviderTargetOrganization } from './provider_target_organization';
import { TaxClass as _TaxClass } from './tax_class';
import { GradeMapping as _GradeMapping } from './grade_mapping';
import { GradingSchema as _GradingSchema } from './grading_schema';
import { Survey as _Survey } from './survey';
import { SurveySettings as _SurveySettings } from './survey_settings';
import { Poll as _Poll } from './poll';
import { PollChoice as _PollChoice } from './poll_choice';
import { PollVote as _PollVote } from './poll_vote';
import { UserPinnedCourse as _UserPinnedCourse } from './user_pinned_course';
import { CourseApprovalRequest as _CourseApprovalRequest } from './course_approval_request';
import { CertificateTemplate as _CertificateTemplate } from './certificate_template';
import { CourseSyllabus as _CourseSyllabus } from './course_syllabus';
import { FormFieldTypeModifier as _FormFieldTypeModifier } from './form_field_type_modifier';
import { FormFieldTypeAvailableModifier as _FormFieldTypeAvailableModifier } from './form_field_type_available_modifier';
import { FormFieldType as _FormFieldType } from './form_field_type';
import { FormField as _FormField } from './form_field';
import { OrganizationRegistrationFormField as _OrganizationRegistrationFormField } from './organization_registration_form_field';
import { OrganizationRegistrationFormFieldModifier as _OrganizationRegistrationFormFieldModifier } from './organization_registration_form_field_modifier';
import { UserFormFieldData as _UserFormFieldData } from './user_form_field_data';
import { PermissionContext as _PermissionContext } from './permission_context';
import { Permission as _Permission } from './permission';
import { PermissionPeer as _PermissionPeer } from './permission_peer';
import { PermissionPrerequisite as _PermissionPrerequisite } from './permission_prerequisite';
import { PermissionSet as _PermissionSet } from './permission_set';
import { PermissionSetPermission as _PermissionSetPermission } from './permission_set_permission';
import { PermissionCategory as _PermissionCategory } from './permission_category';
import { FontFamily as _FontFamily } from './font_family';
import { FontFamilyStyle as _FontFamilyStyle } from './font_family_style';
import { OrganizationFontFamily as _OrganizationFontFamily } from './organization_font_family';
import { TermKeyCategory as _TermKeyCategory } from './term_key_category';
import { TermKey as _TermKey } from './term_key';
import { TermKeySuggestion as _TermKeySuggestion } from './term_key_suggestion';
import { OrganizationTerm as _OrganizationTerm } from './organization_term';
import { OrganizationCreditUnitName as _OrganizationCreditUnitName } from './organization_credit_unit_name';
import { CoursePassingCriteria as _CoursePassingCriteria } from './course_passing_criteria';
import { UdacityCourse as _UdacityCourse } from './udacity_course';
import { CornellCourse as _CornellCourse } from './cornell_course';
import { ExternalCourseType as _ExternalCourseType } from './external_course_type';
import { ExternalCourse as _ExternalCourse } from './external_course';
import { ExternalCourseTargetOrganization as _ExternalCourseTargetOrganization } from './external_course_target_organization';
import { ExternalCourseEnrollment as _ExternalCourseEnrollment } from './external_course_enrollment';
import { ExternalCourseTargetOrganizationCreditUnit as _ExternalCourseTargetOrganizationCreditUnit } from './external_course_target_organization_credit_unit';
import { OrganizationAnnouncement as _OrganizationAnnouncement } from './organization_announcement';
import { OrganizationAnnouncementTranslation as _OrganizationAnnouncementTranslation } from './organization_announcement_translation';
import { OrganizationAnnouncementDismissedUser as _OrganizationAnnouncementDismissedUser } from './organization_announcement_dismissed_user';
import { ApiKey as _ApiKey } from './api_key';
import { SamlRequest as _SamlRequest } from './saml_request';
import { CourseReminderEmail as _CourseReminderEmail } from './course_reminder_email';
import { OrganizationFieldGroup as _OrganizationFieldGroup } from './organization_field_group';
import { OrganizationFieldSort as _OrganizationFieldSort } from './organization_field_sort';
import { CourseConfirmationCheckbox as _CourseConfirmationCheckbox } from './course_confirmation_checkbox';
import { CourseConfirmationCheckboxTranslation as _CourseConfirmationCheckboxTranslation } from './course_confirmation_checkbox_translation';
import { UserCourseConfirmationCheckbox as _UserCourseConfirmationCheckbox } from './user_course_confirmation_checkbox';
import { Article as _Article } from './article';
import { TopicTag as _TopicTag } from './topic_tag';
import { ArticleTag as _ArticleTag } from './article_tag';
import { GalleryMedium as _GalleryMedium } from './gallery_medium';

export namespace PrismaModel {
  export class action_events extends _action_events {}
  export class personal_access_tokens extends _personal_access_tokens {}
  export class failed_jobs extends _failed_jobs {}
  export class nova_notifications extends _nova_notifications {}
  export class NovaUsers extends _NovaUsers {}
  export class User extends _User {}
  export class UserTranslation extends _UserTranslation {}
  export class UserRefreshToken extends _UserRefreshToken {}
  export class UserTwoFactorAuthentication extends _UserTwoFactorAuthentication {}
  export class UserSingleSignOn extends _UserSingleSignOn {}
  export class UserApprovalRequest extends _UserApprovalRequest {}
  export class CoachDetails extends _CoachDetails {}
  export class CoachWorkExperience extends _CoachWorkExperience {}
  export class CoachCertifications extends _CoachCertifications {}
  export class CoachEducation extends _CoachEducation {}
  export class CoachLanguages extends _CoachLanguages {}
  export class CoachingSpecialization extends _CoachingSpecialization {}
  export class CoachSpecializations extends _CoachSpecializations {}
  export class CoachingPositions extends _CoachingPositions {}
  export class CoachPositions extends _CoachPositions {}
  export class Media extends _Media {}
  export class MediaTranscript extends _MediaTranscript {}
  export class SocialEvent extends _SocialEvent {}
  export class SocialEventAttendanceItem extends _SocialEventAttendanceItem {}
  export class SocialEventAttendance extends _SocialEventAttendance {}
  export class SocialEventAttachment extends _SocialEventAttachment {}
  export class SocialEventSeenBy extends _SocialEventSeenBy {}
  export class EventTranslation extends _EventTranslation {}
  export class EventHosts extends _EventHosts {}
  export class UserEventAttendance extends _UserEventAttendance {}
  export class UserInterestedEvents extends _UserInterestedEvents {}
  export class Guest extends _Guest {}
  export class SocialGroup extends _SocialGroup {}
  export class UserGroupsMemberShips extends _UserGroupsMemberShips {}
  export class Post extends _Post {}
  export class PostMedia extends _PostMedia {}
  export class PostLikes extends _PostLikes {}
  export class PostSaves extends _PostSaves {}
  export class PostComment extends _PostComment {}
  export class PostCommentsLike extends _PostCommentsLike {}
  export class LearningCategories extends _LearningCategories {}
  export class Course extends _Course {}
  export class UserCourseInterest extends _UserCourseInterest {}
  export class CourseTypes extends _CourseTypes {}
  export class CourseTranslation extends _CourseTranslation {}
  export class CourseSection extends _CourseSection {}
  export class CourseSectionPrerequisite extends _CourseSectionPrerequisite {}
  export class CourseSubsection extends _CourseSubsection {}
  export class SubsectionQuestionnaire extends _SubsectionQuestionnaire {}
  export class SubsectionQuestion extends _SubsectionQuestion {}
  export class SubsectionQuestionAnswer extends _SubsectionQuestionAnswer {}
  export class CourseOnCampusLecture extends _CourseOnCampusLecture {}
  export class CourseResourceAccessRestriction extends _CourseResourceAccessRestriction {}
  export class CourseResourceAccessRestrictionStudent extends _CourseResourceAccessRestrictionStudent {}
  export class CourseResourceAccessRestrictionGroup extends _CourseResourceAccessRestrictionGroup {}
  export class SubsectionNotes extends _SubsectionNotes {}
  export class SubsectionAttachments extends _SubsectionAttachments {}
  export class CourseEnrollee extends _CourseEnrollee {}
  export class CourseInstructor extends _CourseInstructor {}
  export class UserWishlistedCourse extends _UserWishlistedCourse {}
  export class StudentFinishedCourseSubsections extends _StudentFinishedCourseSubsections {}
  export class StudentFinishedCourse extends _StudentFinishedCourse {}
  export class QoyodInvoice extends _QoyodInvoice {}
  export class QoyodUser extends _QoyodUser {}
  export class QoyodProduct extends _QoyodProduct {}
  export class OrderRefund extends _OrderRefund {}
  export class OrganizationRefundPolicy extends _OrganizationRefundPolicy {}
  export class RefundRule extends _RefundRule {}
  export class OrgRefundRule extends _OrgRefundRule {}
  export class RefundRestriction extends _RefundRestriction {}
  export class OrgRefundRestriction extends _OrgRefundRestriction {}
  export class Order extends _Order {}
  export class OrderInvoice extends _OrderInvoice {}
  export class FinancialStatus extends _FinancialStatus {}
  export class OrderPayment extends _OrderPayment {}
  export class PaymentMethod extends _PaymentMethod {}
  export class PurchasedItem extends _PurchasedItem {}
  export class PurchasedItemMetadata extends _PurchasedItemMetadata {}
  export class PurchasedItemType extends _PurchasedItemType {}
  export class Voucher extends _Voucher {}
  export class VoucherRestrictionByItemtype extends _VoucherRestrictionByItemtype {}
  export class VoucherRestrictionByOrganizationOrUser extends _VoucherRestrictionByOrganizationOrUser {}
  export class VoucherConsumption extends _VoucherConsumption {}
  export class OttuPayment extends _OttuPayment {}
  export class PaymentWebhook extends _PaymentWebhook {}
  export class PaymentGateway extends _PaymentGateway {}
  export class PurchaseRedirectURL extends _PurchaseRedirectURL {}
  export class UserResetPasswordAttempt extends _UserResetPasswordAttempt {}
  export class UserLoginAttempt extends _UserLoginAttempt {}
  export class EmailSubscription extends _EmailSubscription {}
  export class LibraryTags extends _LibraryTags {}
  export class LibraryTagsTranslation extends _LibraryTagsTranslation {}
  export class LibraryMedia extends _LibraryMedia {}
  export class LibraryMediaTranslation extends _LibraryMediaTranslation {}
  export class UserRecentSearches extends _UserRecentSearches {}
  export class UserLastVisitedSubsection extends _UserLastVisitedSubsection {}
  export class CourseSurvey extends _CourseSurvey {}
  export class CourseAnnouncement extends _CourseAnnouncement {}
  export class CourseAnnouncementsMedia extends _CourseAnnouncementsMedia {}
  export class CourseAttendance extends _CourseAttendance {}
  export class StudentCourseAttendance extends _StudentCourseAttendance {}
  export class StudentCourseCertificate extends _StudentCourseCertificate {}
  export class StudentCourseCertificateMedium extends _StudentCourseCertificateMedium {}
  export class CourseAssignment extends _CourseAssignment {}
  export class AssignmentSubmissionType extends _AssignmentSubmissionType {}
  export class AssignmentSubmission extends _AssignmentSubmission {}
  export class AssignmentSubmissionMedia extends _AssignmentSubmissionMedia {}
  export class Rubric extends _Rubric {}
  export class RubricCriterion extends _RubricCriterion {}
  export class RubricCriterionRating extends _RubricCriterionRating {}
  export class CourseGrade extends _CourseGrade {}
  export class StudentCourseGrade extends _StudentCourseGrade {}
  export class StudentCourseGradeRubricAssessment extends _StudentCourseGradeRubricAssessment {}
  export class ColorKey extends _ColorKey {}
  export class LinkKey extends _LinkKey {}
  export class ConfigurationKey extends _ConfigurationKey {}
  export class SendGridTemplate extends _SendGridTemplate {}
  export class Specialization extends _Specialization {}
  export class SpecializationCourse extends _SpecializationCourse {}
  export class SpecializationEnrollee extends _SpecializationEnrollee {}
  export class QuestionType extends _QuestionType {}
  export class QuestionTypeDetails extends _QuestionTypeDetails {}
  export class QuestionStemMedia extends _QuestionStemMedia {}
  export class QuestionStem extends _QuestionStem {}
  export class QuestionDifficultyLevel extends _QuestionDifficultyLevel {}
  export class SingleQuestionCategory extends _SingleQuestionCategory {}
  export class SingleQuestionTag extends _SingleQuestionTag {}
  export class QuestionsTag extends _QuestionsTag {}
  export class QuestionCategory extends _QuestionCategory {}
  export class QuestionExplanation extends _QuestionExplanation {}
  export class QuestionDetail extends _QuestionDetail {}
  export class QuestionAnswerKey extends _QuestionAnswerKey {}
  export class Question extends _Question {}
  export class AnswerType extends _AnswerType {}
  export class AnswerStem extends _AnswerStem {}
  export class AnswerExplanation extends _AnswerExplanation {}
  export class Answer extends _Answer {}
  export class Exam extends _Exam {}
  export class SubsectionExam extends _SubsectionExam {}
  export class ExamSettings extends _ExamSettings {}
  export class QuestionRubricCriterion extends _QuestionRubricCriterion {}
  export class StudentGradeForQuestionRubricCriterion extends _StudentGradeForQuestionRubricCriterion {}
  export class ExamGradingSetttings extends _ExamGradingSetttings {}
  export class ExamQuestion extends _ExamQuestion {}
  export class ExamAttempt extends _ExamAttempt {}
  export class ExamAttemptEvent extends _ExamAttemptEvent {}
  export class ExamAttemptQuestion extends _ExamAttemptQuestion {}
  export class ExamInstance extends _ExamInstance {}
  export class StudentSubsectionExamGrade extends _StudentSubsectionExamGrade {}
  export class AnswerReview extends _AnswerReview {}
  export class ExamAttemptAnswer extends _ExamAttemptAnswer {}
  export class LearningOutcome extends _LearningOutcome {}
  export class QuestionLearningOutcome extends _QuestionLearningOutcome {}
  export class ExamLearningOutcome extends _ExamLearningOutcome {}
  export class LearningGroup extends _LearningGroup {}
  export class LearningGroupStudent extends _LearningGroupStudent {}
  export class CourseOrganizationLearningGroup extends _CourseOrganizationLearningGroup {}
  export class DueDateType extends _DueDateType {}
  export class Checklist extends _Checklist {}
  export class ChecklistEnrollment extends _ChecklistEnrollment {}
  export class ChecklistSettings extends _ChecklistSettings {}
  export class StudentEnrolledChecklist extends _StudentEnrolledChecklist {}
  export class ChecklistLearningGroup extends _ChecklistLearningGroup {}
  export class ChecklistStudent extends _ChecklistStudent {}
  export class ChecklistItem extends _ChecklistItem {}
  export class ChecklistItemSettings extends _ChecklistItemSettings {}
  export class ChecklistItemStudentCompletion extends _ChecklistItemStudentCompletion {}
  export class StudentCompletedChecklist extends _StudentCompletedChecklist {}
  export class CourseOrganizationUser extends _CourseOrganizationUser {}
  export class ScormCourse extends _ScormCourse {}
  export class ScormCourseRegistration extends _ScormCourseRegistration {}
  export class ScormCourseRegistrationProgress extends _ScormCourseRegistrationProgress {}
  export class CourseDiscussionBoardItem extends _CourseDiscussionBoardItem {}
  export class CourseDiscussionBoardItemSettings extends _CourseDiscussionBoardItemSettings {}
  export class CourseDiscussionBoardItemComment extends _CourseDiscussionBoardItemComment {}
  export class CourseDiscussionBoardItemCommentMedia extends _CourseDiscussionBoardItemCommentMedia {}
  export class UserReadCourseDiscussionBoardItemComment extends _UserReadCourseDiscussionBoardItemComment {}
  export class CourseDiscussionBoardItemCommentLike extends _CourseDiscussionBoardItemCommentLike {}
  export class CanvasCourse extends _CanvasCourse {}
  export class ProviderBookingSettings extends _ProviderBookingSettings {}
  export class ProviderBusinnessDay extends _ProviderBusinnessDay {}
  export class ProviderBusinessHour extends _ProviderBusinessHour {}
  export class ProviderDisabledSlot extends _ProviderDisabledSlot {}
  export class ProviderBookedSlot extends _ProviderBookedSlot {}
  export class ProviderReschedulingCredit extends _ProviderReschedulingCredit {}
  export class CourseGroup extends _CourseGroup {}
  export class CourseGroupStudent extends _CourseGroupStudent {}
  export class GroupCourseGrade extends _GroupCourseGrade {}
  export class GroupCourseGradeRubricAssessment extends _GroupCourseGradeRubricAssessment {}
  export class TechnicalSupportTicket extends _TechnicalSupportTicket {}
  export class PlagiarismReport extends _PlagiarismReport {}
  export class CopyLeaksReport extends _CopyLeaksReport {}
  export class VideoConferencingTool extends _VideoConferencingTool {}
  export class VideoConferenceType extends _VideoConferenceType {}
  export class VideoConference extends _VideoConference {}
  export class ZoomMeeting extends _ZoomMeeting {}
  export class ZoomWebinar extends _ZoomWebinar {}
  export class CourseOnlineLecture extends _CourseOnlineLecture {}
  export class Tooltip extends _Tooltip {}
  export class TooltipContent extends _TooltipContent {}
  export class LanguageCode extends _LanguageCode {}
  export class ZoomOAuthToken extends _ZoomOAuthToken {}
  export class Organization extends _Organization {}
  export class BillingCycleType extends _BillingCycleType {}
  export class OrganizationPricingModel extends _OrganizationPricingModel {}
  export class OrganizationFuturePricingModel extends _OrganizationFuturePricingModel {}
  export class OrganizationMember extends _OrganizationMember {}
  export class OrganizationLink extends _OrganizationLink {}
  export class OrganizationColor extends _OrganizationColor {}
  export class OrganizationConfiguration extends _OrganizationConfiguration {}
  export class OrganizationTaxClass extends _OrganizationTaxClass {}
  export class CourseTargetOrganization extends _CourseTargetOrganization {}
  export class CourseTargetOrganizationCreditUnit extends _CourseTargetOrganizationCreditUnit {}
  export class SpecializationTargetOrganization extends _SpecializationTargetOrganization {}
  export class SocialGroupTargetOrganization extends _SocialGroupTargetOrganization {}
  export class SocialEventTargetOrganization extends _SocialEventTargetOrganization {}
  export class ProviderTargetOrganization extends _ProviderTargetOrganization {}
  export class TaxClass extends _TaxClass {}
  export class GradeMapping extends _GradeMapping {}
  export class GradingSchema extends _GradingSchema {}
  export class Survey extends _Survey {}
  export class SurveySettings extends _SurveySettings {}
  export class Poll extends _Poll {}
  export class PollChoice extends _PollChoice {}
  export class PollVote extends _PollVote {}
  export class UserPinnedCourse extends _UserPinnedCourse {}
  export class CourseApprovalRequest extends _CourseApprovalRequest {}
  export class CertificateTemplate extends _CertificateTemplate {}
  export class CourseSyllabus extends _CourseSyllabus {}
  export class FormFieldTypeModifier extends _FormFieldTypeModifier {}
  export class FormFieldTypeAvailableModifier extends _FormFieldTypeAvailableModifier {}
  export class FormFieldType extends _FormFieldType {}
  export class FormField extends _FormField {}
  export class OrganizationRegistrationFormField extends _OrganizationRegistrationFormField {}
  export class OrganizationRegistrationFormFieldModifier extends _OrganizationRegistrationFormFieldModifier {}
  export class UserFormFieldData extends _UserFormFieldData {}
  export class PermissionContext extends _PermissionContext {}
  export class Permission extends _Permission {}
  export class PermissionPeer extends _PermissionPeer {}
  export class PermissionPrerequisite extends _PermissionPrerequisite {}
  export class PermissionSet extends _PermissionSet {}
  export class PermissionSetPermission extends _PermissionSetPermission {}
  export class PermissionCategory extends _PermissionCategory {}
  export class FontFamily extends _FontFamily {}
  export class FontFamilyStyle extends _FontFamilyStyle {}
  export class OrganizationFontFamily extends _OrganizationFontFamily {}
  export class TermKeyCategory extends _TermKeyCategory {}
  export class TermKey extends _TermKey {}
  export class TermKeySuggestion extends _TermKeySuggestion {}
  export class OrganizationTerm extends _OrganizationTerm {}
  export class OrganizationCreditUnitName extends _OrganizationCreditUnitName {}
  export class CoursePassingCriteria extends _CoursePassingCriteria {}
  export class UdacityCourse extends _UdacityCourse {}
  export class CornellCourse extends _CornellCourse {}
  export class ExternalCourseType extends _ExternalCourseType {}
  export class ExternalCourse extends _ExternalCourse {}
  export class ExternalCourseTargetOrganization extends _ExternalCourseTargetOrganization {}
  export class ExternalCourseEnrollment extends _ExternalCourseEnrollment {}
  export class ExternalCourseTargetOrganizationCreditUnit extends _ExternalCourseTargetOrganizationCreditUnit {}
  export class OrganizationAnnouncement extends _OrganizationAnnouncement {}
  export class OrganizationAnnouncementTranslation extends _OrganizationAnnouncementTranslation {}
  export class OrganizationAnnouncementDismissedUser extends _OrganizationAnnouncementDismissedUser {}
  export class ApiKey extends _ApiKey {}
  export class SamlRequest extends _SamlRequest {}
  export class CourseReminderEmail extends _CourseReminderEmail {}
  export class OrganizationFieldGroup extends _OrganizationFieldGroup {}
  export class OrganizationFieldSort extends _OrganizationFieldSort {}
  export class CourseConfirmationCheckbox extends _CourseConfirmationCheckbox {}
  export class CourseConfirmationCheckboxTranslation extends _CourseConfirmationCheckboxTranslation {}
  export class UserCourseConfirmationCheckbox extends _UserCourseConfirmationCheckbox {}
  export class Article extends _Article {}
  export class TopicTag extends _TopicTag {}
  export class ArticleTag extends _ArticleTag {}
  export class GalleryMedium extends _GalleryMedium {}

  export const extraModels = [
    action_events,
    personal_access_tokens,
    failed_jobs,
    nova_notifications,
    NovaUsers,
    User,
    UserTranslation,
    UserRefreshToken,
    UserTwoFactorAuthentication,
    UserSingleSignOn,
    UserApprovalRequest,
    CoachDetails,
    CoachWorkExperience,
    CoachCertifications,
    CoachEducation,
    CoachLanguages,
    CoachingSpecialization,
    CoachSpecializations,
    CoachingPositions,
    CoachPositions,
    Media,
    MediaTranscript,
    SocialEvent,
    SocialEventAttendanceItem,
    SocialEventAttendance,
    SocialEventAttachment,
    SocialEventSeenBy,
    EventTranslation,
    EventHosts,
    UserEventAttendance,
    UserInterestedEvents,
    Guest,
    SocialGroup,
    UserGroupsMemberShips,
    Post,
    PostMedia,
    PostLikes,
    PostSaves,
    PostComment,
    PostCommentsLike,
    LearningCategories,
    Course,
    UserCourseInterest,
    CourseTypes,
    CourseTranslation,
    CourseSection,
    CourseSectionPrerequisite,
    CourseSubsection,
    SubsectionQuestionnaire,
    SubsectionQuestion,
    SubsectionQuestionAnswer,
    CourseOnCampusLecture,
    CourseResourceAccessRestriction,
    CourseResourceAccessRestrictionStudent,
    CourseResourceAccessRestrictionGroup,
    SubsectionNotes,
    SubsectionAttachments,
    CourseEnrollee,
    CourseInstructor,
    UserWishlistedCourse,
    StudentFinishedCourseSubsections,
    StudentFinishedCourse,
    QoyodInvoice,
    QoyodUser,
    QoyodProduct,
    OrderRefund,
    OrganizationRefundPolicy,
    RefundRule,
    OrgRefundRule,
    RefundRestriction,
    OrgRefundRestriction,
    Order,
    OrderInvoice,
    FinancialStatus,
    OrderPayment,
    PaymentMethod,
    PurchasedItem,
    PurchasedItemMetadata,
    PurchasedItemType,
    Voucher,
    VoucherRestrictionByItemtype,
    VoucherRestrictionByOrganizationOrUser,
    VoucherConsumption,
    OttuPayment,
    PaymentWebhook,
    PaymentGateway,
    PurchaseRedirectURL,
    UserResetPasswordAttempt,
    UserLoginAttempt,
    EmailSubscription,
    LibraryTags,
    LibraryTagsTranslation,
    LibraryMedia,
    LibraryMediaTranslation,
    UserRecentSearches,
    UserLastVisitedSubsection,
    CourseSurvey,
    CourseAnnouncement,
    CourseAnnouncementsMedia,
    CourseAttendance,
    StudentCourseAttendance,
    StudentCourseCertificate,
    StudentCourseCertificateMedium,
    CourseAssignment,
    AssignmentSubmissionType,
    AssignmentSubmission,
    AssignmentSubmissionMedia,
    Rubric,
    RubricCriterion,
    RubricCriterionRating,
    CourseGrade,
    StudentCourseGrade,
    StudentCourseGradeRubricAssessment,
    ColorKey,
    LinkKey,
    ConfigurationKey,
    SendGridTemplate,
    Specialization,
    SpecializationCourse,
    SpecializationEnrollee,
    QuestionType,
    QuestionTypeDetails,
    QuestionStemMedia,
    QuestionStem,
    QuestionDifficultyLevel,
    SingleQuestionCategory,
    SingleQuestionTag,
    QuestionsTag,
    QuestionCategory,
    QuestionExplanation,
    QuestionDetail,
    QuestionAnswerKey,
    Question,
    AnswerType,
    AnswerStem,
    AnswerExplanation,
    Answer,
    Exam,
    SubsectionExam,
    ExamSettings,
    QuestionRubricCriterion,
    StudentGradeForQuestionRubricCriterion,
    ExamGradingSetttings,
    ExamQuestion,
    ExamAttempt,
    ExamAttemptEvent,
    ExamAttemptQuestion,
    ExamInstance,
    StudentSubsectionExamGrade,
    AnswerReview,
    ExamAttemptAnswer,
    LearningOutcome,
    QuestionLearningOutcome,
    ExamLearningOutcome,
    LearningGroup,
    LearningGroupStudent,
    CourseOrganizationLearningGroup,
    DueDateType,
    Checklist,
    ChecklistEnrollment,
    ChecklistSettings,
    StudentEnrolledChecklist,
    ChecklistLearningGroup,
    ChecklistStudent,
    ChecklistItem,
    ChecklistItemSettings,
    ChecklistItemStudentCompletion,
    StudentCompletedChecklist,
    CourseOrganizationUser,
    ScormCourse,
    ScormCourseRegistration,
    ScormCourseRegistrationProgress,
    CourseDiscussionBoardItem,
    CourseDiscussionBoardItemSettings,
    CourseDiscussionBoardItemComment,
    CourseDiscussionBoardItemCommentMedia,
    UserReadCourseDiscussionBoardItemComment,
    CourseDiscussionBoardItemCommentLike,
    CanvasCourse,
    ProviderBookingSettings,
    ProviderBusinnessDay,
    ProviderBusinessHour,
    ProviderDisabledSlot,
    ProviderBookedSlot,
    ProviderReschedulingCredit,
    CourseGroup,
    CourseGroupStudent,
    GroupCourseGrade,
    GroupCourseGradeRubricAssessment,
    TechnicalSupportTicket,
    PlagiarismReport,
    CopyLeaksReport,
    VideoConferencingTool,
    VideoConferenceType,
    VideoConference,
    ZoomMeeting,
    ZoomWebinar,
    CourseOnlineLecture,
    Tooltip,
    TooltipContent,
    LanguageCode,
    ZoomOAuthToken,
    Organization,
    BillingCycleType,
    OrganizationPricingModel,
    OrganizationFuturePricingModel,
    OrganizationMember,
    OrganizationLink,
    OrganizationColor,
    OrganizationConfiguration,
    OrganizationTaxClass,
    CourseTargetOrganization,
    CourseTargetOrganizationCreditUnit,
    SpecializationTargetOrganization,
    SocialGroupTargetOrganization,
    SocialEventTargetOrganization,
    ProviderTargetOrganization,
    TaxClass,
    GradeMapping,
    GradingSchema,
    Survey,
    SurveySettings,
    Poll,
    PollChoice,
    PollVote,
    UserPinnedCourse,
    CourseApprovalRequest,
    CertificateTemplate,
    CourseSyllabus,
    FormFieldTypeModifier,
    FormFieldTypeAvailableModifier,
    FormFieldType,
    FormField,
    OrganizationRegistrationFormField,
    OrganizationRegistrationFormFieldModifier,
    UserFormFieldData,
    PermissionContext,
    Permission,
    PermissionPeer,
    PermissionPrerequisite,
    PermissionSet,
    PermissionSetPermission,
    PermissionCategory,
    FontFamily,
    FontFamilyStyle,
    OrganizationFontFamily,
    TermKeyCategory,
    TermKey,
    TermKeySuggestion,
    OrganizationTerm,
    OrganizationCreditUnitName,
    CoursePassingCriteria,
    UdacityCourse,
    CornellCourse,
    ExternalCourseType,
    ExternalCourse,
    ExternalCourseTargetOrganization,
    ExternalCourseEnrollment,
    ExternalCourseTargetOrganizationCreditUnit,
    OrganizationAnnouncement,
    OrganizationAnnouncementTranslation,
    OrganizationAnnouncementDismissedUser,
    ApiKey,
    SamlRequest,
    CourseReminderEmail,
    OrganizationFieldGroup,
    OrganizationFieldSort,
    CourseConfirmationCheckbox,
    CourseConfirmationCheckboxTranslation,
    UserCourseConfirmationCheckbox,
    Article,
    TopicTag,
    ArticleTag,
    GalleryMedium,
  ];
}
