import { CourseConfirmationCheckbox } from './course_confirmation_checkbox';

export class CourseConfirmationCheckboxTranslation {
  id: number;

  language: string = 'en';

  text: string;

  courseConfirmationCheckboxId: number;

  courseConfirmationCheckbox: CourseConfirmationCheckbox;

  createdAt: Date;

  updatedAt?: Date;
}
