import { SocialEvent } from './social_event';
import { Language } from '../exported_type_enums/exported_type_enums';

export class EventTranslation {
  id: number;

  eventId: number;

  language: Language = Language.ENGLISH;

  title?: string;

  description?: string;

  targetAudience?: string;

  outline?: string;

  location?: string;

  createdAt: Date;

  updatedAt?: Date;

  event: SocialEvent;
}
