import { User } from './user';
import { Organization } from './organization';

export class ProviderTargetOrganization {
  id: number;

  providerId: number;

  provider: User;

  organizationId: number;

  organization: Organization;

  price: number;

  createdAt: Date;

  updatedAt?: Date;
}
