export class failed_jobs {
  id: BigInt;

  uuid: string;

  connection: string;

  queue: string;

  payload: string;

  exception: string;

  failed_at: Date;
}
