import { User } from './user';

export class ProviderBookingSettings {
  id: number;

  providerId: number;

  provider: User;

  duration: number = 60;

  mustBookInAdvance: boolean;

  inAdvanceHours: number;

  enableFreeSession: boolean;

  freeSessionDuration: number = 30;

  enableCancellation: boolean;

  cancellationMinimumHours: number;

  timezone: string = '00:00';

  maximumFutureDays: number = 365;

  createdAt: Date;

  updatedAt?: Date;
}
