import type { Paginated } from '@/helpers/interfaces/paginated.interface';
import { axiosI } from '@/plugins';
import type { OrganizationAnnouncement } from '@/submodules/generated_types/types/organization_announcement';
import { dashboardApiHandler } from '@/postman-to-ts/apiHandler/dashboard';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

export const useAnnouncementsStore = defineStore('announcementsStore', () => {
  const annmouncmentsItems: Ref<
    Paginated<OrganizationAnnouncement> | undefined
  > = ref();

  async function getAnnouncementsItems() {
    const { path, method } = dashboardApiHandler.announcements.getAnnouncements;

    try {
      const { data } = await axiosI<Paginated<OrganizationAnnouncement>>(
        path(),
        {
          method,
          params: {
            skip: 0,
            take: Number.MAX_SAFE_INTEGER,
            filterDismissed: 1,
            filterExpired: 1,
          },
        }
      );

      annmouncmentsItems.value = data;
      return data;
    } catch (e) {
      throw Error('Something went wrong');
    }
  }
  return { getAnnouncementsItems, annmouncmentsItems };
});
