<script setup lang="ts">
  import { inject, ref, type ComputedRef, type Ref } from 'vue';
  import LanguageToggle from './../../LanguageToggle.vue';
  import LoginAndRegister from './../../LoginAndRegister.vue';
  import SideMenuItems from './SideMenuItems.vue';

  const isLTR: Ref<boolean> = inject('isLTR', ref(true));
  const isLoggedIn: Ref<boolean> = inject('isLoggedIn', ref(false));
  defineProps<{ openMenu: boolean }>();
  const showLogin: Ref<boolean> = inject('showLogin', ref(false));
  const headerTitle: Ref<string> = inject('headerTitle', ref(''));
  const haveHeader: ComputedRef<boolean> | Ref<boolean> = inject(
    'haveHeader',
    ref(false)
  );
  const haveLanguageToggle: Ref<boolean> = inject(
    'haveLanguageToggle',
    ref(true)
  );
</script>

<template>
  <v-navigation-drawer
    temporary
    class="position-fixed nav-drawer px-2"
    aria-label="Menu"
    :model-value="openMenu"
    @update:model-value="$emit('update:openMenu', $event)"
    aria-owns="menu-drawer-items"
    role="listbox"
    :id="isLTR ? 'menu-drawer' : 'menu-drawer-rtl'"
  >
    <div class="d-flex justify-end my-2">
      <v-btn
        size="30"
        color="primary"
        aria-label="Close or open menu"
        role="option"
        :icon="!isLTR ? 'mdi-chevron-right' : 'mdi-chevron-left'"
        @click="$emit('update:openMenu', false)"
      ></v-btn>
    </div>
    <div v-if="haveHeader || $slots.headerSubtitle">
      <div
        v-if="haveHeader"
        :title="headerTitle"
        class="d-flex align-center ga-3 w-100 justify-start mb-3 px-2"
      >
        <slot v-if="$slots.prependIcon" name="prependIcon" />
        <div
          v-if="headerTitle"
          class="text-subtitle-1 font-weight-medium text-primary"
        >
          <span v-if="headerTitle?.length > 25">
            {{ headerTitle?.slice(0, 25) }}...
          </span>
          <span v-else>{{ headerTitle }}</span>
        </div>
        <slot v-if="$slots.appendIcon" name="appendIcon" />
      </div>
      <slot v-if="$slots.headerSubtitle" name="headerSubtitle" />
    </div>
    <div role="option">
      <SideMenuItems :rail="!openMenu" />
    </div>
    <template v-slot:append>
      <div v-if="!isLoggedIn" class="ma-2">
        <div role="option" class="mb-2" v-if="haveLanguageToggle">
          <v-list aria-label="Language Drawer Items" role="menu" class="pa-0">
            <LanguageToggle />
          </v-list>
        </div>
        <div
          class="d-flex w-100 flex-column gap-2 d-sm-none"
          role="option"
          aria-label="Login and register"
          v-if="showLogin"
        >
          <LoginAndRegister />
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<style lang="scss"></style>
../../LanguageToggle.vue../../LoginAndRegister.vue
../../LanguageToggle.vue../../LoginAndRegister.vue
