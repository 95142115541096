import { User } from './user';
import { Course } from './course';
import { StudentCourseCertificateMedium } from './student_course_certificate_medium';

export class StudentCourseCertificate {
  id: string;

  userId?: number;

  user?: User;

  courseId?: number;

  course?: Course;

  isIssued: boolean;

  createdAt: Date;

  updatedAt: Date;

  media: StudentCourseCertificateMedium[];
}
