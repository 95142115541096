export const financialSystemApiHandler = {
  users: {
    addToCart: {
      method: 'POST',
      path: (payload: { courseId: string | number }) =>
        `orders/add/${payload.courseId}`,
    },
    'create-test-order': {
      method: 'POST',
      path: () => `orders/test-order`,
    },
    requestRefund: {
      method: 'POST',
      path: (payload: { purchasedItemId: string | number }) =>
        `orders/refund/${payload.purchasedItemId}`,
    },
    getOrderById: {
      method: 'GET',
      path: (payload: { orderId: string | number }) =>
        `orders/${payload.orderId}`,
    },
    getCart: {
      method: 'GET',
      path: () => `orders/cart`,
    },
    paymentDisclosure: {
      method: 'POST',
      path: () => `orders/payment-cc`,
    },
    checkout: {
      method: 'POST',
      path: () => `orders/checkout`,
    },
    checkoutTamara: {
      method: 'POST',
      path: () => `orders/tamara-checkout`,
    },
    checkoutTabby: {
      method: 'POST',
      path: () => `orders/tabby-checkout`,
    },
    sendEmail: {
      method: 'POST',
      path: () => `orders/checkout`,
    },
    'migrateDONTTOUCH!': {
      method: 'POST',
      path: () => `orders/migrate`,
    },
    removeItemFromCart: {
      method: 'DELETE',
      path: (payload: { purchasedItemId: string | number }) =>
        `orders/${payload.purchasedItemId}`,
    },
    allOrders: {
      method: 'GET',
      path: () => `orders/all`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    allOrdersPaginated: {
      method: 'GET',
      path: () => `orders/all-v2`,
      params: {} as { skip?: string | number; take?: string | number },
    },
  },
  qoyod: {
    getAllCustomers: {
      method: 'GET',
      path: () => `qoyod`,
    },
    updateCustomer: {
      method: 'PUT',
      path: () => `qoyod`,
    },
    createInvoice: {
      method: 'POST',
      path: () => `qoyod/create-invoice`,
    },
  },
  vouchers: {
    createVoucher: {
      method: 'POST',
      path: () => `orders/create-voucher`,
    },
    applyVoucher: {
      method: 'POST',
      path: () => `orders/apply-voucher`,
    },
    removeVoucher: {
      method: 'POST',
      path: () => `orders/remove-voucher`,
    },
  },
};
