<script setup lang="ts">
  import { passwordValidator } from '@/composables/usePasswordValidator.composable';
  import { useShowToast } from '@/composables/useShowToast.composable';
  import { useI18 } from '@/plugins';
  import { useAuthStore } from '@/stores/auth.store';
  import { storeToRefs } from 'pinia';
  import { computed, ref } from 'vue';
  import ChangePasswordError from './ChangePasswordError.vue';

  const { t } = useI18();
  const emit = defineEmits(['close']);
  const currentPassword = ref('');
  const showCurrentPassword = ref(false);
  const newPassword = ref('');
  const showNewPassword = ref(false);
  const buttonLoading = ref(false);
  const changePasswordForm = ref(null);
  const passwordRules = [(v: string) => !!v || t('shared.thisFieldIsRequired')];
  const uppercaseError = ref(true);
  const lowercaseError = ref(true);
  const numberError = ref(true);
  const specialCharacterError = ref(true);
  const lengthError = ref(true);
  const matchError = ref(true);
  const confirmPassword = ref('');
  const showConfirmPassword = ref(false);
  const confirmPasswordError = ref(true);

  const checkError = () => {
    const errors = passwordValidator(newPassword.value);
    if (
      newPassword.value === currentPassword.value ||
      newPassword.value.length === 0 ||
      currentPassword.value.length === 0
    )
      matchError.value = true;
    else matchError.value = false;
    if (newPassword.value.length < 8) lengthError.value = true;
    else lengthError.value = false;
    if (errors.includes('uppercase')) uppercaseError.value = true;
    else uppercaseError.value = false;
    if (errors.includes('lowercase')) lowercaseError.value = true;
    else lowercaseError.value = false;
    if (errors.includes('number')) numberError.value = true;
    else numberError.value = false;
    if (errors.includes('special')) specialCharacterError.value = true;
    else specialCharacterError.value = false;
    if (confirmPassword.value === newPassword.value && newPassword.value.length)
      confirmPasswordError.value = false;
    else confirmPasswordError.value = true;
  };
  const error = ref('');

  const authStore = useAuthStore();
  const changePassword = async () => {
    error.value = '';
    if (
      !uppercaseError.value &&
      !lowercaseError.value &&
      !numberError.value &&
      !specialCharacterError.value &&
      !lengthError.value &&
      !matchError.value &&
      !confirmPasswordError.value
    ) {
      try {
        buttonLoading.value = true;
        const res = await authStore.changePassword({
          oldPassword: currentPassword.value,
          newPassword: newPassword.value,
        });
        switch (res.request.status) {
          case 200:
          case 201:
            useShowToast.success({
              msg: t('account.passwordChanged'),
            });
            emit('close');
            break;
          case 400:
          case 404:
            if (
              res?.response?.data?.message ==
              'New password cannot be the same as old'
            ) {
              error.value = t('account.newPasswordNotSameAsOld');
            } else {
              error.value = t('account.oldPasswordRule');
            }
            break;
          default:
            error.value = t('shared.somethingWentWrong');
            break;
        }
        if (error.value)
          useShowToast.error({
            msg: error.value,
          });
      } catch (error) {
        console.log(error);
      } finally {
        buttonLoading.value = false;
      }
    }
  };
  const { shouldResetPassword } = storeToRefs(authStore);
  const userName = computed(() => authStore.user?.firstName);
</script>

<template>
  <v-card class="ma-auto" max-width="600px" min-height="300px">
    <template v-slot:title>
      <div class="d-flex align-center justify-space-between">
        <p class="text-primary text-h5">
          {{ t('account.changePassword') }}
        </p>
        <v-btn
          v-if="!shouldResetPassword"
          @click="emit('close')"
          icon="mdi-close"
          variant="text"
          size="small"
        ></v-btn>
      </div>
    </template>
    <v-card-text>
      <div class="mb-5">
        <p class="text-subtitle-1">
          {{ t('account.changePasswordHintLabel') }}
        </p>
      </div>
      <v-form
        @submit.prevent="changePassword"
        validate-on="submit"
        ref="changePasswordForm"
      >
        <!-- add hidden username field for better accessibility -->
        <v-text-field
          hide-details
          :label="t('account.username')"
          :model-value="userName"
          autocomplete="username"
          class="d-none"
        ></v-text-field>
        <v-text-field
          hide-details
          class="mb-3"
          @input="checkError"
          :label="t('account.oldPassword')"
          :rules="passwordRules"
          v-model="currentPassword"
          :type="showCurrentPassword ? 'text' : 'password'"
          :append-inner-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showCurrentPassword = !showCurrentPassword"
          variant="outlined"
          autocomplete="old-password"
        ></v-text-field>
        <v-text-field
          hide-details
          class="mb-3"
          @input="checkError"
          :label="t('account.newPassword')"
          :rules="passwordRules"
          v-model="newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          :append-inner-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showNewPassword = !showNewPassword"
          variant="outlined"
          autocomplete="new-password"
        ></v-text-field>
        <v-text-field
          class="mb-3"
          hide-details
          @input="checkError"
          :rules="passwordRules"
          :label="t('formsFiled.confirmPassword')"
          v-model="confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showConfirmPassword = !showConfirmPassword"
          variant="outlined"
          autocomplete="confirm-new-password"
        ></v-text-field>

        <v-row>
          <ChangePasswordError
            :error="matchError"
            :error-text="t('account.matchError')"
          />
          <ChangePasswordError
            :error="lengthError"
            :error-text="t('account.lengthError')"
          />
          <ChangePasswordError
            :error="uppercaseError"
            :error-text="t('account.uppercaseError')"
          />
          <ChangePasswordError
            :error="lowercaseError"
            :error-text="t('account.lowerError')"
          />
          <ChangePasswordError
            :error="numberError"
            :error-text="t('account.numberError')"
          />
          <ChangePasswordError
            :error="specialCharacterError"
            :error-text="t('account.specialCharError')"
          />
          <ChangePasswordError
            :error="confirmPasswordError"
            :error-text="t('account.confirmPasswordError')"
          />
        </v-row>

        <div class="d-flex justify-end align-start my-3">
          <v-btn
            type="submit"
            :disabled="buttonLoading"
            :loading="buttonLoading"
            width="150px"
            color="primary"
          >
            {{ t('shared.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
