import { LearningGroup } from './learning_group';
import { User } from './user';

export class LearningGroupStudent {
  id: number;

  learningGroupId: number;

  learningGroup: LearningGroup;

  studentId: number;

  student: User;

  createdAt: Date;

  updatedAt?: Date;
}
