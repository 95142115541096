import { Organization } from './organization';
import { GradeMapping } from './grade_mapping';
import { Course } from './course';

export class GradingSchema {
  id: number;

  name?: string;

  isDefaultSchema: boolean;

  organizationId?: number;

  organization?: Organization;

  gradeMappings: GradeMapping[];

  courses: Course[];
}
