import { Organization } from './organization';
import { TermKey } from './term_key';
import { Media } from './media';

export class OrganizationTerm {
  id: number;

  organizationId: number;

  organization: Organization;

  termKeyId: number;

  termKey: TermKey;

  mediumId?: number;

  medium?: Media;

  language: string = 'en';

  value?: object;

  createdAt: Date;

  updatedAt?: Date;
}
