import { Order } from './order';
import { Organization } from './organization';

export class OrderInvoice {
  id: number;

  orderId: number;

  order: Order;

  finalAmount?: number;

  organizationId?: number;

  organization?: Organization;

  createdAt: Date;

  updatedAt: Date;
}
