import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const socialRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.social.eventRoot.path,
    children: [
      {
        path: '',
        name: EnumRoutes.social.events.name,
        component: () => import('@/views/social/events/EventsLayout.vue'),
        meta: EnumRoutes.social.events.meta,
        redirect: EnumRoutes.social.events.path,
        children: [
          {
            path: EnumRoutes.social.events.path,
            name: EnumRoutes.social.events.name,
            component: () =>
              import('@/views/social/events/SocialEventsPage.vue'),
            meta: EnumRoutes.social.events.meta,
          },
          {
            path: EnumRoutes.social.enrolledEvents.path,
            name: EnumRoutes.social.enrolledEvents.name,
            component: () =>
              import('@/views/social/events/EnrolledEventsPage.vue'),
            meta: EnumRoutes.social.enrolledEvents.meta,
          },
        ],
      },

      {
        path: EnumRoutes.social.eventPage.path,
        name: EnumRoutes.social.eventPage.name,
        component: () => import('@/views/social/events/SocialEventPage.vue'),
        meta: EnumRoutes.social.eventPage.meta,
      },
      {
        path: EnumRoutes.social.eventTicket.path,
        name: EnumRoutes.social.eventTicket.name,
        component: () => import('@/views/social/events/EventTicket.vue'),
        meta: EnumRoutes.social.eventTicket.meta,
      },
    ],
  },
  {
    name: EnumRoutes.social.socialGroups.name,
    path: EnumRoutes.social.socialGroups.path,
    meta: EnumRoutes.social.socialGroups.meta,
    redirect: EnumRoutes.social.enrolledGroups.path,
    component: () => import('@/views/social/groups/GroupLayout.vue'),
    children: [
      {
        path: EnumRoutes.social.enrolledGroups.path,
        name: EnumRoutes.social.enrolledGroups.name,
        meta: EnumRoutes.social.enrolledGroups.meta,
        component: () => import('@/views/social/groups/EnrolledGroupsPage.vue'),
      },
    ],
  },
  {
    path: EnumRoutes.social.groupPage.path,
    name: EnumRoutes.social.groupPage.name,
    component: () => import('@/views/social/groups/GroupPage.vue'),
    meta: EnumRoutes.social.groupPage.meta,
  },
  {
    path: EnumRoutes.social.postPage.path,
    name: EnumRoutes.social.postPage.name,
    component: () => import('@/views/social/groups/PostPage.vue'),
    meta: EnumRoutes.social.postPage.meta,
  },
];
