import { ExamAttemptAnswer } from './exam_attempt_answer';
import { Media } from './media';
import { User } from './user';
import { StudentGradeForQuestionRubricCriterion } from './student_grade_for_question_rubric_criterion';
import { PlagiarismReport } from './plagiarism_report';

export class AnswerReview {
  id: number;

  examAttemptAnswerId: number;

  examAttemptAnswer: ExamAttemptAnswer;

  isCorrect: boolean;

  points: number;

  isReviewed: boolean;

  studentAnswer?: string;

  studentUploadedMediaId?: number;

  studentUploadedMedia?: Media;

  instructorId?: number;

  instructor?: User;

  instructorReviewNote?: string;

  instructorReviewMediaId?: number;

  instructorReviewMedia?: Media;

  studentGradeForQuestionRubricCriteria: StudentGradeForQuestionRubricCriterion[];

  createdAt: Date;

  updatedAt?: Date;

  plagiarismReport?: PlagiarismReport;
}
