import { CourseOnCampusLecture } from './course_on_campus_lecture';
import { Media } from './media';
import { CourseDiscussionBoardItem } from './course_discussion_board_item';
import { CourseAssignment } from './course_assignment';
import { CourseOnlineLecture } from './course_online_lecture';
import { Course } from './course';
import { CourseSection } from './course_section';
import { SubsectionAttachments } from './subsection_attachments';
import { StudentFinishedCourseSubsections } from './student_finished_course_subsections';
import { UserLastVisitedSubsection } from './user_last_visited_subsection';
import { SubsectionNotes } from './subsection_notes';
import { SubsectionQuestionnaire } from './subsection_questionnaire';
import { SubsectionExam } from './subsection_exam';
import { ScormCourse } from './scorm_course';
import { Survey } from './survey';

export class CourseSubsection {
  id: number;

  sectionId: number;

  isPublished: boolean = true;

  isOptional: boolean;

  isTextOnly: boolean;

  name: string;

  description?: string;

  onCampusLectureId?: number;

  onCampusLecture?: CourseOnCampusLecture;

  fileId?: number;

  file?: Media;

  discussionBoardItemId?: number;

  discussionBoardItem?: CourseDiscussionBoardItem;

  assignmentId?: number;

  assignment?: CourseAssignment;

  onlineLectureId?: number;

  onlineLecture?: CourseOnlineLecture;

  weight: number = 1;

  courseId?: number;

  course?: Course;

  createdAt: Date;

  updatedAt: Date;

  section: CourseSection;

  subsectionAttachments: SubsectionAttachments[];

  usersWhoFinishedTheSubsection: StudentFinishedCourseSubsections[];

  usersOnThisSubsection: UserLastVisitedSubsection[];

  subsectionNotes: SubsectionNotes[];

  questionnaireId?: number;

  questionnaire?: SubsectionQuestionnaire;

  exam?: SubsectionExam;

  scormContent?: ScormCourse;

  surveyId?: number;

  survey?: Survey;
}
