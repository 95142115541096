import { FormFieldType } from './form_field_type';
import { OrganizationRegistrationFormField } from './organization_registration_form_field';
import { UserFormFieldData } from './user_form_field_data';

export class FormField {
  id: number;

  slug: string;

  name: string;

  description: string;

  fieldKey: string;

  typeSlug: string;

  type: FormFieldType;

  createdAt: Date;

  updatedAt?: Date;

  organizationRegistrationFormField: OrganizationRegistrationFormField[];

  usersData: UserFormFieldData[];
}
