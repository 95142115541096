import { LibraryMedia } from './library_media';
import { Media } from './media';
import { Language } from '../exported_type_enums/exported_type_enums';

export class LibraryMediaTranslation {
  id: number;

  libraryMediaId: number;

  libraryMedia: LibraryMedia;

  language: Language;

  name?: string;

  mediaId?: number;

  media?: Media;

  createdAt: Date;

  updatedAt: Date;
}
