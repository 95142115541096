export class personal_access_tokens {
  id: BigInt;

  tokenable_type: string;

  tokenable_id: BigInt;

  name: string;

  token: string;

  abilities?: string;

  last_used_at?: Date;

  created_at?: Date;

  updated_at?: Date;
}
