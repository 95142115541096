import { ExamAttempt } from './exam_attempt';
import { ExamQuestion } from './exam_question';
import { ExamAttemptAnswer } from './exam_attempt_answer';

export class ExamAttemptQuestion {
  id: number;

  examAttemptId: number;

  index: number = 1;

  examAttempt: ExamAttempt;

  examQuestionId: number;

  examQuestion: ExamQuestion;

  studentAnswer: ExamAttemptAnswer[];

  parentQuestionId?: number;

  parentQuestion?: ExamAttemptQuestion;

  childQuestions: ExamAttemptQuestion[];

  createdAt: Date;

  updatedAt?: Date;
}
