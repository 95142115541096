import type { OrganizationTerminology } from '@/helpers/interfaces/organization.term.interface';
import { pinia } from '@/pinia';
import { axiosI } from '@/plugins';
import { Organization } from '@/submodules/generated_types/types/organization';
import { ApiHandler } from '@/postman-to-ts/apiHandler';
import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';
import {
  EnumOrganizationConfig,
  EnumOrganizationTerminology,
} from './../helpers/enums/organization-config.enum';
import { useLocaleStore } from './locale.store';
import type { Media } from '@/submodules/generated_types/types/media';
export const useOrganizationStore = defineStore('organization', () => {
  const organization: Ref<Organization | null> = ref(null);
  const twoFactorEnabled: Ref<boolean> = ref(false);
  const registrationRequiresApproval: Ref<{
    domain: string;
    strategy: string;
  } | null> = ref(null);
  const marketplaceEnabled: Ref<boolean> = ref(false);
  const selfEnrollmentEnabled: Ref<boolean> = ref(false);
  const allowAnonymousCourseView: Ref<boolean> = ref(false);
  const allowAnonymousEventView: Ref<boolean> = ref(false);
  const certificatesEnabled: Ref<boolean> = ref(false);
  const passingCriteriaConfigurationEnabledForAdmins: Ref<boolean> = ref(false);
  const passingCriteriaConfigurationEnabledForInstructors: Ref<boolean> =
    ref(false);
  const selfRegistrationEnabled: Ref<boolean> = ref(false);
  const organizationTerminology: Ref<
    Record<string, OrganizationTerminology[]>
  > = ref({});
  const loginPageImage: Ref<string> = ref('');
  const loginOrganizationLogo: Ref<string> = ref('');
  const courseBannerImage: Ref<string> = ref('');
  const eventBannerImage: Ref<string> = ref('');
  const courseBannerTitle: Ref<string> = ref('');
  const courseBannerSubtitle: Ref<string> = ref('');
  const eventBannerTitle: Ref<string> = ref('');
  const eventBannerSubtitle: Ref<string> = ref('');
  const externalCourseBannerImage: Ref<string> = ref('');
  const externalCourseBannerTitle: Ref<string> = ref('');
  const externalCourseBannerSubtitle: Ref<string> = ref('');

  const studentText: Ref<string> = ref('');
  const studentsText: Ref<string> = ref('');
  const moduleText: Ref<string> = ref('');
  const modulesText: Ref<string> = ref('');
  const instructorText: Ref<string> = ref('');
  const instructorsText: Ref<string> = ref('');
  const courseText: Ref<string> = ref('');
  const coursesText: Ref<string> = ref('');
  const blendedText: Ref<string> = ref('');
  const dashboardText: Ref<string> = ref('');
  const enrollmentTermAndConditions: Ref<string> = ref('');
  const callOrganization = async () => {
    try {
      const { path, method } = ApiHandler.auth.getOrganization;
      const { data } = await axiosI<Organization>(path(), {
        method,
      });
      organization.value = data;
      setOrganizationConfig();
    } catch (error) {
      console.log(error);
    }
  };
  const setOrganizationConfig = () => {
    organization.value?.config.forEach((config) => {
      switch (config.configurationKey.key) {
        case EnumOrganizationConfig.TWO_FACTOR_ENABLED:
          twoFactorEnabled.value = !!config.value;
          break;
        case EnumOrganizationConfig.REGISTRATION_REQUIRES_APPROVAL:
          registrationRequiresApproval.value = {
            domain:
              (
                config.value as {
                  domain: string;
                  strategy: string;
                }
              )?.domain || '',
            strategy:
              (
                config.value as {
                  domain: string;
                  strategy: string;
                }
              )?.strategy || '',
          };
          break;
        case EnumOrganizationConfig.MARKETPLACE_ENABLED:
          marketplaceEnabled.value = !!config.value;
          break;
        case EnumOrganizationConfig.SELF_ENROLLMENT_ENABLED:
          selfEnrollmentEnabled.value = !!config.value;
          break;
        case EnumOrganizationConfig.ALLOW_ANONYMOUS_COURSE_VIEW:
          allowAnonymousCourseView.value = !!config.value;
          break;
        case EnumOrganizationConfig.ALLOW_ANONYMOUS_EVENT_VIEW:
          allowAnonymousEventView.value = !!config.value;
          break;
        case EnumOrganizationConfig.CERTIFICATES_ENABLED:
          certificatesEnabled.value = !!config.value;
          break;
        case EnumOrganizationConfig.PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_ADMINS:
          passingCriteriaConfigurationEnabledForAdmins.value = !!config.value;
          break;
        case EnumOrganizationConfig.PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_INSTRUCTORS:
          passingCriteriaConfigurationEnabledForInstructors.value =
            !!config.value;
          break;
        case EnumOrganizationConfig.SELF_REGISTRATION_ENABLED:
          selfRegistrationEnabled.value = !!config.value;
          break;
      }
    });
  };
  const getOrganizationTerminology = async () => {
    try {
      const { path, method } =
        ApiHandler.organizations.getOrganizationsTerminology;
      const { data } = await axiosI<Record<string, OrganizationTerminology[]>>(
        path(),
        { method }
      );
      organizationTerminology.value = data;
      setOrganizationTerms();
      return data;
    } catch (error) {
      return error;
    }
  };
  const getMediaValue: (
    term?: OrganizationTerminology,
    localeKey?: 'ar' | 'en'
  ) => Media | undefined | null = (
    term?: OrganizationTerminology,
    localeKey?: 'ar' | 'en'
  ) => {
    const locale = localeKey || (useLocaleStore(pinia).locale as 'ar' | 'en');
    if (term?.defaultValue?.isRequired) {
      let value: Media | undefined | null = undefined;
      term.organizationTerms?.forEach((termKey) => {
        if (termKey.language == locale) {
          value = termKey.medium || term.defaultValue.media || undefined;
        }
      });
      return value || undefined;
    } else {
      if (term?.organizationTerms?.length) {
        let value: Media | undefined | null = undefined;
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale) {
            value = termKey.medium || undefined;
          }
        });
        return value || undefined;
      }
      // special case for login organization logo
      else if (
        term?.slug === EnumOrganizationTerminology.LOGIN_ORGANIZATION_LOGO
      )
        return organization.value?.logo;
      else return term?.defaultValue.media || undefined;
    }
  };
  const getValue: (
    isSingular: boolean,
    term?: OrganizationTerminology,
    localeKey?: 'ar' | 'en'
  ) => string = (
    isSingular: boolean,
    term?: OrganizationTerminology,
    localeKey?: 'ar' | 'en'
  ) => {
    const locale = localeKey || (useLocaleStore(pinia).locale as 'ar' | 'en');
    if (term?.defaultValue?.isRequired) {
      if (!term.organizationTerms?.length) {
        return (
          (isSingular
            ? term.defaultValue[locale].singular
            : term.defaultValue[locale].plural) || ''
        );
      } else {
        let value = '';
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale) {
            value =
              (isSingular ? termKey.value.singular : termKey.value.plural) ||
              (isSingular
                ? term.defaultValue[locale].singular
                : term.defaultValue[locale].plural) ||
              '';
          }
        });
        return value;
      }
    } else {
      if (term?.organizationTerms?.length) {
        let value = '';
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale)
            value =
              (isSingular ? termKey.value.singular : termKey.value.plural) ||
              '';
        });
        return value;
      } else
        return (
          (isSingular
            ? term?.defaultValue[locale].singular
            : term?.defaultValue[locale].plural) || ''
        );
    }
  };
  const setOrganizationTerms = () => {
    /** >>>>>>>>>>>>>>> Login Page Image <<<<<<<<<<<<<<< **/
    loginPageImage.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.AUTH_IMAGE
        ]?.[0]
      )?.url || '';
    loginOrganizationLogo.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.LOGIN_ORGANIZATION_LOGO
        ]?.[0]
      )?.url || '';

    /** >>>>>>>>>>>>>>> External Course Banner <<<<<<<<<<<<<<< **/
    //>>>>>>> Image
    externalCourseBannerImage.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.EXTERNAL_COURSE_HEADER_IMAGE
        ]?.[0]
      )?.url || '';
    //>>>>>>> Title

    externalCourseBannerTitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.EXTERNAL_COURSE_BANNER_TITLE
        ]?.[0]
      ) || '';
    //>>>>>>> Subtitle
    externalCourseBannerSubtitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.EXTERNAL_COURSE_BANNER_SUBTITLE
        ]?.[0]
      ) || '';
    /** >>>>>>>>>>>>>>> Course Banner <<<<<<<<<<<<<<< **/
    //>>>>>>> Image
    courseBannerImage.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.COURSE_HEADER_IMAGE
        ]?.[0]
      )?.url || '';
    //>>>>>>> Title

    courseBannerTitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.COURSE_BANNER_TITLE
        ]?.[0]
      ) || '';
    //>>>>>>> Subtitle
    courseBannerSubtitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.COURSE_BANNER_SUBTITLE
        ]?.[0]
      ) || '';
    /** >>>>>>>>>>>>>>> Event Banner <<<<<<<<<<<<<<< **/
    //>>>>>>> Image
    eventBannerImage.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.EVENT_HEADER_IMAGE
        ]?.[0]
      )?.url || '';
    //>>>>>>> Title
    eventBannerTitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.EVENT_BANNER_TITLE
        ]?.[0]
      ) || '';
    //>>>>>>> Subtitle
    eventBannerSubtitle.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.EVENT_BANNER_SUBTITLE
        ]?.[0]
      ) || '';
    /** >>>>>>>>>>>>>>> Text Keys <<<<<<<<<<<<<<< **/
    //>>>>>>> Blended
    blendedText.value =
      getValue(
        true,
        organizationTerminology.value[EnumOrganizationTerminology.BLENDED]?.[0]
      ) || '';
    //>>>>>>> Course
    courseText.value =
      getValue(
        true,
        organizationTerminology.value[EnumOrganizationTerminology.COURSE]?.[0]
      ) || '';
    coursesText.value =
      getValue(
        false,
        organizationTerminology.value[EnumOrganizationTerminology.COURSE]?.[0]
      ) || '';
    //>>>>>>> Dashboard
    dashboardText.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.DASHBOARD
        ]?.[0]
      ) || '';
    //>>>>>>> Instructor
    instructorText.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.INSTRUCTOR
        ]?.[0]
      ) || '';
    instructorsText.value =
      getValue(
        false,
        organizationTerminology.value[
          EnumOrganizationTerminology.INSTRUCTOR
        ]?.[0]
      ) || '';
    //>>>>>>> Module
    moduleText.value =
      getValue(
        true,
        organizationTerminology.value[EnumOrganizationTerminology.MODULE]?.[0]
      ) || '';
    modulesText.value =
      getValue(
        false,
        organizationTerminology.value[EnumOrganizationTerminology.MODULE]?.[0]
      ) || '';
    //>>>>>>> Student
    studentText.value =
      getValue(
        true,
        organizationTerminology.value[EnumOrganizationTerminology.STUDENT]?.[0]
      ) || '';
    studentsText.value =
      getValue(
        false,
        organizationTerminology.value[EnumOrganizationTerminology.STUDENT]?.[0]
      ) || '';

    enrollmentTermAndConditions.value =
      getValue(
        true,
        organizationTerminology.value[
          EnumOrganizationTerminology.ENROLLMENT_TERMS_AND_CONDITIONS
        ]?.[0]
      ) || '';
  };
  return {
    organization,
    callOrganization,
    twoFactorEnabled,
    registrationRequiresApproval,
    marketplaceEnabled,
    selfEnrollmentEnabled,
    allowAnonymousCourseView,
    allowAnonymousEventView,
    certificatesEnabled,
    passingCriteriaConfigurationEnabledForAdmins,
    passingCriteriaConfigurationEnabledForInstructors,
    selfRegistrationEnabled,
    getOrganizationTerminology,
    loginPageImage,
    courseBannerImage,
    eventBannerImage,
    courseBannerTitle,
    courseBannerSubtitle,
    eventBannerTitle,
    eventBannerSubtitle,
    studentText,
    studentsText,
    moduleText,
    modulesText,
    instructorText,
    instructorsText,
    courseText,
    coursesText,
    blendedText,
    dashboardText,
    setOrganizationTerms,
    loginOrganizationLogo,
    enrollmentTermAndConditions,
    externalCourseBannerImage,
    externalCourseBannerSubtitle,
    externalCourseBannerTitle,
  };
});
