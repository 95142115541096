import { User } from './user';

export class ProviderReschedulingCredit {
  id: number;

  credit: number;

  userId: number;

  user: User;

  providerId: number;

  provider: User;

  createdAt: Date;

  updatedAt?: Date;
}
