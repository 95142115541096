import { Poll } from './poll';
import { Media } from './media';
import { PollVote } from './poll_vote';
import { User } from './user';

export class PollChoice {
  id: number;

  text?: string;

  pollId: number;

  poll: Poll;

  mediaId?: number;

  media?: Media;

  votes: PollVote[];

  userId?: number;

  user?: User;

  addedByStudent: boolean;

  createdAt: Date;

  updatedAt?: Date;
}
