import { TermKey } from './term_key';

export class TermKeySuggestion {
  id: number;

  termKeyId: number;

  termKey: TermKey;

  language: string = 'en';

  value: string;

  createdAt: Date;

  updatedAt?: Date;
}
