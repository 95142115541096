import { User } from './user';
import { PostComment } from './post_comment';

export class PostCommentsLike {
  id: number;

  userId: number;

  commentId: number;

  user: User;

  comment: PostComment;

  createdAt: Date;

  updatedAt: Date;
}
