import { Guest } from './guest';
import { EventHosts } from './event_hosts';
import { Media } from './media';
import { User } from './user';
import { SocialGroup } from './social_group';
import { Organization } from './organization';
import { UserEventAttendance } from './user_event_attendance';
import { UserInterestedEvents } from './user_interested_events';
import { Post } from './post';
import { EventTranslation } from './event_translation';
import { SocialEventTargetOrganization } from './social_event_target_organization';
import { SocialEventSeenBy } from './social_event_seen_by';
import { SocialEventAttachment } from './social_event_attachment';
import { SocialEventAttendanceItem } from './social_event_attendance_item';
import {
  EventType,
  Language,
} from '../exported_type_enums/exported_type_enums';

export class SocialEvent {
  id: number;

  title: string;

  normalizedTitle?: string;

  creditHours: number;

  ceu: number;

  description: string;

  targetAudience?: string;

  outline?: string;

  type: EventType = EventType.ONLINE;

  language: Language = Language.ENGLISH;

  isPublished: boolean = true;

  maxEnrollmentCount?: number;

  guests: Guest[];

  eventHosts: EventHosts[];

  mediaId?: number;

  media?: Media;

  createdById: number;

  createdBy: User;

  startTime: Date;

  endingTime: Date;

  startEnrollmentDate?: Date;

  endEnrollmentDate?: Date;

  meetingUrl?: string;

  longitude?: number;

  latitude?: number;

  location?: string;

  createdAt: Date;

  updatedAt: Date;

  groupId?: number;

  group?: SocialGroup;

  organizationId?: number;

  organization?: Organization;

  isDeleted: boolean;

  attendees: UserEventAttendance[];

  interestedUsers: UserInterestedEvents[];

  postsMentioningThisEvent: Post[];

  translations: EventTranslation[];

  targetOrganizations: SocialEventTargetOrganization[];

  seenBy: SocialEventSeenBy[];

  attachments: SocialEventAttachment[];

  attendanceItems: SocialEventAttendanceItem[];
}
