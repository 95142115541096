import { EnumRoutes } from '@/helpers/enums/routes.enum';
import type { RouteRecordRaw } from 'vue-router';

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: EnumRoutes.auth.root.path,
    name: EnumRoutes.auth.root.name,
    redirect: '/auth/login',
    component: () => import('@/views/auth/AuthLayoutBasic.vue'),
    meta: EnumRoutes.auth.root.meta,
    children: [
      {
        name: EnumRoutes.auth.login.name,
        path: EnumRoutes.auth.login.path,
        component: () => import('@/views/auth/Login.vue'),
        meta: EnumRoutes.auth.login.meta,
      },

      {
        name: EnumRoutes.auth.twoFactorVerification.name,
        path: EnumRoutes.auth.twoFactorVerification.path,
        component: () => import('@/views/auth/VerificationCode.vue'),
        meta: EnumRoutes.auth.twoFactorVerification.meta,
      },
      {
        name: EnumRoutes.auth.forgotPassword.name,
        path: EnumRoutes.auth.forgotPassword.path,
        component: () => import('@/views/auth/ForgetPassword.vue'),
        meta: EnumRoutes.auth.forgotPassword.meta,
      },
      {
        name: EnumRoutes.auth.resetPassword.name,
        path: EnumRoutes.auth.resetPassword.path,
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: EnumRoutes.auth.resetPassword.meta,
      },
      {
        name: EnumRoutes.auth.verifyEmail.name,
        path: EnumRoutes.auth.verifyEmail.path,
        component: () => import('@/views/auth/VerifyEmail.vue'),
        meta: EnumRoutes.auth.verifyEmail.meta,
      },
    ],
  },
  {
    name: EnumRoutes.auth.register.name,
    path: EnumRoutes.auth.register.path,
    component: () =>
      import('@/views/auth/dynamic-register-form/RegisterForm.vue'),
    meta: EnumRoutes.auth.register.meta,
  },
];
