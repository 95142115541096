import { User } from './user';
import { CourseTargetOrganization } from './course_target_organization';

export class CourseOrganizationUser {
  id: number;

  userId: number;

  user: User;

  courseOrganizationId: number;

  courseOrganization: CourseTargetOrganization;

  createdAt: Date;

  updatedAt?: Date;
}
