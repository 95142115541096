import { CourseGrade } from './course_grade';
import { User } from './user';
import { ExamInstance } from './exam_instance';
import { StudentCourseGrade } from './student_course_grade';

export class StudentSubsectionExamGrade {
  id: number;

  courseGradeId: number;

  courseGrade: CourseGrade;

  studentId: number;

  student: User;

  examInstanceId: number;

  examInstance: ExamInstance;

  grade: number;

  isPassed: boolean;

  studentCourseGradeId?: number;

  studentCourseGrade?: StudentCourseGrade;

  createdAt: Date;

  updatedAt?: Date;
}
