import { CourseDiscussionBoardItem } from './course_discussion_board_item';

export class CourseDiscussionBoardItemSettings {
  id: number;

  isLocked: boolean;

  unlockDate?: Date;

  lockDate?: Date;

  courseDiscussionBoardItemId: number;

  courseDiscussionBoardItem: CourseDiscussionBoardItem;

  createdAt: Date;

  updatedAt: Date;
}
