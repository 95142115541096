import { Course } from './course';
import { User } from './user';

export class UserWishlistedCourse {
  id: number;

  courseId: number;

  course: Course;

  userId: number;

  user: User;

  createdAt: Date;

  updatedAt: Date;
}
