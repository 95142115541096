import { Media } from './media';
import { Post } from './post';

export class PostMedia {
  id: number;

  mediaId: number;

  media: Media;

  postId: number;

  post: Post;

  createdAt: Date;

  updatedAt: Date;
}
